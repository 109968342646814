<template>
    <div style="display: inline-block">
        <!-- 下拉框 -->
        <span v-if="selectData.label" class="sn_search_label">{{
            selectData.label
        }}</span>
        <el-select
            v-show="selectData && selectData.isShow"
            v-model="currentValue"
            size="mini"
            class="option"
            :class="selectData.class"
            :placeholder="selectData.placeholder"
            :multiple="selectData.multiple || false"
            collapse-tags
            :clearable="selectData.clearable || false"
            filterable
            :popper-append-to-body="false"
            :style="{ width: selectData.width || null }"
            @change="
                (val) =>
                    handleChange(selectData.operateType, [
                        val,
                        selectData.value,
                    ])
            "
        >
            <el-option
                v-for="(v, index) in selectData.list"
                :key="index"
                :value="v[selectData.optionValue] || index"
                :label="v[selectData.optionLabel] || v"
            ></el-option>
        </el-select>
    </div>
</template>
<script>
export default {
    name: "sn_search_item",
    data() {
        return {
            currentValue: "",
        };
    },
    props: {
        selectData: {
            default: () => {
                return {};
            },
        },
    },
    watch: {
        selectData: {
            deep: true,
            immediate: true,
            handler() {
                if (this.selectData.default) {
                    this.currentValue = this.selectData.default;
                } else if (this.selectData.selectValue) {
                    this.currentValue = this.selectData.selectValue;
                }
            },
        },
    },
    mounted() {},
    methods: {
        handleChange(type, value) {
            this.$emit("handleChange", type, value);
        },
        btnClick(val) {
            this.$emit("btnClick", val);
        },
        resetValue(id, value) {
            if (this.selectData.id === id) {
                this.currentValue = value;
            }
        },
    },
};
</script>
<style scoped></style>
