export default (router, store) => {
    router.beforeEach((to, from, next) => {
        let {
            path
        } = to;
        // let noList = ["/big_screen", "/get_video", "/get_mobile_video", "/big_screen_map"]
        let noList = ["/big_screen", "/get_video", "/get_mobile_video",]
        if (noList.includes(path)) {
            next();
            return
        }
        if (path == '/login') {
            next();
            // store.commit(state.loginPeo, null)
            localStorage.clear();
            return;
        } else {
            // 先判断用户的登录情况
            // console.log("store", store);
            // store.getters.loginPeo
            if (!localStorage.getItem('userId')) {
                next('/login');
                return;
            } else {
                next();
            }

        }
    });
}