import { Message } from "element-ui";
let fullscreen = false;
import { Loading } from "element-ui";
import axios from "axios";
import store from "@store";
export default {
    getDate(separator) {
        let sep = separator || ".";
        let time = new Date();
        let year = time.getFullYear();
        let month = time.getMonth() + 1;
        let day = time.getDate();
        return [year, month, day].map(this.formatNumber).join(sep);
    },
    dateLessThanToday() {
        // 时间小于今天
        return {
            disabledDate(time) {
                return time.getTime() > Date.now();
            },
        };
    },
    beginDateCompare(startTime) {
        // 开始时间 < 结束时间 条件判断
        return {
            disabledDate(time) {
                if (startTime !== "") {
                    return time.getTime() < Date.parse(startTime);
                }
            },
        };
    },
    endDateCompare(endTime) {
        // 结束时间 > 开始时间 条件判断
        return {
            disabledDate(time) {
                if (endTime !== "") {
                    return time.getTime() > Date.parse(endTime);
                }
            },
        };
    },
    showMsg(msg, state) {
        // 弹出成功 失败 提示框
        // 不重复弹出
        let messageDom = document.getElementsByClassName("el-message");
        if (messageDom.length > 0) {
            return false;
        }
        Message({
            showClose: true,
            message: msg,
            type: state,
            duration: 2000,
        });
    },
    getScreenWidthState() {
        // 获取屏幕宽度 返回 表格 高度
        return window.screen.width > 1500 ? true : false;
    },
    someDaysAgo(value) {
        // 最近几天
        const start = new Date();
        return this.getTimeYmd(
            start.setTime(start.getTime() - 3600 * 1000 * 24 * value)
        );
    },
    someDaysAgoToSecond(value) {
        // 最近几天到时分秒
        const start = new Date();
        return this.getTimeYmdhms(
            start.setTime(start.getTime() - 3600 * 1000 * 24 * value)
        );
    },
    someMonthsAgo(monthNum) {
        // 最近几月
        let date = new Date();
        let year = date.getFullYear();
        let month = date.getMonth() + 1;
        let year2 = year;
        let month2 = parseInt(month) - monthNum;
        if (month2 <= 0) {
            let absM = Math.abs(month2);
            year2 =
                parseInt(year2) -
                Math.ceil(absM / 12 == 0 ? 1 : parseInt(absM) / 12);
            month2 = 12 - (absM % 12);
        }
        if (month2 < 10) {
            month2 = "0" + month2;
        }
        let t2 = year2 + "-" + month2;
        return t2;
    },
    getTimeY(num = 0) {
        // 获取时间 年
        let date = new Date();
        let data_year = date.getFullYear() - num;
        return data_year.toString();
    },
    getMonth(m) {
        let time = new Date();
        let month = m || time.getMonth() + 1;
        return this.formatNumber(month);
    },
    getDay(m) {
        let time = new Date();
        let day = m || time.getDate();
        return this.formatNumber(day);
    },
    formatNumber(n) {
        let str = n.toString();
        return str[1] ? str : `0${str}`;
    },
    // 获取时间 当年月
    getTimeYm(value) {
        let date = value ? new Date(value) : new Date();
        let data_year = date.getFullYear();
        let data_month;
        if (date.getMonth() + 1 < 10) {
            data_month = "0" + (date.getMonth() + 1);
        } else {
            data_month = date.getMonth() + 1;
        }
        return `${data_year}-${data_month}`;
    },
    getTimeYmd(value) {
        // 获取时间 年月日
        let date = value ? new Date(value) : new Date();
        let data_year = date.getFullYear();
        let data_month;
        let data_day;

        if (date.getMonth() + 1 < 10) {
            data_month = "0" + (date.getMonth() + 1);
        } else {
            data_month = date.getMonth() + 1;
        }

        if (date.getDate() + 1 <= 10) {
            data_day = "0" + date.getDate();
        } else {
            data_day = date.getDate();
        }
        return data_year + "-" + data_month + "-" + data_day;
    },
    getTimeYmdhms(value) {
        // 获取时间 年月日 时分秒
        let date = value ? new Date(value) : new Date();
        let data_year = date.getFullYear();
        let data_month;
        let data_day;

        let data_hour;
        let data_minute;
        let data_second;

        if (date.getMonth() + 1 < 10) {
            data_month = "0" + (date.getMonth() + 1);
        } else {
            data_month = date.getMonth() + 1;
        }

        if (date.getDate() + 1 < 10) {
            data_day = "0" + date.getDate();
        } else {
            data_day = date.getDate();
        }

        if (date.getHours() + 1 <= 10) {
            data_hour = "0" + date.getHours();
        } else {
            data_hour = date.getHours();
        }

        if (date.getMinutes() + 1 <= 10) {
            data_minute = "0" + date.getMinutes();
        } else {
            data_minute = date.getMinutes();
        }

        if (date.getSeconds() + 1 <= 10) {
            data_second = "0" + date.getSeconds();
        } else {
            data_second = date.getSeconds();
        }

        return (
            data_year +
            "-" +
            data_month +
            "-" +
            data_day +
            " " +
            data_hour +
            ":" +
            data_minute +
            ":" +
            data_second
        );
    },

    // 获取 各种格式的 当前时间
    getNowTime(objFrom) {
        /*
            参数说明
            typeNum
              w: 星期     星期五
              y：年       2021
              ym:年月     2021-11
              ymd:年月日  2021-11-12
              hms:时分秒 19:25:09
              ymdhms://年月日 时分秒 2021-11-12 19:25:09
        */
        let obj = {
            value: "", //当前时间
            typeNum: "ymd", //默认 年月日时间格式
            separator: "-", //时间的分隔符号
        };
        let week = [
            "星期日",
            "星期一",
            "星期二",
            "星期三",
            "星期四",
            "星期五",
            "星期六",
        ];
        obj = { ...obj, ...objFrom };
        let date = obj.value ? new Date(obj.value) : new Date();
        let data_year = date.getFullYear();
        let data_month;
        let data_day;

        let data_hour;
        let data_minute;
        let data_second;

        if (date.getMonth() + 1 < 10) {
            data_month = "0" + (date.getMonth() + 1);
        } else {
            data_month = date.getMonth() + 1;
        }

        if (date.getDate() + 1 <= 10) {
            data_day = "0" + date.getDate();
        } else {
            data_day = date.getDate();
        }

        if (date.getHours() + 1 <= 10) {
            data_hour = "0" + date.getHours();
        } else {
            data_hour = date.getHours();
        }

        if (date.getMinutes() + 1 <= 10) {
            data_minute = "0" + date.getMinutes();
        } else {
            data_minute = date.getMinutes();
        }

        if (date.getSeconds() + 1 <= 10) {
            data_second = "0" + date.getSeconds();
        } else {
            data_second = date.getSeconds();
        }

        let time = "";
        switch (obj.typeNum) {
            case "w": //星期
                time = week[date.getDay()];
                break;
            case "y": //年
                time = data_year;
                break;
            case "ym": //年月
                time = data_year + obj.separator + data_month;
                break;
            case "ymd": //年月日
                time =
                    data_year +
                    obj.separator +
                    data_month +
                    obj.separator +
                    data_day;
                break;
            case "hms": // 时分秒
                time = data_hour + ":" + data_minute + ":" + data_second;
                break;
            case "ymdhms": //年月日 时分秒
                time =
                    data_year +
                    obj.separator +
                    data_month +
                    obj.separator +
                    data_day +
                    " " +
                    data_hour +
                    ":" +
                    data_minute +
                    ":" +
                    data_second;
                break;
        }
        return time;
    },
    // 获取 各种格式的 非当前时间
    getNoNowTime(objFrom) {
        /*
            参数说明
            typeNum
                ym:年月     2021-11
                ymd:年月日  2021-11-12
                ymdhms://年月日 时分秒 2021-11-12 19:25:09
       */
        let obj = {
            value: 0, // 10 十天之后 -10 十天之前
            typeNum: "ymd", //
            separator: "-", //时间分隔符号
        };
        obj = { ...obj, ...objFrom };
        const start = new Date();
        let time = "";
        let day = "";
        let value = "";
        switch (obj.typeNum) {
            case "ymd": //最近几天
                day = 3600 * 1000 * 24 * obj.value;
                break;
            case "ymdhms": //最近几天到时分秒
                day = 3600 * 1000 * 24 * obj.value;
                break;
            case "ym": //最近几月
                day = 3600 * 1000 * 24 * 30 * obj.value;
                break;
        }
        if (obj.value) {
            value = start.setTime(start.getTime() + day);
        } else {
            value = start.setTime(start.getTime() - day);
        }
        time = this.getNowTime({
            typeNum: obj.typeNum,
            value: value,
            separator: obj.separator,
        });
        return time;
    },
    // 全屏操作
    fullScreen() {
        let element = document.documentElement;
        if (this.fullscreen) {
            if (document.exitFullscreen) {
                document.exitFullscreen();
            } else if (document.webkitCancelFullScreen) {
                document.webkitCancelFullScreen();
            } else if (document.mozCancelFullScreen) {
                document.mozCancelFullScreen();
            } else if (document.msExitFullscreen) {
                document.msExitFullscreen();
            }
        } else {
            if (element.requestFullscreen) {
                element.requestFullscreen();
            } else if (element.webkitRequestFullScreen) {
                element.webkitRequestFullScreen();
            } else if (element.mozRequestFullScreen) {
                element.mozRequestFullScreen();
            } else if (element.msRequestFullscreen) {
                // IE11
                element.msRequestFullscreen();
            }
        }
        this.fullscreen = !this.fullscreen;
    },
    exitFullscreen() {
        if (document.exitFullScreen) {
            document.exitFullScreen();
        } else if (document.mozCancelFullScreen) {
            document.mozCancelFullScreen();
        } else if (document.webkitExitFullscreen) {
            document.webkitExitFullscreen();
        } else if (document.msExitFullscreen) {
            document.msExitFullscreen();
        }
    },
    regExpTelephonePhone(value) {
        // 电话 + 手机 正则表达式
        let regExp = /^([1]\d{10}|([\(（]?0[0-9]{2,3}[）\)]?[-]?)?([2-9][0-9]{6,7})+(\-[0-9]{1,4})?)$/;
        // console.log("regExpPhone", regExp.test(value));
        return regExp.test(value);
    },
    regExpEmail(value) {
        // 邮箱正则表达式
        let regExp = /^\w+((-\w+)|(\.\w+))*\@[A-Za-z0-9]+((\.|-)[A-Za-z0-9]+)*\.[A-Za-z0-9]+$/;
        // console.log("regExpEmail", regExp.test(value));
        return regExp.test(value);
    },
    regExpPositiveInteger(value) {
        // 正整数正则表达式
        let regExp = /^[1-9]\d*$/;
        // console.log("regExpPositiveInteger", regExp.test(value));
        return regExp.test(value);
    },
    //   判断 str 字符串 是否含有 special 字符串
    isContainString(str, special) {
        let reg = RegExp(`${special}`);
        return reg.test(str);
    },
    loadFile(file) {
        // 下载文件
        let url = "";
        if (this.isContainString(file, "http")) {
            url = file;
        } else {
            url = `${DOWN_URL}${file}`;
        }
        window.open(url);
    },
    containGroup(group) {
        // 是否含有图层组
        if (!group) {
            return false;
        }
        let num = 0;
        group.eachLayer(() => {
            num++;
        });
        return num;
    },
    aryListFindOne(list, listKey, findId) {
        // 从数组里面找到某一个 list, "NAME", e.name
        // 数组  数组中的key 匹配属性
        let obj = {};
        list.forEach((item, index) => {
            if (item[listKey] === findId) {
                obj = item;
            }
        });
        return obj;
    },
    //1360 1920 全屏 非全屏 判断
    screenReset() {
        let clientHeight = document.body.clientHeight;
        let obj = {
            height1920: null,
            height1920not: null,
            height1360: null,
            height1360not: null,
        };

        if (
            clientHeight == 1080 ||
            clientHeight == 1079 ||
            clientHeight == 1073
        ) {
            obj.height1920 = true;
        } else {
            obj.height1920 = false;
        }

        if (clientHeight < 1079 && clientHeight > 930) {
            obj.height1920not = true;
        } else {
            obj.height1920not = false;
        }

        if (clientHeight == 768 || clientHeight == 767) {
            obj.height1360 = true;
        } else {
            obj.height1360 = false;
        }

        if (clientHeight < 740) {
            obj.height1360not = true;
        } else {
            obj.height1360not = false;
        }

        return obj;
    },
    // 将一个数组分割成多个数组
    // [1,2,3,4,5,6,7,8,9]=>[[1,2,3],[4,5,6],[7,8,9]]
    arrayDevide(array, subGroupLength) {
        let index = 0;
        let newArray = [];
        while (index < array.length) {
            newArray.push(array.slice(index, (index += subGroupLength)));
        }
        return newArray;
    },
    // 修改矢量切片服务加载后的样式，达到叠加多个地图的功能
    dealVectorLayers() {
        let vectorLayers = document.querySelectorAll(".mapboxgl-map");
        vectorLayers.forEach((layer) => {
            layer.style.position = "absolute";
            layer.style.left = 0;
            layer.style.top = 0;
        });
    },
    // 时间 禁止选中
    getTimeNoClick(list) {
        let timeList = [];
        list.map((item) => {
            const [year, month, day] = item.split("-");
            let time = new Date(year, month - 1, day).getTime();
            timeList.push(time);
        });
        let obj = {
            disabledDate(time) {
                const res = !timeList.includes(time.getTime());
                return res;
            },
        };
        return obj;
    },
    // 获得缩略图路径  中图 _Middlethumb 小图 _Minthumb
    getThumbnailImg(url, size = "_Middlethumb") {
        let index1 = url.lastIndexOf(".");
        let index2 = url.length;
        let urlfront = url.substring(0, index1); // 图片路径前半部分
        let format = url.substring(index1, index2); // 图片路径 后缀名
        let imgurl = `${urlfront}${size}${format}`;
        return imgurl;
    },
    // 获取Guid
    getGuid() {
        let guid = "";
        for (let i = 1; i <= 32; i++) {
            let n = Math.floor(Math.random() * 16.0).toString(16);
            guid += n;
            if (i == 8 || i == 12 || i == 16 || i == 20) guid += "-";
        }
        return guid;
    },
    // 导出附件
    exportFile(obj) {
        let url = obj.url;
        let name = obj.name ? obj.name : "";
        let para = obj.para ? obj.para : null;
        let loadingInstance = Loading.service({
            lock: true,
            text: obj.loadingText ? obj.loadingText : "loading",
            spinner: "el-icon-loading",
            background: "rgba(0, 0, 0, 0.7)",
        });
        $.ajax({
            headers: {
                Token: localStorage.sysAccessToken, // 此处放置请求到的用户token
                Authorization: localStorage.sysAccessToken, // 此处放置请求到的用户token
            },
            data: para,
            type: "GET",
            url: url,
            xhrFields: { responseType: "arraybuffer" },
            success: (data, status, xhr) => {
                if (!name) {
                    name = decodeURI(
                        xhr
                            .getResponseHeader("Content-Disposition")
                            .split(";")[2]
                            .split("''")[1]
                    );
                }

                if (!data.byteLength) {
                    this.showMsg(`暂无文件`, "warning");
                } else {
                    let blob = new Blob([data], { type: data.type });
                    let objectUrl = URL.createObjectURL(blob);
                    let a = document.createElement("a");
                    document.body.appendChild(a);
                    a.setAttribute("style", "display:none");
                    a.setAttribute("href", objectUrl);
                    a.setAttribute("target", "_blank");
                    a.setAttribute("download", name);
                    a.click();
                    URL.revokeObjectURL(objectUrl);
                    loadingInstance.close();
                }
            },
            error: (err) => {
                if (obj.item) {
                    console.log(obj.item.ID);
                }
                this.showMsg(`导出失败`, "warning");
                loadingInstance.close();
            },
        });
    },
    // 获取遗产要素状态颜色 找不到 返回所有的 颜色
    getYcysStateColor(name) {
        let color = "";
        let list = [];
        window.YCYS_COLOR_LIST.map((item) => {
            list.push(item.color);
            if (item.label == name || item.desc == name) {
                color = item.color;
            }
        });
        if (color) {
            return color;
        } else {
            return list;
        }
    },
    // 获取 预警等级 状态颜色 找不到 返回所有的 颜色
    getYjdjStateColor(name) {
        let color = "";
        let list = [];
        window.YJDJ_COLOR_LIST.map((item) => {
            list.push(item.color);
            if (item.label == name) {
                color = item.color;
            }
        });
        if (color) {
            return color;
        } else {
            return list;
        }
    },
    // 加载矢量图层多次叠加
    showVectorLayers() {
        let vectorLayers = document.querySelectorAll(".mapboxgl-map");
        vectorLayers.forEach((layer) => {
            layer.style.position = "absolute";
            layer.style.left = 0;
            layer.style.top = 0;
            layer.style.zIndex = 3;
        });
    },
    //随机生成RGB颜色
    getRandomRgbColor(num) {
        let result = null;
        let r = "";
        let g = "";
        let b = "";
        if (num) {
            let list = [];
            let color = "";
            for (let i = 0; i <= num; i++) {
                r = Math.floor(Math.random() * 256); //随机生成256以内r值
                g = Math.floor(Math.random() * 256); //随机生成256以内g值
                b = Math.floor(Math.random() * 256); //随机生成256以内b值
                color = `rgb(${r},${g},${b})`; //返回rgb(r,g,b)格式颜色
                // 颜色去重
                if (list.includes(color)) {
                    num++;
                } else {
                    list.push(color);
                }
            }
            result = list;
        } else {
            r = Math.floor(Math.random() * 256); //随机生成256以内r值
            g = Math.floor(Math.random() * 256); //随机生成256以内g值
            b = Math.floor(Math.random() * 256); //随机生成256以内b值
            result = `rgb(${r},${g},${b})`; //返回rgb(r,g,b)格式颜色
        }
        return result;
    },
    // 电话号码 添加*号
    hidePhoneNum(str) {
        let newStr = "";
        if (str.length) {
            switch (str.length) {
                case 11: //手机号码 18132990492
                    newStr = str.slice(0, 3) + "****" + str.slice(7);
                    break;
                case 7:
                case 12: //电话号码
                    newStr = str.slice(0, 3) + "****";
                    break;
                default:
                    newStr = str.slice(0, 3) + "****" + str.slice(-6);
                    break;
            }
            return newStr;
        }
    },
    getFlvUrl(item) {
        // 创建axios实例
        let instance = axios.create({
            // timeout: 1000 * 15,
            headers: {
                "Content-Type": "application/json",
            },
        });
        // item.ID = "00871486411312246162";
        let url = window.GETFLV_URL + item.ID;
        return new Promise((resolve, reject) => {
            instance.get(url).then((res) => {
                resolve(res.data);
            });
        });
    },
    getFyName(obj) {
        let name = "";
        if (store.getters.jczlFyNum == 0) {
            name = obj.chineseName;
        } else {
            name = obj.englistName;
        }
        return name;
    },
    // 深度合并对象
    deepMerge(obj1, obj2) {
        let key;
        for (key in obj2) {
            // 如果target(也就是obj1[key])存在，且是对象的话再去调用deepMerge，否则就是obj1[key]里面没这个对象，需要与obj2[key]合并
            // 如果obj2[key]没有值或者值不是对象，此时直接替换obj1[key]
            obj1[key] =
                obj1[key] &&
                obj1[key].toString() === "[object Object]" &&
                obj2[key] &&
                obj2[key].toString() === "[object Object]"
                    ? this.deepMerge(obj1[key], obj2[key])
                    : (obj1[key] = obj2[key]);
        }
        return obj1;
    },
};
