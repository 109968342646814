<template>
    <div id="control-item">
        <!-- <div class="control-title">
            <label for="">{{controlStructure.label}}</label>
        </div> -->
        <el-input
            v-if="controlStructure.type === 'text'"
            v-model="controlData[controlStructure.key]"
            clearable
            :placeholder="controlStructure.palceholder"
            size="small"
            :disabled="!isEdit"
        ></el-input>

        <el-select
            v-if="controlStructure.type === 'select'"
            v-model="controlData[controlStructure.key]"
            clearable
            :placeholder="controlStructure.palceholder"
            size="small"
            :disabled="!isEdit"
        >
            <el-option
                v-for="item in controlStructure.options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
            >
            </el-option>
        </el-select>

        <el-input
            v-if="controlStructure.type === 'textarea'"
            v-model="controlData[controlStructure.key]"
            type="textarea"
            autosize
            size="small"
            :placeholder="controlStructure.palceholder"
            :disabled="!isEdit"
        >
        </el-input>

        <el-date-picker
            v-if="controlStructure.type === 'date'"
            v-model="controlData[controlStructure.key]"
            :type="controlStructure.dateType"
            size="small"
            :placeholder="controlStructure.palceholder"
            :value-format="controlStructure.valueFormat"
            :disabled="!isEdit"
        >
        </el-date-picker>
        <el-upload
            v-if="controlStructure.type === 'pic'"
            :action="controlStructure.uploadUrl"
            accept="image/png,image/jpg,image/jpeg,image/gif"
            list-type="picture-card"
            :on-preview="handlePictureCardPreview"
            :on-remove="handleRemove"
            :on-success="handleSuccess"
            :on-error="handleError"
            :on-exceed="handleExceed"
            :class="{ noneupload: pic }"
            :limit="limit"
            :file-list="fileList"
            :disabled="!isEdit"
        >
            <i class="el-icon-plus"></i>
        </el-upload>
        <el-dialog :visible.sync="dialogVisible">
            <img width="100%" :src="dialogImageUrl" alt="" />
        </el-dialog>
    </div>
</template>
<script>
export default {
    name: "control-line",
    props: {
        controlStructure: {
            type: Object,
            default: {},
        },
        controlData: {
            type: Object,
            default: {},
        },
        isEdit: {
            type: Boolean,
            default: true,
        },
    },
    data() {
        return {
            dialogVisible: false,
            dialogImageUrl: "",
            limit: 1,
            pic: "",
            fileList: [],
        };
    },
    computed: {},
    watch: {
        controlData: function() {
            this.getFileList();
        },
        controlStructure: {
            deep: true,
            immediate: true,
            handler() {
                // console.log(
                //     "%c 🍒 controlStructure: ",
                //     "font-size:20px;background-color: #B03734;color:#fff;",
                //     this.controlStructure,
                //     window.REQUEST_URL + "UpLoad/FileSave/"
                // );
            },
        },
    },
    mounted() {
        this.getFileList();
    },
    methods: {
        getFileList() {
            if (this.controlData && this.controlData.sytlj) {
                this.fileList = [
                    {
                        url: this.controlData.sytlj,
                    },
                ];
                this.pic = this.controlData.sytlj;
            } else {
                this.fileList = [];
                this.pic = "";
            }
        },
        handleRemove(file, fileList) {
            this.controlData[this.controlStructure.key] = "";
            this.fileList = [];
            this.pic = "";
        },
        handlePictureCardPreview(file) {
            this.dialogImageUrl = file.url;
            this.dialogVisible = true;
        },
        handleSuccess(response, file, fileList) {
            // debugger;
            this.controlData[this.controlStructure.key] =
                response.ResultValue[0].FilePath;
            this.pic = response.ResultValue[0].FilePath;
            this.fileList = [
                {
                    url: this.pic,
                },
            ];
        },
        handleError() {
            this.$message({
                type: "error",
                message: "上传图片失败",
            });
        },
        handleExceed() {},
    },
};
</script>

<style lang="scss" scoped></style>
