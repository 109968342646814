import monitorToBasicDataMap from "./monitor_to_basic";//数据来自于基础数据
import dataFromDb from "./data_from_db"; //数据来自于数据库录入
import dataFromZrhj from "./zrhj_data"; //数据来自于自然环境模块 设备录入

let teaMinitorlist = ['500102']//茶叶加工技艺监测数据 
let noEditlist = ['90502']//没有编辑按钮的监测数据 
// SHZT 0 提交未审核/已提交  1 未提交  2 审核通过  3 审核不通过
const commonMethods = {
    // 0 可查看  1 可编辑  2 可审核
    isPower(type) {
        let powerList = localStorage.monitoringPower.split(',');
        return powerList.indexOf(type) > -1;
    },
    // 显示新建按钮 1
    showNewBtn(row, vm) {
        if (vm) {
            // 只做展示的监测数据 的 列表请求 不显示 新建按钮
            const ItemID = vm.$route.params.ItemID;
            if (monitorToBasicDataMap[ItemID]) {
                return false
            }
        }
        let inPower = this.isPower('1');
        return inPower;
    },
    // 显示导出按钮
    showExportBtn() {
        let inPower = this.isPower('3');
        return inPower;
    },
    // 显示查看按钮
    showSeeBtn(row, vm) {
        let state = false
        if (vm) {
            const ItemID = vm.$route.params.ItemID;
            if (!teaMinitorlist.includes(ItemID)) {
                state = true
            }
        }
        state = !this.showEditBtn(row, vm)
        return state;
    },
    // 显示编辑按钮 
    showEditBtn(row, vm) {
        if (vm) {
            const ItemID = vm.$route.params.ItemID;
            // 数据来源于基础数据的
            // 数据来源于数据库录入的
            // 数据来源于自然环境模块 设备录入的
            // 茶叶加工技艺监测数据
            if (monitorToBasicDataMap[ItemID] ||
                dataFromDb.includes(ItemID) ||
                dataFromZrhj.includes(ItemID) ||
                teaMinitorlist.includes(ItemID) ||
                noEditlist.includes(ItemID)) {
                return false
            }
        }

        let inPower = this.isPower('1');
        if ((Number(row.SHZT) === 1 || Number(row.SHZT) === 3) && inPower) {
            return true;
        } else {
            return false;
        }
    },
    // 显示编辑按钮 管理员 只保存数据 不修改数据状态
    showEditBtnAdmin(row, vm) {
        let inPower = this.isPower('2');//可审核
        let state = false

        if (vm) {
            // 数据来源于基础数据的  false
            // 角色包括 超级管理员的 true
            // 茶叶加工技艺监测数据 true
            const ItemID = vm.$route.params.ItemID;
            if (noEditlist.includes(ItemID)) {
                return false
            }
            if (ItemID == "1205") {
                if (inPower || localStorage.roleId.split(",").includes(window.ROLE_ID.CJGLY)) {
                    state = true
                }
            } else if (ItemID == "90501") {
                //  民居建设 
                if (inPower) {
                    state = true
                }
            } else if (!monitorToBasicDataMap[ItemID] &&
                localStorage.roleId.split(",").includes(window.ROLE_ID.CJGLY)) {
                if (teaMinitorlist.includes(ItemID)) {
                    state = true
                } else if (row.SHZT == '0' || row.SHZT == '2') {
                    state = true
                }
            }

        }
        return state
    },
    // 显示审核按钮 
    showCheckBtn(row, vm) {
        let inPower = this.isPower('2');
        let state = false
        if (vm) {
            const ItemID = vm.$route.params.ItemID;
            if (!teaMinitorlist.includes(ItemID) &&
                row.SHZT !== '' &&
                Number(row.SHZT) === 0 &&
                inPower) {
                state = true
            }
        }
        return state
    },
    // 显示删除按钮  超级管理员 无视数据状态
    showDelBtn(row, vm) {
        let list = ['500102']
        if (vm) {
            // 只做展示的监测数据 的 列表请求 不显示 新建按钮
            const ItemID = vm.$route.params.ItemID;
            if (monitorToBasicDataMap[ItemID] || list.includes(ItemID)) {
                return false
            }
        }
        if (localStorage.roleId.split(",").includes(window.ROLE_ID.CJGLY)) {
            return true
        }
        let inPower = this.isPower('1');
        if ((Number(row.SHZT) === 1 || Number(row.SHZT) === 3) && inPower) {
            return true;
        } else {
            return false;
        }
    },
    // 显示添加子节点的按钮（遗产要素清单）第三级以后不能再添加
    showAddBtn(row) {
        let inPower = this.isPower('1');
        if (Number(row.SHZT) === 2 && String(row.YCYSBM).length <= 21 && inPower) {
            return true;
        } else {
            return false;
        }
    },
    // 显示版本变更的按钮
    showRefreshBtn(row) {
        let inPower = this.isPower('1');
        if (Number(row.SHZT) === 2 && inPower) {
            return true;
        } else {
            return false;
        }
    },
    // 编辑页面的按钮
    // 保存
    showSubmit() {
        let inPower = this.isPower('1');
        return inPower;
    },
    // 对接按钮
    showConnect(row, vm) {
        let state = false
        // 气象 大气质量 气象灾害
        let list = ['8020101', '8020202', '8020512']
        const ItemID = vm.$route.params.ItemID;
        if (list.includes(ItemID)) {
            if (row.SFYDJ != 1) {
                state = true
            } else
                state = false
            return state;
        }
        if (row.SHZT === window.SHZT.AUDIT_PASSED && row.SFYDJ != 1) {
            state = true
        }
        return state;
    },
    // 1205 是否显示转为异常按钮
    showAbnormal(row, vm) {
        // 未转化为异常  
        // 审核通过的  
        // 具有审核权限的 
        // 数据来源于小程序的 LRFS 2 移动端 4 小程序
        let state = false
        if (vm) {
            // 只做展示的监测数据 的 列表请求 不显示 新建按钮
            const ItemID = vm.$route.params.ItemID;
            let inPower = this.isPower('2');
            // 转为异常按钮显示条件： 不是审核未通过  类型为异常记录 登录用户有审核权限 之前未转为异常
            if (ItemID == "1205" &&
                row.SFZYC == '0' &&
                Number(row.SHZT) === 2 &&
                inPower &&
                row.LRFS == "4") {
                state = true;
            }
        }
        return state
    },
    // 1205 回复按钮 只有小程序端的一键上报的数据 才显示 回复按钮
    showReply(row, vm) {
        let state = false
        if (vm) {
            const ItemID = vm.$route.params.ItemID;
            let inPower = this.isPower('2');
            if (ItemID == "1205" && inPower && row.LRFS == "4") {
                state = true;
            }
        }
        return state;
    },
    // 1201 异常转预警
    showWarnBtn(row, vm) {
        // 未转化为异常  审核通过的  具有审核权限的
        // SFZYJ 0 未转化 1 已转化 row.SFZYJ == 0 &&
        let state = false
        if (vm) {
            const ItemID = vm.$route.params.ItemID;
            let inPower = this.isPower('2');
            if (ItemID == "1201" && row.SFZYJ == 0 && row.YCSFJC == '否' && this.isPower('2')) {
                state = true;
            }
        }
        return state;
    },
    // 191 舆情记录 显示舆情操作按钮
    showYqOperateBtn(row, vm) {
        let state = false
        if (vm) {
            const ItemID = vm.$route.params.ItemID;
            if (ItemID == "191" && !Number(row.ZFM)) {
                state = true;
            }
        }
        return state;
    },
    showColumnAdd(row, vm) {
        let state = false
        if (vm) {
            // 只做展示的监测数据 的 列表请求 不显示 新建按钮
            const ItemID = vm.$route.params.ItemID;
            let list = ['101',]
            if (list.includes(ItemID)) {
                state = true;
            }
        }
        return state
    },
};
export { commonMethods };