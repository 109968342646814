export default {
    path: '/dataService_management',
    name: 'dataService_management',
    redirect: { name: 'service_monitor' },
    meta: {
        auth: "50"
    },
    component: () =>
        import ("@views/dataService_management"),
    children: [{
            path: '/running_state',
            name: 'running_state',
            redirect: { name: 'equipment_state' },
            meta: {
                auth: "5001"
            },
            component: () =>
                import ("@views/dataService_management/running_state"),
            children: [{
                    path: '/equipment_state',
                    name: 'equipment_state',
                    component: () =>
                        import ("@views/dataService_management/running_state/equipment_state")
                },
                {
                    path: '/equipment_total_result',
                    name: 'equipment_total_result',
                    component: () =>
                        import ("@views/dataService_management/running_state/equipment_state/equipment_total_result")
                },
                {
                    path: '/service_state',
                    name: 'service_state',
                    component: () =>
                        import ("@views/dataService_management/running_state/service_state")
                }
            ]
        }, {
            path: '/data_management',
            name: 'data_management',
            meta: {
                auth: "5002"
            },
            // redirect: { name: 'equipmentdata_management' },
            component: () =>
                import ("@views/dataService_management/data_management"),
            children: [{
                path: '/equipmentdata_management',
                name: 'equipmentdata_management',
                redirect: { name: 'equipmentdata_waterquality' },
                component: () =>
                    import ("@views/dataService_management/data_management/equipmentdata_management"),
                children: [{
                    path: '/equipmentdata_waterquality',
                    name: 'equipmentdata_waterquality',
                    redirect: { name: 'equipmentdata_waterquality_grid' },
                    component: () =>
                        import ("@views/dataService_management/data_management/equipmentdata_management/water_quality"),
                    children: [{
                        path: '/equipmentdata_waterquality_grid',
                        name: 'equipmentdata_waterquality_grid',
                        component: () =>
                            import ("@views/dataService_management/data_management/equipmentdata_management/water_quality/grid")
                    }, {
                        path: '/equipmentdata_waterquality_form',
                        name: 'equipmentdata_waterquality_form',
                        component: () =>
                            import ("@views/dataService_management/data_management/equipmentdata_management/water_quality/form")
                    }]
                }, {
                    path: '/equipmentdata_windspeed',
                    name: 'equipmentdata_windspeed',
                    redirect: { name: 'equipmentdata_windspeed_grid' },
                    component: () =>
                        import ("@views/dataService_management/data_management/equipmentdata_management/wind_speed"),
                    children: [{
                        path: '/equipmentdata_windspeed_grid',
                        name: 'equipmentdata_windspeed_grid',
                        component: () =>
                            import ("@views/dataService_management/data_management/equipmentdata_management/wind_speed/grid")
                    }, {
                        path: '/equipmentdata_windspeed_form',
                        name: 'equipmentdata_windspeed_form',
                        component: () =>
                            import ("@views/dataService_management/data_management/equipmentdata_management/wind_speed/form")
                    }]
                }, {
                    path: '/equipmentdata_soilmoisture',
                    name: 'equipmentdata_soilmoisture',
                    redirect: { name: 'equipmentdata_soilmoisture_grid' },
                    component: () =>
                        import ("@views/dataService_management/data_management/equipmentdata_management/soil_moisture"),
                    children: [{
                        path: '/equipmentdata_soilmoisture_grid',
                        name: 'equipmentdata_soilmoisture_grid',
                        component: () =>
                            import ("@views/dataService_management/data_management/equipmentdata_management/soil_moisture/grid")
                    }, {
                        path: '/equipmentdata_soilmoisture_form',
                        name: 'equipmentdata_soilmoisture_form',
                        component: () =>
                            import ("@views/dataService_management/data_management/equipmentdata_management/soil_moisture/form")
                    }]
                }, {
                    path: '/equipmentdata_meteorological',
                    name: 'equipmentdata_meteorological',
                    redirect: { name: 'equipmentdata_meteorological_grid' },
                    component: () =>
                        import ("@views/dataService_management/data_management/equipmentdata_management/meteorological"),
                    children: [{
                        path: '/equipmentdata_meteorological_grid',
                        name: 'equipmentdata_meteorological_grid',
                        component: () =>
                            import ("@views/dataService_management/data_management/equipmentdata_management/meteorological/grid")
                    }, {
                        path: '/equipmentdata_meteorological_form',
                        name: 'equipmentdata_meteorological_form',
                        component: () =>
                            import ("@views/dataService_management/data_management/equipmentdata_management/meteorological/form")
                    }]
                }, {
                    path: '/equipmentdata_airquality',
                    name: 'equipmentdata_airquality',
                    redirect: { name: 'equipmentdata_airquality_grid' },
                    component: () =>
                        import ("@views/dataService_management/data_management/equipmentdata_management/air_quality"),
                    children: [{
                        path: '/equipmentdata_airquality_grid',
                        name: 'equipmentdata_airquality_grid',
                        component: () =>
                            import ("@views/dataService_management/data_management/equipmentdata_management/air_quality/grid")
                    }, {
                        path: '/equipmentdata_airquality_form',
                        name: 'equipmentdata_airquality_form',
                        component: () =>
                            import ("@views/dataService_management/data_management/equipmentdata_management/air_quality/form")
                    }]
                }, {
                    path: '/equipmentdata_micro_airquality',
                    name: 'equipmentdata_micro_airquality',
                    redirect: { name: 'equipmentdata_micro_airquality_grid' },
                    component: () =>
                        import ("@views/dataService_management/data_management/equipmentdata_management/micro_air_quality"),
                    children: [{
                        path: '/equipmentdata_micro_airquality_grid',
                        name: 'equipmentdata_micro_airquality_grid',
                        component: () =>
                            import ("@views/dataService_management/data_management/equipmentdata_management/micro_air_quality/grid")
                    }, {
                        path: '/equipmentdata_micro_airquality_form',
                        name: 'equipmentdata_micro_airquality_form',
                        component: () =>
                            import ("@views/dataService_management/data_management/equipmentdata_management/micro_air_quality/form")
                    }]
                }, {
                    path: '/equipmentdata_microenvironment',
                    name: 'equipmentdata_microenvironment',
                    redirect: { name: 'equipmentdata_microenvironment_grid' },
                    component: () =>
                        import ("@views/dataService_management/data_management/equipmentdata_management/microenvironment"),
                    children: [{
                        path: '/equipmentdata_microenvironment_grid',
                        name: 'equipmentdata_microenvironment_grid',
                        component: () =>
                            import ("@views/dataService_management/data_management/equipmentdata_management/microenvironment/grid")
                    }, {
                        path: '/equipmentdata_microenvironment_form',
                        name: 'equipmentdata_microenvironment_form',
                        component: () =>
                            import ("@views/dataService_management/data_management/equipmentdata_management/microenvironment/form")
                    }]
                }, {
                    path: '/equipmentdata_meteorologicaldisaster',
                    name: 'equipmentdata_meteorologicaldisaster',
                    redirect: { name: 'equipmentdata_meteorologicaldisaster_grid' },
                    component: () =>
                        import ("@views/dataService_management/data_management/equipmentdata_management/meteorological_disaster"),
                    children: [{
                        path: '/equipmentdata_meteorologicaldisaster_grid',
                        name: 'equipmentdata_meteorologicaldisaster_grid',
                        component: () =>
                            import ("@views/dataService_management/data_management/equipmentdata_management/meteorological_disaster/grid")
                    }, {
                        path: '/equipmentdata_meteorologicaldisaster_form',
                        name: 'equipmentdata_meteorologicaldisaster_form',
                        component: () =>
                            import ("@views/dataService_management/data_management/equipmentdata_management/meteorological_disaster/form")
                    }]
                }, {
                    path: '/equipmentdata_crack',
                    name: 'equipmentdata_crack',
                    redirect: { name: 'equipmentdata_crack_grid' },
                    component: () =>
                        import ("@views/dataService_management/data_management/equipmentdata_management/crack"),
                    children: [{
                        path: '/equipmentdata_crack_grid',
                        name: 'equipmentdata_crack_grid',
                        component: () =>
                            import ("@views/dataService_management/data_management/equipmentdata_management/crack/grid")
                    }, {
                        path: '/equipmentdata_crack_form',
                        name: 'equipmentdata_crack_form',
                        component: () =>
                            import ("@views/dataService_management/data_management/equipmentdata_management/crack/form")
                    }]
                }, {
                    path: '/equipmentdata_tilt',
                    name: 'equipmentdata_tilt',
                    redirect: { name: 'equipmentdata_tilt_grid' },
                    component: () =>
                        import ("@views/dataService_management/data_management/equipmentdata_management/tilt"),
                    children: [{
                        path: '/equipmentdata_tilt_grid',
                        name: 'equipmentdata_tilt_grid',
                        component: () =>
                            import ("@views/dataService_management/data_management/equipmentdata_management/tilt/grid")
                    }, {
                        path: '/equipmentdata_tilt_form',
                        name: 'equipmentdata_tilt_form',
                        component: () =>
                            import ("@views/dataService_management/data_management/equipmentdata_management/tilt/form")
                    }]
                }, {
                    path: '/disease_data',
                    name: 'disease_data',
                    component: () =>
                        import ("@views/dataService_management/data_management/equipmentdata_management/disease_data")
                }]
            }, {
                path: '/servicedata_management',
                name: 'servicedata_management',
                redirect: { name: 'servicedata_qx' },
                component: () =>
                    import ("@views/dataService_management/data_management/servicedata_management"),
                children: [{
                    path: '/servicedata_qx',
                    name: 'servicedata_qx',
                    redirect: { name: 'servicedata_qx_grid' },
                    component: () =>
                        import ("@views/dataService_management/data_management/servicedata_management/qx"),
                    children: [{
                        path: '/servicedata_qx_grid',
                        name: 'servicedata_qx_grid',
                        component: () =>
                            import ("@views/dataService_management/data_management/servicedata_management/qx/grid")
                    }, {
                        path: '/servicedata_qx_form',
                        name: 'servicedata_qx_form',
                        component: () =>
                            import ("@views/dataService_management/data_management/servicedata_management/qx/form")
                    }]
                }, {
                    path: '/servicedata_yjxx',
                    name: 'servicedata_yjxx',
                    redirect: { name: 'servicedata_yjxx_grid' },
                    component: () =>
                        import ("@views/dataService_management/data_management/servicedata_management/yjxx"),
                    children: [{
                        path: '/servicedata_yjxx_grid',
                        name: 'servicedata_yjxx_grid',
                        component: () =>
                            import ("@views/dataService_management/data_management/servicedata_management/yjxx/grid")
                    }, {
                        path: '/servicedata_yjxx_form',
                        name: 'servicedata_yjxx_form',
                        component: () =>
                            import ("@views/dataService_management/data_management/servicedata_management/yjxx/form")
                    }]
                }, {
                    path: '/servicedata_dqzlsb',
                    name: 'servicedata_dqzlsb',
                    redirect: { name: 'servicedata_dqzlsb_grid' },
                    component: () =>
                        import ("@views/dataService_management/data_management/servicedata_management/dqzlsb"),
                    children: [{
                        path: '/servicedata_dqzlsb_grid',
                        name: 'servicedata_dqzlsb_grid',
                        component: () =>
                            import ("@views/dataService_management/data_management/servicedata_management/dqzlsb/grid")
                    }, {
                        path: '/servicedata_dqzlsb_form',
                        name: 'servicedata_dqzlsb_form',
                        component: () =>
                            import ("@views/dataService_management/data_management/servicedata_management/dqzlsb/form")
                    }]
                }]
            }]
        }, {
            path: '/configuration',
            name: 'configuration',
            meta: {
                auth: "5003"
            },
            redirect: { name: 'service_configuration' },
            component: () =>
                import ("@views/dataService_management/configuration_management"),
            children: [{
                path: '/service_configuration',
                name: 'service_configuration',
                component: () =>
                    import ("@views/dataService_management/configuration_management/service_configuration")
            }, {
                path: '/equipment_configuration',
                name: 'equipment_configuration',
                redirect: { name: 'equipconfig_grid' },
                component: () =>
                    import ("@views/dataService_management/configuration_management/equipment_configuration"),
                children: [{
                    path: '/equipconfig_grid',
                    name: 'equipconfig_grid',
                    component: () =>
                        import ("@views/dataService_management/configuration_management/equipment_configuration/equipconfig_grid")
                }, {
                    path: '/equipconfig_form',
                    name: 'equipconfig_form',
                    component: () =>
                        import ("@views/dataService_management/configuration_management/equipment_configuration/equipconfig_form")
                }]
            }]
        }, {
            path: '/equipment_management',
            name: 'equipment_management',
            meta: {
                auth: "5004"
            },
            redirect: { name: 'equipmanage_grid' },
            component: () =>
                import ("@views/dataService_management/equipment_management"),
            children: [{
                path: '/equipmanage_grid',
                name: 'equipmanage_grid',
                component: () =>
                    import ("@views/dataService_management/equipment_management/equipmanage_grid")
            }, {
                path: '/equipmanage_form',
                name: 'equipmanage_form',
                component: () =>
                    import ("@views/dataService_management/equipment_management/equipmanage_form")
            }, {
                path: '/measure_robot_form',
                name: 'measure_robot_form',
                component: () =>
                    import ("@views/dataService_management/equipment_management/measure_robot_form")
            }]
        }, {
            path: '/mapservice_management',
            name: 'mapservice_management',
            meta: {
                auth: "5005"
            },
            redirect: { name: 'mapservice_manage_grid' },
            component: () =>
                import ("@views/dataService_management/mapservice_management"),
            children: [{
                path: '/mapservice_manage_grid',
                name: 'mapservice_manage_grid',
                component: () =>
                    import ("@views/dataService_management/mapservice_management/mapservice_manage_grid")
            }, {
                path: '/mapservice_manage_form',
                name: 'mapservice_manage_form',
                component: () =>
                    import ("@views/dataService_management/mapservice_management/mapservice_manage_form")
            }]
        },
        {
            path: '/service_monitor',
            name: 'service_monitor',
            meta: {
                auth: '5006'
            },
            component: () =>
                import ("@views/dataService_management/service_monitor"),
        },
        {
            path: '/service_manage',
            name: 'service_manage',
            meta: {
                auth: '5007'
            },
            component: () =>
                import ("@views/dataService_management/service_manage"),
        },
    ]
}