export default {
    path: '/monitoringcloud',
    name: 'monitoringcloud',
    redirect: {
        name: "taskManage"
    },
    meta: {
        auth: "20"
    },
    component: () =>
        import("@views/monitoring_cloud/index.vue"),
    children: [{
        path: "/taskManage",
        name: "taskManage",
        meta: {
            auth: "2001"
        },
        component: () =>
            import("@views/monitoring_cloud/task_manage/task_manage.vue") // 任务管理
    }, {
        path: "/abnormalWarning",
        name: "abnormalWarning",
        meta: {
            auth: "2002"
        },
        component: () =>
            import("@views/monitoring_cloud/abnormal_warning/abnormal_warning.vue") // 异常预警
    }, {
        path: "/abnormalWarningMap",
        name: "abnormalWarningMap",
        meta: {
            auth: "2002"
        },
        component: () =>
            import("@views/monitoring_cloud/abnormal_warning/abnormal_warning_map.vue") // 异常预警-地图视图
    }, {
        path: "/taskExecution",
        name: "taskExecution",
        meta: {
            auth: "2003"
        },
        component: () =>
            import("@views/monitoring_cloud/task_execution/task_execution.vue") // 任务执行
    },
    {
        path: "/peopleLocus",
        name: "peopleLocus",
        meta: {
            auth: "2004"
        },
        component: () =>
            import("@views/monitoring_cloud/people_locus/people_locus.vue") // 人员轨迹
    },
    {
        path: "/dataStatistics",
        name: "dataStatistics",
        meta: {
            auth: "2005"
        },
        component: () =>
            import("@views/monitoring_cloud/data_statistics/data_statistics.vue") // 数据统计
    },
    {
        path: "/taskLog",
        name: "taskLog",
        meta: {
            auth: "2006"
        },
        component: () =>
            import("@views/monitoring_cloud/task_log/task_log.vue") // 任务日志
    },
    {
        path: "/forestPeople",
        name: "forestPeople",
        meta: {
            auth: "2007",
            title: "森林护林员"
        },
        component: () =>
            import("@views/monitoring_cloud/forest_people/index.vue")
    },
    {
        path: "/forestPeopleInfo",
        name: "forestPeopleInfo",
        meta: {
            title: "森林护林员详情"
        },
        component: () =>
            import("@views/monitoring_cloud/forest_people/forestPeopleInfo.vue")
    },
    {
        path: "/patrolReport",
        name: "patrolReport",
        meta: {
            auth: "2008",
            title: "巡查报告"
        },
        component: () =>
            import("@views/monitoring_cloud/patrol_report/index.vue")
    },
    {
        path: "/dailyPatrol",
        name: "dailyPatrol",
        meta: {
            auth: "2001"
        },
        component: () =>
            import("@views/monitoring_cloud/task_manage/daily_patrol/daily_patrol.vue") // 日常巡查
    },
    {
        path: "/ancientTeaTree",
        name: "ancientTeaTree",
        meta: {
            auth: "2001"
        },
        component: () =>
            import("@views/monitoring_cloud/task_manage/ancient_tea_tree/ancient_tea_tree.vue") // 日常巡查
    },
    {
        path: "/ontologyDisease",
        name: "ontologyDisease",
        meta: {
            auth: "2001"
        },
        component: () =>
            import("@views/monitoring_cloud/task_manage/ontology_disease/ontology_disease.vue") // 本体病害
    }, {
        path: "/heritageMonomer",
        name: "heritageMonomer",
        meta: {
            auth: "2001"
        },
        component: () =>
            import("@views/monitoring_cloud/task_manage/heritage_monomer/heritage_monomer.vue") // 遗产要素
    },
    {
        path: "/touristVisitors",
        name: "touristVisitors",
        meta: {
            auth: "2001"
        },
        component: () =>
            import("@views/monitoring_cloud/task_manage/tourist_visitors/tourist_visitors.vue") // 旅游游客
    }, {
        path: "/protectionWorks",
        name: "protectionWorks",
        meta: {
            auth: "2001"
        },
        component: () =>
            import("@views/monitoring_cloud/task_manage/protection_works/protection_works.vue") // 保护工程
    },
    {
        path: "/viewingCorridor",
        name: "viewingCorridor",
        meta: {
            auth: "2001"
        },
        component: () =>
            import("@views/monitoring_cloud/task_manage/viewing_corridor/viewing_corridor.vue") // 景观视廊
    },
    {
        path: "/landscapeStyle",
        name: "landscapeStyle",
        meta: {
            auth: "2001"
        },
        component: () =>
            import("@views/monitoring_cloud/task_manage/landscape_style/landscape_style.vue") // 景观风貌
    },
    {
        path: "/newProject",
        name: "newProject",
        meta: {
            auth: "2001"
        },
        component: () =>
            import("@views/monitoring_cloud/task_manage/new_project/new_project.vue") // 新建项目
    }, {
        path: "/monitorResidentialConstruct",
        name: "monitorResidentialConstruct",
        meta: {
            auth: "2001"
        },
        component: () =>
            import("@views/monitoring_cloud/task_manage/monitor_residential_construct/index.vue") // 新建项目
    }, {
        path: "/archaeologyDig",
        name: "archaeologyDig",
        meta: {
            auth: "2001"
        },
        component: () =>
            import("@views/monitoring_cloud/task_manage/archaeology_dig/archaeology_dig.vue") // 考古发掘
    }, {
        path: "/commuterRushHour",
        name: "commuterRushHour",
        meta: {
            auth: "2001"
        },
        component: () =>
            import("@views/monitoring_cloud/task_manage/commuter_rush_hour/commuter_rush_hour.vue") // 客流高峰现场照片
    }, {
        path: "/ethnicCulture",
        name: "ethnicCulture",
        meta: {
            auth: "2001"
        },
        component: () =>
            import("@views/monitoring_cloud/task_manage/ethnic_culture/ethnic_culture.vue") // 民族文化
    }, {
        path: "/residentialAssess",
        name: "residentialAssess",
        meta: {
            auth: "2001"
        },
        component: () =>
            import("@views/monitoring_cloud/task_manage/residential_assess/residential_assess.vue") // 民居考评
    }
    ]

};