import { $GET, $POST, $AXIOS } from '@request/http.js';
import * as Types from './mutation-type.js';

const state = {

};

const getters = {

};

const mutations = {

};

const actions = {
    // 考评奖励类型
    async GetKPJLEnum({ dispatch, commit, state }, payload) {
        let result = await $GET('PeopleHouseMJDC/GetKPJLEnum', payload);
        return result;
    },
    // 考评列表
    async GetWSKPList({ dispatch, commit, state }, payload) {
        let result = await $GET('PeopleHouseMJDC/GetWSKPList', payload);
        return result;
    },
    // 获取居民考评奖励比和历史评分折线图
    async GetWSKPTuInfo({ dispatch, commit, state }, payload) {
        let result = await $GET('PeopleHouseMJDC/GetWSKPTuInfo', payload);
        return result;
    },
    //  获取居民历史评分明细
    async GetWSKPDataInfo({ dispatch, commit, state }, payload) {
        let result = await $GET('PeopleHouseMJDC/GetWSKPDataInfo', payload);
        return result;
    },
    //  统计获取考评户次和考评结果
    async GetTjKphcAndKpjg({ dispatch, commit, state }, payload) {
        let result = await $GET('PeopleHouseMJDC/GetTjKphcAndKpjg', payload);
        return result;
    },
    //  统计获取村民小组排名
    async GetTjCmxzPm({ dispatch, commit, state }, payload) {
        let result = await $GET('PeopleHouseMJDC/GetTjCmxzPm', payload);
        return result;
    },
    //  统计获取民居排名
    async GetTjMjPm({ dispatch, commit, state }, payload) {
        let result = await $GET('PeopleHouseMJDC/GetTjMjPm', payload);
        return result;
    },
    //  统计获取扣分项
    async GetTjKfx({ dispatch, commit, state }, payload) {
        let result = await $GET('PeopleHouseMJDC/GetTjKfx', payload);
        return result;
    },
};
export default {
    state,
    getters,
    mutations,
    actions
};