import { $GET } from '@request/http.js';
import * as Types from '../mutation-type.js';

const state = {
    rcxcPanelNum: 0, // 0 总览 1 片区 2 遗产要素 3 病害
    rcxcAreaList: [], // 所有片区
    rcxcAreaItem: null, // 某个片区
    rcxcAreaActiveNum: null, // 某个片区
    rxcxYcdwId: "", // 异常点位 id
    rxcxYclbItem: null, // 异常列表
    rxcxYctjNum: null, // 异常类型统计
    rxcxRgsbNum: 0, // 人共上报数量
}

const getters = {
    [Types.rcxcPanelNum]: ({
        rcxcPanelNum
    }) => rcxcPanelNum,
    [Types.rcxcAreaList]: ({
        rcxcAreaList
    }) => rcxcAreaList,
    [Types.rcxcAreaItem]: ({
        rcxcAreaItem
    }) => rcxcAreaItem,
    [Types.rcxcAreaActiveNum]: ({
        rcxcAreaActiveNum
    }) => rcxcAreaActiveNum,

    [Types.rxcxYcdwId]: ({
        rxcxYcdwId
    }) => rxcxYcdwId,
    [Types.rxcxYclbItem]: ({
        rxcxYclbItem
    }) => rxcxYclbItem,
    [Types.rxcxYctjNum]: ({
        rxcxYctjNum
    }) => rxcxYctjNum,
    [Types.rxcxRgsbNum]: ({
        rxcxRgsbNum
    }) => rxcxRgsbNum,
    
};

const mutations = {
    [Types.SET_RCXCPANELNUM]: (state, rcxcPanelNum) => {
        state.rcxcPanelNum = rcxcPanelNum;
    },
    [Types.SET_RCXCAREALIST]: (state, rcxcAreaList) => {
        state.rcxcAreaList = rcxcAreaList;
    },
    [Types.SET_RCXCAREAITEM]: (state, rcxcAreaItem) => {
        state.rcxcAreaItem = rcxcAreaItem;
    },
    [Types.SET_RCXCAREAACTIVENUM]: (state, rcxcAreaActiveNum) => {
        state.rcxcAreaActiveNum = rcxcAreaActiveNum;
    },
    [Types.SET_RCXCYCDWID]: (state, rxcxYcdwId) => {
        state.rxcxYcdwId = rxcxYcdwId;
    },
    [Types.SET_RCXCYCLBITEM]: (state, rxcxYclbItem) => {
        state.rxcxYclbItem = rxcxYclbItem;
    },
    [Types.SET_RCXCRXTJNUM]: (state, rxcxYctjNum) => {
        state.rxcxYctjNum = rxcxYctjNum;
    },
    [Types.SET_RCXCRGSBNUM]: (state, rxcxRgsbNum) => {
        state.rxcxRgsbNum = rxcxRgsbNum;
    },
};

const actions = {
    // 巡查片区 
    async GetRcxcXcpq({
        dispatch,
        commit
    }, payload) {
        let result = await $GET('LargeShowRcxc/GetRcxcXcpq', payload);
        return result.RESULTVALUE;
    },
    // 打卡点 
    async GetSpotStatistic({
        dispatch,
        commit
    }, payload) {
        let result = await $GET('LargeShowRcxc/GetSpotStatistic', payload);
        return result.RESULTVALUE;
    },
    //  巡查统计时间 年 
    async GetInspectionStatisticsYearList({
        dispatch,
        commit
    }, payload) {
        let result = await $GET('LargeShowRcxc/GetInspectionStatisticsYearList', payload);
        return result.RESULTVALUE;
    },
    //  巡查统计
    async GetRcxcSjtj({
        dispatch,
        commit
    }, payload) {
        let result = await $GET('LargeShowRcxc/GetRcxcSjtj', payload);
        return result.RESULTVALUE;
    },
    //  异常列表
    async GetRcxcYclb({
        dispatch,
        commit
    }, payload) {
        let result = await $GET('LargeShowRcxc/GetRcxcYclb', payload);
        return result.RESULTVALUE;
    },
    //  基本信息和异常跟踪
    async GetRcxcJbxxYcgz({
        dispatch,
        commit
    }, payload) {
        let result = await $GET('LargeShowRcxc/GetRcxcJbxxYcgz', payload);
        return result.RESULTVALUE;
    },
    //右侧接口  结果统计 
    async GetRcxcCount({
        dispatch,
        commit
    }, payload) {
        let result = await $GET('LargeShowRcxc/GetRcxcCount', payload);
        return result.RESULTVALUE;
    },
    //  地图 采集点 
    async GetRcxcTckzXcd({
        dispatch,
        commit
    }, payload) {
        let result = await $GET('LargeShowRcxc/GetRcxcTckzXcd', payload);
        return result.RESULTVALUE;
    },
    //  地图 签到点 
    async GetRcxcTckzQdd({
        dispatch,
        commit
    }, payload) {
        let result = await $GET('LargeShowRcxc/GetRcxcTckzQdd', payload);
        return result.RESULTVALUE;
    },
    //  地图 获取巡查人员时间列表 
    async GetRcxcXcryAndDate({
        dispatch,
        commit
    }, payload) {
        let result = await $GET('LargeShowRcxc/GetRcxcXcryAndDate', payload);
        return result.RESULTVALUE;
    },
    //  地图 获取巡查人员是否 有轨迹 
    async GetRcxcXclxByDate({
        dispatch,
        commit
    }, payload) {
        let result = await $GET('LargeShowRcxc/GetRcxcXclxByDate', payload);
        return result.RESULTVALUE;
    },
    //  地图 获取巡查点位详情 
    async GetRcxcXcdDetail({
        dispatch,
        commit
    }, payload) {
        let result = await $GET('LargeShowRcxc/GetRcxcXcdDetail', payload);
        return result.RESULTVALUE;
    },
    //  地图 获取巡查人员详情 
    async GetRcxcRyxx({
        dispatch,
        commit
    }, payload) {
        let result = await $GET('LargeShowRcxc/GetRcxcRyxx', payload);
        return result.RESULTVALUE;
    },
    //  异常统计(是否已解除)
    async GetAbnormalRecordStatistics({
        dispatch,
        commit
    }, payload) {
        let result = await $GET('LargeShowRcxc/GetAbnormalRecordStatistics', payload);
        return result.RESULTVALUE;
    },
    // 异常统计(遗产组成)
    async GetAbnormalRecordComposition({
        dispatch,
        commit
    }, payload) {
        let result = await $GET('LargeShowRcxc/GetAbnormalRecordComposition', payload);
        return result.RESULTVALUE;
    },
    // 异常统计(类型)
    async GetRcxcYclx({
        dispatch,
        commit
    }, payload) {
        let result = await $GET('LargeShowRcxc/GetRcxcYclx', payload);
        return result.RESULTVALUE;
    },
    // 异常事件
    async GetAllYcjl({
        dispatch,
        commit
    }, payload) {
        let result = await $GET('LargeShowRcxc/GetAllYcjl', payload);
        return result.RESULTVALUE;
    },

};
export default {
    state,
    getters,
    mutations,
    actions
};