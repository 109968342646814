// 公共 start
export const SET_STATE_MUTATION = 'setStateMutation';
export const GET_LEFT_MENU_INFO = 'GET_LEFT_MENU_INFO'
// 公共 end
// 登陆 start
export const heritageId = 'heritageId';
export const userName = 'userName';
export const userRealName = 'userRealName';
export const userId = 'userId';
export const departmentId = 'departmentId';
export const modulePower = 'modulePower';
export const roleId = 'roleId';
export const refreshTimer = 'refreshTimer';
export const accessToken = 'accessToken';
export const refreshToken = 'refreshToken';

export const SET_HERITAGEID = 'SET_HERITAGEID';
export const SET_USERNAME = 'SET_USERNAME';
export const SET_USERREALNAME = 'SET_USERREALNAME';
export const SET_USERID = 'SET_USERID';
export const SET_DEPARTMENTID = 'SET_DEPARTMENTID';
export const SET_MODULEPOWER = 'SET_MODULEPOWER';
export const SET_ROLEID = 'SET_ROLEID';
export const SET_REFRESHTIMER = 'SET_REFRESHTIMER';
export const SET_ACCESSTOKEN = 'SET_ACCESSTOKEN';
export const SET_REFRESHTOKEN = 'SET_REFRESHTOKEN';
export const SET_SYS_ACCESSTOKEN = 'SET_SYS_ACCESSTOKEN';
export const SET_SYS_REFRESHTOKEN = 'SET_SYS_REFRESHTOKEN';
// 登陆 end

// 数据采集 start
export const dataGetXzc = 'dataGetXzc'; // 行政村
export const dataGetCmxz = 'dataGetCmxz'; // 村民小组
export const dataGetJmbh = 'dataGetJmbh'; // 居民编号
export const dataGetJmlx = 'dataGetJmlx'; // 居民类型
export const dataGetDcyxm = 'dataGetDcyxm'; // 调查员姓名
export const dataGetSjztDcy = 'dataGetSjztDcy'; // 数据状态调查员
export const dataGetSjztCcy = 'dataGetSjztCcy'; // 数据状态调查员
export const dataGetPageIndex = 'dataGetPageIndex'; // 数据状态
export const dataGetZtActiveNum = 'dataGetZtActiveNum'; // 数据状态选择

export const SET_DATAGETXZC = 'SET_DATAGETXZC';
export const SET_DATAGETCMXZ = 'SET_DATAGETCMXZ';
export const SET_DATAGEJMBH = 'SET_DATAGEJMBH';
export const SET_DATAGEJMLX = 'SET_DATAGEJMLX';
export const SET_DATAGEDCYXM = 'SET_DATAGEDCYXM';
export const SET_DATAGETSJZTDCY = 'SET_DATAGETSJZTDCY';
export const SET_DATAGETSJZTCCY = 'SET_DATAGETSJZTCCY';
export const SET_DATAPAGEINDEX = 'SET_DATAPAGEINDEX';
export const SET_DATAGETZTACTIVENUM = 'SET_DATAGETZTACTIVENUM';
// 数据采集 end

// 大屏部分 start
// -----------监测总览-- start--------
export const isScale = 'isScale';
export const jczlYcysTypeItem = 'jczlYcysTypeItem';
export const jczlYcysList = 'jczlYcysList';
export const jczlYczcItem = 'jczlYczcItem';
export const jczlYczcItemYcysList = 'jczlYczcItemYcysList';
export const jczlYcysItem = 'jczlYcysItem';
export const jczlJghjState = 'jczlJghjState';
export const jczlYjItem = 'jczlYjItem';
export const jczlSxtState = 'jczlSxtState';
export const jczlFyNum = 'jczlFyNum';

export const SET_ISSCALE = 'SET_ISSCALE';
export const SET_JCZLYCYSTYPE = 'SET_JCZLYCYSTYPE';
export const SET_JCZLYCYSLIST = 'SET_JCZLYCYSLIST';
export const SET_JCZLYCZCITEM = 'SET_JCZLYCZCITEM';
export const SET_JCZLYCZCITEMYCYSLIST = 'SET_JCZLYCZCITEMYCYSLIST';
export const SET_JCZLYCYSITEM = 'SET_JCZLYCYSITEM';
export const SET_JCZLJGHJSTATE = 'SET_JCZLJGHJSTATE';
export const SET_JCZLYJITEM = 'SET_JCZLYJITEM';
export const SET_JCZLSXTSTATE = 'SET_JCZLSXTSTATE';
export const SET_JCZLFYNUM = 'SET_JCZLFYNUM';
// -----------监测总览-- end--------

// -----------生态环境-- start--------
export const sthjJcnrSelect = 'sthjJcnrSelect';
export const sthjJlfxxqSelect = 'sthjJlfxxqSelect';
export const sthjGsmmTypeName = 'sthjGsmmTypeName';
export const sthjDeviceItem = 'sthjDeviceItem';

export const SET_STHJJCNRSELECT = 'SET_STHJJCNRSELECT';
export const SET_STHJJLFXXQSELECT = 'SET_STHJJLFXXQSELECT';
export const SET_STHJGSMMTYPENAME = 'SET_STHJGSMMTYPENAME';
export const SET_STHJDEVICEITEM = 'SET_STHJDEVICEITEM';

// -----------生态环境-- end--------

// -----------本体病害-- start--------

// -----------本体病害-- end--------

// -----------旅游游客-- start--------

// -----------旅游游客-- end--------

//  保护工程 start

//  保护工程 end

// -----------日常巡查-- start--------
export const rcxcPanelNum = 'rcxcPanelNum';
export const rcxcAreaList = 'rcxcAreaList';
export const rcxcAreaItem = 'rcxcAreaItem';
export const rcxcAreaActiveNum = 'rcxcAreaActiveNum';
export const rxcxYcdwId = 'rxcxYcdwId';
export const rxcxYclbItem = 'rxcxYclbItem';
export const rxcxYctjNum = 'rxcxYctjNum';
export const rxcxRgsbNum = 'rxcxRgsbNum';

export const SET_RCXCPANELNUM = 'SET_RCXCPANELNUM';
export const SET_RCXCAREALIST = 'SET_RCXCAREALIST';
export const SET_RCXCAREAITEM = 'SET_RCXCAREAITEM';
export const SET_RCXCAREAACTIVENUM = 'SET_RCXCAREAACTIVENUM';
export const SET_RCXCYCDWID = 'SET_RCXCYCDWID';
export const SET_RCXCYCLBITEM = 'SET_RCXCYCLBITEM';
export const SET_RCXCRXTJNUM = 'SET_RCXCRXTJNUM';
export const SET_RCXCRGSBNUM = 'SET_RCXCRGSBNUM';

// -----------日常巡查-- end--------

// -----------建设控制-- start--------

// -----------建设控制-- end--------
// 大屏部分 end





// 专家咨询-专家分析---------------
export const undoneData = 'expert_consultation/undoneData';
export const undoneTotal = 'expert_consultation/undoneTotal';
export const selectData = "expert_consultation/selectData";
export const doneData = 'expert_consultation/doneData';
export const doneTotal = 'expert_consultation/doneTotal';

// 分析评估---------------
export const undoneDataAssess = 'expert_consultation/undoneDataAssess';
export const undoneTotalAssess = 'expert_consultation/undoneTotalAssess';
export const doneDataAssess = 'expert_consultation/doneDataAssess';
export const doneTotalAssess = 'expert_consultation/doneTotalAssess';

// 专家咨询-专家分析---------------
export const SET_UNDONEDATA = 'expert_consultation/SET_UNDONEDATA';
export const SET_UNDONETOTAL = 'expert_consultation/SET_UNDONETOTAL';
export const SET_SELECTDATA = "expert_consultation/SET_SELECTDATA";
export const SET_DONEDATA = 'expert_consultation/SET_DONEDATA';
export const SET_DONETOTAL = 'expert_consultation/SET_DONETOTAL';


// 数据接入系统---------------------
export const queryParamState = 'dataService_management/queryParamState';

// 设备运行状态---------
export const RunningStatusResult = 'dataService_management/RunningStatusResult';
export const SBQDLIST = 'dataService_management/SBQDLIST';
export const TOTALLIST = 'dataService_management/TOTALLIST';
export const SingleEquip = 'dataService_management/SingleEquip';
export const abnormalCountWhole = 'dataService_management/abnormalCountWhole';

// 共享服务状态-----------
export const serviceStatusResult = 'dataService_management/serviceStatusResult';
export const zdqdList = 'dataService_management/zdqdList';
export const totalserviceList = 'dataService_management/totalserviceList';
export const ResultList = 'dataService_management/ResultList';

// 服务配置--------------
export const zrhjpzType = 'dataService_management/zrhjpzType';
export const zrhjpzList = 'dataService_management/zrhjpzList';
export const enumData = 'dataService_management/enumData';
export const fzrData = 'dataService_management/fzrData';
export const tableData = 'dataService_management/tableData';
export const tableTotal = 'dataService_management/tableTotal';

// 数据接入系统---------------------
export const SET_QUERYPARAM = 'dataService_management/SET_QUERYPARAM';

// 设备运行状态---------
export const SET_RunningStatusResult = 'dataService_management/SET_RunningStatusResult';
export const SET_SBQDLIST = 'dataService_management/SET_SBQDLIST';
export const SET_TOTALLIST = 'dataService_management/SET_TOTALLIST';
export const SET_SingleEquip = 'dataService_management/SET_SingleEquip';
export const SET_ABNORMALCOUNTWHOLE = 'dataService_management/SET_ABNORMALCOUNTWHOLE';

// 共享服务状态-----------
export const SET_ServiceStatusResult = 'dataService_management/SET_ServiceStatusResult';
export const SET_ZDQDLIST = 'dataService_management/SET_ZDQDLIST';
export const SET_TOTALSERVICLIST = 'dataService_management/SET_TOTALSERVICLIST';
export const SET_ResultList = 'dataService_management/SET_ResultList';

// 服务配置--------------
export const SET_ZRHJPZTYPE = 'dataService_management/SET_ZRHJPZTYPE';
export const SET_ZRHJPZLIST = 'dataService_management/SET_ZRHJPZLIST';
export const SET_ENUMDATA = 'dataService_management/SET_ENUMDATA';
export const SET_FZRDATA = 'dataService_management/SET_FZRDATA';
export const SET_TABLEDATA = 'dataService_management/SET_TABLEDATA';
export const SET_TABLETOTAL = 'dataService_management/SET_TABLETOTAL';