import Vue from "vue";
import Router from "vue-router";
Vue.use(Router);
// 重写路由的push方法,修改基础数据页面同一路由的报错
const routerPush = Router.prototype.push;
Router.prototype.push = function push(location) {
    return routerPush.call(this, location).catch((error) => error);
};
import monitorData from "./modules/monitor_data"; // 数据采集管理
import basicData from "./modules/basic_data"; // 基础数据管理
import userManage from "./modules/user_manage"; // 运维管理
import monitorCloud from "./modules/monitor_cloud"; // 监测云
import expertConsultation from "./modules/expert_consultation"; // 专家咨询系统
import analysisAssess from "./modules/analysis_assess"; // 分析评估系统
import distribution from "./modules/distribution"; // 业务分配
import dataServiceManagement from "./modules/dataService_management"; // 数据接入系统
import remoteSense from "./modules/remote_sense"; // 遥感监测

export default new Router({
    routes: [
        {
            path: "/",
            redirect: "/login",
        },
        {
            path: "/login",
            name: "login",
            component: () => import("@views/login/index.vue"),
            meta: {
                title: "登录",
            },
        },
        {
            path: "/login2",
            name: "login2",
            component: () => import("@views/login/index.vue"),
            meta: {
                title: "登录",
            },
        },
        {
            path: "/login3",
            name: "login3",
            component: () => import("@views/login/index.vue"),
            meta: {
                title: "登录",
            },
        },
        {
            path: "/home",
            name: "home",
            component: () => import("@views/home/index.vue"),
            meta: {
                title: "主页",
            },
        },
       
        {
            path: "/data_get",
            name: "data_get",
            redirect: {
                name: "data_get",
            },
            component: () => import("@views/data_get/index.vue"),
            meta: {
                title: "数据采集",
            },
            children: [
                {
                    path: "/data_get",
                    name: "data_get",
                    component: () =>
                        import("@views/data_get/table_list/index.vue"),
                    meta: {
                        title: "数据采集列表",
                    },
                },
               
                {
                    path: "/detail/:size/:strId/:typeId:mjbh",
                    name: "detail",
                    component: () =>
                        import("@views/data_get/data_action/index.vue"),
                    meta: {
                        title: "数据采集上传操作",
                    },
                },
            ],
        },
        {
            path: "/big_screen",
            name: "big_screen",
            component: () => import("@views/big_screen/index.vue"),
            meta: {
                title: "大屏",
            },
        },
        // {
        //     path: '/big_screen_map',
        //     name: "big_screen_map",
        //     component: () => import("@views/big_screen/load_map/index.vue"),
        //     meta: {
        //         title: '加载大屏地图'
        //     }
        // },
        {
            path: "/get_video",
            name: "get_video",
            component: () => import("@views/big_screen/all_video/index.vue"),
            meta: {
                title: "视频流",
            },
        },
        {
            path: "/get_mobile_video",
            name: "get_mobile_video",
            component: () => import("@views/big_screen/all_video/mobile.vue"),
            meta: {
                title: "移动端",
            },
        },
        {
            path: "/ly",
            name: "ly",
            component: () => import("@views/big_screen/ly.vue"),
            meta: {
                title: "大屏",
            },
        },
        {
            path: "/workdesk",
            name: "workdesk",
            meta: {
                auth: "10",
            },
            redirect: {
                name: "monitoringTable",
            },
            component: () => import("@views/working_desk/index.vue"), // 工作台面
            children: [
                basicData,
                monitorData,
                remoteSense,
                {
                    path: "/warning_dispose",
                    name: "warning_dispose",
                    component: () =>
                        import("@views/working_desk/warning_dispose"), // 预警处置
                    redirect: { name: "warning_dispose_grid" },
                    children: [
                        {
                            path: "/warning_dispose_grid",
                            name: "warning_dispose_grid",
                            component: () =>
                                import(
                                    "@views/working_desk/warning_dispose/grid"
                                ),
                        },
                        {
                            path: "/warning_dispose_form",
                            name: "warning_dispose_form",
                            component: () =>
                                import(
                                    "@views/working_desk/warning_dispose/form"
                                ),
                        },
                    ],
                },
                {
                    path: "/analysis-assess",
                    name: "analysis-assess",
                    meta: {
                        auth: "1005",
                    },
                    component: () =>
                        import("@views/working_desk/analysis_assess"),
                },
                {
                    path: "/consulting_expert",
                    name: "consulting_expert",
                    redirect: { name: "consulting_expert_grid" },
                    meta: {
                        auth: "8001",
                    },
                    component: () =>
                        import("@views/expert_consultation/consulting_expert"),
                    children: [
                        {
                            path: "/consulting_expert_grid",
                            name: "consulting_expert_grid",
                            component: () =>
                                import(
                                    "@views/expert_consultation/consulting_expert/grid"
                                ),
                        },
                        {
                            path: "/consulting_expert_form",
                            name: "consulting_expert_form",
                            component: () =>
                                import(
                                    "@views/expert_consultation/consulting_expert/form"
                                ),
                        },
                    ],
                },
                {
                    path: "/expert_analysis",
                    name: "expert_analysis",
                    meta: {
                        auth: "8002",
                    },
                    // redirect: { name: 'expert_analysis_grid' },
                    component: () =>
                        import("@views/expert_consultation/expert_analysis"),
                    // children: [{
                    //     path: '/expert_analysis_grid',
                    //     name: 'expert_analysis_grid',
                    //     component: () =>
                    //         import ("@views/expert_consultation/expert_analysis/grid")
                    // }, {
                    //     path: '/expert_analysis_form',
                    //     name: 'expert_analysis_form',
                    //     component: () =>
                    //         import ("@views/expert_consultation/expert_analysis/form")
                    // }]
                },
                {
                    path: "/expert_information",
                    name: "expert_information",
                    meta: {
                        auth: "8003",
                    },
                    redirect: { name: "expert_information_grid" },
                    component: () =>
                        import("@views/expert_consultation/expert_information"),
                    children: [
                        {
                            path: "/expert_information_grid",
                            name: "expert_information_grid",
                            component: () =>
                                import(
                                    "@views/expert_consultation/expert_information/grid"
                                ),
                        },
                        {
                            path: "/expert_information_form",
                            name: "expert_information_form",
                            component: () =>
                                import(
                                    "@views/expert_consultation/expert_information/form"
                                ),
                        },
                    ],
                },
            ],
        },
        userManage,
        monitorCloud,
        distribution,
        expertConsultation,
        analysisAssess,
        dataServiceManagement,
    ],
});
