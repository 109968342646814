import {
    $GET,
    $POST,
    $AXIOS
} from '@request/http.js';
import * as Types from '../mutation-type.js';

const state = {
    bhgcYczcItem: null,//遗产要素 某一个
    bhgcYcysItem: null,//遗产要素 某一个
    bhgcPanelNum: 1,//右侧面板数
    bhgcItem: null,//保护工程 某一个
    bhgcJcwzItem: "",// 某一个监测点位
    bhgcActiveNum: null,// 保护工程 选中项
    bhgcJcdwItem: null,// 监测点位 某一个
};

const getters = {
    bhgcYczcItem(state, getters) {
        return state.bhgcYczcItem;
    },
    bhgcYcysItem(state, getters) {
        return state.bhgcYcysItem;
    },
    bhgcPanelNum(state, getters) {
        return state.bhgcPanelNum;
    },
    bhgcItem(state, getters) {
        return state.bhgcItem;
    },
    bhgcJcwzItem(state, getters) {
        return state.bhgcJcwzItem;
    },
    bhgcActiveNum(state, getters) {
        return state.bhgcActiveNum;
    },
    bhgcJcdwItem(state, getters) {
        return state.bhgcJcdwItem;
    },
};

const mutations = {
    setStateBhgc: (state, payload) => {
        for (const prop in payload) {
            if (payload[prop] !== undefined) {
                state[prop] = payload[prop];
            }
        }
    },
};

const actions = {
    // 1 左侧遗产要素列表
    async getBhgcYcysList({ dispatch }, payload) {
        let result = await $GET('LargeShowBhgc/getBhgcYcysList', payload);
        return result.RESULTVALUE;
    },
    /**
     *2 保护工程列表
     * @param {*} ycysbm
     */
    async getBhgcDpList({ dispatch }, payload) {
        let result = await $GET('LargeShowBhgc/getBhgcDpList', payload);
        return result.RESULTVALUE;
    },
    /**
     *3 保护工程详情
     * @param {*} bhgcID
     */
    async getBhgcDetail({ dispatch }, payload) {
        let result = await $GET('LargeShowBhgc/getBhgcDetail', payload);
        return result.RESULTVALUE;
    },
    /**
     *4 保护工程数据统计
     * @param {*} ycysbm
     * @param {*} rq
     */
    async getBhgcSlStatistics({ dispatch }, payload) {
        let result = await $GET('LargeShowBhgc/getBhgcSlStatistics', payload);
        return result.RESULTVALUE;
    },
    /**
     * 5 保护工程分类数据统计
     * @param {*} rq
     */
    async getBhgcFLSlStatistics({ dispatch }, payload) {
        let result = await $GET('LargeShowBhgc/getBhgcFLSlStatistics', payload);
        return result.RESULTVALUE;
    },
    /**
     *6、 保护工程跟踪信息-位置下拉框
     * @param {*} bhgcID
     */
    async getJcwzList({ dispatch }, payload) {
        let result = await $GET('LargeShowBhgc/getJcwzList', payload);
        return result.RESULTVALUE;
    },
    /**
     *7. 保护工程跟踪信息
     * @param {*} jcwzID
     * @param {*} cjsj 采集时间（年-月-日）
     */
    async getBhgcGzxx({ dispatch }, payload) {
        let result = await $GET('LargeShowBhgc/getBhgcGzxx', payload);
        return result.RESULTVALUE;
    },
    // 8.保护工程统计
    async getBhgcStatistics({ dispatch }, payload) {
        let result = await $GET('LargeShowBhgc/getBhgcStatistics', payload);
        return result.RESULTVALUE;
    },
    // 9.保护工程数量与经费统计
    async getBhgcSlJFStatistics({ dispatch }, payload) {
        let result = await $GET('LargeShowBhgc/getBhgcSlJFStatistics', payload);
        return result.RESULTVALUE;
    },
    //10. 保护工程进展情况统计
    async getBhgcJzqkStatistics({ dispatch }, payload) {
        let result = await $GET('LargeShowBhgc/getBhgcJzqkStatistics', payload);
        return result.RESULTVALUE;
    },
    /**
     * 11.保护工程经费来源统计
     * @param {*} year 
     */
    async BhgcJflyStatistics({ dispatch }, payload) {
        let result = await $GET('LargeShowBhgc/BhgcJflyStatistics', payload);
        return result.RESULTVALUE;
    },
    /**
     * 	12、 日期下拉框
     */
    async getYearSelect({ dispatch }, payload) {
        let result = await $GET('LargeShowBhgc/getYearSelect', payload);
        return result.RESULTVALUE;
    },
    /**
     * 	13、 保护工程分类数量经费统计
     */
    async BhgcFljfStatistics({ dispatch }, payload) {
        let result = await $GET('LargeShowBhgc/BhgcFljfStatistics', payload);
        return result.RESULTVALUE;
    },
    /**
     * 
     * @param {*} year 
     */
    async YsgcList({ dispatch }, payload) {
        let result = await $GET('LargeShowBhgc/YsgcList', payload);
        return result.RESULTVALUE;
    },
    // 摄像头列表
    async MapCameraList({ dispatch }, payload) {
        let result = await $GET('LargeShow/MapCameraList', payload);
        return result.RESULTVALUE;
    },

};
export default {
    state,
    getters,
    mutations,
    actions
};