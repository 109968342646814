export const self = {
    loading: false,
    snTable: {
        table: {
            data: [],
            rowKey: 'ID',
            treeProps: {
                children: 'Children',
                hasChildren: 'hasChildren'
            }
        },
        columns: {
            // 选择、序号
            columnsOperate: [
                // {
                //     isShow: true,
                //     type: 'selection',
                //     width: 35
                // }, 
                {
                    isShow: true,
                    label: '序号',
                    type: 'index',
                    width: 50
                },

            ],
            // 数据列
            columnsData: [
                {
                    isShow: true,
                    label: '监测对象名称',
                    prop: 'MC',
                    minWidth: 105,
                    beforeIcon: 'el-icon-circle-plus',
                    showMethod: 'showBeforeIcon'
                },
                {
                    isShow: true,
                    label: '监测配置方案名称',
                    prop: 'JCPZFAMC',
                    minWidth: 105,
                },
                {
                    isShow: false,
                    label: '监测位置名称',
                    prop: 'JCWZMC',
                    minWidth: 105,
                },
                {
                    isShow: true,
                    label: '配置状态',
                    prop: 'ZT_BM',
                    minWidth: 105
                },
                {
                    isShow: true,
                    label: '设备采集周期',
                    prop: 'ZQ',
                    prop1: 'ZQDW_BM',
                    minWidth: 100,
                    transMethod: 'getCjzqText'
                },
            ],
            // 操作列
            columnsBtn: {
                width: '200',
                buttonData: [
                    {
                        showMethod: 'showEditBtn',
                        btnType: 'icon',
                        operateType: 'columnEdit',
                        title: '配置',
                        icon: 'el-icon-s-tools',
                        color: '#20a0ff',
                        class: 'one'
                    },
                    {
                        isShow: true,
                        showMethod: 'showPauseBtn',
                        btnType: 'icon',
                        operateType: 'columnPause',
                        title: '暂停生成任务',
                        icon: 'fa fa-pause-circle-o two',
                        color: '#20a0ff',
                    },
                    {
                        isShow: true,
                        showMethod: 'showPlayBtn',
                        btnType: 'icon',
                        operateType: 'columnPlay',
                        title: '启动任务',
                        icon: 'fa fa-play-circle two',
                        color: '#20a0ff'
                    },
                    {
                        isShow: true,
                        showMethod: 'showAllotBtn',
                        btnType: 'img',
                        operateType: 'columnAllot',
                        title: '分配任务',
                        src: 'monitoring_cloud/distribution.png',
                        class: 'three',
                    },
                    {
                        isShow: true,
                        showMethod: 'showEndBtn',
                        btnType: 'icon',
                        operateType: 'columnEnd',
                        title: '结束任务',
                        icon: 'fa fa-lock four',
                        color: '#20a0ff'
                    },
                    {
                        isShow: true,
                        showMethod: 'showSeeBtn',
                        btnType: 'img',
                        operateType: 'columnSee',
                        title: '查看详情',
                        src: 'monitoring_cloud/detail.png',
                        class: 'five',
                    },
                    {
                        showMethod: 'showDelBtn',
                        btnType: 'icon',
                        operateType: 'columnDelete',
                        title: '删除',
                        icon: 'fa fa-trash-o six',
                        backColor: 'transparent',
                        color: 'red',
                    }
                ]
            }
        },
    },
    snPage: {
        tiaoshu: 20,
        count: 0,
        currentPage: 1,
        operateType: 'page'
    },
    snCount: {
        eachtotal: [20, 50, 100],
        defaultTiaoshu: 20
    },
    snSearch: {
        inputData: [
            {
                label: '关键字',
                placeholder: "请输入关键字",
                value: 'keyword',
                operateType: 'search',
                isShow: true
            },
        ],
        selectData: [],
        searchBtn: {
            buttonData: [
                {
                    btnType: 'button',
                    operateType: 'searchBtn',
                    name: '查询',
                    round: true,
                    backColor: '#2a63d5',
                    color: '#fff'
                }
            ]
        }
    },
    snButton: {
        buttonData: [
        ]
    }
};
