/* eslint-disable no-unused-vars */
import {
    $GET,
    $POST,
    $AXIOS
} from '@request/http.js';
import * as Types from './mutation-type.js';

const state = {
    system: "", // 根据域名判断是哪个系统
};

const getters = {
    system(state, getters) {
        return state.system;
    },
};

const mutations = {
    SET_COMMON_MUTATION: (state, payload) => {
        for (const prop in payload) {
            if (payload[prop] !== undefined) {
                state[prop] = payload[prop];
            }
        }
    },

};

const actions = {
    // 获取矢量地图
    async getVectorStyle({
        dispatch,
        commit
    }, payload) {
        let result = await $AXIOS({
            method: 'get',
            url: payload.url,
            dataType: 'json',
        });
        return result.data;
    },
};
export default {
    state,
    getters,
    mutations,
    actions
};