export default {
    path: "/usermanage",
    name: "usermanage",
    redirect: {
        name: "user"
    },
    meta: {
        auth: "70",
        title: "运维管理"
    },
    component: () => import("@views/user_manage/index.vue"),
    children: [
        {
            path: "/user",
            name: "user",
            component: () => import("@views/user_manage/user/user.vue"),
            meta: {
                auth: "7001",
                title: "用户管理"
            }
        }, {
            path: "/userinfo",
            name: "userinfo",
            component: () => import("@views/user_manage/user/userinfo.vue"),
            meta: {
                auth: "7001",
                title: "新建用户"
            }
        }, {
            path: "/department",
            name: "department",
            component: () => import("@views/user_manage/department/department.vue"),
            meta: {
                auth: "7002",
                title: "部门管理"
            },
        }, {
            path: "/departmentinfo",
            name: "departmentinfo",
            component: () => import("@views/user_manage/department/departinfo.vue"),
            meta: {
                auth: "7002",
                title: "新建部门"
            }
        }, {
            path: "/role",
            name: "role",
            component: () => import("@views/user_manage/role/role.vue"),
            meta: {
                auth: "7003",
                title: "角色管理"
            }
        }, {
            path: "/roleinfo",
            name: "roleinfo",
            component: () => import("@views/user_manage/role/roleinfo.vue"),
            meta: {
                auth: "7003",
                title: "新建角色"
            }
        }, {
            path: "/survey",
            name: "survey",
            component: () => import("@views/user_manage/survey/survey.vue"),
            meta: {
                auth: "7004",
                title: "民居调查权限管理"
            }
        }, {
            path: "/data_impower",
            name: "data_impower",
            component: () => import("@views/user_manage/data_impower/index.vue"),
            meta: {
                auth: "7005",
                title: "权限管理"
            }
        }, {
            path: "/equipment",
            name: "equipment",
            component: () => import("@views/user_manage/equipment/equipment.vue"),
            meta: {
                title: "设备管理"
            }
        }, {
            path: "/record",
            name: "record",
            component: () => import("@views/user_manage/record/record.vue"),
            meta: {
                title: "日志管理"
            }
        }]
};