import {
    $GET,
    $POST,
    $AXIOS
} from '@request/http.js';

const state = {
    lyj_inspect_state: true, // 显示 隐藏 巡查管理 统计
};

const getters = {
    lyj_inspect_state(state, getters) {
        return state.lyj_inspect_state;
    },

};

const mutations = {
    SET_LYJ_XCGL: (state, payload) => {
        for (const prop in payload) {
            if (payload[prop] !== undefined) {
                state[prop] = payload[prop];
            }
        }
    },
};

const actions = {
    // 获取工作情况数据信息
    async GetTaskStatisticForLyj({ dispatch }, payload) {
        let result = await $GET('LyjTableTopStatistics/GetTaskStatisticForLyj', payload);
        return result
    },
    // 获取工量数据信息
    async GetWorkLoadStatistic({ dispatch }, payload) {
        let result = await $GET('LyjTableTopStatistics/GetWorkLoadStatistic', payload);
        return result
    },

};
export default {
    state,
    getters,
    mutations,
    actions
};