import {
    $GET,
    $POST,
    $AXIOS
} from '@request/http.js';
import * as Types from '../mutation-type.js';

const state = {
    // demo: '', // demo
};

const getters = {
    // [Types.demo]: ({
    //     demo
    // }) => demo,
};

const mutations = {
    // [Types.SET_DEMO]: (state, demo) => {
    //     state.demo = demo;
    // },
};

const actions = {
    // demo 
    // async GetPOMonSASList({
    //     dispatch,
    //     commit
    // }, payload) {
    //     let result = await $GET('MOPublicopinionMonitor/GetPOMonSASList', payload);
    //     return result.RESULTVALUE;
    // },

    // 左侧-茶用途
    async GetTeaUse({ dispatch }, payload) {
        let result = await $GET('LargeShowCwh/GetTeaUse', payload);
        return result;
    },
    // 左侧-茶用途年份
    async GetTeaUseYear({ dispatch }, payload) {
        let result = await $GET('LargeShowCwh/GetTeaUseYear', payload);
        return result;
    },
    // 中间-茶叶加工技艺流程
    async GetTeaWork({ dispatch }, payload) {
        let result = await $GET('LargeShowCwh/GetTeaWork', payload);
        return result;
    },
    // 中间-茶叶加工技艺照片
    async GetTeaWorkPic({ dispatch }, payload) {
        let result = await $GET('LargeShowCwh/GetTeaWorkPic', payload);
        return result;
    },
    // 右侧-今年数据
    async GetNewYearData({ dispatch }, payload) {
        let result = await $GET('LargeShowCwh/GetNewYearData', payload);
        return result;
    },
    //右侧-茶企名称
    async GetTeaEnterpriseName({ dispatch }, payload) {
        let result = await $GET('LargeShowCwh/GetTeaEnterpriseName', payload);
        return result;
    },
    //右侧-茶产量统计
    async GetTeaOutput({ dispatch }, payload) {
        let result = await $GET('LargeShowCwh/GetTeaOutput', payload);
        return result;
    },
    //右侧-产量统计
    async GetOutPutStatistic({ dispatch }, payload) {
        let result = await $GET('LargeShowCwh/GetOutPutStatistic', payload);
        return result;
    },
    //右侧-销量统计
    async GetTeaSale({ dispatch }, payload) {
        let result = await $GET('LargeShowCwh/GetTeaSale', payload);
        return result;
    },
    //右侧-茶企信息
    async GetTeaEnterpriseData({ dispatch }, payload) {
        let result = await $GET('LargeShowCwh/GetTeaEnterpriseData', payload);
        return result;
    },
};
export default {
    state,
    getters,
    mutations,
    actions
};