import { $GET, $POST, $AXIOS } from '@request/http.js';
import * as Types from './mutation-type.js';

const state = {
    // leftMenuInfo: {},
};

const getters = {
    // [Types.GET_LEFT_MENU_INFO]: ({ leftMenuInfo }) => leftMenuInfo,
};

const mutations = {
    [Types.SET_STATE_MUTATION]: (state, payload) => {
        for (const prop in payload) {
            if (payload[prop] !== undefined) {
                state[prop] = payload[prop];
            }
        }
    },
};

const actions = {
    // 监测数据对接到总平台
    async dockData({ dispatch, commit, state }, payload) {
        let result = await $GET('dock/DockData', payload);
        return result;
    },
    
    // 基础数据对接到总平台
    async DockBasicData({ dispatch, commit, state }, payload) {
        let result = await $GET('Dock/DockBasicData', payload);
        return result;
    },
};

export default {
    state,
    getters,
    mutations,
    actions
};