<template>
    <div class="bread-main">
        <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item @click="goHome" :to="{ path: '/home' }"
                >首页</el-breadcrumb-item
            >
            <el-breadcrumb-item
                v-for="(item, index) in breadlist"
                :key="index"
                :to="{ path: item.path }"
                >{{ item.name }}</el-breadcrumb-item
            >
            <el-breadcrumb-item>{{ itemName }}</el-breadcrumb-item>
        </el-breadcrumb>
    </div>
</template>
<script>
// import { setInterval } from 'timers';
export default {
    name: "assistant",
    props: {
        itemName: {
            type: String,
            default: function () {
                return "";
            },
        },
        breadlist: {
            type: Array,
            default: function () {
                return [];
            },
        },
    },
    data() {
        return {};
    },
    computed: {},
    watch: {},
    mounted() {
        // this.getRouterList();
    },
    methods: {
        getRouterList() {
            setInterval(() => {
                this.breadlist = JSON.parse(localStorage.routerList);
            }, 10);
        },
        goHome() {
            this.clearLocalStortage();
        },
        clearLocalStortage() {
            localStorage.removeItem("snSearch");
            localStorage.removeItem("snPage");
        },
    },
};
</script>
<style lang="scss" scoped>
* {
    box-sizing: border-box;
}

.bread-main {
    width: 100%;
    height: 42px;
    line-height: 42px;
    vertical-align: middle;
    text-align: left;
    padding-left: 10px;
    border-bottom: 1px solid #ccc;
    .el-breadcrumb {
        line-height: 42px;
    }
}
</style>
