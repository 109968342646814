/* eslint-disable no-unused-vars */
import {
    $GET,
    $POST,
    $AXIOS
} from '@request/http.js';
import {
    $GET_Power,
    $POST_Power
} from '@request/http_power.js';
import * as Types from './mutation-type.js';

const state = {
    heritageId: '1105900100000',
    userName: '管理员',
    userRealName: '管理员',
    userId: 'f69d2068-9767-472f-8bb2-a04fa3d8055f',
    departmentId: '2810bb00-7332-4e92-a1cc-e4350ceb6e44',
    modulePower: '', // 功能权限
    roleId: '',
    refreshTimer: null, // 刷新token的定时器
};

const getters = {
    [Types.heritageId]: ({
        heritageId
    }) => heritageId,
    [Types.userName]: ({
        userName
    }) => userName,
    [Types.userRealName]: ({
        userRealName
    }) => userRealName,
    [Types.userId]: ({
        userId
    }) => userId,
    [Types.departmentId]: ({
        departmentId
    }) => departmentId,
    [Types.modulePower]: ({
        modulePower
    }) => modulePower,
    [Types.roleId]: ({
        roleId
    }) => roleId,
    [Types.refreshTimer]: ({
        refreshTimer
    }) => refreshTimer,
};

const mutations = {
    [Types.SET_HERITAGEID]: (state, heritageId) => {
        state.heritageId = heritageId;
    },
    [Types.SET_USERNAME]: (state, userName) => {
        state.userName = userName;
    },
    [Types.SET_USERREALNAME]: (state, userRealName) => {
        state.userRealName = userRealName;
    },
    [Types.SET_USERID]: (state, userId) => {
        state.userId = userId;
    },
    [Types.SET_DEPARTMENTID]: (state, departmentId) => {
        state.departmentId = departmentId;
    },
    [Types.SET_MODULEPOWER]: (state, modulePower) => {
        state.modulePower = modulePower;
    },
    [Types.SET_ROLEID]: (state, roleId) => {
        state.roleId = roleId;
    },
    [Types.SET_ACCESSTOKEN]: (state, accessToken) => {
        state.accessToken = accessToken;
        localStorage.accessToken = accessToken;
    },
    [Types.SET_REFRESHTOKEN]: (state, refreshToken) => {
        state.refreshToken = refreshToken;
        localStorage.refreshToken = refreshToken;
    },
    [Types.SET_SYS_ACCESSTOKEN]: (state, sysAccessToken) => {
        state.sysAccessToken = sysAccessToken;
        localStorage.sysAccessToken = sysAccessToken;
    },
    [Types.SET_SYS_REFRESHTOKEN]: (state, sysRefreshToken) => {
        state.sysRefreshToken = sysRefreshToken;
        localStorage.sysRefreshToken = sysRefreshToken;
    },
    [Types.SET_REFRESHTIMER]: (state, refreshTimer) => {
        state.refreshTimer = refreshTimer;
    },
};

const actions = {
    // 获取验证码
    async GenerateRandomCodePicutreByBitMap({ dispatch, commit, state }, payload) {
        let result = await $GET_Power('CustomLogin/GenerateRandomCodePicutreByBitMap', payload);
        return result;
    },
    // 登陆
    async loginNeedCode({ dispatch, commit, state }, payload) {
        let result = await $GET_Power('CustomLogin/loginNeedCode', payload);
        return result;
    },
    // 忘记密码
    async forgetPWD({ dispatch, commit }, payload) {
        let result = await $POST("Login/ForgetPWD", payload);
        return result;
    },
    // 获取短信验证码
    async getMessageCode({ dispatch, commit }, payload) {
        let result = await $GET("Login/GetMessageCode", payload);
        return result;
    },
    // 获取权限
    async GetFuncTreeFirst({ dispatch, commit }, args) {
        let result = await $GET_Power("CustomLogin/GetFuncTreeFirst", args);
        return result.ISSUCCESS ? result.RESULTVALUE : [];
    },
    // 获取二级权限
    async getFuncTreeSecond({ dispatch, commit }, args) {
        let result = await $GET_Power('CustomLogin/GetFuncTreeSecond', args);
        return result.IsSuccess ? result.ResultValue : [];
    },
    // 重新获取Token
    async refreshToken({ dispatch, commit }, payload) {
        let result = await $POST_Power('CustomLogin/RefreshToken', payload);
        return result;
    },
    // 获取子系统token服务
    async getSysToken({ dispatch, commit }, payload) {
        let result = await $GET_Power('ApiToken/GetApiToken', payload);
        return result;
    },
    // 重新获取子系统Token
    async refreshSysToken({ dispatch, commit }, payload) {
        let result = await $GET_Power('ApiToken/RefreshApiToken', payload);
        return result;
    },
    // 无验证码登录
    async LoginNoVCode({ dispatch, commit }, payload) {
        let result = await $GET_Power('CustomLogin/LoginNoVCode', payload);
        return result;
    },
};
export default {
    state,
    getters,
    mutations,
    actions
};