// 数据来自于数据库
let list = [
    {
        name: "古树名木",
        itemID: "306"
    },
    {
        name: "古茶树",
        itemID: "307"
    },
    {
        name: "病虫害-虫情测报",
        itemID: "706"
    },
    {
        name: "森林病虫害遥感监测",
        itemID: "707"
    },
    {
        name: "古茶林病虫害调查",
        itemID: "708"
    },
    {
        name: "动物多样性监测",
        itemID: "8020515"
    },
    {
        name: "物候监测",
        itemID: "8020516"
    }, {
        name: "客流高峰时段现场照片",
        itemID: "1105"
    }, {
        name: "日常巡查记录",
        itemID: "1202"
    },
]
let listNew = []
list.map((item) => {
    listNew.push(item.itemID)
})
export default listNew