import {
    $GET,
    $POST,
    $AXIOS
} from '@request/http.js';

const state = {
    // demo: '', // demo
    btbhMap: null,
    btbhPanelNum: 0,
    btbhYcys: null,
    btbhYcysType: null,
    btbhYcysIndex: null,
    btbhDetail: null,
    btbhBhjcShow: 0,
    bhList: [], // 左侧病害列表
};

const getters = {
    btbhMap(state, getters) {
        return state.btbhMap;
    },
    btbhPanelNum(state, getters) {
        return state.btbhPanelNum;
    },
    btbhYcys(state, getters) {
        return state.btbhYcys;
    },
    btbhYcysType(state, getters) {
        return state.btbhYcysType;
    },
    btbhYcysIndex(state, getters) {
        return state.btbhYcysIndex;
    },
    btbhDetail(state, getters) {
        return state.btbhDetail;
    },
    btbhBhjcShow(state, getters) {
        return state.btbhBhjcShow;
    },
    btbhBhList(state, getters) {
        return state.bhList;
    }
};

const mutations = {
    // [Types.SET_DEMO]: (state, demo) => {
    //     state.demo = demo;
    // },
    SET_STATE_BTBH: (state, payload) => {
        for (const prop in payload) {
            if (payload[prop] !== undefined) {
                state[prop] = payload[prop];
            }
        }
    },
};

const actions = {
    // 左侧遗产要素列表
    async getBtbhYcysList({ dispatch }, payload) {
        let result = await $GET('LargeShowBtbh/GetBtbhYcys', payload);
        return result.ISSUCCESS ? result.RESULTVALUE : [];
    },
    // 左侧病害列表
    async getBhList({ dispatch }, payload) {
        let result = await $GET('LargeShowBtbh/GetBhList', payload);
        return result.RESULTVALUE;
    },
    // 左侧病害类型图表
    async getBhLxStatistic({ dispatch }, payload) {
        let result = await $GET('LargeShowBtbh/GetBhLxStatistic', payload);
        return result.ISSUCCESS ? result.RESULTVALUE : [];
    },
    // 右侧-总体-保存状态和监测概况
    async getBtbhJCGKex({ dispatch }, payload) {
        let result = await $GET('LargeShowBtbh/GetBtbhJCGKex', payload);
        return result.RESULTVALUE;
    },
    // 右侧-总体-病害类型
    async getBtbhCountDetail({ dispatch }, payload) {
        let result = await $GET('LargeShowBtbh/GetBtbhCountDetail', payload);
        return result.RESULTVALUE;
    },
    // 右侧-总体-要素保存状况
    async getYcysbcztStatistic({ dispatch }, payload) {
        let result = await $GET('LargeShowBtbh/GetYcysbcztStatistic', payload);
        return result.ISSUCCESS ? result.RESULTVALUE : [];
    },
    // 右侧-总体-病害控制
    async getBhkzztpg({ dispatch }, payload) {
        let result = await $GET('LargeShowBtbh/GetBhkzztpg', payload);
        return result.ISSUCCESS ? result.RESULTVALUE : { BHKZZTPG: [], KZZTPGINFO: [] };
    },
    // 右侧-遗产要素-保存状态
    async getYcysPgInfo({ dispatch }, payload) {
        let result = await $GET('LargeShowBtbh/GetYcysPgInfo', payload);
        return result.ISSUCCESS ? result.RESULTVALUE : [];
    },
    // 右侧-遗产要素-病害控制
    async getBtbhjcgkByYcys({ dispatch }, payload) {
        let result = await $GET('LargeShowBtbh/GetBtbhjcgkByYcys', payload);
        return result.ISSUCCESS ? result.RESULTVALUE : [];
    },
    // 右侧-本体病害-基本信息
    async getBtbhDetail({ dispatch }, payload) {
        let result = await $GET('LargeShowBtbh/GetBtbhDetail', payload);
        return result.ISSUCCESS ? result.RESULTVALUE : [];
    },
    // 右侧-本体病害-病害监测-病害位置列表
    async getBhwzAndCjsj({ dispatch }, payload) {
        let result = await $GET('LargeShowBtbh/GetBhwzAndCjsj', payload);
        return result.ISSUCCESS ? result.RESULTVALUE : [];
    },
    // 右侧-本体病害-病害监测-基础数据和监测数据
    async getBhJcsjData({ dispatch }, payload) {
        let result = await $GET('LargeShowBtbh/GetBhJcsjData', payload);
        return result.ISSUCCESS ? result.RESULTVALUE : [];
    },

};
export default {
    state,
    getters,
    mutations,
    actions
};