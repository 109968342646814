const SHZT = {
    SUBMITED: "0", // 未审核
    NOT_SUBMITED: "1", // 未提交
    AUDIT_PASSED: "2", // 已通过
    AUDIT_NOT_PASSED: "3", // 未通过
}

const ROLE_ID = {
    CJR: '3c06635d-f4a3-45a1-bc15-f7411a34c27f', //采集人
    SHR: '3c6f186d-b5b0-4489-8f60-7c2aeaf289ef', //审核人
    CJGLY: '64ff7764-a015-42fc-98ed-1b6489cfa4cf',//管理员
    CJGLYLYJ: '0743784d-7cbe-4ae2-8eae-754f46c854be',//林业局 管理员
    JCZXRY: '4fc0a328-3e75-48f8-935a-d17bf2514a33',//景迈监测中心人员
}
const YCYS_COLOR_LIST = [
    {
        label: "可控",//病害评估
        color: "#00bd8d",
        desc: "好"//遗产要素
    },
    {
        label: "轻微",
        color: "#ffff00",
        desc: "良好"
    },
    {
        label: "严重",
        color: "#ffb508",
        desc: "一般"
    },
    {
        label: "非常严重",
        color: "#ff4c4c",
        desc: "差"
    },
    {
        label: '未监测',
        color: '#959595',
        desc: '未监测'
    },
]

const YJDJ_COLOR_LIST = [
    {
        label: "一级预警",
        color: "#ff4c4c",
    },
    {
        label: "二级预警",
        color: "#ffb508",
    },
    {
        label: "三级预警",
        color: "#ffff00",
    },
    {
        label: "四级预警",
        color: "#2EA7E0",
    },
]
// 民居角色
const DCY = 'e6a17c2d-3ed9-4f6b-98bf-f322f5ccb9e9'  // 调查员
const CCY = '44f2ebb8-588b-493d-a188-16d3a230e6b2'  // 抽查员
const GLY = 'f07af58c-e2b8-4152-83e9-c78c2c59fa7f'  // 管理员

const SUPER_ADMIN_ROLEID = '64ff7764-a015-42fc-98ed-1b6489cfa4cf'  //超级管理员 角色id

const mapCenter = [22.18115145028871, 100.00174215469247]; //地图中心点 维度降低 上动
const mapFitBounds = [ // 地图fit 经纬度  非监测总览大屏
    [22.270369329420593, 100.1301817654325],
    [22.098401434154542, 99.87600592318087],
];

// 经纬度格式测试
const lngTest = /^([0-9.]+,)[0-9.]+$/
const latTest = /^([0-9.]+,)[0-9.]+$/

export default {
    SHZT,
    ROLE_ID,
    YCYS_COLOR_LIST,
    YJDJ_COLOR_LIST,
    DCY,
    CCY,
    GLY,
    SUPER_ADMIN_ROLEID,
    mapCenter,
    mapFitBounds,
    lngTest,
    latTest
};