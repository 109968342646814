/* eslint-disable */
L.Control.MousePosition = L.Control.extend({
    options: {
        position: 'bottomleft',
        separator: ' : ',
        emptystring: 'Unavailable',
        lngFirst: false,
        numDigits: 5,
        lngFormatter: undefined,
        latFormatter: undefined,
        prefix: "",
        showZoom: false
    },

    onAdd: function (map) {
        this._container = L.DomUtil.create('div', 'leaflet-control-mouseposition');
        L.DomEvent.disableClickPropagation(this._container);
        map.on('mousemove', this._onMouseMove, this);
        this._container.innerHTML = this.options.emptystring;
        return this._container;
    },

    onRemove: function (map) {
        map.off('mousemove', this._onMouseMove);
    },

    _onMouseMove: function (e) {
        let zoom = this._map.getZoom();
        let lng = this.options.lngFormatter ? this.options.lngFormatter(e.latlng.lng) : L.Util.formatNum(e.latlng.lng, this.options.numDigits);
        let lat = this.options.latFormatter ? this.options.latFormatter(e.latlng.lat) : L.Util.formatNum(e.latlng.lat, this.options.numDigits);
        let value = this.options.lngFirst ? "经度:" + lng.toFixed(this.options.numDigits) + this.options.separator + "纬度:" + lat.toFixed(this.options.numDigits) : "纬度:" + lat.toFixed(this.options.numDigits) + this.options.separator + "经度:" + lng.toFixed(this.options.numDigits);
        let prefixAndValue = this.options.prefix + ' ' + value;
        let zoomInfo = "地图显示级别:" + (zoom + 1) + "级&nbsp&nbsp ";
        prefixAndValue = this.options.showZoom ? (zoomInfo + prefixAndValue) : prefixAndValue;
        this._container.innerHTML = prefixAndValue;
    }

});

L.Map.mergeOptions({
    positionControl: false
});

L.Map.addInitHook(function () {
    if (this.options.positionControl) {
        this.positionControl = new L.Control.MousePosition();
        this.addControl(this.positionControl);
    }
});

L.control.mousePosition = function (options) {
    return new L.Control.MousePosition(options);
};
