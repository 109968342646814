<template>
    <div style="display: inline-block">
        <el-date-picker v-if="dateData && dateData.isShow"
                        v-model="currentValue"
                        size="mini"
                        class="time option"
                        :class="dateData.class"
                        :picker-options="dateData.option"
                        align="left"
                        :type="dateData.type || 'date'"
                        :style="{ width: dateData.width || null }"
                        :range-separator="dateData.rangeSeparator || ''"
                        :start-placeholder="dateData.startPlaceholder || ''"
                        :end-placeholder="dateData.endPlaceholder || ''"
                        :placeholder="dateData.placeholder"
                        :value-format="dateData.valueFormat || 'yyyy-MM-dd'"
                        @change="
                (val) =>
                    handleChange(dateData.operateType, [val, dateData.value])
            "></el-date-picker>
    </div>
</template>
<script>
export default {
    name: "sn_search_item",
    props: {
        dateData: {
            default: function () {
                return {};
            },
        },
        // selectTimeValue: String // date默认选中项
    },
    data () {
        return {
            currentValue: "",
        };
    },
    watch: {
        dateData: {
            deep: true,
            immediate: true,
            handler () {
            }
        }
    },
    mounted () {
        if (this.selectValue != null) {
            this.currentValue = this.selectValue;
        }
    },
    methods: {
        handleChange (type, value) {
            this.$emit("handleChange", type, value);
        },
        btnClick (val) {
            this.$emit("btnClick", val);
        },
        resetValue (id, value) {
            console.log(this.dateData, id, value);

            if (this.dateData.id === id) {
                this.currentValue = value;
            }
        },
    },
};
</script>
<style scoped>
</style>