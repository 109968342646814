const SHZT = window.SHZT;
const commonMethods = {
    getRowClassMethod(row) {
        const value = row.row;
        let className = '';
        if (!(Number(value.BBH) > 1)) {
            className = 'no-history'
        }
        return className;
    },
    getCjzqText(zq, row) {
        if (String(row.LRFS) === '1' || String(row.LRFS) === '3') {
            return '--';
        }
        if (String(row.SFSZQXJC) === '0') {
            return '无周期';
        }
        return row.ZQ + row.ZQDW_BM;
    },
    // 显示暂停的按钮
    showPauseBtn(row) {
        if (row.isHis) {
            return false;
        }
        if (!row.JCPZID) {
            return false;
        }
        if ([1].includes(Number(row.ZT))) {
            return true;
        } else {
            return false;
        }
    },
    // 显示开始任务的按钮
    showPlayBtn(row) {
        if (row.isHis) {
            return false;
        }
        if (!row.JCPZID) {
            return false;
        }
        if ([0, 2].includes(Number(row.ZT))) {
            return true;
        } else {
            return false;
        }
    },
    // 显示开始任务按钮--有采集人才能开始
    showPlayBtnByCJR(row) {
        if (row.isHis) {
            return false;
        }
        if (!row.JCPZID) {
            return false;
        }
        if (Number(row.ZT) === 0 && (!row.SJCJFZRID)) {
            return true;
        } else {
            return false;
        }
    },
    // 显示结束任务按钮
    showEndBtn(row) {
        if (row.isHis) {
            return false;
        }
        if (!row.JCPZID) {
            return false;
        }
        if ([1, 2].includes(Number(row.ZT))) {
            return true;
        } else {
            return false;
        }
    },
    // 显示分配任务按钮, 选择框可选择
    showAllotBtn(row) {
        if (row.isHis) {
            return false;
        }
        if (!row.JCPZID) {
            return false;
        }
        if (Number(row.ZT) !== 3) {
            return true;
        } else {
            return false;
        }
    },
    showStart(row) {
        return (row.JCPZID) && Number(row.ZT) === 1;
    },
    showPause(row) {
        return !(Number(row.ZT) === 1) && (row.JCPZID);
    },
    showEdit(row) {
        return !!row.JCPZID;
    },
    showDel(row) {
        return (row.JCPZID);
    },
    // 显示查看按钮
    showSeeBtn(row) {
        if (!row.JCPZID) {
            return false;
        }
        return true;
    },
    // 显示编辑按钮
    showEditBtn(row) {
        if ((row.ZT === '') && (!row.JCPZID)) {
            return true;
        } else {
            return false;
        }
    },
    // 显示删除按钮
    showDelBtn(row) {
        if (row.isHis) {
            return false;
        }
        if (!row.JCPZID) {
            return false;
        }
        if ([0, 3].includes(Number(row.ZT))) {
            return true;
        } else {
            return false;
        }
    },
    // 显示添加子节点的按钮（遗产要素清单）第三级以后不能再添加
    showAddBtn(row) {
        if (row.isHis) {
            return false;
        }
        if (Number(row.SHZT) === Number(SHZT.AUDIT_PASSED) && String(row.YCYSBM).length <= 21) {
            return true;
        } else {
            return false;
        }
    },
    // 显示版本变更的按钮
    showRefreshBtn(row) {
        if (row.isHis) {
            return false;
        }
        if (Number(row.SHZT) === Number(SHZT.AUDIT_PASSED)) {
            return true;
        } else {
            return false;
        }
    },
    spanMethod({ row, column, rowIndex, columnIndex }, data, expandRowData) {
        // const is704 = data.some(ele => String(ele.JCX).includes(704));
        // const is705 = data.some(ele => String(ele.JCX).includes(705));
        // if((!is704)&&(!is705)){
        //     if([0,1].includes(columnIndex)){
        //         if(expandRowData.some(ele=>ele._ID === row._ID)){
        //             return [2,1]
        //         }
        //     }
        // }
        // // 针对704 监测对象列表
        // else{
        const JcdxidList = data.map(ele => ele._ID);
        if (!JcdxidList.some(ele => !!ele)) {
            if ([0, 1].includes(columnIndex)) {
                if (expandRowData.some(ele => ele.ID && ele.ID === row.ID)) {
                    return [2, 1]
                }
            }
        }
        else if ([0, 1].includes(columnIndex)) {
            // 向前查找 只要遇到一个相同监测对象的 span为0
            if ((!data[rowIndex]) || (!data[rowIndex]._ID)) return;
            const expandSpan = Number(expandRowData.filter(ele => ele._ID === row._ID).length);
            if (rowIndex !== 0) {
                if (data[rowIndex - 1]._ID === data[rowIndex]._ID) {
                    return [0, 1];
                }
            }
            // 向后查找 每遇到一个相同监测对象的 span加一
            let tempIndex = rowIndex;
            while (JcdxidList[tempIndex] === data[rowIndex]._ID) {
                tempIndex++;
            }
            return [(tempIndex - rowIndex) + expandSpan, 1];
        }
        // }
    },
    showConfigBtn(row) {
        if (row.ZT === null) {
            return true;
        }
        return false;
    },
    showBeforeIcon(row) {
        if (row.isHis) {
            return false;
        }
        return !this.showEditBtn(row);
    },
    showEditionAddBtn(row) {
        if (row.isHis) {
            return false;
        }
        return !!row.JCPZID;
    },
};
export { commonMethods };