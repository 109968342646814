// 哪些项目需要枚举字段
export default [
    {
        name: "申遗承诺履行情况",
        itemID: "101"
    },
    {
        name: "保护管理经费",
        itemID: "204"
    },
    {
        name: "管理制度",
        itemID: "205"
    },
    {
        name: "总体格局变化记录及记录图",
        itemID: "402"
    },
    {
        name: "遗产使用功能变化记录及记录图",
        itemID: "502"
    },
    {
        name: "遗产要素单体或局部照片监测数据",
        itemID: "602"
    },
    {
        name: "遗产要素现状记录",
        itemID: "603"
    },
    // ----------- 本体与载体病害 未查询
    {
        name: "病害调查监测工作情况记录",
        itemID: "702"
    },
    {
        name: "宣传教育活动照片",
        itemID: "182"
    },
    // 非物质文化遗产传承
    {
        name: "民族文化基础数据",
        itemID: "500201"
    },
    {
        name: "民族文化监测数据",
        itemID: "500202"
    },
    // {
    //     name: "demo",
    //     itemID: "demo"
    // },
    // {
    //     name: "demo",
    //     itemID: "demo"
    // },
    // {
    //     name: "demo",
    //     itemID: "demo"
    // },
    // {
    //     name: "demo",
    //     itemID: "demo"
    // },





]
