<template>
    <div>
        <!-- 输入框 -->
        <span v-if="inputData.label" class="sn_search_label">{{
            inputData.label
        }}</span>
        <el-input
            v-if="inputData && inputData.isShow"
            v-model.trim="currentValue"
            type="text"
            clearable
            class="input option"
            :placeholder="inputData.placeholder"
            size="mini"
            @input="
                (val) =>
                    handleChange(inputData.operateType, [val, inputData.value])
            "
        ></el-input>
    </div>
</template>
<script>
export default {
    name: "sn_input",
    props: {
        item: {
            default: () => {
                return {};
            },
        },
    },
    data() {
        return {
            currentValue: "",
            inputData: {},
        };
    },
    watch: {
        item: {
            deep: true,
            immediate: true,
            handler() {
                this.inputData = JSON.parse(this.item);
                if (this.inputData.default) {
                    this.currentValue = this.inputData.default;
                }
            },
        },
    },
    mounted() {},
    methods: {
        handleChange(type, value) {
            this.$emit("handleChange", type, value);
        },
        btnClick(val) {
            this.$emit("btnClick", val);
        },
    },
};
</script>
<style scoped>
</style>