<template>
    <div class="mudule">
        <div class="muduleTitle" v-if="text">
            <div class="lineYellow"></div>
            <div class="text flex">
                <div
                    v-for="(item, index) in text.split(',')"
                    :key="index"
                    class="textItem"
                    :class="[
                        text.split(',').length > 1 ? 'pointer' : '',
                        index == activeNum ? '' : 'textDefault',
                    ]"
                    @click="getTextItem(item, index)"
                >
                    {{ item }}
                </div>
            </div>
            <div class="decorate flex-between">
                <div class="decorate_1"></div>
                <div class="decorate_2"></div>
                <div class="decorate_3"></div>
                <div class="decorate_4"></div>
                <div class="decorate_5 flex-1"></div>
                <div class="decorate_6"></div>
            </div>
        </div>

        <div
            v-if="dataState"
            class="muduleCtn"
            :style="{
                height: text ? 'calc(100% - 60px)' : '100%',
                paddingTop: '10px',
            }"
        >
            <slot name="basic-module"></slot>
        </div>

        <div v-else class="noMonitor">{{ noDataText }}</div>
    </div>
</template>
<script>
export default {
    name: "",
    data() {
        return {
            activeNum: 0,
        };
    },
    components: {},
    computed: {},
    props: {
        fontSize: {
            type: String,
            default: "18px",
        },
        text: {
            type: String,
            default: "",
        },
        noDataText: {
            type: String,
            default: "暂未监测",
        },
        dataState: {
            type: Boolean,
            default: true,
            // true 有数据 false 没有数据 显示暂未监测
        },
    },
    mounted() {},
    methods: {
        getTextItem(item, index) {
            this.activeNum = index;
            this.$emit("getTextItem", item);
        },
        resetActive() {
            this.activeNum = 0;
        },
    },
};
</script>
<style scoped lang="scss">
.mudule {
    width: 100%;
    height: 100%;
    padding: 0 24px;
    position: relative;

    .muduleTitle {
        width: 100%;
        padding-top: 15px;
        height: 60px;
        position: relative;
        .lineYellow {
            width: 50px;
            height: 3px;
            background: rgba(255, 205, 128, 1);
        }
        .text {
            font-weight: 500;
            font-size: 20px;
            color: rgba(168, 200, 255, 1);
            height: 38px;
            line-height: 38px;
            .textItem {
                margin-right: 10px;
            }
        }
        .textDefault {
            color: #4f6b97;
        }
        .decorate {
            height: 3px;
            .decorate_1 {
                width: 76px;
                height: 100%;
                background: rgba(154, 180, 227, 1);
            }
            .decorate_2 {
                width: 28px;
                height: 100%;
                background: rgba(53, 80, 124, 0.5);
                border: 1px solid rgba(154, 180, 227, 1);
                margin: 0 4px;
            }
            .decorate_3 {
                width: 30px;
                height: 100%;
                background: rgba(154, 180, 227, 1);
            }
            .decorate_4 {
                width: 4px;
                height: 4px;
                background: rgba(154, 180, 227, 1);
                border-radius: 50%;
                margin: 0 4px 0 8px;
            }
            .decorate_5 {
                height: 100%;
                background: rgba(53, 80, 124, 0.5);
            }
            .decorate_6 {
                width: 4px;
                height: 4px;
                background: rgba(154, 180, 227, 1);
                border-radius: 50%;
            }
        }
    }
    .muduleCtn {
        position: relative;
        z-index: 2;
    }
    .noMonitor {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) rotate(-30deg);
        font-size: 24px;
        color: rgb(69, 112, 141);
        z-index: 2000;
    }
}
</style>
