// 只做展示的监测数据 的 列表请求
// 在监测数据里调用基础数据
export default {
    20101: 17,   //保护管理机构
    20102: 18,   //监测机构
    202: 22,     //专项保护管理法规、规章
    702: 12,     //病害分布图及病害调查监测工作情况记录
    90102: 21,   //保护区划界线描述与管理规定
    1301: 25,    //现有安消防系统硬件设施信息
    1501: 24,    //保护展示与环境整治工程记录
    1601: 19,     //保护管理规划编制记录
};