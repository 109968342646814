import Vue from "vue";
import Vuex from "vuex";
import dock from './modules/dock'; // 对接服务模块
import common from './modules/common';
import login from './modules/login';
import data_get from './modules/data_get';
import residence_evaluate from './modules/residence_evaluate';//民居考评
import monitorCloud from './modules/monitorCloud';
import basic_data from './modules/basic_data';
import monitor_data from './modules/monitor_data';
import userManage from './modules/userManage';
import expertConsultation from './modules/expert_consultation'; // 专家咨询系统
import analysisAssess from './modules/analysis_assess'; // 分析评估系统
import warningDispose from './modules/warning_dispose'; // 预警
import dataServiceManagement from './modules/dataService_management'; // 数据接入系统

// 大屏 start
import monitorAll from './modules/big_screen/monitor_all'; //监测总览
import ecologicalEnvironment from './modules/big_screen/ecological_environment'; //生态环境
import ontologicalDisease from './modules/big_screen/ontological_disease'; //本体病害
import protectProject from './modules/big_screen/protect_project'; //保护工程
import dailyInspect from './modules/big_screen/daily_inspect'; //日常巡查
import buildControl from './modules/big_screen/build_control'; //建设控制
import teaCulture from './modules/big_screen/tea_culture'; //茶文化
// 大屏 end

// 系统2 林业局 start
import lyjCommon from './modules/lyj/common'; //工作台面
import lyjDataAdmin from './modules/lyj/data_admin'; //数据管理
import lyjInspectAdmin from './modules/lyj/inspect_admin'; //日常管理
import lyjWorkDesk from './modules/lyj/work_desk'; //工作台面
// 系统2 林业局 end

// 系统3 茶特中心 start
import ctzxCommon from './modules/ctzx/common'; //工作台面
import ctzxDataAdmin from './modules/ctzx/data_admin'; //数据管理
import ctzxWorkDesk from './modules/ctzx/work_desk'; //工作台面
// 系统3 林业局 end


Vue.use(Vuex);

export default new Vuex.Store({
    state: {},
    mutations: {},
    actions: {},
    modules: {
        dock,
        common,
        login,
        data_get,
        residence_evaluate,
        monitorAll,
        ecologicalEnvironment,
        ontologicalDisease,
        protectProject,
        dailyInspect,
        buildControl,
        teaCulture,
        monitorCloud,
        basic_data,
        monitor_data,
        userManage,
        expertConsultation,
        analysisAssess,
        warningDispose,
        dataServiceManagement,
        lyjCommon,
        lyjDataAdmin,
        lyjInspectAdmin,
        lyjWorkDesk,
        ctzxCommon,
        ctzxDataAdmin,
        ctzxWorkDesk
    }
});