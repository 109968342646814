export default {
    path: "/distribution",
    name: "distribution",
    redirect: {
        name: "distribution_basic"
    },
    meta: {
        auth: "30"
    },
    component: () =>
        import("@views/distribution/index.vue"), // 配置页
    children: [
        {
            path: "/warning_plan",
            name: "warning_plan",
            component: () =>
                import("@views/distribution/warning_plan"),
            redirect: { name: 'warning_plan_grid' },
            children: [{
                path: "/warning_plan_grid",
                name: "warning_plan_grid",
                component: () =>
                    import("@views/distribution/warning_plan/grid")
            },
            {
                path: "/warning_plan_form",
                name: "warning_plan_form",
                component: () =>
                    import("@views/distribution/warning_plan/form")
            }
            ]
        },
        {
            path: "/warning_object_set",
            name: "warning_object_set",
            component: () =>
                import("@views/distribution/warning_object_set"),
            redirect: { name: 'warning_object_set_grid' },
            children: [{
                path: "/warning_object_set_grid",
                name: "warning_object_set_grid",
                component: () =>
                    import("@views/distribution/warning_object_set/grid")
            }]
        },

        {
            path: "/warning_config",
            name: "warning_config",
            component: () =>
                import("@views/distribution/warning_config"),
            redirect: { name: 'warning_config_grid' },
            children: [{
                path: "/warning_config_grid",
                name: "warning_config_grid",
                component: () =>
                    import("@views/distribution/warning_config/grid")
            },
            {
                path: "/warning_config_form",
                name: "warning_config_form",
                component: () =>
                    import("@views/distribution/warning_config/form")
            }
            ]
        }
    ],
};