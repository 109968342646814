const commonData = {
    loading: false,
    snTable: {
        table: {
            data: [],
            rowKey: 'ID',
            treeProps: {
                children: 'Children',
                hasChildren: 'hasChildren'
            }
        },
        columns: {
            // 选择、序号
            columnsOperate: [],
            // 数据列
            columnsData: [],
            // 操作列
            columnsBtn: {
                width: '200',
                buttonData: [
                    {
                        showMethod: 'showEditBtn',
                        btnType: 'icon',
                        operateType: 'columnEdit',
                        title: '配置',
                        icon: 'el-icon-s-tools',
                        color: '#20a0ff',
                        class: 'one'
                    },
                    {
                        isShow: true,
                        showMethod: 'showPauseBtn',
                        btnType: 'icon',
                        operateType: 'columnPause',
                        title: '暂停生成任务',
                        icon: 'fa fa-pause-circle-o two',
                        color: '#20a0ff',
                    },
                    {
                        isShow: true,
                        showMethod: 'showPlayBtnByCJR',
                        btnType: 'icon',
                        operateType: '',
                        title: '请分配采集人再启动任务',
                        icon: 'fa fa-play-circle two',
                        color: '#20a0ff'
                    },
                    {
                        isShow: true,
                        showMethod: 'showPlayBtn',
                        btnType: 'icon',
                        operateType: 'columnPlay',
                        title: '启动任务',
                        icon: 'fa fa-play-circle two',
                        color: '#20a0ff'
                    },
                    {
                        isShow: true,
                        showMethod: 'showAllotBtn',
                        btnType: 'img',
                        operateType: 'columnAllot',
                        title: '分配任务',
                        src: 'monitoring_cloud/distribution.png',
                        class: 'three',
                    },
                    {
                        isShow: true,
                        showMethod: 'showEndBtn',
                        btnType: 'icon',
                        operateType: 'columnEnd',
                        title: '结束任务',
                        icon: 'fa fa-lock four',
                        color: '#20a0ff'
                    },
                    {
                        isShow: true,
                        showMethod: 'showSeeBtn',
                        btnType: 'img',
                        operateType: 'columnSee',
                        title: '查看详情',
                        src: 'monitoring_cloud/detail.png',
                        class: 'five',
                    },
                    {
                        showMethod: 'showDelBtn',
                        btnType: 'icon',
                        operateType: 'columnDelete',
                        title: '删除',
                        icon: 'fa fa-trash-o six',
                        backColor: 'transparent',
                        color: 'red',
                    },
                    {
                        showMethod: 'showEditionAddBtn',
                        btnType: 'icon',
                        operateType: 'columnEditionAdd',
                        title: '新增版本',
                        icon: 'el-icon-refresh',
                        backColor: 'transparent',
                        color: '#20a0ff'
                    },
                ]
            }
        },
    },
    snPage: {
        tiaoshu: 20,
        count: 0,
        currentPage: 1,
        operateType: 'page'
    },
    snCount: {
        eachtotal: [20, 50, 100],
        defaultTiaoshu: 20
    },
    snSearch: {
        inputData: [],
    },
    snButton: {
        buttonData: [
            {
                isShow: true,
                btnType: 'button',
                operateType: 'buttonConfig',
                name: '配置',
                round: true,
                backColor: '#192b81',
                color: '#fff'
            },
            {
                isShow: false,
                btnType: 'button',
                operateType: 'toRcxcMap',
                name: '地图视图',
                round: true,
                backColor: '#28ccd9',
                color: '#fff'
            }
        ]
    }
};
export { commonData };