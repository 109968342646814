import {
    $GET,
    $POST,
    $AXIOS
} from '@request/http.js';

const state = {
    // demo: '', // demo
};

const getters = {
    // btbhMap(state, getters) {
    //     return state.btbhMap;
    // },

};

const mutations = {
    // SET_STATE_S: (state, payload) => {
    //     for (const prop in payload) {
    //         if (payload[prop] !== undefined) {
    //             state[prop] = payload[prop];
    //         }
    //     }
    // },
};

const actions = {
    // 右侧-本体病害-病害监测-基础数据和监测数据
    // async getBhJcsjData({ dispatch }, payload) {
    //     let result = await $GET('LargeShowBtbh/GetBhJcsjData', payload);
    //     return result.ISSUCCESS ? result.RESULTVALUE : [];
    // },

};
export default {
    state,
    getters,
    mutations,
    actions
};