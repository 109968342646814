const SHZT = window.SHZT;
const ROLE_ID = window.ROLE_ID;
const commonMethods = {
    getRowClassMethod(row) {
        const value = row.row;
        let className = '';
        if (!(Number(value.BBH) > 1)) {
            className = 'no-history'
        }
        return className;
    },
    getCjzqText(zq, row) {
        if (row.SFSZQXJC === '0') {
            return '无周期';
        }
        return row.ZQ + row.ZQDW_BM;
    },
    // 显示暂停的按钮
    showPauseBtn(row) {
        if (row.isHis) {
            return false;
        }
        if (!row.JCPZID) {
            return false;
        }
        if ([1].includes(Number(row.ZT))) {
            return true;
        } else {
            return false;
        }
    },
    // 显示开始任务的按钮
    showPlayBtn(row) {
        if (row.isHis) {
            return false;
        }
        if (!row.JCPZID) {
            return false;
        }
        if ([0, 2].includes(Number(row.ZT)) && row.SJCJFZRID) {
            return true;
        } else {
            return false;
        }
    },
    // 显示开始任务按钮--有采集人才能开始
    showPlayBtnByCJR(row) {
        if (row.isHis) {
            return false;
        }
        if (!row.JCPZID) {
            return false;
        }
        if (Number(row.ZT) === 0 && (!row.SJCJFZRID)) {
            return true;
        } else {
            return false;
        }
    },
    // 显示结束任务按钮
    showEndBtn(row) {
        if (row.isHis) {
            return false;
        }
        if (!row.JCPZID) {
            return false;
        }
        if ([1, 2].includes(Number(row.ZT))) {
            return true;
        } else {
            return false;
        }
    },
    // 显示分配任务按钮, 选择框可选择
    showAllotBtn(row) {
        if (row.isHis) {
            return false;
        }
        if (!row.JCPZID) {
            return false;
        }
        if (Number(row.ZT) !== 3) {
            return true;
        } else {
            return false;
        }
    },
    showStart(row) {
        return (row.JCPZID) && Number(row.ZT) === 1;
    },
    showPause(row) {
        return !(Number(row.ZT) === 1) && (row.JCPZID);
    },
    showEdit(row) {
        return !!row.JCPZID;
    },
    showDel(row) {
        return (row.JCPZID);
    },
    // 显示查看按钮
    showSeeBtn(row) {
        if (!row.JCPZID) {
            return false;
        }
        return true;
    },
    // 显示编辑按钮
    showEditBtn(row) {
        if ([0].includes(Number(row.ZT)) && (!row.JCPZID)) {
            return true;
        } else {
            return false;
        }
    },
    // 显示删除按钮
    showDelBtn(row) {
        if (row.isHis) {
            return false;
        }
        if (!row.JCPZID) {
            return false;
        }
        if ([0, 3].includes(Number(row.ZT))) {
            return localStorage.ROLEID === ROLE_ID.CJGLY;
        } else {
            return false;
        }
    },
    // 显示添加子节点的按钮（遗产要素清单）第三级以后不能再添加
    showAddBtn(row) {
        if (row.isHis) {
            return false;
        }
        if (Number(row.SHZT) === Number(SHZT.AUDIT_PASSED) && String(row.YCYSBM).length <= 21) {
            return true;
        } else {
            return false;
        }
    },
    // 显示版本变更的按钮
    showRefreshBtn(row) {
        if (row.isHis) {
            return false;
        }
        if (Number(row.SHZT) === Number(SHZT.AUDIT_PASSED)) {
            return true;
        } else {
            return false;
        }
    },
    showConfigBtn(row) {
        if (row.ZT === null) {
            return true;
        }
        return false;
    },
    showBeforeIcon(row) {
        return !this.showEditBtn(row);
    },
    showEditionAddBtn(row) {
        if (row.isHis) {
            return false;
        }
        return !!row.JCPZID;
    },
    spanMethod({ row, column, rowIndex, columnIndex }, data, expandRowData) {
        if ([0, 1].includes(columnIndex)) {
            if (expandRowData.some(ele => ele.ID === row.ID)) {
                return [2, 1]
            }
        }
    },
};
export { commonMethods };