import Vue from 'vue';
import App from './App.vue'
import store from "./store";
import router from "./router";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
Vue.use(ElementUI)
import "@assets/css/font.css";
import "@assets/font/font-awesome/css/font-awesome.min.css";
import "@assets/css/base.scss";
import "@assets/css/public.scss";
import "@assets/css/map.scss";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import "@assets/css/publicTable.scss";
import "@assets/css/tableGroup.scss";
import "@assets/css/monitoring_cloud.scss";
import "@assets/css/coverStyle.scss";
import "@assets/css/iconfont/iconfont.css";
import "@assets/css/animateSelf.css";
import "@assets/css/globalSkin.scss";
import "vue-dplayer/dist/vue-dplayer.css";

// 引入日历插件
import VCalendar from "v-calendar";
import "v-calendar/lib/v-calendar.min.css";
Vue.use(VCalendar);

// 引入MD5
import md5 from "js-md5";
window.MD5 = md5;

// 公共方法
import common from "@libs/common.js";
Vue.prototype.common = common;

import data_config from "@public/config/data_config.js";
for (const key in data_config) {
    window[key] = data_config[key];
}

// 全局注册公共组件
import components from "@libs/components";
Vue.use(components);

// 引入 echarts
import echarts from "echarts";
Vue.prototype.$echarts = echarts;
import 'echarts-gl'

// 翻译
import fany from "@libs/fanyi";
Vue.prototype.fany = fany;

// 路由拦截
import routerIntercept from "@/router/router-intercept";
routerIntercept(router, store);

// 加载 leaflet 相关插件
import * as L from "leaflet";
Vue.L = Vue.prototype.$L = L;
import "leaflet/dist/leaflet.css";
import "@libs/MousePosition.js";
import "mapbox-gl-leaflet";
import "mapbox-gl/dist/mapbox-gl.js";
import '@libs/leaflet-draw/leaflet-tilelayer-wmts-src.js'
import "@libs/leaflet_plug/moveMarker/leaflet-SnakeAnim.js"; //marker移动
import '@libs/leaflet_plug/moveMarker/leaflet.motion.min.js' //marker移动
// import '@libs/leaflet_plug/moveMarker/leaflet.polylineDecorator.js' //箭头


// 图片浏览查看器 viewerjs
import "viewerjs/dist/viewer.css";
import Viewer from "v-viewer";
Vue.use(Viewer, {
    defaultOptions: {
        zIndex: 9999,
    },
});

import 'xe-utils'
import VXETable from "vxe-table";
import "vxe-table/lib/style.css";
Vue.use(VXETable);

// 引入 animate.css
import animated from "animate.css";
Vue.use(animated);

//引入3D立体轮播图
import Carousel3d from 'vue-carousel-3d';
Vue.use(Carousel3d);

// 大屏模块组件
import BasicModule from "@views/big_screen/comp/BasicModule";
Vue.component("basic-module", BasicModule);

// 地理空间分析库，处理各种地图算法
import * as turf from '@turf/turf'
window.turf = turf

new Vue({
    store,
    router,
    render: h => h(App),
}).$mount('#app')


