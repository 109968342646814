/* eslint-disable no-unused-vars */
// 专家咨询
import { $GET, $POST, $AXIOS } from '@request/http.js';
import { Message } from 'element-ui';
import * as Types from './mutation-type.js';

const state = {
    undoneData: [],
    undoneTotal: 0,
    selectData: null,
    doneData: [],
    doneTotal: 0,
};

const getters = {
    [Types.undoneData]: ({ undoneData }) => undoneData,
    [Types.undoneTotal]: ({ undoneTotal }) => undoneTotal,
    [Types.selectData]: ({ selectData }) => selectData,
    [Types.doneData]: ({ doneData }) => doneData,
    [Types.doneTotal]: ({ doneTotal }) => doneTotal,
};

const mutations = {
    [Types.SET_UNDONEDATA]: (state, undoneData) => state.undoneData = undoneData,
    [Types.SET_UNDONETOTAL]: (state, undoneTotal) => state.undoneTotal = undoneTotal,
    [Types.SET_SELECTDATA]: (state, selectData) => state.selectData = selectData,
    [Types.SET_DONEDATA]: (state, doneData) => state.doneData = doneData,
    [Types.SET_DONETOTAL]: (state, doneTotal) => state.doneTotal = doneTotal,
};

const actions = {
    // 1.咨询专家：获取专家任务列表
    async GetExpertTaskList({ dispatch, commit, state }, payload) {
        let result = await $GET('ExpertConsultation/GetExpertTaskList', payload);
        return result.ResultValue;
    },
    // 1.咨询专家：获取优先级枚举
    async GetEnumItem({ dispatch, commit, state }, payload) {
        let result = await $GET('MonitorData/GetEnumItem', payload);
        return result.ResultValue;
    },
    // 1.咨询专家：获取专家单位列表服务
    async GetExpertCompanyList({ dispatch, commit, state }, payload) {
        let result = await $GET('ExpertConsultation/GetExpertCompanyList', payload);
        return result.ResultValue;
    },
    // 1.咨询专家：获取专家姓名列表服务
    async GetExpertNameList({ dispatch, commit, state }, payload) {
        let result = await $GET('ExpertConsultation/GetExpertNameList', payload);
        return result.ResultValue;
    },
    // 1.咨询专家：获取任务对象类型服务
    async GetTaskObjectType({ dispatch, commit, state }, payload) {
        let result = await $GET('ExpertConsultation/GetTaskObjectType', payload);
        return result.ResultValue;
    },
    // 1.咨询专家：获取任务对象列表服务
    async GetTaskObjectList({ dispatch, commit, state }, payload) {
        let result = await $GET('ExpertConsultation/GetTaskObjectList', payload);
        return result.ResultValue;
    },
    // 1.咨询专家：保存任务信息服务
    async SaveTaskInfo({ dispatch, commit, state }, payload) {
        let result = await $POST('ExpertConsultation/SaveTaskInfo', payload);
        return result;
    },
    // 1.咨询专家：获取任务信息详情服务
    async GetTaskInfoDetails({ dispatch, commit, state }, payload) {
        let result = await $GET('ExpertConsultation/GetTaskInfoDetails', payload);
        return result.ResultValue;
    },
    // 1.咨询专家：保存处置进程服务
    async SaveDealProcess({ dispatch, commit, state }, payload) {
        let result = await $POST('ExpertConsultation/SaveDealProcess', payload);
        return result;
    },
    // 1.咨询专家：获取处置进程界面服务
    async GetDealProcess({ dispatch, commit, state }, payload) {
        let result = await $GET('ExpertConsultation/GetDealProcess', payload);
        return result.ResultValue;
    },
    // 1.咨询专家：删除附件
    async DelFileInfo({ dispatch, commit, state }, payload) {
        let result = await $POST('ExpertConsultation/DelFileInfo', payload);
        return result;
    },



    // 2.专家分析：获取待分析评估列表
    async GetWTExpertAnalysisList({ dispatch, commit }, payload) {
        let result = await $GET('ExpertConsultation/GetWTExpertAnalysisList', payload);
        if (result && result.IsSuccess && result.ResultValue) {
            commit(Types.SET_UNDONEDATA, result.ResultValue.list);
            commit(Types.SET_UNDONETOTAL, result.ResultValue.count);
        } else {
            commit(Types.SET_UNDONEDATA, []);
            commit(Types.SET_UNDONETOTAL, 0);
        }
    },
    // 2.专家分析：获取待分析评估记录详情
    async GetExpertAnalysisTask({ dispatch, commit, state }, payload) {
        let result = await $GET('ExpertConsultation/GetExpertAnalysisTask', payload);
        return result.ResultValue;
    },
    // 2.专家分析：获取已分析评估记录
    async GetSUExpertAnalysisList({ dispatch, commit, state }, payload) {
        let result = await $GET('ExpertConsultation/GetSUExpertAnalysisList', payload);
        // return result.ResultValue;
        if (result && result.IsSuccess && result.ResultValue) {
            commit(Types.SET_DONEDATA, result.ResultValue.list);
            commit(Types.SET_DONETOTAL, result.ResultValue.count);
        } else {
            commit(Types.SET_DONEDATA, []);
            commit(Types.SET_DONETOTAL, 0);
        }
    },
    // 2.专家信息：专家上传评估分析结果
    async SaveExpertAnalysis({ dispatch, commit, state }, payload) {
        let result = await $POST('ExpertConsultation/SaveExpertAnalysis', payload);
        return result;
    },
    // 2.专家分析：获取已分析评估记录详情
    async GetExpertAnalysisDetails({ dispatch, commit, state }, payload) {
        let result = await $GET('ExpertConsultation/GetExpertAnalysisDetails', payload);
        return result.ResultValue;
    },
    // 2.专家分析：获取专家评估结果信息
    async GetDealProcessExpert({ dispatch, commit, state }, payload) {
        let result = await $GET('ExpertConsultation/GetDealProcessExpert', payload);
        return result.ResultValue;
    },



    // 3.专家信息：获取专家信息列表
    async GetExpertList({ dispatch, commit, state }, payload) {
        let result = await $GET('ExpertConsultation/GetExpertList', payload);
        return result.RESULTVALUE;
    },
    // 3.专家信息：获取专家的研究方向树结构
    async GetResearchDirection({ dispatch, commit, state }, payload) {
        let result = await $GET('ExpertConsultation/GetResearchDirection', payload);
        return result.ResultValue;
    },
    // 3.专家信息：保存专家信息服务
    async SaveExpertInfo({ dispatch, commit, state }, payload) {
        let result = await $POST('ExpertConsultation/SaveExpertInfo', payload);
        return result;
    },
    // 3.专家信息：获取专家信息详情服务
    async GetExpertInfoDetails({ dispatch, commit, state }, payload) {
        let result = await $GET('ExpertConsultation/GetExpertInfoDetails', payload);
        return result.ResultValue;
    },
    // 3.专家信息：专家信息删除服务
    async DelExpertInfo({ dispatch, commit, state }, payload) {
        let result = await $POST('ExpertConsultation/DelExpertInfo', payload);
        return result;
    },
    // 3.专家信息：创建账户
    async SaveToUser({ dispatch, commit, state }, payload) {
        let result = await $POST('ExpertConsultation/SaveToUser', payload);
        return result;
    },
};
export default {
    state,
    getters,
    mutations,
    actions
};