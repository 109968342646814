import {
    $GET,
    $POST,
    $AXIOS
} from '@request/http.js';

const state = {
    lyj_work_state: true, // 显示 隐藏 工作台面的 统计
};

const getters = {
    lyj_work_state(state, getters) {
        return state.lyj_work_state;
    },

};

const mutations = {
    SET_LYJ_GZTM: (state, payload) => {
        for (const prop in payload) {
            if (payload[prop] !== undefined) {
                state[prop] = payload[prop];
            }
        }
    },
};

const actions = {
    // 获取个人数据统计
    async GetPersonnelDataStatistic({ dispatch }, payload) {
        let result = await $GET('LyjTableTopStatistics/GetPersonnelDataStatistic', payload);
        return result.ISSUCCESS ? result.RESULTVALUE : [];
    },
    //  获取工作情况数据统计
    async GetWorkStatusStatistic({ dispatch }, payload) {
        let result = await $GET('LyjTableTopStatistics/GetWorkStatusStatistic', payload);
        return result.ISSUCCESS ? result.RESULTVALUE : [];
    },
    //  获取监测数据信息统计
    async GetMonitorDataStatisic({ dispatch }, payload) {
        let result = await $GET('LyjTableTopStatistics/GetMonitorDataStatisic', payload);
        return result.ISSUCCESS ? result.RESULTVALUE : [];
    },

};
export default {
    state,
    getters,
    mutations,
    actions
};