// 基础数据采集模块-附件上传组件
<template>
    <div id="basic-upload">
        <div></div>
        <div v-show="isEdit"
             class="upload-tool">
            <div v-show="!onlyOne || (onlyOne && metaData.length === 0)">
                <el-upload :show-file-list="showDefaultList"
                           :accept="'.' + uploadFormart[basicType].join(',.')"
                           :action="`${BASE_URL}UpLoad/FileSave?LJ=${savePath}`"
                           :multiple="!onlyOne"
                           :limit="limit"
                           :on-success="successUpload"
                           :on-error="errorUpload"
                           :on-progress="progressUpload">
                    <el-button size="small"
                               type="primary"
                               class="upload-btn">点击上传</el-button>
                </el-upload>
                <el-progress v-if="percentage > 0 && percentage < 100"
                             :percentage="percentage"></el-progress>
            </div>

            <span>最多上传{{ limit }}个文件，单个上传文件请控制在{{
                    maxSize
                }}M以内,上传格式限于：{{
                    uploadFormart[basicType].join(",")
                }}</span>
        </div>
        <div v-if="metaData.length > 0"
             class="upload-content">
            <div class="upload-left">
                <ul class="upload-list">
                    <li v-for="(file, index) in metaData"
                        :key="index"
                        class="upload-file"
                        :class="{ current: currentFileIndex === index }"
                        @click="changeFile(index)">
                        <div class="file-img-container">
                            <!-- :preview-src-list="[file.path]" -->
                            <el-image v-if="isPic(file.path)"
                                      class="file-img"
                                      :src="file.path"
                                      fit="cover"
                                      v-viewer>
                            </el-image>
                            <el-image v-else
                                      class="file-img"
                                      fit="none"
                                      :src="getFormartIcon(file.path)">
                            </el-image>
                        </div>
                        <span :title="file.fjmc">{{ file.fjmc }}</span>
                        <!-- 审核状态 -->
                        <img v-if="file.shzt !== 1 && itemId === '24'"
                             class="file-state"
                             :src="getSrc(file, currentFileIndex === index)"
                             alt="" />
                        <div class="file-btn">
                            <button v-show="
                                    isEdit &&
                                    (itemId === '24'
                                        ? file.shzt === 3 || file.shzt === 1
                                        : true)
                                "
                                    type="button"
                                    title="删除"
                                    class="file-btn-delete"
                                    @click.prevent="deleteFile(file.jcsjid, index)"></button>
                            <button type="button"
                                    title="下载"
                                    class="file-btn-download"
                                    @click.prevent="downloadFile(file.path)"></button>
                        </div>
                    </li>
                </ul>
            </div>
            <div v-if="showFileDetail"
                 class="upload-right">
                <sn-button v-if="isCheckShow && showCheckBtn()"
                           :snButton="snButton"
                           @handleChange="handleChange"></sn-button>
                <common-form ref="commonForm"
                             :fromData.sync="metaData[currentFileIndex]"
                             :fromStructure="fromStructure"
                             :isEdit="
                        isEdit &&
                        (itemId === '24'
                            ? metaData[currentFileIndex].shzt === 1 ||
                              metaData[currentFileIndex].shzt === 3
                            : true)
                    ">
                </common-form>
            </div>
        </div>
    </div>
</template>

<script>
import basicFormData from "@comp/form/form_config.js";
import commonForm from "@comp/form/common_form.vue";
import { mapActions } from "vuex";
export default {
    name: "basic-upload",
    components: {
        commonForm,
    },
    props: {
        savePath: {
            default: "basicData",
        },
        limit: {
            default: 5,
        },
        itemId: {
            type: String,
            default: "",
        },
        basicType: {
            type: String,
            default: "document",
        },
        metaData: {
            type: Array,
            default: () => [],
        },
        isEdit: {
            type: Boolean,
            default: true,
        },
        onlyOne: {
            type: Boolean,
            default: false,
        },
        code: {
            type: String,
            default: "JCSJ",
        },
        isCheckShow: {
            type: Boolean,
            default: false,
        },
        showFileDetail: {
            type: Boolean,
            default: true,
        },
        // 最大上传文件大小 单位：M(兆)
        maxSize: {
            type: Number,
            default: 500,
        },
    },
    data () {
        return {
            BASE_URL: window.REQUEST_URL,
            showDefaultList: false,
            uploadFormart: {
                document: [
                    "pdf",
                    "doc",
                    "docx",
                    "xls",
                    "xlsx",
                    "txt",
                    "zip",
                    "rar",
                ],
                picture: [
                    "gif",
                    "jpg",
                    "jpeg",
                    "bmp",
                    "png",
                    "tiff",
                    "rar",
                    "zip",
                ],
                pictureSpecial: ["gif", "jpg", "jpeg", "bmp", "png"], // 针对 遗产要素单体或局部照片，要被选为基准照片，所以只可以上传能直接展示的格式
                imgAndVideo: ["gif", "jpg", "jpeg", "bmp", "png", "mp4"], //针对 非物质文化传承/茶文化/茶叶加工技艺监测数据
                drawing: ["dwg", "rar", "zip"],
                image: [
                    "spot",
                    "tm",
                    "etm",
                    "geoeve",
                    "quickbird",
                    "rar",
                    "zip",
                ],
                fj: ["pdf", "doc", "docx", "xls", "xlsx", "txt", "zip", "rar"],
                documentWordPdf: ["pdf", "doc", "docx"],
            },
            pictureFormat: ["gif", "jpg", "jpeg", "bmp", "png"],
            formartIcon: {
                dwg: "file/dwg",
                xls: "file/xls",
                shp: "file/shp",
                tiff: "file/tiff",
                txt: "file/txt",
                pdf: "file/pdf",
                zip: "file/zip",
                rar: "file/rar",
            },
            currentFileIndex: 0,
            percentage: 0,
            fileNames: {
                document: "wdmc",
                picture: "tpmc",
                pictureSpecial: "tpmc",
                imgAndVideo: "tpmc",
                drawing: "tzmc",
                image: "yxmc",
                documentWordPdf: "wdmc",
            },
            snButton: {
                buttonData: [
                    {
                        isShow: true,
                        btnType: "button",
                        operateType: "savePass",
                        editType: "2",
                        name: "审核通过",
                        round: true,
                        backColor: "#28cdd9",
                        color: "#fff",
                    },
                    {
                        isShow: true,
                        btnType: "button",
                        operateType: "saveNoPass",
                        editType: "2",
                        name: "审核不通过",
                        round: true,
                        backColor: "#6f32ff",
                        color: "#fff",
                    },
                ],
            },
        };
    },
    computed: {
        fromStructure () {
            return basicFormData[this.basicType];
        },
    },
    watch: {
        metaData: {
            handler: function (val) {
                this.fillWdlx(val);
            },
            deep: true,
        },
    },
    mounted () {
        console.log(this.metaData, "=====================");
    },
    methods: {
        ...mapActions(["deleteBasicFile", "DeleteMonitorDataAppendix"]),
        fillWdlx (val) {
            const basicTypeList = basicFormData[this.basicType];
            let labelList = [];
            if (Array.isArray(basicTypeList)) {
                const wdlxList = basicTypeList.find((ele) => {
                    return ele.key === "wdlx";
                });
                if (wdlxList && Array.isArray(wdlxList.options)) {
                    labelList = wdlxList.options.map(({ label, ext }) => ({
                        label,
                        ext,
                    }));
                }
            }
            if (val && val.length > 0) {
                val.forEach((item) => {
                    const lastIndex = item.fjmc && item.fjmc.lastIndexOf(".");
                    if (lastIndex > -1) {
                        const wdlx = item.fjmc
                            .substr(lastIndex + 1)
                            .toUpperCase();
                        const temp = labelList.find((ele) =>
                            ele.ext.includes(wdlx)
                        );
                        const find = temp && temp.label;
                        if (find) {
                            item.wdlx = find;
                        } else {
                            item.wdlx = "其他";
                        }
                    }
                });
            }
        },
        // 判断是否为图片格式
        isPic (url) {
            if (!url) return false;
            let fileFormat = url.split(".").pop().toLowerCase();
            return this.pictureFormat.indexOf(fileFormat) !== -1;
        },
        getFormartIcon (url) {
            let fileFormat = url.split(".").pop().toLowerCase();
            let icon = this.formartIcon[fileFormat] || "file/normal";
            return require("@image/" + icon + ".png");
        },
        successUpload (response, file, fileList) {
            this.percentage = 0;
            let newFile = {};
            if (response.IsSuccess) {
                console.log("上传成功", response, file, fileList);
                let result = response.ResultValue[0];
                newFile.fjmc = result.FileName;
                newFile.path = result.FilePath;
                newFile.jcsjid = result.Guid;
                let fileMc = this.fileNames[this.basicType];
                newFile[fileMc] = result.FileName.substring(
                    0,
                    result.FileName.lastIndexOf(".")
                );
                newFile.code = this.code;
                newFile.shzt = 1;
                this.metaData.push(newFile);
                this.changeFile(this.metaData.length - 1);
            }
        },
        errorUpload (error, file, fileList) {
            this.percentage = 0;
            console.log("上传失败");
            this.$message({
                message: "上传失败",
                type: "error",
            });
        },
        progressUpload (e, file, fileList) {
            this.percentage = Math.floor(file.percentage);
            console.log("上传中", e, file, fileList);
        },
        changeFile (index) {
            this.currentFileIndex = index;
        },
        async deleteFile (fileID, index) {
            let ItemID = this.$route.query.ItemID;
            let isYT = ItemID === "28" ? true : false; // 是否为舆图
            if (ItemID === "28") {
                // 舆图类可以切换，不能用query
                ItemID = this.basicType === "picture" ? "2801" : "2802";
            }
            let arg = {
                fjid: fileID,
                ItemID: ItemID,
            };

            let storeBasic =
                JSON.parse(localStorage.getItem("basicCurrent")) || {};
            let storeMonitor =
                JSON.parse(localStorage.getItem("monitorCurrent")) || {};
            let result;
            if (
                storeBasic.ItemID === arg.ItemID ||
                (isYT && storeBasic.ItemID === ItemID.slice(0, 2))
            ) {
                result = await this.deleteBasicFile(arg);
            } else if (storeMonitor.ItemID === arg.ItemID) {
                result = await this.DeleteMonitorDataAppendix(arg);
            }
            console.log(result, "result");
            if (result.IsSuccess) {
                this.metaData.splice(index, 1);
                let mateLength = this.metaData.length;
                let currentIndex = mateLength > index ? index : mateLength - 1;
                this.changeFile(currentIndex);
            }
        },
        downloadFile (url) {
            window.open(url, "_blank");
        },
        validate () {
            return this.$refs.commonForm && this.$refs.commonForm.checkForm();
        },
        getSrc (file, isCurrent) {
            let src = isCurrent ? file.shzt : String(file.shzt) + file.shzt;
            let imgSrc = require("@image/public/" + src + ".png");
            return imgSrc;
        },
        showCheckBtn () {
            return (
                this.metaData[this.currentFileIndex].shzt !== 2 &&
                this.metaData[this.currentFileIndex].shzt !== 3
            );
        },
        handleChange (type) {
            this.$emit(
                "handleChange",
                type,
                this.metaData[this.currentFileIndex].id
            );
        },
        beforeUpload (file) {
            if (this.metaData.length >= this.limit) {
                this.$message({
                    message: `您的上传文件数量已经达到上限`,
                    type: "warning",
                });
                return false;
            }
            if (this.maxSize && file.size > this.maxSize * 1000000) {
                this.$message({
                    message: `请勿上传大于${this.maxSize}M的文件`,
                    type: "warning",
                });
                return false;
            }
        },
    },
};
</script>

<style lang="scss" scoped>
#basic-upload {
    // border: solid 1px #f00;
    padding: 10px 0px 10px 20px;
    .upload-tool {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        height: 50px;
        .upload-btn {
            height: 35px;
            width: 260px;
            border: none;
            border-radius: 3px;
            background: #28cdd9;
            color: #fff;
            font-size: 15px;
        }
        span {
            font-size: 12px;
            padding-left: 20px;
            line-height: 35px;
        }
    }
    .upload-content {
        display: flex;
        flex-flow: row nowrap;
        .upload-left {
            // border: solid 1px #f00;
            flex: 0 0 260px;
            .upload-list {
                min-height: 300px;
                background: #fff;
                .upload-file {
                    height: 50px;
                    line-height: 16px;
                    padding: 0px 10px;
                    border-top: solid 1px #ccc;
                    display: flex;
                    position: relative;
                    flex-flow: row nowrap;
                    align-items: center;
                    z-index: 1;
                    &:first-child {
                        border: none;
                    }
                    .file-img-container {
                        flex: 0 0 50px;
                        position: relative;
                        height: calc(100% - 10px);
                        display: flex;
                        cursor: pointer;
                        align-items: center;
                        .file-img {
                            width: 100%;
                            height: 100%;
                        }
                    }
                    span {
                        text-align: left;
                        color: #333;
                        padding-left: 5px;
                        word-break: break-all;
                        max-height: 100%;
                        overflow-y: hidden;
                    }
                    .file-state {
                        position: absolute;
                        top: 0;
                        right: 0;
                    }
                    .file-btn {
                        width: 80px;
                        height: 100%;
                        position: absolute;
                        display: none;
                        flex-flow: row nowrap;
                        justify-content: space-between;
                        align-content: center;
                        right: -80px;
                        border-left: solid 1px #fff;
                        background-color: #85a3c3;
                        border-radius: 0 5px 5px 0;
                        .file-btn-delete {
                            flex: 1 1 50%;
                            border: none;
                            cursor: pointer;
                            background: url('~@image/public/delete.png')
                                no-repeat center;
                        }
                        .file-btn-download {
                            flex: 1 1 50%;
                            border: none;
                            cursor: pointer;
                            background: url('~@image/public/download.png')
                                no-repeat center;
                        }
                    }
                }
                .current {
                    height: 80px;
                    line-height: 20px;
                    background: #85a3c3;
                    .file-img-container {
                        flex: 0 0 80px;
                    }
                    span {
                        color: #fff;
                    }
                    &:hover .file-btn {
                        display: flex;
                    }
                }
            }
        }
        .upload-right {
            // border: solid 1px #f00;
            flex: auto;
        }
    }
}
</style>
