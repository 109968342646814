export const self = {
    loading: false,
    snTable: {
        table: {
            data: [],
            rowKey: 'JCPZID',
            treeProps: {
                children: 'Children',
                hasChildren: 'hasChildren'
            }
        },
        columns: {
            // 选择、序号
            columnsOperate: [
                // {
                //     isShow: true,
                //     type: 'selection',
                //     width: 35
                // }, 
                {
                    isShow: true,
                    label: '序号',
                    type: 'index',
                    width: 50
                },

            ],
            // 数据列
            columnsData: [
                {
                    isShow: true,
                    label: '名称',
                    prop: 'JCPZFAMC',
                    minWidth: 105,
                    expanded:true
                },
                {
                    isShow: true,
                    label: '配置状态',
                    prop: 'ZT_BM',
                    minWidth: 105
                },
                {
                    isShow: true,
                    label: '采集方式',
                    prop: 'LRFS',
                    minWidth: 65,
                    transList: {
                        0: '系统平台',
                        1: '设备采集',
                        2: '移动采集',
                    }
                },
                {
                    isShow: true,
                    label: '数据采集负责人',
                    prop: 'SJCJFZRMC',
                    minWidth: 70
                },
                {
                    isShow: true,
                    label: '采集周期',
                    prop: 'ZQ',
                    prop1: 'ZQDW_BM',
                    minWidth: 50,
                    transMethod: 'getCjzqText'
                },
            ],
            // 操作列
            columnsBtn: {
                width: 200,
                buttonData: [
                    {
                        isShow: true,
                        showMethod: 'showPauseBtn',
                        btnType: 'icon',
                        operateType: 'columnPause',
                        title: '暂停生成任务',
                        icon: 'fa fa-pause-circle-o one',
                        color: '#20a0ff',
                        class: 'one'
                    },
                    {
                        isShow: true,
                        showMethod: 'showPlayBtn',
                        btnType: 'icon',
                        operateType: 'columnPlay',
                        title: '启动任务',
                        icon: 'fa fa-play-circle one',
                        color: '#20a0ff',
                        class: 'one'
                    },
                    {
                        isShow: true,
                        showMethod: 'showEndBtn',
                        btnType: 'icon',
                        operateType: 'columnEnd',
                        title: '结束任务',
                        icon: 'fa fa-lock two',
                        color: '#20a0ff',
                        class: 'two'
                    },
                    {
                        isShow: true,
                        btnType: 'img',
                        operateType: 'columnSee',
                        title: '查看详情',
                        src: 'monitoring_cloud/detail.png',
                        class: 'three',
                    },
                    {
                        isShow: false,
                        showMethod: 'showDelBtn',
                        btnType: 'icon',
                        operateType: 'columnDelete',
                        title: '删除',
                        icon: 'fa fa-trash-o four',
                        backColor: 'transparent',
                        color: 'red'
                    },
                    {
                        showMethod: 'showEditionAddBtn',
                        btnType: 'icon',
                        operateType: 'columnEditionAdd',
                        title: '新增版本',
                        icon: 'el-icon-refresh',
                        backColor: 'transparent',
                        color: '#20a0ff'
                    },
                ]
            }
        },
    },
    snPage: {
        tiaoshu: 20,
        count: 0,
        currentPage: 1,
        operateType: 'page'
    },
    snCount: {
        eachtotal: [20, 50, 100],
        defaultTiaoshu: 20
    },
    snSearch: {
        inputData: [
            {
                label: '关键字',
                placeholder: "请输入关键字",
                value: 'keyword',
                operateType: 'search',
                isShow: true
            },
        ],
        selectData: [],
        searchBtn: {
            buttonData: [
                {
                    btnType: 'button',
                    operateType: 'searchBtn',
                    name: '查询',
                    round: true,
                    backColor: '#2a63d5',
                    color: '#fff'
                }
            ]
        }
    },
    snButton: {
        buttonData: [
            {
                isShow: true,
                btnType: 'button',
                operateType: 'buttonConfig',
                name: '配置',
                round: true,
                backColor: '#192b81',
                color: '#fff',
                disabled: false
            },
        ]
    }
};
