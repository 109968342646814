<template>
    <div class="sn_load">
        <span class="el-icon-loading"></span>
    </div>
</template>
<script>
export default {
    name: 'sn-load',
    props: {
    },
    data() {
        return {
        };
    },
    computed: {},
    mounted() {},
    methods: {
    }
};
</script>
<style lang="scss" scoped>
</style>