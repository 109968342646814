export default {
    path: '/analysis_assess',
    name: 'analysis_assess',
    redirect: { name: 'analysis_assesss' },
    meta: {
        auth: "40"
    },
    component: () =>
        import ("@views/analysis_assess"),
    children: [{
        //     path: '/statistic_analysis', // 统计分析
        //     name: 'statistic_analysis',
        //     meta: {
        //         auth: "401"
        //     },
        //     component: () =>
        //         import ("@views/analysis_assess/statistic_analysis"),
        // }, {
        path: '/annual_report', // 监测年报
        name: 'annual_report',
        meta: {
            auth: "401"
        },
        component: () =>
            import ("@views/analysis_assess/annual_report"),
    }, {
        //     path: '/monthly_report', // 月度报告
        //     name: 'monthly_report',
        //     meta: {
        //         auth: "403"
        //     },
        //     component: () =>
        //         import ("@views/analysis_assess/monthly_report"),
        // }, {
        path: '/report_manage', // 报告管理
        name: 'report_manage',
        meta: {
            auth: "402"
        },
        component: () =>
            import ("@views/analysis_assess/report_manage"),
        redirect: { name: 'report_manage_grid' },
        children: [{
            path: '/report_manage_grid',
            name: 'report_manage_grid',
            component: () =>
                import ("@views/analysis_assess/report_manage/report_manage_grid")
        }, {
            path: '/report_manage_form',
            name: 'report_manage_form',
            component: () =>
                import ("@views/analysis_assess/report_manage/report_manage_form")
        }, {
            path: '/report_manage_up',
            name: 'report_manage_up',
            component: () =>
                import ("@views/analysis_assess/report_manage/report_manage_up")
        }]
    }, {
        //     path: '/model_manage', // 模型管理
        //     name: 'model_manage',
        //     meta: {
        //         auth: "405"
        //     },
        //     component: () =>
        //         import ("@views/analysis_assess/model_manage"),
        // }, {
        path: '/analysis_assesss', // 分析评估
        name: 'analysis_assesss',
        meta: {
            auth: "403"
        },
        component: () =>
            import ("@views/analysis_assess/analysis_assess"),
        redirect: { name: 'assess_target_grid' },
        children: [{
            path: '/assess_target_grid',
            name: 'assess_target_grid',
            component: () =>
                import ("@views/analysis_assess/analysis_assess/1_assess_target_grid")
        }, {
            path: '/assess_target_form',
            name: 'assess_target_form',
            component: () =>
                import ("@views/analysis_assess/analysis_assess/1_assess_target_form")
        }, {
            path: '/assess_record_grid',
            name: 'assess_record_grid',
            component: () =>
                import ("@views/analysis_assess/analysis_assess/2_assess_record_grid")
        }, {
            path: '/assess_record_form',
            name: 'assess_record_form',
            component: () =>
                import ("@views/analysis_assess/analysis_assess/2_assess_record_form")
        }, {
            path: '/assess_config_grid',
            name: 'assess_config_grid',
            component: () =>
                import ("@views/analysis_assess/analysis_assess/3_assess_config_grid")
        }, {
            path: '/assess_config_form',
            name: 'assess_config_form',
            component: () =>
                import ("@views/analysis_assess/analysis_assess/3_assess_config_form")
        }]
    }, {
        path: '/assess_result', // 评估结果
        name: 'assess_result',
        meta: {
            auth: "404"
        },
        component: () =>
            import ("@views/analysis_assess/assess_result"),
        redirect: { name: 'assess_result_list' },
        children: [{
            path: '/assess_result_list',
            name: 'assess_result_list',
            component: () =>
                import ("@views/analysis_assess/assess_result/assess_result_list")
        }]
    }]
}