import { $GET, $POST } from '@request/http.js';
import { Message } from 'element-ui';

const state = {
    tableData: null,
};

const getters = {

};

const mutations = {

};

const actions = {
    // 获取基础数据列表
    async getBasicList({ dispatch, commit }, payload) {
        let result = await $GET('GatherPart/GetBasicList', payload);
        return result;
    },
    // 获得基础数据和监测数据左侧列表服务
    async GetBasicLeftTree({ dispatch, commit }, args) {
        let result = await $GET('DataCount/GetLeftMenuTree', args);
        return result.ResultValue;
    },
    // 删除附件服务
    async deleteBasicFile({ dispatch, commit }, args) {
        let result = await $GET('GatherPart/DeleteJCSJAppendix', args);
        return result;
    },
    // 基础数据保存服务
    async saveBasicData({ dispatch, commit }, args) {
        let result = await $POST('GatherPart/SaveBasicData', args);
        return result;
    },
    // 基础数据 审核服务  
    async updateDataSHZT({ dispatch, commit }, args) {
        let result = await $GET('GatherPart/UpdateJCSJSHZT', args);
        return result;
    },
    // 基础数据 保护展示与环境整治工程记录/附件审核
    async UpdateJCSJBhgcFilesSHZT({ dispatch, commit }, args) {
        let result = await $GET('GatherPart/UpdateJCSJBhgcFilesSHZT', args);
        return result;
    },
    // 基础数据获取详情服务
    async getGatherData({ dispatch, commit }, args) {
        let result = await $GET('GatherPart/GetBasicDataDetail', args);
        return result;
    },
    // 获得遗产要素列表 接口暂时废弃，该调用监测数据里 获取遗产要素的接口
    async getYcysList({ dispatch, commit }, args) {
        let result = await $GET('OperationandMaintenanceManagement/GetYCYSList', args);
        return result;
    },
    // 根据遗产要素获取遗产组成 保护工程专用
    async GetYczcListByYcys({ dispatch, commit }, args) {
        let result = await $GET('LargeShowMonitoringOverview/GetYczcListByYcys', args);
        return result;
    },
    // 根据遗产组成获取遗产要素 保护工程专用
    async GetYcysListByYczc({ dispatch, commit }, args) {
        let result = await $GET('LargeShowMonitoringOverview/GetYcysListByYczc', args);
        return result;
    },
    // 创建病害编号
    async createBHBH({ dispatch, commit }, args) {
        let result = await $GET('GatherPart/CreateBtyztbhBHBH', args);
        return result;
    },
    // 获取遗产要素单体局部测绘图
    async GetBhlmtList({ dispatch, commit }, args) {
        let result = await $GET('GatherPart/GetBhlmtList', args);
        return result;
    },
    // 定义描述与注意事项服务
    async GetDefinitionAndNote({ dispatch, commit }, args) {
        let result = await $GET('GatherPart/GetDefinitionAndNote', args);
        return result;
    },
    // 删除基础数据服务
    async deleteBasicData({ dispatch, commit }, args) {
        let result = await $GET('GatherPart/DeleteJCSJAllMessage', args);
        return result;
    },
    // 获取承诺事项来源列表
    async getcnsxlyList({ dispatch, commit }, args) {
        let result = await $GET('GatherPart/GetCnsxly', args);
        return result;
    },
    // 根据遗产要素获取病害类型
    async getbhlxList({ dispatch, commit }, args) {
        let result = await $GET('GatherPart/GetBHDCJLType', args);
        return result;
    },
    // 删除保护区划管理遗产区/缓冲区服务
    async DeleteBhqhYcqOrHcq({ dispatch, commit }, args) {
        let result = await $GET('GatherPart/DeleteBhqhYcqOrHcq', args);
        return result;
    },
    // 部门和人员
    async GetDepartmentAndUser({ dispatch, commit }, args) {
        let result = await $GET('OperationandMaintenanceManagement/GetDepartmentAndUser', args);
        return result;
    },
};
export default {
    state,
    getters,
    mutations,
    actions
};