<template>
    <div class="noDataPart">
        <div
            v-if="noDataState"
            class="noDataText"
            :style="{ color: color, fontSize: fontSize }"
        >
            {{ noDataText }}
        </div>
        <div v-else class="warpImg" :class="`warpImgType${typeNum}`">
            <img
                class="img"
                :src="require(`@image/noImg/type${typeNum}.png`)"
                :style="{ width: width }"
            />
        </div>
    </div>
</template>
<script>
export default {
    name: "",
    components: {},
    props: {
        noDataState: {
            type: Boolean,
            default: false,
        },
        noDataText: {
            type: String,
            default: "暂无数据",
        },
        color: {
            type: String,
            default: "#a8c8ff",
        },
        fontSize: {
            type: String,
            default: "24px",
        },
        typeNum: {
            type: String,
            default: "0", //0 黑色 1 白色
        },
        width: {
            type: String,
            default: "200px",
        },
    },
    data() {
        return {};
    },
    computed: {},
    watch: {},
    mounted() {},
    methods: {},
};
</script>
<style scoped lang="scss">
.noDataPart {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    .warpImg {
        width: 100%;
        height: 100%;

        .img {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }
    .noDataText {
        position: absolute;
        top: 50%;
        left: 50%;
        // width: 100px;
        transform: translate(-50%, -50%) rotate(-30deg);
        z-index: 2000;
    }
}

.warpImgType0 {
    border: 1px solid #35507c;
    background: #142d4e;
    opacity: 0.75;
}

.warpImgType1 {
    border: 1px solid #e5e5e5;
    background: #ffffff;
}
</style>
