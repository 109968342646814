<template>
    <div
        :class="[
            treeselectData.label ? 'flex flex-y-center' : 'box-inline-block',
        ]"
    >
        <!-- 下拉框 -->
        <span v-if="treeselectData.label" class="sn_search_label">{{
            treeselectData.label
        }}</span>
        <treeselect
            v-if="treeselectData && treeselectData.isShow"
            v-model="currentValue"
            :disabled="treeselectData.disabled"
            size="mini"
            class="option sn_vue_tree_select"
            :class="treeselectData.class"
            :placeholder="treeselectData.placeholder"
            :multiple="treeselectData.multiple || false"
            collapse-tags
            :clearable="treeselectData.clearable || false"
            filterable
            :popper-append-to-body="false"
            :style="{ width: treeselectData.width || null }"
            :options="treeselectData.list"
            :defaultExpandLevel="Infinity"
            :disableBranchNodes="disableBranchNodes"
            :searchable="treeselectData.searchable || false"
            :alwaysOpen="false"
            :valueConsistsOf="'ALL'"
            clearAllText=""
            clearValueText=""
            @input="handleinputed"
            @open="handleOpened"
        />
    </div>
</template>
<script>
import Treeselect from "@riophae/vue-treeselect";
export default {
    name: "sn_search_item",
    props: {
        treeselectData: {
            default: function () {
                return {
                    disabled: false,
                };
            },
        },
        treeselectValue: {
            type: [String, Array],
            default: "",
        },
    },
    data() {
        return {
            currentValue: null,
        };
    },
    components: {
        Treeselect,
    },
    computed: {
        disableBranchNodes() {
            return this.treeselectData.hasOwnProperty("disableBranchNodes")
                ? this.treeselectData.disableBranchNodes
                : true;
        },
    },
    watch: {
        // treeselectData: {
        //     immediate: true,
        //     handler() {
        //         if (this.treeselectData.selectValue) {
        //             this.currentValue = this.treeselectData.selectValue;
        //         } else {
        //             this.currentValue = null;
        //         }
        //     },
        //     deep: true,
        // },
        treeselectValue: {
            handler() {
                if (this.treeselectValue) {
                    this.currentValue = this.treeselectValue;
                } else {
                    this.currentValue = null;
                }
            },
            immediate: true,
        },
    },
    methods: {
        btnClick(val) {
            this.$emit("btnClick", val);
        },
        handleinputed(node, instanceId) {
            this.$emit("handleChange", this.treeselectData.operateType, [
                node,
                this.treeselectData.value,
            ]);
        },
        handleOpened() {
            let _this = this;
            $(document).ready(function () {
                _this.$emit("openVueTree");
                let tmp = document.getElementsByClassName(
                    "vue-treeselect__menu"
                );
                if (tmp && tmp.length > 0 && tmp[0].className) {
                    document.getElementsByClassName(
                        "vue-treeselect__menu"
                    )[0].className = "vue-treeselect__menu vue-tree-scrollbar";
                }
            });
        },
    },
};
</script>
<style lang="scss" scoped>
.box-inline-block {
    display: inline-block;
}
</style>
<style lang="scss">
.sn_vue_tree_select {
    * {
        box-sizing: border-box;
    }
    .vue-treeselect__menu {
        margin-top: 5px;
        border-radius: 4px;
        border: 1px solid #e4e7ed;
        background-color: #fff;
        box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
        box-sizing: border-box;
    }
    .vue-treeselect__option--highlight {
        background-color: #f5f7fa;
    }
    .vue-treeselect__list-item {
        font-size: 14px;
        position: relative;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        color: #606266;
        // min-height: 34px;
        line-height: 34px;
        box-sizing: border-box;
        cursor: pointer;
    }
    .vue-treeselect__control {
        height: 28px;
        box-sizing: border-box;
    }
    .vue-treeselect__placeholder {
        line-height: 30px;
        color: rgb(192, 196, 204);
        font-size: 13px;
    }
    .vue-treeselect__single-value {
        font-size: 12px;
        line-height: 28px;
    }
    &.vue-treeselect--open.vue-treeselect--open-below {
        .vue-treeselect__control {
            border-bottom-left-radius: 4px;
            border-bottom-right-radius: 4px;
        }
    }
    &.vue-treeselect--open:not(.selectRound) {
        .vue-treeselect__menu-container {
            position: relative;
            &::before {
                content: "";
                position: absolute;
                top: 2px;
                left: 35px;
                width: 0;
                height: 0;
                border-left: 6px solid transparent;
                border-right: 6px solid transparent;
                border-bottom: 6px solid #fff;
                z-index: 2;
            }
        }
    }
    &.vue-treeselect--has-value {
        .vue-treeselect__input {
            line-height: 1;
            vertical-align: middle;
        }
    }
    &.vue-treeselect--single {
        .vue-treeselect__input-container {
            height: 28px;
        }
        .vue-treeselect__input-container {
            display: flex;
            align-items: center;
        }
    }
    &.vue-treeselect--multi {
        .vue-treeselect__multi-value-label {
            line-height: 16px;
        }
        .vue-treeselect__control {
            height: 32px;
        }
    }
}
</style>