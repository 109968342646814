export default {
    path: "/remoteData",
    name: "remoteData",
    meta: {
        auth: "1",
        title: "遥感专项监测",
    },
    redirect: {
        name: "remoteTable",
    },
    component: () => import("@views/working_desk/remote_sense/index.vue"),
    children: [
        {
            path: "/remoteTable/:ItemID/:MC",
            name: "remoteTable",
            meta: {
                auth: "1",
            },
            component: () =>
                import("@views/working_desk/remote_sense/remote_table.vue"),
        },
        {
            path: "/remote_sense",
            name: "remote_sense",
            meta: {
                title: "遥感专项监测",
            },
            component: () =>
                import("@views/working_desk/remote_sense/table_type/index.vue"),
            itemId: "90305",
        },
    ],
};
