<template>
  <div>
    <el-select
      v-model="yjzbValue"
      :title="isYjzbDisabled ? titleTip : ''"
      class="yjzb-selsect"
      placeholder="请选择预警指标"
      size="mini"
      multiple
      filterable
      :disabled="isYjzbDisabled"
      @change="handleYjzbChanged"
    >
      <el-option
        v-for="(item, index) in yjzbOptions"
        :key="index + item.ZBBM"
        :label="item.ZBMC"
        :value="item.ZBBM"
      >
      </el-option>
    </el-select>
  </div>
</template>
<script>
import { mapActions } from "vuex";
export default {
  props: {
    dataSource: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      yjzbOptions: [], // 预警指标数据列表
      yjzbValue: [], // 预警指标
      isYjzbDisabled: false,
      titleTip: "该预警对象已有配置，不可修改",
    };
  },
  watch: {
    dataSource: {
      immediate: true,
      handler() {
        console.log(1, "dataSource--watch：", this.dataSource);
        this.getYjzbDetail();
      },
    },
  },
  mounted() {},
  methods: {
    ...mapActions([
      "GetYjzbDetail", // 获取预警指标项
      "SaveYjdxData", // 保存预警项数据
    ]),
    handleDataSource() {
      // SJLX：数据类型 ；1：数值类 2：枚举类

      if (this.dataSource.YJZBXZJGZDMC) {
        this.yjzbValue = this.dataSource.YJZBXZJGZDMC.split(",");
      } else {
        this.yjzbValue = [];
      }

      // 控制下拉框是否禁用
      // "ISEDIT": "0"//是否可编辑；1不可编辑
      if (Number(this.dataSource.ISEDIT) === 1) {
        this.isYjzbDisabled = true;
      } else {
        this.isYjzbDisabled = false;
      }
    },
    // 获取预警指标项
    async getYjzbDetail() {
      this.yjzbOptions = [];
      this.yjzbValue = [];
      if (this.yjzbOptions && this.yjzbOptions.length > 0) return;
      if (this.dataSource && this.dataSource.ID) {
        let yjxId = this.dataSource.ID;
        let result = await this.GetYjzbDetail({
          yjxId: yjxId, // string，预警项ID
        });
        if (result && result.length > 0) {
          this.yjzbOptions = result;
          this.handleDataSource();
        }
      }
    },
    handleYjzbChanged(val) {
      console.log(44444, val);
      let YJZBXZJGZDMC = "";
      let YJZBXZJGZSMC = "";
      let nameArr = [];
      if (val && val.length > 0) {
        if (this.yjzbOptions && this.yjzbOptions.length > 0) {
          for (let i = 0; i < val.length; i++) {
            for (let j = 0; j < this.yjzbOptions.length; j++) {
              if (val[i] === this.yjzbOptions[j].ZBBM) {
                nameArr.push(this.yjzbOptions[j].ZBMC);
              }
            }
          }
          YJZBXZJGZDMC = val.toString();
          YJZBXZJGZSMC = nameArr.toString();
        }
      } else {
        // this.$message({
        //     type: "warning",
        //     message: "请选择预警指标"
        // });
      }
      this.saveYjdxData(YJZBXZJGZDMC, YJZBXZJGZSMC);
    },
    // 保存预警项数据
    async saveYjdxData(YJZBXZJGZDMC, YJZBXZJGZSMC) {
      let yjxDto = {
        ID: this.dataSource.ID,
        DXLXBM: this.dataSource.DXLXBM, // 对象类型编码
        DXLXMC: this.dataSource.DXLXMC, // 对象类型名称
        SJLX: this.dataSource.SJLX, // 数据类型；1：数值类 2：枚举类
        DXID: this.dataSource.DXID, // 对象ID
        DXMC: this.dataSource.DXMC, // 对象名称
        YJZBXZJGZDMC: YJZBXZJGZDMC, // 预警指标选择结果ID
        YJZBXZJGZSMC: YJZBXZJGZSMC, // 预警指标选择结果展示名称,多个用逗号分隔
      };
      let result = await this.SaveYjdxData({
        yjxDto: yjxDto, // string，预警项数据-是个对象
      });
      console.log(33333, result);
      if (result.ISSUCCESS) {
        this.$message({
          type: "success",
          message: "保存成功",
        });
      } else {
        this.$message({
          type: "error",
          message: "保存失败",
        });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.yjzb-selsect.el-select {
  width: 100%;
}
</style>