// const temp = {};
const contextA = require.context('../basic/table_config', true, /^\.\/sn-table-group-\w+.js$/);
contextA.keys().forEach(path => {
    let filename = path.substr(2);
    filename = filename.substr(0, filename.length - 3);
    exports[filename] = contextA(path);
});
const contextB = require.context('../monitor/table_config', true, /^\.\/sn-table-group-\w+.js$/);

contextB.keys().forEach(path => {
    let filename = path.substr(2);
    filename = filename.substr(0, filename.length - 3);
    exports[filename] = contextB(path);
});

const basicCommonMethods = require('../basic/table_config/table-common-methods');
const monitorCommonMethods = require('../monitor/table_config/table-common-methods');
exports.commonMethods = {
    basicCommonMethods,
    monitorCommonMethods
}