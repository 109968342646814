<template>
    <viewer
        :options="options"
        :images="imgList"
        @inited="inited"
        v-if="imgList && imgList.length"
        class="viewerAlert"
        ref="viewer"
    >
        <template v-for="(item, index) in imgList">
            <img
                :src="item.thumbnail"
                :data-src="item.src"
                :alt="item.alt"
                :key="index"
            />
        </template>
    </viewer>
</template>

<script>
export default {
    name: "",
    data() {
        return {
            options: {
                url: "data-src",
                title: (image) => {
                    return image.alt;
                },
            },
            index: 0,
        };
    },
    props: {
        imgList: Array,
    },
    mounted() {},
    components: {},
    watch: {},
    methods: {
        inited(viewer) {
            this.$viewer = viewer;
            this.$viewer.view(this.index);
        },
        showViewer(index) {
            if (index) {
                this.index = index;
                this.$viewer.view(this.index);
                // this.$viewer.show(index)
            }
        },
    },
};
</script>

<style scoped>
.viewerAlert {
    display: none;
}
</style>
