<template>
    <div class="">
        <el-dialog
            :close-on-click-modal="false"
            :modal-append-to-body="false"
            :modal="true"
            :append-to-body="appendToBody"
            :title="dialogData.title"
            :visible.sync="dialogData.dialog"
            :width="dialogData.width"
            :class="dialogData.class"
            class="commonDialog"
            @close="close"
        >
            <slot></slot>
            <div v-show="dialogData.action" class="flex-end">
                <el-button
                    type="primary"
                    class="submitBtn"
                    round
                    @click="onSubmit"
                    >确定</el-button
                >
                <el-button
                    type="danger"
                    class="cancelBtn"
                    round
                    @click="dialogData.dialog = false"
                    >取消</el-button
                >
            </div>
        </el-dialog>
    </div>
</template>
<script>
export default {
    name: "sn-dialog",
    components: {},
    props: {
        dialogData: {
            default: function() {
                return {};
            },
        },
        appendToBody: {
            tyle: Boolean,
            default: function() {
                return false;
            },
        },
    },
    data() {
        return {};
    },
    mounted() {},
    methods: {
        onSubmit() {
            this.$emit("onSubmit", this.dialogData);
        },
        close() {
            this.$emit("closeDialog");
        },
    },
};
</script>
<style scoped>
.cancel {
    width: 100px;
    height: 33px;
    background: #de9700;
    text-align: center;
    line-height: 33px;
    border-radius: 5px;
    margin-right: 20px;
    font-size: 14px;
    color: #fff;
}

.confirm {
    width: 100px;
    height: 33px;
    background: #019848;
    text-align: center;
    line-height: 33px;
    border-radius: 5px;
    font-size: 14px;
    color: #fff;
}
</style>
