<template>
    <div class="sn_page">
        <el-pagination
            small
            class="pagination"
            :current-page="currentPage"
            :page-size="tiaoshu"
            layout="prev, pager, next, jumper"
            :total="count"
            :hide-on-single-page="true"
            @current-change="handleCurrentChange"
        >
        </el-pagination>
    </div>
</template>
<script>
export default {
    name: "sn_page",
    props: {
        snPage: {
            default: function () {
                return {};
            },
        },
    },
    data() {
        return {
            count: 20,
            tiaoshu: 10,
            currentPage: 1,
            operateType: "",
        };
    },
    watch: {
        snPage: {
            immediate: true,
            handler: function () {
                this.handleData();
            },
            deep: true,
        },
    },
    mounted() {},
    methods: {
        handleData() {
            this.$nextTick(() => {
                Object.assign(this.$data, this.$options.data());
                Object.keys(this.snPage).forEach((key) => {
                    this[key] = this.snPage[key];
                });
            });
        },
        // 翻页
        handleCurrentChange(val) {
            this.currentPage = val;
            this.$emit("handleChange", this.operateType, [val]);
        },
    },
};
</script>
<style scoped>
</style>