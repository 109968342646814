export default {
    document: [{
        type: "text",
        key: "wdmc",
        label: '文档名称',
        required: true,
        palceholder: '',
        validators: {
            "minlength": 50
        }
    },
    {
        type: "select",
        key: "wdlx",
        label: '文档类型',
        required: false,
        palceholder: '',
        options: [{
            label: 'WORD',
            value: 'WORD',
            ext: ['DOC', 'DOCX']
        },
        {
            label: 'EXCEL',
            value: 'EXCEL',
            ext: ['XLS', 'XLSX']
        },
        {
            label: 'PDF',
            value: 'PDF',
            ext: ['PDF']
        },
        {
            label: 'TXT',
            value: 'TXT',
            ext: ['TXT']
        },
        {
            label: '其它',
            value: '其它',
            ext: []
        },
        ],
        validators: {
            "minlength": 50
        }
    },
    {
        type: "text",
        key: "wdbb",
        label: '文档版本',
        required: false,
        palceholder: '',
        validators: {
            "minlength": 50
        }
    },
    {
        type: "date",
        key: "wdcjsj",
        label: '文档创建时间',
        dateType: 'date',
        valueFormat: 'yyyy-MM-dd',
        required: false,
        palceholder: '',
        validators: {
            "minlength": 50
        }
    },
    {
        type: "text",
        key: "wdzz",
        label: '文档作者',
        required: false,
        palceholder: '',
        validators: {
            "minlength": 50
        }
    },
    {
        type: "text",
        key: "wdbzdw",
        label: '文档编制单位',
        required: false,
        palceholder: '',
        validators: {
            "minlength": 50
        }
    }
    ],
    picture: [{
        type: "text",
        key: "tpmc",
        label: '图片名称',
        required: true,
        palceholder: '',
        validators: {
            "minlength": 50
        }
    },
    {
        type: "text",
        key: "tpsjl",
        label: '图片数据量',
        required: false,
        palceholder: '',
        validators: {
            "minlength": 50
        }
    },
    {
        type: "text",
        key: "pssb",
        label: '拍摄设备',
        required: false,
        palceholder: '',
        validators: {
            "minlength": 50
        }
    },
    {
        type: "select",
        key: "tpgs",
        label: '图片格式',
        required: false,
        palceholder: '',
        options: [{
            label: 'JPEG',
            value: 'JPEG'
        },
        {
            label: 'JPG',
            value: 'JPG'
        },
        {
            label: 'PNG',
            value: 'PNG'
        },
        {
            label: 'TIFF',
            value: 'TIFF'
        },
        {
            label: 'GIF',
            value: 'GIF'
        },
        {
            label: 'BMP',
            value: 'BMP'
        },
        {
            label: '其它',
            value: '其它'
        },
        ],
        validators: {
            "minlength": 50
        }
    },
    {
        type: "date",
        key: "tpscsj",
        label: '图片生成时间',
        dateType: 'date',
        valueFormat: 'yyyy-MM-dd',
        required: false,
        palceholder: '',
        validators: {
            "minlength": 50
        }
    },
    {
        type: "text",
        key: "tpcjzrr",
        label: '图片采集负责人',
        required: false,
        palceholder: '',
        validators: {
            "minlength": 50
        }
    },
    {
        type: "text",
        key: "sjcjdbh",
        label: '图片采集点编号',
        required: false,
        palceholder: '',
        validators: {
            "minlength": 50
        }
    },
    {
        type: "text",
        key: "cjdzbxx",
        label: '采集点坐标信息',
        required: false,
        palceholder: '',
        validators: {
            "minlength": 50
        }
    }

    ],
    pictureSpecial: [{
        type: "text",
        key: "tpmc",
        label: '图片名称',
        required: true,
        palceholder: '',
        validators: {
            "minlength": 50
        }
    },
    {
        type: "text",
        key: "tpsjl",
        label: '图片数据量',
        required: false,
        palceholder: '',
        validators: {
            "minlength": 50
        }
    },
    {
        type: "text",
        key: "pssb",
        label: '拍摄设备',
        required: false,
        palceholder: '',
        validators: {
            "minlength": 50
        }
    },
    {
        type: "select",
        key: "tpgs",
        label: '图片格式',
        required: false,
        palceholder: '',
        options: [{
            label: 'JPEG',
            value: 'JPEG'
        },
        {
            label: 'JPG',
            value: 'JPG'
        },
        {
            label: 'PNG',
            value: 'PNG'
        },
        {
            label: 'TIFF',
            value: 'TIFF'
        },
        {
            label: 'GIF',
            value: 'GIF'
        },
        {
            label: 'BMP',
            value: 'BMP'
        },
        {
            label: '其它',
            value: '其它'
        },
        ],
        validators: {
            "minlength": 50
        }
    },
    {
        type: "date",
        key: "tpscsj",
        label: '图片生成时间',
        dateType: 'date',
        valueFormat: 'yyyy-MM-dd',
        required: false,
        palceholder: '',
        validators: {
            "minlength": 50
        }
    },
    {
        type: "text",
        key: "tpcjzrr",
        label: '图片采集负责人',
        required: false,
        palceholder: '',
        validators: {
            "minlength": 50
        }
    },
    {
        type: "text",
        key: "sjcjdbh",
        label: '图片采集点编号',
        required: false,
        palceholder: '',
        validators: {
            "minlength": 50
        }
    },
    {
        type: "text",
        key: "cjdzbxx",
        label: '采集点坐标信息',
        required: false,
        palceholder: '',
        validators: {
            "minlength": 50
        }
    }
    ],
    imgAndVideo: [{
        type: "text",
        key: "tpmc",
        label: '附件名称',
        required: true,
        palceholder: '',
        validators: {
            "minlength": 50
        }
    },
    {
        type: "text",
        key: "tpsjl",
        label: '附件数据量',
        required: false,
        palceholder: '',
        validators: {
            "minlength": 50
        }
    },
    {
        type: "text",
        key: "pssb",
        label: '拍摄设备',
        required: false,
        palceholder: '',
        validators: {
            "minlength": 50
        }
    },
    {
        type: "select",
        key: "tpgs",
        label: '附件格式',
        required: false,
        palceholder: '',
        options: [{
            label: 'JPEG',
            value: 'JPEG'
        },
        {
            label: 'JPG',
            value: 'JPG'
        },
        {
            label: 'PNG',
            value: 'PNG'
        },
        {
            label: 'TIFF',
            value: 'TIFF'
        },
        {
            label: 'GIF',
            value: 'GIF'
        },
        {
            label: 'BMP',
            value: 'BMP'
        },
        {
            label: 'MP4',
            value: 'MP4'
        },
        {
            label: '其它',
            value: '其它'
        },
        ],
        validators: {
            "minlength": 50
        }
    },
    {
        type: "date",
        key: "tpscsj",
        label: '图片生成时间',
        dateType: 'date',
        valueFormat: 'yyyy-MM-dd',
        required: false,
        palceholder: '',
        validators: {
            "minlength": 50
        }
    },
    {
        type: "text",
        key: "tpcjzrr",
        label: '图片采集负责人',
        required: false,
        palceholder: '',
        validators: {
            "minlength": 50
        }
    },
    {
        type: "text",
        key: "sjcjdbh",
        label: '图片采集点编号',
        required: false,
        palceholder: '',
        validators: {
            "minlength": 50
        }
    },
    {
        type: "text",
        key: "cjdzbxx",
        label: '采集点坐标信息',
        required: false,
        palceholder: '',
        validators: {
            "minlength": 50
        }
    }
    ],
    drawing: [{
        type: "text",
        key: "tzmc",
        label: '图纸名称',
        required: true,
        palceholder: '',
        validators: {
            "minlength": 50
        }
    },
    {
        type: "text",
        key: "tznrlx",
        label: '图纸内容类型',
        required: false,
        palceholder: '',
        validators: {
            "minlength": 50
        }
    },
    {
        type: "select",
        key: "tzgs",
        label: '图纸格式',
        required: false,
        palceholder: '',
        options: [{
            label: 'DWG',
            value: 'DWG'
        },
        {
            label: 'SHIP',
            value: 'SHIP'
        },
        {
            label: 'TIF',
            value: 'TIF'
        },
        {
            label: 'IMG',
            value: 'IMG'
        },
        {
            label: '其它',
            value: '其它'
        },
        ],
        validators: {
            "minlength": 50
        }
    },
    {
        type: "text",
        key: "tzsxblc",
        label: '比例尺',
        required: true,
        palceholder: '',
        validators: {
            "minlength": 50
        }
    },
    {
        type: "text",
        key: "chr",
        label: '测绘人',
        required: false,
        palceholder: '',
        validators: {
            "minlength": 50
        }
    },
    {
        type: "date",
        key: "ctsj",
        label: '成图时间',
        dateType: 'date',
        valueFormat: 'yyyy-MM-dd',
        required: true,
        palceholder: '',
        validators: {
            "minlength": 50
        }
    },
    {
        type: "text",
        key: "chzrdw",
        label: '测绘责任单位',
        required: false,
        palceholder: '',
        validators: {
            "minlength": 50
        }
    },
    {
        type: "pic",
        key: "sytlj",
        label: '示意图',
        required: true,
        palceholder: '',
        uploadUrl: window.REQUEST_URL + 'UpLoad/FileSave/'
    }
    ],
    image: [{
        type: "text",
        key: "yxmc",
        label: '影像名称',
        required: true,
        palceholder: '',
        validators: {
            "minlength": 50
        }
    },
    {
        type: "text",
        key: "yxfbl",
        label: '影像分辨率',
        required: true,
        palceholder: '',
        validators: {
            "minlength": 50
        }
    },
    {
        type: "text",
        key: "yxsjl",
        label: '影像数据量',
        required: false,
        palceholder: '',
        validators: {
            "minlength": 50
        }
    },
    {
        type: "select",
        key: "yxlx",
        label: '影像类型',
        required: false,
        palceholder: '',
        options: [{
            label: 'SPOT',
            value: 'SPOT'
        },
        {
            label: 'TIM',
            value: 'TIM'
        },
        {
            label: 'ETM',
            value: 'ETM'
        },
        {
            label: 'GEOEYE',
            value: 'GEOEYE'
        },
        {
            label: 'QUICKBIRD',
            value: 'QUICKBIRD'
        },
        {
            label: '其它',
            value: '其它'
        },
        ],
        validators: {
            "minlength": 50
        }
    },
    {
        type: "date",
        key: "yxscsj",
        label: '影像生成时间',
        dateType: 'year',
        valueFormat: 'yyyy',
        required: true,
        palceholder: '',
        validators: {
            "minlength": 50
        }
    },
    {
        type: "text",
        key: "yxly",
        label: '影像来源',
        required: false,
        palceholder: '',
        validators: {
            "minlength": 50
        }
    },
    {
        type: "text",
        key: "ysyxcjdw",
        label: '原始影像采集单位',
        required: false,
        palceholder: '',
        validators: {
            "minlength": 50
        }
    },
    {
        type: "text",
        key: "yxcldw",
        label: '影像处理单位',
        required: false,
        palceholder: '',
        validators: {
            "minlength": 50
        }
    },
    {
        type: "text",
        key: "yxfw",
        label: '影像范围',
        required: false,
        palceholder: '',
        validators: {
            "minlength": 50
        }
    },
    {
        type: "pic",
        key: "sytlj",
        label: '示意图',
        required: true,
        palceholder: '',
        uploadUrl: window.REQUEST_URL + 'UpLoad/FileSave/'
    }
    ],
    fj: [
        {
            type: "text",
            key: "fjmc",
            label: '附件名称',
            required: true,
            palceholder: '',
            validators: {
                "minlength": 50
            }
        },
        {
            type: "select",
            key: "wdlx",
            label: '文档类型',
            required: false,
            palceholder: '',
            options: [
                {
                    label: 'WORD',
                    value: 'WORD'
                },
                {
                    label: 'EXCEL',
                    value: 'EXCEL'
                },
                {
                    label: 'PDF',
                    value: 'PDF'
                },
                {
                    label: 'TXT',
                    value: 'TXT'
                },
                {
                    label: '其它',
                    value: '其它'
                },
            ],
            validators: {
                "minlength": 50
            }
        },
        {
            type: "text",
            key: "wdbb",
            label: '文档版本',
            required: false,
            palceholder: '',
            validators: {
                "minlength": 50
            }
        },
        {
            type: "date",
            key: "wdcjsj",
            label: '文档创建时间',
            dateType: 'date',
            valueFormat: 'yyyy-MM-dd',
            required: false,
            palceholder: '',
            validators: {
                "minlength": 50
            }
        },
        {
            type: "text",
            key: "wdzz",
            label: '文档作者',
            required: false,
            palceholder: '',
            validators: {
                "minlength": 50
            }
        },
        {
            type: "text",
            key: "wdbzdw",
            label: '文档编制单位',
            required: false,
            palceholder: '',
            validators: {
                "minlength": 50
            }
        }
    ],
    documentWordPdf: [{
        type: "text",
        key: "wdmc",
        label: '文档名称',
        required: true,
        palceholder: '',
        validators: {
            "minlength": 50
        }
    },
    {
        type: "select",
        key: "wdlx",
        label: '文档类型',
        required: false,
        palceholder: '',
        options: [{
            label: 'WORD',
            value: 'WORD',
            ext: ['DOC', 'DOCX']
        },
        {
            label: 'PDF',
            value: 'PDF',
            ext: ['PDF']
        },
        ],
        validators: {
            "minlength": 50
        }
    },
    {
        type: "text",
        key: "wdbb",
        label: '文档版本',
        required: false,
        palceholder: '',
        validators: {
            "minlength": 50
        }
    },
    {
        type: "date",
        key: "wdcjsj",
        label: '文档创建时间',
        dateType: 'date',
        valueFormat: 'yyyy-MM-dd',
        required: false,
        palceholder: '',
        validators: {
            "minlength": 50
        }
    },
    {
        type: "text",
        key: "wdzz",
        label: '文档作者',
        required: false,
        palceholder: '',
        validators: {
            "minlength": 50
        }
    },
    {
        type: "text",
        key: "wdbzdw",
        label: '文档编制单位',
        required: false,
        palceholder: '',
        validators: {
            "minlength": 50
        }
    }
    ],
};