export default {
    path: "/monitoringdata",
    name: "monitoringdata",
    meta: {
        auth: "1",
        title: "监测数据采集"
    },
    redirect: {
        name: "monitoringTable"
    },
    component: () => import('@views/working_desk/monitoring_data/index.vue'),
    children: [
        {
            path: '/monitoringTable/:ItemID/:MC',
            name: 'monitoringTable',
            meta: {
                auth: "1",
            },
            component: () => import('@views/working_desk/monitoring_data/monitoring_table.vue'),
        },
        {
            path: '/sycnlxqk',
            name: 'sycnlxqk',
            meta: {
                title: "申遗承诺履行情况"
            },
            component: () => import('@views/working_desk/monitoring_data/table_type/1_commit_heritage/sycnlxqk.vue'),
            itemId: '101',
        },
        // 机构与能力建设 start
        {
            path: '/bhgljg',
            name: 'bhgljg',
            meta: {
                title: "保护管理机构"
            },
            component: () => import('@views/working_desk/monitoring_data/table_type/2_institutions_capacity_build/bhgljg.vue'),
            itemId: '20101',

        },
        {
            path: '/jcjg',
            name: 'jcjg',
            meta: {
                title: "监测机构"
            },
            component: () => import('@views/working_desk/monitoring_data/table_type/2_institutions_capacity_build/jcjg.vue'),
            itemId: '20102'
        },
        {
            path: '/zxglbhfggz',
            name: 'zxglbhfggz',
            meta: {
                title: "专项保护管理法规、规章"
            },
            component: () => import('@views/working_desk/monitoring_data/table_type/2_institutions_capacity_build/zxglbhfggz.vue'),
            itemId: '202'
        },
        {
            path: '/bhglxgpxjl',
            name: 'bhglxgpxjl',
            meta: {
                title: "保护管理相关培训记录"
            },
            component: () => import('@views/working_desk/monitoring_data/table_type/2_institutions_capacity_build/bhglxgpxjl.vue'),
            itemId: '203'
        },
        {
            path: '/bhgljf',
            name: 'bhgljf',
            meta: {
                title: "保护管理经费"
            },
            component: () => import('@views/working_desk/monitoring_data/table_type/2_institutions_capacity_build/bhgljf.vue'),
            itemId: '204'
        },
        {
            path: '/glzd',
            name: 'glzd',
            meta: {
                title: "管理制度"
            },
            component: () => import('@views/working_desk/monitoring_data/table_type/2_institutions_capacity_build/glzd.vue'),
            itemId: '205'
        },
        // 机构与能力建设 end
        // 遗产基础信息 start
        {
            path: '/monitor_yczt',
            name: 'monitor_yczt',
            meta: {
                title: "遗产总图"
            },
            component: () => import('@views/working_desk/monitoring_data/table_type/3_heritage_basic_info/monitor_yczt.vue'),
        },
        {
            path: '/monitor_sygnjzt',
            name: 'monitor_sygnjzt',
            meta: {
                title: "使用功能基准图"
            },
            component: () => import('@views/working_desk/monitoring_data/table_type/3_heritage_basic_info/monitor_sygnjzt.vue'),
        },
        {
            path: '/monitor_ycysfbt',
            name: 'monitor_ycysfbt',
            meta: {
                title: "遗产要素分布图"
            },
            component: () => import('@views/working_desk/monitoring_data/table_type/3_heritage_basic_info/monitor_ycysfbt.vue'),
        },
        {
            path: '/ycysdthjbchjzthbzyx',
            name: 'ycysdthjbchjzthbzyx',
            meta: {
                title: "遗产要素单体或局部测绘基准图和标志性影像"
            },
            component: () => import('@views/working_desk/monitoring_data/table_type/3_heritage_basic_info/monitoring_304.vue'),
        },
        {
            path: '/gsmm',
            name: 'gsmm',
            meta: {
                title: "古树名木"
            },
            component: () => import('@views/working_desk/monitoring_data/table_type/3_heritage_basic_info/gsmm.vue'),
            itemId: '306'
        },
        {
            path: '/gcs',
            name: 'gcs',
            meta: {
                title: "古茶树"
            },
            component: () => import('@views/working_desk/monitoring_data/table_type/3_heritage_basic_info/gcs.vue'),
            itemId: '307'
        },
        // 遗产基础信息 end
        // 总体格局变化 start
        {
            path: '/ztgjbhjl',
            name: 'ztgjbhjl',
            meta: {
                title: "总体格局变化记录图"
            },
            component: () => import('@views/working_desk/monitoring_data/table_type/4_overall_pattern_change/ztgjbhjl.vue'),
            itemId: '402'
        },
        {
            path: '/ztgjbhjlMap',
            name: 'ztgjbhjlMap',
            meta: {
                title: "总体格局变化记录图  点击 地图视图"
            },
            component: () => import('@views/working_desk/monitoring_data/table_type/4_overall_pattern_change/ztgjbhjlMap.vue')
        },
        // 总体格局变化 end
        // 遗产使用功能变化 start
        {
            path: '/sygnbhjl',
            name: 'sygnbhjl',
            meta: {
                title: "遗产使用功能变化记录及记录图"
            },
            component: () => import('@views/working_desk/monitoring_data/table_type/5_heritage_function_change/sygnbhjl.vue'),
            itemId: '502'
        },
        // 遗产使用功能变化 end
        // 遗产要素单体 start
        {
            path: '/ycysdtjbyxtjcsj',
            name: 'ycysdtjbyxtjcsj',
            meta: {
                title: "遗产要素单体或局部影像基准数据"
            },
            component: () => import('@views/working_desk/monitoring_data/table_type/6_heritage_monomer/ycysdtjbyxtjcsj.vue'),
            itemId: '602'
        },
        {
            path: '/ycysxzjl',
            name: 'ycysxzjl',
            meta: {
                title: "遗产要素现状记录"
            },
            component: () => import('@views/working_desk/monitoring_data/table_type/6_heritage_monomer/ycysxzjl.vue'),
            itemId: '603'
        },
        // 遗产要素单体 end
        // 本体与载体病害 start
        {
            path: '/diseaseMap',
            name: 'diseaseMap',
            meta: {
                title: "病害分布位置"
            },
            // 在 病害调查监测工作情况记录 点击并地图视图进入
            component: () => import('@views/working_desk/monitoring_data/table_type/7_ontology_carrier_diseases/diseaseMap.vue')
        },
        {
            path: '/bhdcjl',
            name: 'bhdcjl',
            meta: {
                title: "病害分布图及病害调查监测工作情况记录"
            },
            component: () => import('@views/working_desk/monitoring_data/table_type/7_ontology_carrier_diseases/bhdcjl.vue'),
            itemId: '702'
        },
        {
            path: '/bh_lf',
            name: 'bh_lf',
            meta: {
                title: "裂缝",
                itemId: '7050201'
            },
            component: () => import('@views/working_desk/monitoring_data/table_type/7_ontology_carrier_diseases/bh.vue'),
            itemId: '7050201'
        },
        {
            path: '/bh_gjbx',
            name: 'bh_gjbx',
            meta: {
                title: "构建变形",
                itemId: '7050202'
            },
            component: () => import('@views/working_desk/monitoring_data/table_type/7_ontology_carrier_diseases/bh.vue'),
            itemId: '7050202'
        },
        {
            path: '/bh_gjqs',
            name: 'bh_gjqs',
            meta: {
                title: "构建缺失",
                itemId: '7050203'
            },
            component: () => import('@views/working_desk/monitoring_data/table_type/7_ontology_carrier_diseases/bh.vue'),
            itemId: '7050203'
        },
        {
            path: '/bh_gjsd',
            name: 'bh_gjsd',
            meta: {
                title: "构建松动",
                itemId: '7050204'
            },
            component: () => import('@views/working_desk/monitoring_data/table_type/7_ontology_carrier_diseases/bh.vue'),
            itemId: '7050204'
        },
        {
            path: '/bh_zx',
            name: 'bh_zx',
            meta: {
                title: "糟朽",
                itemId: '7050205'
            },
            component: () => import('@views/working_desk/monitoring_data/table_type/7_ontology_carrier_diseases/bh.vue'),
            itemId: '7050205'
        },
        {
            path: '/bh_zwbh',
            name: 'bh_zwbh',
            meta: {
                title: "植物病害",
                itemId: '7050206'
            },
            component: () => import('@views/working_desk/monitoring_data/table_type/7_ontology_carrier_diseases/bh.vue'),
            itemId: '7050206'
        },
        {
            path: '/bh_swbh',
            name: 'bh_swbh',
            meta: {
                title: "生物病害",
                itemId: '7050207'
            },
            component: () => import('@views/working_desk/monitoring_data/table_type/7_ontology_carrier_diseases/bh.vue'),
            itemId: '7050207'
        },
        {
            path: '/bh_bjycj',
            name: 'bh_bjycj',
            meta: {
                title: "不均匀沉降",
                itemId: '7050208'
            },
            component: () => import('@views/working_desk/monitoring_data/table_type/7_ontology_carrier_diseases/bh.vue'),
            itemId: '7050208'
        },


        {
            path: '/bhkzztpg',
            name: 'bhkzztpg',
            meta: {
                title: "病害控制状态评估"
            },
            component: () => import('@views/working_desk/monitoring_data/table_type/7_ontology_carrier_diseases/bhkzztpg.vue'),
            itemId: '704'
        },
        {
            path: '/bchcqbg',
            name: 'bchcqbg',
            meta: {
                title: "病虫害-虫情测报"
            },
            component: () => import('@views/working_desk/monitoring_data/table_type/7_ontology_carrier_diseases/bchcqbg.vue'),
            itemId: '706'
        },
        {
            path: '/gclbchdc',
            name: 'gclbchdc',
            meta: {
                title: "古茶林病虫害调查"
            },
            component: () => import('@views/working_desk/monitoring_data/table_type/7_ontology_carrier_diseases/gclbchdc.vue'),
            itemId: '708'
        },
        // 本体与载体病害 end
        // 自然环境 start
        {
            path: '/zrhjjcgzqkjl',
            name: 'zrhjjcgzqkjl',
            meta: {
                title: "自然环境监测工作情况记录"
            },
            component: () => import('@views/working_desk/monitoring_data/table_type/8_natural_environment/zrhjjcgzqkjl.vue'),
            itemId: '801'
        },
        {
            path: '/zr_qx',
            name: 'zr_qx',
            meta: {
                title: "气象"
            },
            component: () => import('@views/working_desk/monitoring_data/table_type/8_natural_environment/monitor_data/zr_qx.vue'),
            itemId: '8020101'
        },
        {
            path: '/zr_dqzl',
            name: 'zr_dqzl',
            meta: {
                title: "大气质量"
            },
            component: () => import('@views/working_desk/monitoring_data/table_type/8_natural_environment/monitor_data/zr_dqzl.vue'),
            itemId: '8020202'
        },
        {
            path: '/zr_trsq',
            name: 'zr_trsq',
            meta: {
                title: "土壤墒情"
            },
            component: () => import('@views/working_desk/monitoring_data/table_type/8_natural_environment/monitor_data/zr_trsq.vue'),
            itemId: '8020504'
        },
        {
            path: '/zr_sz',
            name: 'zr_sz',
            meta: {
                title: "水质"
            },
            component: () => import('@views/working_desk/monitoring_data/table_type/8_natural_environment/monitor_data/zr_sz.vue'),
            itemId: '8020510'
        },
        {
            path: '/zr_fs',
            name: 'zr_fs',
            meta: {
                title: "风速"
            },
            component: () => import('@views/working_desk/monitoring_data/table_type/8_natural_environment/monitor_data/zr_fs.vue'),
            itemId: '8020511'
        },
        {
            path: '/zr_whj',
            name: 'zr_whj',
            meta: {
                title: "微环境"
            },
            component: () => import('@views/working_desk/monitoring_data/table_type/8_natural_environment/monitor_data/zr_whj.vue'),
            itemId: '8020502'
        },
        {
            path: '/zr_qxzh',
            name: 'zr_qxzh',
            meta: {
                title: "气象灾害"
            },
            component: () => import('@views/working_desk/monitoring_data/table_type/8_natural_environment/monitor_data/zr_qxzh.vue'),
            itemId: '8020512'
        },
        {
            path: '/zr_wdydjl',
            name: 'zr_wdydjl',
            meta: {
                title: "温度月度记录"
            },
            component: () => import('@views/working_desk/monitoring_data/table_type/8_natural_environment/monitor_data/zr_wdydjl.vue'),
            itemId: '8020102'
        },
        {
            path: '/zr_whjqxjc',
            name: 'zr_whjqxjc',
            meta: {
                title: "微环境气象监测"
            },
            component: () => import('@views/working_desk/monitoring_data/table_type/8_natural_environment/monitor_data/zr_whjqxjc.vue'),
            itemId: '8020103'
        },
        {
            path: '/zr_jylydjl',
            name: 'zr_jylydjl',
            meta: {
                title: "降雨量月度记录"
            },
            component: () => import('@views/working_desk/monitoring_data/table_type/8_natural_environment/monitor_data/zr_jylydjl.vue'),
            itemId: '8020104'
        },
        {
            path: '/zr_whjkqzljc',
            name: 'zr_whjkqzljc',
            meta: {
                title: "微环境空气质量监测"
            },
            component: () => import('@views/working_desk/monitoring_data/table_type/8_natural_environment/monitor_data/zr_whjkqzljc.vue'),
            itemId: '8020204'
        },
        {
            path: '/zr_zwdyxjc',
            name: 'zr_zwdyxjc',
            meta: {
                title: "植物多样性监测"
            },
            component: () => import('@views/working_desk/monitoring_data/table_type/8_natural_environment/monitor_data/zr_zwdyxjc.vue'),
            itemId: '8020514'
        },
        {
            path: '/zr_dwdyxjc',
            name: 'zr_dwdyxjc',
            meta: {
                title: "动物多样性监测"
            },
            component: () => import('@views/working_desk/monitoring_data/table_type/8_natural_environment/monitor_data/zr_dwdyxjc.vue'),
            itemId: '8020515'
        },
        {
            path: '/zr_whjc',
            name: 'zr_whjc',
            meta: {
                title: "物候监测"
            },
            component: () => import('@views/working_desk/monitoring_data/table_type/8_natural_environment/monitor_data/zr_whjc.vue'),
            itemId: '8020516'
        },
        {
            path: '/hjyxpg',
            name: 'hjyxpg',
            meta: {
                title: "环境影响评估"
            },
            component: () => import('@views/working_desk/monitoring_data/table_type/8_natural_environment/hjyxpg.vue'),
            itemId: '803'
        },
        {
            path: '/szjl',
            name: 'szjl',
            meta: {
                title: "受灾记录"
            },
            component: () => import('@views/working_desk/monitoring_data/table_type/8_natural_environment/szjl.vue'),
            itemId: '804'
        },
        // 自然环境 end
        // 建设控制 start

        {
            path: '/ycqhcq',
            name: 'ycqhcq',
            meta: {
                title: "保护区划图及保护区划界线描述与管理规定 遗产区缓冲区"
            },
            component: () => import('@views/working_desk/monitoring_data/table_type/9_construct_control/ycqhcq.vue'),
            itemId: '90101'
        },
        {
            path: '/protectLine',
            name: 'protectLine',
            meta: {
                title: "保护区划界线描述与管理规定"
            },
            component: () => import('@views/working_desk/monitoring_data/table_type/9_construct_control/protectLine.vue'),
            itemId: '90102'
        },
        {
            path: '/xjxm',
            name: 'xjxm',
            meta: {
                title: "新建项目"
            },
            component: () => import('@views/working_desk/monitoring_data/table_type/9_construct_control/xjxm.vue'),
            itemId: '90201'
        },
        {
            path: '/residential_construct',
            name: 'residential_construct',
            meta: {
                title: "民居建设"
            },
            component: () => import('@views/working_desk/monitoring_data/table_type/9_construct_control/residential_construct.vue'),
            itemId: '90501'
        }, {
            path: '/residential_construct_monitor',
            name: 'residential_construct_monitor',
            meta: {
                title: "民居建设"
            },
            component: () => import('@views/working_desk/monitoring_data/table_type/9_construct_control/residential_construct_monitor.vue'),
            itemId: '90502'
        },
        {
            path: '/xjxmgzjl',
            name: 'xjxmgzjl',
            meta: {
                title: "新建项目现场环境照片"
            },
            component: () => import('@views/working_desk/monitoring_data/table_type/9_construct_control/xjxmgzjl.vue'),
            itemId: '90203'
        },
        {
            path: "/jgsx_basic",
            name: "jgsx_basic",
            meta: {
                title: "建设控制--景观视线基础数据"
            },
            component: () => import("@views/working_desk/monitoring_data/table_type/9_construct_control/jgsx_basic.vue"),
            itemId: '90301',
        },
        {
            path: "/jgsx_monitor",
            name: "jgsx_monitor",
            meta: {
                title: "建设控制--景观视线监测数据"
            },
            component: () => import("@views/working_desk/monitoring_data/table_type/9_construct_control/jgsx_monitor.vue"),
            itemId: '90302',
        },
        {
            path: '/jgfm_basic',
            name: 'jgfm_basic',
            meta: {
                title: "景观风貌基础数据"
            },
            component: () => import('@views/working_desk/monitoring_data/table_type/9_construct_control/jgfm_basic.vue'),
            itemId: '90303'
        },
        {
            path: '/jgfm_monitor',
            name: 'jgfm_monitor',
            meta: {
                title: "景观风貌监测数据"
            },
            component: () => import('@views/working_desk/monitoring_data/table_type/9_construct_control/jgfm_monitor.vue'),
            itemId: '90304'
        },
        {
            path: '/jgfm_time_list',
            name: 'jgfm_time_list',
            meta: {
                title: "景观风貌时间轴"
            },
            component: () => import('@views/working_desk/monitoring_data/table_type/9_construct_control/jgfm_time_list.vue'),
            itemId: '90304'
        },
        {
            path: '/remotesensing',
            name: 'remotesensing',
            meta: {
                title: "遥感专项监测"
            },
            component: () => import('@views/working_desk/monitoring_data/table_type/9_construct_control/remotesensing/index.vue'),
            itemId: '90305'
        },
        {
            path: '/xmdy_map',
            name: 'xmdy_map',
            meta: {
                title: "项目调研地图"
            },
            component: () => import('@views/working_desk/monitoring_data/table_type/9_construct_control/xmdy/xmdy_map.vue'),
        },
        {
            path: '/xmdy_detail',
            name: 'xmdy_detail',
            meta: {
                title: "项目调研详情"
            },
            component: () => import('@views/working_desk/monitoring_data/table_type/9_construct_control/xmdy/xmdy_detail.vue'),
            itemId: '90401'
        },
        // 建设控制 end
        // 社会环境 start
        {
            path: '/tdlyxzt',
            name: 'tdlyxzt',
            meta: {
                title: "土地利用现状图"
            },
            component: () => import('@views/working_desk/monitoring_data/table_type/10_social_environment/tdlyxzt.vue'),
            itemId: '1001'
        },
        {
            path: '/tdlyght',
            name: 'tdlyght',
            meta: {
                title: "土地利用规划图"
            },
            component: () => import('@views/working_desk/monitoring_data/table_type/10_social_environment/tdlyght.vue'),
            itemId: '1002'
        },
        {
            path: '/ycqhhcqshhjndjc',
            name: 'ycqhhcqshhjndjc',
            meta: {
                title: "遗产区和缓冲区社会环境年度监测记录"
            },
            component: () => import('@views/working_desk/monitoring_data/table_type/10_social_environment/ycqhhcqshhjndjc.vue'),
            itemId: '1003'
        },
        {
            path: '/ycszdshhjndjcjl',
            name: 'ycszdshhjndjcjl',
            meta: {
                title: "遗产所在地社会环境年度监测记录"
            },
            component: () => import('@views/working_desk/monitoring_data/table_type/10_social_environment/ycszdshhjndjcjl.vue'),
            itemId: '1004'
        },
        {
            path: '/ccy',
            name: 'ccy',
            meta: {
                title: "茶产业"
            },
            component: () => import('@views/working_desk/monitoring_data/table_type/10_social_environment/ccy.vue'),
            itemId: '1008'
        },
        // 社会环境 end
        // 旅游与游客管理 start
        {
            path: '/rykl',
            name: 'rykl',
            meta: {
                title: "日游客量"
            },
            component: () => import('@views/working_desk/monitoring_data/table_type/11_tourist_manage/rykl.vue'),
            itemId: '1103'
        },
        {
            path: '/ykglydjl',
            name: 'ykglydjl',
            meta: {
                title: "游客管理月度记录"
            },
            component: () => import('@views/working_desk/monitoring_data/table_type/11_tourist_manage/ykglydjl.vue'),
            itemId: '1102'
        },
        {
            path: '/ykyxpg',
            name: 'ykyxpg',
            meta: {
                title: "游客影响评估 游客影响评估"
            },
            component: () => import('@views/working_desk/monitoring_data/table_type/11_tourist_manage/ykyxpg.vue'),
            itemId: '110101'
        },
        {
            path: '/ykfmyxjl',
            name: 'ykfmyxjl',
            meta: {
                title: "游客影响评估 游客负面影响评估"
            },
            component: () => import('@views/working_desk/monitoring_data/table_type/11_tourist_manage/ykfmyxjl.vue'),
            itemId: '110102'
        },
        {
            path: '/lyxyndjl',
            name: 'lyxyndjl',
            meta: {
                title: "旅游效益年度记录"
            },
            component: () => import('@views/working_desk/monitoring_data/table_type/11_tourist_manage/lyxyndjl.vue'),
            itemId: '1104'
        },
        {
            path: '/monitor',
            name: 'monitor',
            meta: {
                title: "游客量监测区域"
            },
            component: () => import('@views/working_desk/monitoring_data/table_type/11_tourist_manage/monitor.vue'),
            itemId: '1107'
        },
        {
            path: '/ssykl',
            name: 'ssykl',
            meta: {
                title: "瞬时游客量"
            },
            component: () => import('@views/working_desk/monitoring_data/table_type/11_tourist_manage/ssykl.vue'),
            itemId: '1106'
        },
        {
            path: '/klgfsdxczp',
            name: 'klgfsdxczp',
            meta: {
                title: "客流高峰时段现场照片"
            },
            component: () => import('@views/working_desk/monitoring_data/table_type/11_tourist_manage/klgfsdxczp.vue'),
            itemId: '1105'
        },
        // 旅游与游客管理 end
        // 日常巡查 start
        {
            path: '/rcxcycjl',
            name: 'rcxcycjl',
            meta: {
                title: "日常巡查异常记录"
            },
            component: () => import('@views/working_desk/monitoring_data/table_type/12_daily_manage/rcxcycjl.vue'),
            itemId: '1201'
        },
        {
            path: '/rcxcjl',
            name: 'rcxcjl',
            meta: {
                title: "日常巡查记录"
            },
            component: () => import('@views/working_desk/monitoring_data/table_type/12_daily_manage/rcxcjl.vue'),
            itemId: '1202'
        },
        {
            path: '/byywhgcjl',
            name: 'byywhgcjl',
            meta: {
                title: "保养与维护工程记录"
            },
            component: () => import('@views/working_desk/monitoring_data/table_type/12_daily_manage/byywhgcjl.vue'),
            itemId: '1203'
        },
        {
            path: '/yjsbjl',
            name: 'yjsbjl',
            meta: {
                title: "一键上报记录"
            },
            component: () => import('@views/working_desk/monitoring_data/table_type/12_daily_manage/yjsbjl.vue'),
            itemId: '1205'
        },
        // 日常巡查 end
        // 安防消防 start
        {
            path: '/xyaxfxtyjssxx',
            name: 'xyaxfxtyjssxx',
            meta: {
                title: "现有安消防系统硬件设施信息"
            },
            component: () => import('@views/working_desk/monitoring_data/table_type/13_security_fire_protect/xyaxfxtyjssxx.vue'),
            itemId: '1301'
        },
        {
            path: '/glzdhyjyajl',
            name: 'glzdhyjyajl',
            meta: {
                title: "管理制度和应急预案记录"
            },
            component: () => import('@views/working_desk/monitoring_data/table_type/13_security_fire_protect/glzdhyjyajl.vue'),
            itemId: '1302'
        },
        {
            path: '/aqsgjl',
            name: 'aqsgjl',
            meta: {
                title: "安全事故记录"
            },
            component: () => import('@views/working_desk/monitoring_data/table_type/13_security_fire_protect/aqsgjl.vue'),
            itemId: '1303'
        },
        // 安防消防 end
        // 考古挖掘 start
        {
            path: '/kgfjxm',
            name: 'kgfjxm',
            meta: {
                title: "考古发掘项目"
            },
            component: () => import('@views/working_desk/monitoring_data/table_type/14_archaeological_excavation/kgfjxm.vue'),
            itemId: '1401'
        },
        {
            path: '/kgfjjl',
            name: 'kgfjjl',
            meta: {
                title: "考古发掘记录"
            },
            component: () => import('@views/working_desk/monitoring_data/table_type/14_archaeological_excavation/kgfjjl.vue'),
            itemId: '1402'
        },
        {
            path: '/xcgz',
            name: 'xcgz',
            meta: {
                title: "现场跟踪"
            },
            component: () => import('@views/working_desk/monitoring_data/table_type/14_archaeological_excavation/xcgz.vue'),
            itemId: '1403'
        },
        {
            path: '/ctww',
            name: 'ctww',
            meta: {
                title: "出土文物"
            },
            component: () => import('@views/working_desk/monitoring_data/table_type/14_archaeological_excavation/ctww.vue'),
            itemId: '1404'
        },
        // 考古挖掘 end
        // 保护展示与环境整治工程 start
        {
            path: '/bhhjzsyhjzzggjl',
            name: 'bhhjzsyhjzzggjl',
            meta: {
                title: "保护展示与环境整治工程记录"
            },
            component: () => import('@views/working_desk/monitoring_data/table_type/15_protect_environment_project/bhhjzsyhjzzggjl.vue'),
            itemId: '1501'
        },
        {
            path: '/bhgcxczp',
            name: 'bhgcxczp',
            meta: {
                title: "现场照片"
            },
            component: () => import('@views/working_desk/monitoring_data/table_type/15_protect_environment_project/bhgcxczp.vue'),
            itemId: '1503'
        },
        // 保护展示与环境整治工程 end
        // 保护管理规划 start
        {
            path: '/bhglghbzjl',
            name: 'bhglghbzjl',
            meta: {
                title: "保护管理规划编制记录"
            },
            component: () => import('@views/working_desk/monitoring_data/table_type/16_protect_manage_plan/bhglghbzjl.vue'),
            itemId: '1601'
        },
        {
            path: '/xxghzxqkjl',
            name: 'xxghzxqkjl',
            meta: {
                title: "现行规划执行情况记录"
            },
            component: () => import('@views/working_desk/monitoring_data/table_type/16_protect_manage_plan/xxghzxqkjl.vue'),
            itemId: '1602'
        },
        // 保护管理规划 end
        {
            path: '/xsyj',
            name: 'xsyj',
            meta: {
                title: "学术研究"
            },
            component: () => import('@views/working_desk/monitoring_data/table_type/17_academic_research/xsyj.vue'),
            itemId: '171'
        },
        // 宣传教育 start
        {
            path: '/xchdqkjl',
            name: 'xchdqkjl',
            meta: {
                title: "宣传教育情况记录"
            },
            component: () => import('@views/working_desk/monitoring_data/table_type/18_publicity_education/xchdqkjl.vue'),
            itemId: '181'
        },
        {
            path: '/xcjyhdzp',
            name: 'xcjyhdzp',
            meta: {
                title: "宣传教育活动照片"
            },
            component: () => import('@views/working_desk/monitoring_data/table_type/18_publicity_education/xcjyhdzp.vue'),
            itemId: '182'
        },
        // 宣传教育 end
        // 舆情记录 
        {
            path: '/yqjl',
            name: 'yqjl',
            meta: {
                title: "舆情记录"
            },
            component: () => import('@views/working_desk/monitoring_data/table_type/19_public_opinion_monitor/yqjl.vue'),
            itemId: '191'
        },
        //  非物质文化遗产传承
        // 茶文化 start
        {
            path: '/cyjgjy_basic',
            name: 'cyjgjy_basic',
            meta: {
                title: "茶叶加工技艺基础数据"
            },
            component: () => import('@views/working_desk/monitoring_data/table_type/20_noMmaterial_heritage/tea_culture/cyjgjy_basic.vue'),
            itemId: '500101'
        },
        {
            // 监测数据table页面 显示的是基础数据
            path: '/cyjgjy_monitor',
            name: 'cyjgjy_monitor',
            meta: {
                title: "茶叶加工技艺监测数据"
            },
            component: () => import('@views/working_desk/monitoring_data/table_type/20_noMmaterial_heritage/tea_culture/cyjgjy_monitor.vue'),
            itemId: '500102'
        },
        {
            path: '/tsdcylyct',
            name: 'tsdcylyct',
            meta: {
                title: "特殊的茶叶利用传统"
            },
            component: () => import('@views/working_desk/monitoring_data/table_type/20_noMmaterial_heritage/tea_culture/tsdcylyct.vue'),
            itemId: '500103'
        },
        // 茶文化 end
        // 民族文化
        {
            path: '/nation_basic',
            name: 'nation_basic',
            meta: {
                title: "民族文化基础数据"
            },
            component: () => import('@views/working_desk/monitoring_data/table_type/20_noMmaterial_heritage/nation_culture/nation_basic.vue'),
            itemId: '500201'
        },
        {
            path: '/nation_monitor',
            name: 'nation_monitor',
            meta: {
                title: "民族文化监测数据"
            },
            component: () => import('@views/working_desk/monitoring_data/table_type/20_noMmaterial_heritage/nation_culture/nation_monitor.vue'),
            itemId: '500202'
        },
        {
            path: '/cultural_inheritor',
            name: 'cultural_inheritor',
            meta: {
                title: "文化传承人"
            },
            component: () => import('@views/working_desk/monitoring_data/table_type/20_noMmaterial_heritage/nation_culture/cultural_inheritor.vue'),
            itemId: '500203'
        },



    ],
};