<template>
    <div class="map">
        <div :id="'mapidbox' + mapid" class="myMap"></div>
        <div
            class="resetIconPart"
            :style="{
                right: mapInfo.resetBtnRight ? mapInfo.resetBtnRight : '',
            }"
        >
            <div class="btnPart flex-end">
                <div
                    v-for="(item, index) in actBtnList"
                    :key="index"
                    class="resetIconItem"
                >
                    <img
                        v-if="item.state"
                        :src="item.src"
                        class="img pointer"
                        @click="getActBtn(item)"
                        :title="item.name"
                    />
                </div>
            </div>
            <div class="layerCtn" v-if="actBtnList[1].layerState">
                <LayerList
                    :listAry="actBtnList[1].listAry"
                    keyValue="map"
                    @switchStateChange="switchStateChange"
                ></LayerList>
            </div>
        </div>

        <div id="mapType" v-show="mapInfo.mapTypeBtn">
            <div
                class="mapTypeCard vectorType"
                @click="baseLayerChange('vector')"
                :class="{ active: mapType == 'vector' }"
            >
                <span>地图</span>
            </div>
            <div
                class="mapTypeCard imgType"
                @click="baseLayerChange('img')"
                :class="{ active: mapType == 'img' }"
            >
                <span>影像</span>
            </div>
            <div
                class="mapTypeCard terType"
                @click="baseLayerChange('ter')"
                :class="{ active: mapType == 'ter' }"
            >
                <span>地形</span>
            </div>
        </div>
    </div>
</template>

<script>
import { $AXIOS } from "@request/http.js";
import { mapActions } from "vuex";
import { derectionData } from "@libs/dailyInspectAreaList";
import LayerList from "./LayerList.vue"; //图层

export default {
    name: "map_tdt",
    props: {
        mapData: Object,
        // 地图id 默认随机数, 遥感监测需指定id
        mapid: {
            type: String,
            default: () => String(Math.random()),
        },
    },
    data() {
        return {
            derectionData,
            mapInfo: {
                mode: "fix", //fix 固定模式  fly 飞行模式  fit 缩放模式
                lng: window.mapCenter[1],
                lat: window.mapCenter[0],
                zoom: 13, //放大级别
                minZoom: 3, //缩放 最小
                maxZoom: 20, //缩放 最大
                zoomDelta: 0.25, //缩放层级
                fitBounds: window.mapFitBounds, //最适合大小展开
                baseMap: true, //是否加载底图
                baseMapNum: 0, //0地图 1影像 2地形
                mapTypeBtn: true, //是否展示地图切换按钮
                url: "", //矢量地图路径
                dragging: true, //拖拽
                dxtState: false, // 地形图 + 遗产区 缓冲区
                areaState: false, // 片区
                resetBtnRight: "",
            },
            basicMap: "", //地图
            mapType: "", // 地图 影像 地形
            vectorLayer: null, //地图
            imgLayer: null, //影像
            terLayer: null, //地形
            vectorTagLayer: null,
            imgTagLayer: null,
            terTagLayer: null,
            markerPopup: null,
            gqyxGroup: null, //高清影像
            gqyxTenGroup: null, //高清影像 10mm
            xcqList: [], //巡查区列表
            xcqLabelGroup: null, //巡查区Label
            actBtnList: [
                {
                    name: "复位",
                    src: require("@image/bigScreen/icon/resetLight.png"),
                    state: true,
                },
                {
                    name: "图层",
                    src: require("@image/bigScreen/icon/layerBtn.png"),
                    layerState: false,
                    state: false,
                    listAry: [
                        {
                            name: "遗产区缓冲区",
                            existState: false,
                            state: true,
                            group: null,
                        },
                        {
                            name: "地形图",
                            existState: false,
                            state: true,
                            group: null,
                        },
                        {
                            name: "巡查片区",
                            existState: false,
                            state: true,
                            group: null,
                        },
                    ],
                },
            ],
        };
    },
    mounted() {
        //marker 弹出框
        this.markerPopup = window.L.popup({
            closeOnClick: false,
            closeButton: false,
            offset: [0, 0],
            className: "markerPopup",
        });
        this.initMap();
    },
    components: {
        LayerList,
    },
    watch: {
        "mapData.baseMapNum": {
            immediate: false,
            handler() {
                switch (this.mapData.baseMapNum) {
                    case 0:
                        this.baseLayerChange("vector");
                        break;
                    case 1:
                        this.baseLayerChange("img");
                        break;
                    case 2:
                        this.baseLayerChange("ter");
                        break;
                    default:
                        this.baseLayerChange();
                }
            },
        },
    },
    methods: {
        ...mapActions(["getVectorStyle", "GetRcxcXcpq"]),
        initMap() {
            this.mapInfo = { ...this.mapInfo, ...this.mapData };
            let id = `mapidbox${this.mapid}`;
            this.basicMap = window.L.map(id, {
                crs: window.L.CRS.EPSG3857,
                editable: true,
                zoomControl: false, //左上角放大 缩小
                zoom: this.mapInfo.zoom,
                minZoom: this.mapInfo.minZoom,
                maxZoom: this.mapInfo.maxZoom,
                zoomSnap: 0.25,
                zoomDelta: this.mapInfo.zoomDelta,
                dragging: this.mapInfo.dragging,
                attributionControl: false, //右下角 加载底图 产生的 链接 隐藏掉
            });

            if (this.mapInfo.mapTypeBtn) {
                this.initControls();
            }
            // 地图模式
            switch (this.mapInfo.mode) {
                case "fix": // 固定模式
                    this.basicMap.setView(
                        [this.mapInfo.lat, this.mapInfo.lng],
                        this.mapInfo.zoom
                    );
                    break;
                case "fly": // 飞行模式
                    this.basicMap.flyTo(
                        [this.mapInfo.lat, this.mapInfo.lng],
                        this.mapInfo.zoom
                    );
                    break;
                case "fit": // 缩放模式
                    this.basicMap.fitBounds([this.mapInfo.fitBounds]);
                    break;
            }

            this.gqyxGroup = window.L.layerGroup().addTo(this.basicMap);
            this.gqyxTenGroup = window.L.layerGroup().addTo(this.basicMap);
            this.xcqLabelGroup = window.L.layerGroup().addTo(this.basicMap);
            this.actBtnList.map((item) => {
                if (item.name == "图层") {
                    item.listAry.map((item1) => {
                        item1.group = window.L.layerGroup().addTo(
                            this.basicMap
                        );
                    });
                }
            });
            this.basicMap.on("zoomend", (e) => {
                let level = e.target._zoom;
                if (level >= 14.5) {
                    // 巡查区
                    this.drawXcqLabel(this.xcqLabelGroup, this.xcqList);
                } else {
                    this.clearLayersGroup(this.xcqLabelGroup);
                }

                if (level >= 18 && this.mapData.baseMapNum != null) {
                    if (!this.common.containGroup(this.gqyxTenGroup)) {
                        this.loadTenGqyx(this.gqyxTenGroup);
                    }
                } else {
                    this.clearLayersGroup(this.gqyxTenGroup);
                }
            });
            this.basicMap.on("click", function(ev) {
                console.log(ev.latlng.lng, ev.latlng.lat);
            });
            this.basicMap.on("zoom", function(ev) {
                // console.log("放大等级:", ev.target._zoom);
            });

            // 加载底图
            if (this.mapInfo.baseMap) {
                this.loadBaseLayer(this.mapInfo.baseMapNum);
            }

            // 显示 显隐图层按钮
            // mapInfo.dxtState: false, // 地形图 + 遗产区 缓冲区
            //     areaState: false, // 片区
            this.actBtnList.map((item) => {
                if (item.name == "图层") {
                    item.listAry.map((item1) => {
                        switch (item1.name) {
                            case "遗产区缓冲区":
                                if (this.mapInfo.dxtState) {
                                    item.state = true;
                                    item1.existState = true;
                                    this.loadYcqHcq(item1.group);
                                }
                                break;
                            case "地形图":
                                if (this.mapInfo.dxtState) {
                                    item.state = true;
                                    item1.existState = true;
                                    this.loadDxtMap(item1.group);
                                }
                                break;
                            case "巡查片区":
                                if (this.mapInfo.areaState) {
                                    item.state = true;
                                    item1.existState = true;
                                    this.getAreaInfo(item1.group);
                                }
                                break;
                        }
                    });
                }
            });

            this.$emit("update:myMap", this.basicMap);
            this.$emit("initmap", this.basicMap);
        },
        //右下角的 审图号
        initControls() {
            let mousePositionControl = window.L.control.mousePosition({
                position: "bottomright",
                separator: ",",
                emptystring: " ",
                lngFirst: true,
                numDigits: 5,
                prefix: "",
                showZoom: true,
            });
            mousePositionControl.addTo(this.basicMap); //显示鼠标位置的地理坐标

            //没有找到获取控件的方法，暂用此简单暴力不优雅的方式解决
            this.basicMap.on("zoom", (ev) => {
                // eslint-disable-next-line
                let mouseposition = $(".leaflet-control-mouseposition");
            
                // let mouseInfo = mouseposition.html();
                let lat = ev.target._lastCenter.lat.toFixed(2);
                let lng = ev.target._lastCenter.lng.toFixed(2);
                let zoomInfo =
                    "<span class='MapNumber' style='background:#ddd;'>审图号：GS(2018)1432号&nbsp</span>地图显示级别:" +
                    (this.basicMap.getZoom() + 1) +
                    "级&nbsp&nbsp 经度:" +
                    lng +
                    "，纬度:" +
                    lat;
                mouseposition.html(zoomInfo);
            });
        },
        // 清空图层组
        clearLayersGroup(group) {
            if (this.common.containGroup(group)) {
                group.clearLayers();
            }
        },
        // 加载底图 地图 影像 地形
        loadBaseLayer(num) {
            this.vectorLayer = this.getWmtsLayer("vec", 0); // 矢量地图
            this.vectorTagLayer = this.getWmtsLayer("cva", 1); // 矢量地图注记
            this.imgLayer = this.getWmtsLayer("img", 0); // 影像地图
            this.imgTagLayer = this.getWmtsLayer("cia", 1); // 影像地图注记
            this.terLayer = this.getWmtsLayer("ter", 0); // 天地图地形晕渲地图
            this.terTagLayer = this.getWmtsLayer("cta", 1); // 天地图地形晕渲地图注记

            switch (num) {
                case 0:
                    this.baseLayerChange("vector");
                    break;
                case 1:
                    this.baseLayerChange("img");
                    break;
                case 2:
                    this.baseLayerChange("ter");
                    break;
            }
        },
        // 加载WMTS图层
        getWmtsLayer(layerName, num) {
            let url = `https://t{s}.tianditu.gov.cn/${layerName}_w/wmts`;
            let layer = new window.L.TileLayer.WMTS(url, {
                tk: window.tk,
                zIndex: num == 0 ? 0 : 10,
                tileSize: 256,
                crs: window.L.CRS.EPSG3857,
                layer: layerName,
                style: "default",
                tilematrixSet: "w",
                format: "tiles",
                subdomains: "01234567",
                minZoom: 3,
                maxZoom: 17,
                isBaseLayer: true,
            });
            return layer;
        },
        //底图切换
        baseLayerChange(maptype) {
            this.clearLayersGroup(this.gqyxGroup);
            this.clearLayersGroup(this.gqyxTenGroup);
            this.mapType = maptype;
            let map = this.basicMap;
            map.eachLayer((layer) => {
                if (layer.options && layer.options.isBaseLayer) {
                    map.removeLayer(layer);
                }
            });
            switch (maptype) {
                case "vector":
                    map.addLayer(this.vectorLayer);
                    map.addLayer(this.vectorTagLayer);
                    break;
                case "img":
                    this.loadGqyx(this.gqyxGroup);
                    map.addLayer(this.imgLayer);
                    map.addLayer(this.imgTagLayer);
                    break;
                case "ter":
                    map.addLayer(this.terLayer);
                    map.addLayer(this.terTagLayer);
                    break;
            }
        },
        // 加载高清影像
        loadGqyx(group) {
            this.clearLayersGroup(group);
            new window.L.tileLayer(window.MAP_GQXY_50_2022, {
                // zIndex: 10,
                minZoom: 0,
                maxZoom: 20,
                unlimited: true,
            }).addTo(group);
        },
        // 加载高清影像
        loadTenGqyx(group) {
            new window.L.tileLayer(window.MAP_GQXY_10_2022, {
                minZoom: 0,
                maxZoom: 22,
            }).addTo(group);
        },
        // 遗产区缓冲区
        async loadYcqHcq(group) {
            let layerStyle = await this.getVectorStyle({
                url: window.MAP_DXT,
            });
            let list = [];
            // 去掉背景色
            layerStyle.layers.map((item) => {
                if (
                    item["source-layer"] &&
                    item["source-layer"] == "遗产区划线"
                ) {
                    list.push(item);
                }
            });
            layerStyle.layers = Array.from(list);
            window.L.mapboxGL({
                accessToken: window.MAPBOX_TOKEN,
                style: layerStyle,
            }).addTo(group);
            this.common.showVectorLayers();
        },
        // 地形图
        async loadDxtMap(group) {
            let layerStyle = await this.getVectorStyle({
                url: window.MAP_DXT,
            });
            let list = [];
            // 去掉背景色
            layerStyle.layers.map((item) => {
                if (
                    item["source-layer"] &&
                    item["source-layer"] != "遗产区划线"
                ) {
                    list.push(item);
                }
            });
            layerStyle.layers = Array.from(list);
            window.L.mapboxGL({
                accessToken: window.MAPBOX_TOKEN,
                style: layerStyle,
            }).addTo(group);
            this.common.showVectorLayers();
        },
        //获取片区数据
        async getAreaInfo(group) {
            let result = await this.GetRcxcXcpq({});
            result.map((item) => {
                let areaItem = JSON.parse(item.QYZB);
                this.derectionData.map((itemInner) => {
                    if (item.ID == itemInner.ID) {
                        if (itemInner.JD) {
                            Object.assign(item, itemInner);
                        } else {
                            let center = window.turf.centerOfMass(areaItem);
                            itemInner.JD = center.geometry.coordinates[0];
                            itemInner.WD = center.geometry.coordinates[1];
                            Object.assign(item, itemInner);
                        }
                    }
                });
            });
            this.xcqList = result;
            this.drawXcq(group, this.xcqList);
        },
        // 画 巡查区
        drawXcq(group, list) {
            list.map((item, index) => {
                let areaItem = JSON.parse(item.QYZB);
                // 巡查区
                let layer = window.L.geoJSON(areaItem, {
                    style: () => {
                        return { color: item.YS };
                    },
                }).addTo(group);
                // layer.on("mouseover", () => {
                //     let lng = 0;
                //     let lat = 0;
                //     let markerList = [];
                //     if (item.JD) {
                //         lng = item.JD;
                //         lat = item.WD;
                //     } else {
                //         if (areaItem.geometry.type == "MultiPolygon") {
                //             areaItem.geometry.coordinates.map((item) => {
                //                 item.map((itemInner) => {
                //                     markerList = [...itemInner, ...markerList];
                //                 });
                //             });
                //         } else {
                //             markerList = areaItem.geometry.coordinates[0];
                //         }
                //         let result = this.common.getPolygonCenter(markerList);
                //         lng = result[0];
                //         lat = result[1];
                //     }
                //     this.markerPopup
                //         .setLatLng([lat, lng])
                //         .setContent(`<div class="tip">${item.QYMC}</div>`)
                //         .openOn(this.basicMap);
                // });
                // layer.on("mouseout", () => {
                //     this.markerPopup.removeFrom(this.basicMap);
                // });
                // layer.on("click", (event) => {
                // let latlng = event.latlng;
                // let value =
                //     latlng.lng.toFixed(8) + "," + latlng.lat.toFixed(8);
                // this.$emit("update:coordinate", value);
                // this.$emit("areaItem", item);
                // });
            });
        },
        drawXcqLabel(group, list) {
            this.clearLayersGroup(group);
            list.map((item, index) => {
                let width = 12 * item.QYMC.length;
                let icon = window.L.divIcon({
                    html: `<div style="width:${width}px;height:16px;white-space:nowrap;color:#000;font-size:12px;font-weight:600;">${item.QYMC}</div>`,
                    className: "",
                    iconSize: [width, 16],
                    iconAnchor: [width / 2, 8],
                });

                let layer = window.L.marker([item.WD, item.JD], {
                    icon: icon,
                }).addTo(group);
            });
        },
        // 地图复位
        changeFitBounds(lat, lng, level = 18) {
            let myMap = this.basicMap;
            if (lat && lng) {
                myMap.setView([lat, lng], level);
            } else {
                myMap.flyToBounds(this.mapInfo.fitBounds);
            }
        },
        // 按钮操作
        getActBtn(item) {
            switch (item.name) {
                case "复位":
                    this.changeFitBounds(
                        this.mapInfo.lat,
                        this.mapInfo.lng,
                        this.mapInfo.zoom
                    );
                    break;
                case "图层":
                    this.actBtnList.map((item) => {
                        if (item.name == "图层") {
                            item.layerState = !item.layerState;
                        }
                    });
                    break;
            }
        },
        // 更改图层
        switchStateChange(state, item) {
            item.state = state;
            if (!state) {
                this.clearLayersGroup(item.group);
                return;
            }
            switch (item.name) {
                case "遗产区缓冲区":
                    this.loadYcqHcq(item.group);
                    break;
                case "地形图":
                    this.loadDxtMap(item.group);
                    break;
                case "巡查片区":
                    this.getAreaInfo(item.group, this.xcqList);
                    break;
                case "土地利用现状图":
                    this.getTdlyxztInfo(item.group);
                    break;
            }
        },
    },
};
</script>

<style scoped lang="scss">
.map {
    position: relative;
    height: 100%;
    width: 100%;
    .myMap {
        height: 100%;
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
    }
    .resetIconPart {
        position: absolute;
        right: 10px;
        top: 10px;
        z-index: 1000;
        .resetIconItem {
            margin-left: 10px;
            .img {
                width: 32px;
                height: 32px;
            }
        }

        .layerCtn {
            margin-top: 10px;
            width: 160px;
            border-radius: 4px;
            box-shadow: 0px 0px 6px 0px #a7a7a7;
            background: #fff;
            position: relative;
        }
    }
    #mapType {
        position: absolute;
        right: 10px;
        bottom: 30px;
        height: 70px;
        width: 106px;
        z-index: 999;
        border-radius: 2px;
        cursor: pointer;
        transition: width 0.8s;
        background: rgba(255, 255, 255, 0.3);
        &:hover {
            width: 288px;
            .vectorType {
                right: 197px;
            }

            .imgType {
                right: 101px;
            }

            .terType {
                right: 5px;
            }
        }
        .mapTypeCard {
            position: absolute;
            top: 5px;
            width: 86px;
            height: 60px;
            border-radius: 5px;
            border: solid 1px rgba(255, 255, 255, 0);
            span {
                position: absolute;
                bottom: -1px;
                left: -1px;
                width: 86px;
                display: inline-block;
                font-size: 12px;
                height: 16px;
                line-height: 16px;
                text-align: center;
                color: #fff;
                background: #888f88;
                opacity: 0.8;
            }
        }

        .vectorType {
            right: 15px;
            background: url("~@image/mapToolbar/mapbtn.png") no-repeat 0 0;
            z-index: 3;
            transition: right 0.8s;
            &:hover {
                border: solid 1px #31a5f7;
                span {
                    background: #31a5f7;
                }
            }
        }

        .imgType {
            right: 10px;
            background: url("~@image/mapToolbar/imagebtn.png") no-repeat 0 0;
            z-index: 2;
            transition: right 0.8s;
            &:hover {
                border: solid 1px #31a5f7;
                span {
                    background: #31a5f7;
                }
            }
        }

        .terType {
            right: 5px;
            background: url("~@image/mapToolbar/terbtn.png") no-repeat 0 0;
            z-index: 1;
            transition: right 0.8s;
            &:hover {
                border: solid 1px #31a5f7;
                span {
                    background: #31a5f7;
                }
            }
        }
        .active {
            border: solid 1px #31a5f7;
            span {
                background: #31a5f7;
            }
        }
    }
}
</style>
