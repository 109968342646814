/* eslint-disable no-unused-vars */
import {
    $GET,
    $POST,
    $AXIOS
} from '@request/http_ctmj.js';
import * as Types from './mutation-type.js';

const state = {
    dataGetXzc: "", // 行政村
    dataGetCmxz: "", // 村民小组
    dataGetJmbh: "", // 居民编号
    dataGetJmlx: "", // 居民类型
    dataGetDcyxm: "", // 调查员姓名
    dataGetSjztDcy: [], // 数据状态调查员
    dataGetSjztCcy: [], // 数据状态抽查员
    dataGetPageIndex: 1,// 表单第几页
    dataGetZtActiveNum: 0 // 数据状态 0 调查 1 抽查
};

const getters = {
    [Types.dataGetXzc]: ({
        dataGetXzc
    }) => dataGetXzc,
    [Types.dataGetCmxz]: ({
        dataGetCmxz
    }) => dataGetCmxz,
    [Types.dataGetJmbh]: ({
        dataGetJmbh
    }) => dataGetJmbh,
    [Types.dataGetJmlx]: ({
        dataGetJmlx
    }) => dataGetJmlx,

    [Types.dataGetDcyxm]: ({
        dataGetDcyxm
    }) => dataGetDcyxm,
    [Types.dataGetSjztDcy]: ({
        dataGetSjztDcy
    }) => dataGetSjztDcy,
    [Types.dataGetSjztCcy]: ({
        dataGetSjztCcy
    }) => dataGetSjztCcy,
    [Types.dataGetPageIndex]: ({
        dataGetPageIndex
    }) => dataGetPageIndex,
    [Types.dataGetZtActiveNum]: ({
        dataGetZtActiveNum
    }) => dataGetZtActiveNum,
};

const mutations = {
    [Types.SET_DATAGETXZC]: (state, dataGetXzc) => {
        state.dataGetXzc = dataGetXzc;
    },
    [Types.SET_DATAGETCMXZ]: (state, dataGetCmxz) => {
        state.dataGetCmxz = dataGetCmxz;
    },
    [Types.SET_DATAGEJMBH]: (state, dataGetJmbh) => {
        state.dataGetJmbh = dataGetJmbh;
    },
    [Types.SET_DATAGEJMLX]: (state, dataGetJmlx) => {
        state.dataGetJmlx = dataGetJmlx;
    },
    [Types.SET_DATAGEDCYXM]: (state, dataGetDcyxm) => {
        state.dataGetDcyxm = dataGetDcyxm;
    },
    [Types.SET_DATAGETSJZTDCY]: (state, dataGetSjztDcy) => {
        state.dataGetSjztDcy = dataGetSjztDcy;
    },
    [Types.SET_DATAGETSJZTCCY]: (state, dataGetSjztCcy) => {
        state.dataGetSjztCcy = dataGetSjztCcy;
    },
    [Types.SET_DATAPAGEINDEX]: (state, dataGetPageIndex) => {
        state.dataGetPageIndex = dataGetPageIndex;
    },
    [Types.SET_DATAGETZTACTIVENUM]: (state, dataGetZtActiveNum) => {
        state.dataGetZtActiveNum = dataGetZtActiveNum;
    },
};

const actions = {
    // 获取枚举
    async GetAllEnumInfobytableID({ dispatch, commit, state }, payload) {
        let result = await $GET('TB/GetAllEnumInfobytableID', payload);
        return result;
    },
    //  获取村列表
    async GetXZQHByCode({ dispatch, commit, state }, payload) {
        let result = await $GET('OperationandMaintenanceManagement/GetXZQHByCode', payload);
        return result;
    },
    // 获取居民调查列表 调查员 登陆
    async GetJmdcList({ dispatch, commit, state }, payload) {
        let result = await $GET('Jmdc/GetJmdcList', payload);
        return result;
    },
    // 获取居民调查列表 抽查员 登陆
    async GetCcyList({ dispatch, commit, state }, payload) {
        let result = await $GET('Jmcc/GetJmdcList', payload);
        return result;
    },
    // 查看详情 调查员
    async GetJmdcDetail({ dispatch, commit, state }, payload) {
        let result = await $GET('Jmdc/GetJmdcDetail', payload);
        return result;
    },
    // 查看详情 抽查员
    async GetJmdcCcyDetail({ dispatch, commit, state }, payload) {
        let result = await $GET('Jmcc/GetJmdcDetail', payload);
        return result;
    },
    // 保存数据 调查
    async SaveJmdcData({ dispatch, commit, state }, payload) {
        let result = await $POST('Jmdc/SaveJmdcData', payload);
        return result;
    },
    // 保存数据 抽查
    async SaveJmdcCcyData({ dispatch, commit, state }, payload) {
        let result = await $POST('Jmcc/SaveJmdcData', payload);
        return result;
    },
    // 获取任务的人员
    async GetTaskCjrList({ dispatch, commit, state }, payload) {
        let result = await $GET('Jmdc/GetTaskCjrList', payload);
        return result;
    },
    // 下发任务 调查员
    async InitJmdcTask({ dispatch, commit, state }, payload) {
        let result = await $POST('Jmdc/InitJmdcTask', payload);
        return result;
    },
    // 下发任务 抽查员
    async InitCcyJmdcTask({ dispatch, commit, state }, payload) {
        let result = await $POST('Jmcc/InitJmdcTask', payload);
        return result;
    },
    // 数据迁移
    async InitJmdcccbData({ dispatch, commit, state }, payload) {
        let result = await $POST('Jmdc/InitJmdcccbData', payload);
        return result;
    },
    // 获取任务信息
    async GetRwInfo({ dispatch, commit, state }, payload) {
        let result = await $GET('Jmdc/GetRwInfo', payload);
        return result;
    },
    // 下拉时间列表 获取详情
    async GetMjDcsjList({ dispatch, commit, state }, payload) {
        let result = await $GET('Jmdc/GetMjDcsjList', payload);
        return result;
    },
};
export default {
    state,
    getters,
    mutations,
    actions
};