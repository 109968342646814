import {
    $GET,
    $POST,
    $AXIOS
} from '@request/http.js';
import * as Types from '../mutation-type.js';

const state = {
    isScale: false, // 是否缩放 true => 缩放  false => 不缩放
    jczlYcysTypeItem: null, // 遗产要素 类型某一个
    jczlYcysList: [], // 遗产要素列表
    jczlYczcItem: null, // 遗产组成某一个
    jczlYczcItemYcysList: [], // 遗产组成某一个下的遗产要素列表
    jczlYcysItem: null, // 遗产要素某一个
    jczlJghjState: false, // 景观环境
    jczlYjItem: "", // 某一个预警
    jczlSxtState: false, //false 公司的监控 true 第三方的监控
    jczlFyNum: 0, //0 汉语 1 英语
};


const getters = {
    [Types.isScale]: ({
        isScale
    }) => isScale,
    [Types.jczlYcysTypeItem]: ({
        jczlYcysTypeItem
    }) => jczlYcysTypeItem,
    [Types.jczlYcysList]: ({
        jczlYcysList
    }) => jczlYcysList,
    [Types.jczlYczcItem]: ({
        jczlYczcItem
    }) => jczlYczcItem,
    [Types.jczlYczcItemYcysList]: ({
        jczlYczcItemYcysList
    }) => jczlYczcItemYcysList,
    [Types.jczlYcysItem]: ({
        jczlYcysItem
    }) => jczlYcysItem,
    [Types.jczlJghjState]: ({
        jczlJghjState
    }) => jczlJghjState,
    [Types.jczlYjItem]: ({
        jczlYjItem
    }) => jczlYjItem,
    [Types.jczlSxtState]: ({
        jczlSxtState
    }) => jczlSxtState,
    [Types.jczlFyNum]: ({
        jczlFyNum
    }) => jczlFyNum,


};

const mutations = {
    [Types.SET_ISSCALE]: (state, isScale) => {
        state.isScale = isScale
    },
    [Types.SET_JCZLYCYSTYPE]: (state, jczlYcysTypeItem) => {
        state.jczlYcysTypeItem = jczlYcysTypeItem;
    },
    [Types.SET_JCZLYCYSLIST]: (state, jczlYcysList) => {
        state.jczlYcysList = jczlYcysList;
    },
    [Types.SET_JCZLYCZCITEM]: (state, jczlYczcItem) => {
        state.jczlYczcItem = jczlYczcItem;
    },
    [Types.SET_JCZLYCZCITEMYCYSLIST]: (state, jczlYczcItemYcysList) => {
        state.jczlYczcItemYcysList = jczlYczcItemYcysList;
    },
    [Types.SET_JCZLYCYSITEM]: (state, jczlYcysItem) => {
        state.jczlYcysItem = jczlYcysItem;
    },
    [Types.SET_JCZLJGHJSTATE]: (state, jczlJghjState) => {
        state.jczlJghjState = jczlJghjState;
    },
    [Types.SET_JCZLYJITEM]: (state, jczlYjItem) => {
        state.jczlYjItem = jczlYjItem;
    },
    [Types.SET_JCZLSXTSTATE]: (state, jczlSxtState) => {
        state.jczlSxtState = jczlSxtState;
    },
    [Types.SET_JCZLFYNUM]: (state, jczlFyNum) => {
        state.jczlFyNum = jczlFyNum;
    },

};

const actions = {
    // 遗产组成
    async GetYczcList({
        dispatch,
        commit
    }, payload) {
        let result = await $GET('LargeShowMonitoringOverview/GetYczcList', payload);
        return result.RESULTVALUE;
    },
    // 遗产要素
    async GetYcysState({
        dispatch,
        commit
    }, payload) {
        let result = await $GET('LargeShowMonitoringOverview/GetYcysState', payload);
        return result.RESULTVALUE;
    },
    // 保存状态
    async GetBhpgAndBhlx({
        dispatch,
        commit
    }, payload) {
        let result = await $GET('LargeShowMonitoringOverview/GetBhpgAndBhlx', payload);
        return result.RESULTVALUE;
    },
    // 预警数据统计
    async GetWarnTotal({
        dispatch,
        commit
    }, payload) {
        let result = await $GET('LargeShowMonitoringOverview/GetWarnTotal', payload);
        return result.RESULTVALUE;
    },
    // 预警数据列表
    async GetWarnInfoList({
        dispatch,
        commit
    }, payload) {
        let result = await $GET('LargeShowMonitoringOverview/GetWarnInfoList', payload);
        return result.RESULTVALUE;
    },
    // 建设控制
    async GetNewProjectTotal({
        dispatch,
        commit
    }, payload) {
        let result = await $GET('LargeShowMonitoringOverview/GetNewProjectTotal', payload);
        return result.RESULTVALUE;
    },
    // 建设控制 新建项目折线图
    async GetNewProjectEcharts({
        dispatch,
        commit
    }, payload) {
        let result = await $GET('LargeShowMonitoringOverview/GetNewProjectEcharts', payload);
        return result.RESULTVALUE;
    },
    // 新建项目GeoJson数据—地图
    async GetNewProjectGeoJson({
        dispatch,
        commit
    }, payload) {
        let result = await $GET('LargeShowMonitoringOverview/GetNewProjectGeoJson', payload);
        return result.RESULTVALUE;
    },
    // 日常巡查 数据统计
    async GetInspectionTotal({
        dispatch,
        commit
    }, payload) {
        let result = await $GET('LargeShowMonitoringOverview/GetInspectionTotal', payload);
        return result.RESULTVALUE;
    },
    // 日常巡查 巡查异常 未解除 已解除
    async GetAbnormalRecordsStatistic({
        dispatch,
        commit
    }, payload) {
        let result = await $GET('LargeShowMonitoringOverview/GetAbnormalRecordsStatistic', payload);
        return result.RESULTVALUE;
    },
    // 旅游游客 数据统计
    async GetTouristTotal({
        dispatch,
        commit
    }, payload) {
        let result = await $GET('LargeShowMonitoringOverview/GetTouristTotal', payload);
        return result.RESULTVALUE;
    },
    // 旅游游客 折线图
    async GetTouristEcharts({
        dispatch,
        commit
    }, payload) {
        let result = await $GET('LargeShowMonitoringOverview/GetTouristEcharts', payload);
        return result.RESULTVALUE;
    },
    // 旅游游客 年份
    async GetTouristYearList({
        dispatch,
        commit
    }, payload) {
        let result = await $GET('LargeShowMonitoringOverview/GetTouristYearList', payload);
        return result.RESULTVALUE;
    },
    // 预警列表 
    async GetWarnInfoList({
        dispatch,
        commit
    }, payload) {
        let result = await $GET('LargeShowMonitoringOverview/GetWarnInfoList', payload);
        return result.RESULTVALUE;
    },
    // 自然环境 预警列表
    async GetNaturalWarnInfoList({
        dispatch,
        commit
    }, payload) {
        let result = await $GET('LargeShowMonitoringOverview/GetNaturalWarnInfoList', payload);
        return result.RESULTVALUE;
    },
    // 自然环境 天气折线图
    async GetWeatherList({
        dispatch,
        commit
    }, payload) {
        let result = await $GET('LargeShowMonitoringOverview/GetWeatherList', payload);
        return result.RESULTVALUE;
    },
    // 旅游景点
    async GetScenicSpotList({
        dispatch,
        commit
    }, payload) {
        let result = await $GET('LargeShowMonitoringOverview/GetScenicSpotList', payload);
        return result.RESULTVALUE;
    },
    // 天气
    async GetQxjcData({
        dispatch,
        commit
    }, payload) {
        let result = await $GET('LargeShowMonitoringOverview/GetQxjcData', payload);
        return result.RESULTVALUE;
    },

    // 地图上的marker start
    // 古茶树
    async GetTeaTreesMap({
        dispatch,
        commit
    }, payload) {
        let result = await $GET('LargeShowMonitoringOverview/GetTeaTreesMap', payload);
        return result.RESULTVALUE;
    },
    // 古树名木
    async GetAncientTreesMap({
        dispatch,
        commit
    }, payload) {
        let result = await $GET('LargeShowMonitoringOverview/GetAncientTreesMap', payload);
        return result.RESULTVALUE;
    },
    // 消防设备
    async GetFireControlsMap({
        dispatch,
        commit
    }, payload) {
        let result = await $GET('LargeShowMonitoringOverview/GetFireControlsMap', payload);
        return result.RESULTVALUE;
    },
    // 景观风貌
    async GetLandscapeStyleMap({
        dispatch,
        commit
    }, payload) {
        let result = await $GET('LargeShowMonitoringOverview/GetLandscapeStyleMap', payload);
        return result.RESULTVALUE;
    },
    // 景观视线
    async GetLandscapeLineMap({
        dispatch,
        commit
    }, payload) {
        let result = await $GET('LargeShowMonitoringOverview/GetLandscapeLineMap', payload);
        return result.RESULTVALUE;
    },
    // 保护工程
    async GetProjectMap({
        dispatch,
        commit
    }, payload) {
        let result = await $GET('LargeShowMonitoringOverview/GetProjectMap', payload);
        return result.RESULTVALUE;
    },
    // 火警
    async GetFireAlarmMap({
        dispatch,
        commit
    }, payload) {
        let result = await $GET('LargeShowMonitoringOverview/GetFireAlarmMap', payload);
        return result.RESULTVALUE;
    },
    // marker end


    //  获取景观监测数据
    async GetJgjcInfo({
        dispatch,
        commit
    }, payload) {
        let result = await $GET('LargeShowMonitoringOverview/GetJgjcInfo', payload);
        return result.RESULTVALUE;
    },
    //  获取防火监测数据
    async GetFhjcInfo({
        dispatch,
        commit
    }, payload) {
        let result = await $GET('LargeShowMonitoringOverview/GetFhjcInfo', payload);
        return result.RESULTVALUE;
    },
    //  获取防火监测数据 列表
    async GetHjDataList({
        dispatch,
        commit
    }, payload) {
        let result = await $GET('LargeShowMonitoringOverview/GetHjDataList', payload);
        return result.RESULTVALUE;
    },
    //  获取防火监测数据 统计图
    async GetHjDataStatistics({
        dispatch,
        commit
    }, payload) {
        let result = await $GET('LargeShowMonitoringOverview/GetHjDataStatistics', payload);
        return result.RESULTVALUE;
    },
    // marker end
    //  本体病害 状态 检测病害 涉及遗产
    async GetJCZLBTBH({
        dispatch,
        commit
    }, payload) {
        let result = await $GET('LargeShowMonitoringOverview/GetJCZLBTBH', payload);
        return result.RESULTVALUE;
    },
    // 获取消防预警设备 没有弹框
    async GetFireWarningEquipments({
        dispatch,
        commit
    }, payload) {
        let result = await $GET('LargeShowMonitoringOverview/GetFireWarningEquipments', payload);
        return result.RESULTVALUE;
    },
    // 项目工程 保护工程
    async GetEngineeringStatistics({
        dispatch,
        commit
    }, payload) {
        let result = await $GET('LargeShowMonitoringOverview/GetEngineeringStatistics', payload);
        return result.RESULTVALUE;
    },
    // 项目工程 新建项目
    async GetNewProjectStatistics({
        dispatch,
        commit
    }, payload) {
        let result = await $GET('LargeShowMonitoringOverview/GetNewProjectStatistics', payload);
        return result.RESULTVALUE;
    },
    // 获取监测记录(社会环境)年份
    async GetMonitoringRecordYears({
        dispatch,
        commit
    }, payload) {
        let result = await $GET('LargeShowMonitoringOverview/GetMonitoringRecordYears', payload);
        return result.RESULTVALUE;
    },
    // 获取监测记录(社会环境)
    async GetMonitoringRecords({
        dispatch,
        commit
    }, payload) {
        let result = await $GET('LargeShowMonitoringOverview/GetMonitoringRecords', payload);
        return result.RESULTVALUE;
    },
    // 获取业态发展记录
    async GetYtfz({
        dispatch,
        commit
    }, payload) {
        const result = await $GET('LargeShowRealTime/GetYtfz', payload);
        return (result.ISSUCCESS) ? result.RESULTVALUE : [];
    },
    //  获取环境监测数据(月降雨量、月平均气温)
    async GetEnvironmentalMonitors({
        dispatch,
        commit
    }, payload) {
        let result = await $GET('LargeShowMonitoringOverview/GetEnvironmentalMonitors', payload);
        return result.RESULTVALUE;
    },
    // 古茶树-详情
    async GetTeaTreesDetail({
        dispatch,
        commit
    }, payload) {
        let result = await $GET('LargeShowMonitoringOverview/GetTeaTreesDetail', payload);
        return result.RESULTVALUE;
    },
    // 获取古树名木-详情
    async GetAncientTreesDetail({
        dispatch,
        commit
    }, payload) {
        let result = await $GET('LargeShowMonitoringOverview/GetAncientTreesDetail', payload);
        return result.RESULTVALUE;
    },

};
export default {
    state,
    getters,
    mutations,
    actions
};