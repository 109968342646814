<template>
    <div :class="['hisEdition',{'is70x':is70x}]">
        <sn-table-sub 
            v-show="!loading"   
            :snTable.sync="snTable" 
            :commonMethods="commonMethods" 
            @handleChange="handleChange"
        >
        </sn-table-sub>
    </div>
</template>

<script>
import snTableSub from "@comp/sn-common/sn_table_sub"
import modulesConfig from "./hisEdition";
import { mapActions } from 'vuex';
export default {
    props:['restData'],
    components:{
        snTableSub
    },
    data(){
        return {
            noTarArr:[203,204,205,402,502,801,90201,90304,90305,1001,1002,1003,1004,1005,1006,1107,1104,110101,110102,1102,1203,1302,1303,1401,],
            modulesConfig,
            loading:false,
            snTable: {},
            commonMethods:{},
            sfyJcdx:null,
        }
    },
    computed:{
        itemId(){
            return Number(this.$route.params.ID);
        },
        is70x(){
            const itemId =  this.$route.params.ItemID;
            return itemId && itemId.startsWith(70);
        },
    },
    created(){
        
    },
    mounted(){
        this.changeModule();
    },
    methods:{
        ...mapActions([
            'getHisJcpzInfo'
        ]),
        handleChange(type, value){
            console.log('历史版本','type',type,'value',value);
            switch (type) {
                case 'columnSee':
                    this.toNewPage(value[0], 0);
                    break;
                default: break;
            }
        },
        toNewPage(value, type) {
            const isHis = true;
            let condition;
            const ItemID = value.JCX;
            const BBSX = value.BBSX;
            switch (ItemID) {
                case '704':
                    condition = JSON.stringify([value].map(({ ID,JCPZID, JCWZID }) => ({ ID,JCPZID, JCWZID }))[0]);
                    break;
                default:
                    condition = JSON.stringify([value].map(({ ID, JCPZID}) => ({ ID,JCPZID }))[0]);
                    break;
            }
            // 判断此条配置 跳转哪个页面
            if(this.itemId < 100){
                const query = {
                    type, // 操作类型
                    JCPZID:value.JCPZID,
                    ItemID:this.itemId,
                    isHis:true,
                    BBSX,
                };
                const params =  {
                    pzlx: 0,
                    pzx: this.itemId
                }
                this.$router.push({
                    name: 'distribution_basic_set',
                    query ,
                    params
                });
            }
            else if (this.sfyJcdx === '1') {
                this.$router.push({
                    name: 'distribution_monitor_set',
                    query: {
                        type, // 操作类型
                        JCPZID:value.JCPZID,
                        ItemID,
                        condition,
                        spotIndex: value.spotIndex || 0,
                        isHis,
                        BBSX
                    },
                    params: {
                        pzlx: 1,
                        pzx: ItemID
                    }
                });
            }
            else if (this.sfyJcdx === '0') {
                this.$router.push({
                    name: 'distribution_monitor_set_notar',
                    query: {
                        type, // 操作类型
                        JCPZID:value.JCPZID,
                        ItemID,
                        isHis,
                        BBSX
                    },
                    params: {
                        pzlx: 1,
                        pzx: ItemID
                    }
                });
            }
        },
        async getTableData(){
            const bbsx = this.restData.BBSX;
            const pzlx = this.itemId<100?0:1;
            const jcxcode = this.itemId;
            const versions  = 1;
            const result = await this.getHisJcpzInfo({bbsx,pzlx,jcxcode,versions});
            const res = result && result.ResultValue;
            let arr = [];
            if(res && res.result && Array.isArray(res.result.data)){
                arr = res.result.data;
                this.sfyJcdx = res.Sfyjcdx;
                arr.forEach((obj,i)=>{
                    obj.RANDOM_ID = (obj.ID||obj.JCPZID) + i;
                })
            }
            this.snTable.table.data = arr;
        },
        async changeModule(){
            let moduleData;
            if((this.itemId)<100){
                const tempModuleData = this.modulesConfig['sn-table-group-basic'].self;
                moduleData = JSON.parse(JSON.stringify(tempModuleData));
                this.commonMethods = this.modulesConfig.commonMethods.basicCommonMethods.commonMethods;
            }
            else {
                this.commonMethods = this.modulesConfig.commonMethods.monitorCommonMethods.commonMethods;
                const commonData = this.modulesConfig['sn-table-group-common'].commonData;
                let tempModuleData;
                try {
                    tempModuleData = this.modulesConfig[`sn-table-group-${this.itemId}`].self;
                } catch (error) {
                    tempModuleData = this.modulesConfig[`sn-table-group-withouttarget`].self;
                }
                moduleData = JSON.parse(JSON.stringify(tempModuleData));
                this.handleDataAll(moduleData, commonData);
            }
            moduleData.snTable.columns.columnsBtn.buttonData = [
                {
                    isShow: true,
                    btnType: 'img',
                    operateType: 'columnSee',
                    title: '查看详情',
                    src: 'monitoring_cloud/detail.png',
                },
            ];
            moduleData.snTable.table.data = [];
            // 没有监测对象的 过滤掉 MC 以外的列
            if(this.noTarArr.includes((this.itemId))){
                const columnsData = moduleData.snTable.columns.columnsData;
                const temp = columnsData.filter(ele => ele.prop !== 'MC');
                // 设定 第一列为展开列
                temp[0].expanded = true;
                moduleData.snTable.columns.columnsData = temp;
            }
            else if([704,705].includes((this.itemId))){
                const columnsData = moduleData.snTable.columns.columnsData;
                const temp = columnsData.filter(ele => ele.prop !== 'MC');
                // 设定 第一列为展开列
                temp[0].expanded = true;
                moduleData.snTable.columns.columnsData = temp;
            }
            else {
                // 其他项 选择展开列
                moduleData.snTable.columns.columnsData[0].expanded = true;
            }
            moduleData.snTable.columns.columnsOperate = [];
            moduleData.snTable.table.rowKey = 'RANDOM_ID';
            this.snTable = moduleData.snTable;
            console.log('=======expand history editon===========',this.snTable);

            await this.getTableData();
        },
        handleDataAll(self, common) {
            Object.keys(common).forEach(key => {
                if (self.hasOwnProperty(key)) {
                    if (typeof common[key] !== 'object') return false;
                    if (key === 'selectData' && Array.isArray(common[key]) && common[key].length > 0) {
                        let hash = {};
                        self[key] = [...self[key], ...common[key]];
                        let newArr = self[key].reduce((item, next) => {
                            hash[next.label] ? '' : (hash[next.label] = true && item.push(next));
                            return item;
                        }, []);
                        self[key] = newArr;
                        this.searchData = [];
                        self[key].forEach(item => {
                            let type = item.value;
                            this.searchData.push({ type: type, value: '' });
                        });
                    }
                    this.handleDataAll(self[key], common[key]);
                } else {
                    self[key] = common[key];
                }
            });
        },
    },
}
</script>

<style lang="scss">
.hisEdition{
    &.is70x{
        padding-left: 48px;
    }
    background: #d3dbdb;
    thead{
        display: none;
    }
    .el-table__body-wrapper{
        table{
            tbody{
                tr.no-history{
                    td{
                        div.cell{
                            div.el-table__expand-icon{
                                opacity: 0;
                                cursor: default!important;
                            }
                        }
                        &:nth-of-type(1),&:nth-of-type(2){
                            >div.cell{
                                // opacity: 0;
                            }
                        }
                    }
                }
            }
        }
    }
}
</style>