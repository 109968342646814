let obj =
{
    ycjcyjpt: {
        chineseName: "遗产监测预警平台",
        englistName: "Heritage monitoring and early warning platform",
    },
    // 监测总览大屏 start
    menuList: [
        {
            chineseName: "监测总览",
            englistName: "Monitoring overview",
        },
        {
            chineseName: "日常巡查",
            englistName: "Daily inspection",
        },
        {
            chineseName: "生态环境",
            englistName: "Ecosystem environment",
        },
        {
            chineseName: "建设控制",
            englistName: "Construction control",
        },
        {
            chineseName: "本体病害",
            // englistName: "Disease of the Heritage Object",
            englistName: "Condition of conservation",
        },
        {
            chineseName: "保护工程",
            // englistName: "Preservation project",
            englistName: "Conservation projects",
        },
        // {
        //     chineseName: "茶文化",
        //     englistName: "Tea culture",
        // },
        {
            chineseName: "综合管理平台",
            englistName: "Integrated management",
    },],
    ycys: {
        chineseName: "遗产要素",
        englistName: "Heritage elements",
    },
    ychj: {
        chineseName: "遗产环境",
        englistName: "Heritage environment",
    },
    rcxc: {
        chineseName: "日常巡查",
        englistName: "Daily inspection",
    },
    ytfz: {
        chineseName: "业态发展",
        englistName: "Business development",
    },
    gcxm: {
        chineseName: "工程项目",
        englistName: "Projects",
    },
    yj: {
        chineseName: "预警",
        englistName: "Early warning",
    },
    hjxz: {
        chineseName: "环境现状",
        englistName: "Heritage environment",
    },
    shhj: {
        chineseName: "社会环境",
        englistName: "Social environment",
    },
    lyyk: {
        chineseName: "旅游游客",
        englistName: "Tourist volume",
    },
    btbh: {
        chineseName: "本体病害",
        // englistName: "Disease of the Heritage Object",
        englistName: "Condition of conservation",
    },
    jgjc: {
        chineseName: "景观监测",
        englistName: "Landscape monitoring",
    },
    zrhj: {
        chineseName: "自然环境",
        englistName: "Natural environment",
    },
    fhjc: {
        chineseName: "防火监测",
        englistName: "Fire monitoring",
    },
    // 监测总览大屏 end
    // 日常巡擦大屏 start 
    xcpq: {
        chineseName: "巡查片区",
        englistName: "Inspection areas",
    },
    xctd: {
        chineseName: "巡查团队",
        englistName: "Inspection team",
    },
    xctj: {
        chineseName: "巡查统计",
        englistName: "Inspection Statistics",
    },
    yctj: {
        chineseName: "异常统计",
        englistName: "Anomaly statistics",
    },
    sb: {
        chineseName: "上报",
        englistName: "Report",
    },
    zfczgz: {
        chineseName: "转发,处置,跟踪",
        englistName: "Forward,Deal with,Track",
    },
    // 日常巡擦大屏 end 
    // 生态环境 start 
    jcnr: {
        chineseName: "监测内容",
        englistName: "Monitoring contents",
    },
    jcgl: {
        chineseName: "监测概览",
        englistName: "Monitoring overview",
    },
    gsmmszzt: {
        chineseName: "古树名木生长状态",
        englistName: "Growth status of ancient or famous trees",
    },
    // 微环境空气质量
    hjkqzlpg: {
        chineseName: "环境空气质量评估",
        englistName: "Ambient Air Quality Assessment",
    },
    sssj: {
        chineseName: "实时数据",
        englistName: "Real-time data",
    },
    jcsj: {
        chineseName: "监测数据",
        englistName: "Monitoring datas",
    },
    // 病虫害虫情测报
    jgtj: {
        chineseName: "结果统计",
        englistName: "Results statistics",
    },
    jlfx: {
        chineseName: "结论分析",
        englistName: "Conclusion analysis",
    },
    // 微环境气象监测
    jcbg: {
        chineseName: "监测报告",
        englistName: "Monitoring report",
    },
    // 古树名木监测
    szzk: {
        chineseName: "生长状况",
        englistName: "Growth Status",
    },
    kstj: {
        chineseName: "科属统计",
        englistName: "Family Statistics",
    },
    fbqk: {
        chineseName: "分布情况",
        englistName: "Distribution",
    },
    // 植物多样性
    zwlx: {
        chineseName: "植物类型",
        englistName: "Plant type",
    },
    dyxtj: {
        chineseName: "多样性统计",
        englistName: "Diversity Statistics",
    },
    // 动物多样性
    wztj: {
        chineseName: "物种统计",
        englistName: "Species Statistics",
    },
    // 古茶林 病虫害调查
    gcsbchwhcd: {
        chineseName: "古茶树病虫害危害程度",
        englistName: "The damage degree of pests and diseases in Old tea forest",
    },
    // 物候监测
    jbxx: {
        chineseName: "基本信息",
        englistName: "Basic Information",
    },
    whjc: {
        chineseName: "物候监测",
        englistName: "Phenology monitoring",
    },
    // 生态环境 end
    // 本体病害 start 
    bhlb: {
        chineseName: "病害列表",
        englistName: "Disease list",
    },
    jcgk: {
        chineseName: "监测概况",
        englistName: "Monitoring overview",
    },
    bhlx: {
        chineseName: "病害类型",
        englistName: "Disease type",
    },
    ysbczt: {
        chineseName: "要素保存状态",
        englistName: "Elements preservation status",
    },
    bhkz: {
        chineseName: "病害控制",
        englistName: "Disease control",
    },
    ztbczt: {
        chineseName: "总体保存状况",
        // englistName: "Overall state of preservation",
        englistName: "Condition of conservation",
    },
    bhjc: {
        chineseName: "病害监测",
        englistName: "Disease monitoring",
    },
    // 本体病害 end
    // 保护工程 start 
    gclb: {
        chineseName: "工程列表",
        englistName: "Projects list",
    },
    bhgctj: {
        chineseName: "保护工程统计",
        englistName: "Conservation Engineering Statistics",
    },
    gxslyjf: {
        chineseName: "工程数量与经费",
        englistName: "Project quantity and funds",
    },
    gcjzqktj: {
        chineseName: "工程进展情况统计",
        englistName: "Project progress statistics",
    },
    gcfl: {
        chineseName: "工程分类",
        englistName: "Projects classification",
    },
    gzxx: {
        chineseName: "跟踪信息",
        englistName: "Tracking information",
    },
    // 保护工程 end
    // 建设控制 start 
    xmsl: {
        chineseName: "项目数量",
        englistName: "Number of Projects",
    },
    xmlb: {
        chineseName: "项目列表",
        englistName: "Projects list",
    },
    xmzt: {
        chineseName: "项目状态",
        englistName: "Project status",
    },
    dbfg: {
        chineseName: "地表覆盖",
        englistName: "Ground cover",
    },
    jsxx: {
        chineseName: "建设信息",
        englistName: "Construction information",
    },
    jzt: {
        chineseName: "基准图",
        englistName: "Benchmark graphs",
    },
    jcxx: {
        chineseName: "监测信息",
        englistName: "Monitoring information",
    },
    // 建设控制 end
    // 茶文化 start
    cyt: {
        chineseName: "茶用途",
        englistName: "Use of Tea",
    },
    cyjgjy: {
        chineseName: "茶叶加工技艺",
        englistName: "Tea Processing Technology",
    },
    jnsj: {
        chineseName: "今年数据",
        englistName: "Data Of This Year",
    },
    ccltj: {
        chineseName: "茶产量统计",
        englistName: "Statistics Of Tea Production ",
    },
    cqxx: {
        chineseName: "茶企信息",
        englistName: "Information Of Tea Enterprise",
    },
    // 茶文化 end

}



export default obj
