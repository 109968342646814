<template>
    <SnTreeselect
        :treeselectData="treeselectData"
        :treeselectValue="treeselectValue"
        @handleChange="handleChange"
        class="treeCjr"
    ></SnTreeselect>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
    name: "",
    props: {
        dialogDataState: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            treeselectValue: "",
            treeselectData: {
                selectValue: "",
                clearable: true,
                searchable: true,
                placeholder: "请选择采集人",
                class: "", //selectRound
                width: "200px",
                list: [],
                value: "",
                operateType: "treesearch",
                isShow: true,
            },
        };
    },
    watch: {
        dialogDataState: {
            handler() {
                if (this.dialogDataState) {
                    this.treeselectValue = null;
                }
            },
        },
    },
    mounted() {
        this.initUserList();
    },
    methods: {
        ...mapActions(["GetDepartmentUserTree"]),
        async initUserList() {
            let _this = this;
            let depID = "";
            if (_this.system === "system2") {
                depID = localStorage.departmentId;
            }
            // _this.cjrList = await _this.getCjrList({
            //     funid: "f1b6f5b2-de6e-11e9-81a5-000c2977b7fd",
            //     depID: depID,
            // });
            let result = await _this.GetDepartmentUserTree({});
            if (result && result.length > 0) {
                result.forEach((item) => {
                    item.id = item.Id;
                    item.label = item.Name;
                    if (item.Children && item.Children.length > 0) {
                        item.children = item.Children;
                        _this.handleChild(item.children);
                    }
                });
                _this.treeselectData.list = result;
            }
        },
        handleChild(val) {
            if (val && val.length > 0) {
                val.forEach((ele) => {
                    ele.id = ele.Id;
                    ele.label = ele.Name;
                    if (ele.Children && ele.Children.length > 0) {
                        ele.children = ele.Children;
                        this.handleChild(ele.children);
                    }
                });
                return val;
            }
        },
        handleChange(type, list) {
            this.$emit("handleChange", list[0] ? list[0] : "");
        },
        // 回显姓名
        setLabelName(id) {
            this.treeselectValue = id;
        },
    },
};
</script>

<style scoped>
</style>
