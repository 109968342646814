const derectionData = [
    {
        ID: "2e98c7fb-6e6b-4583-b3e9-4a3ab95cb5e9",
        QYMC: "芒埂-勐本古茶林",
        JD: "",
        WD: "",
    },
    {
        ID: "d3cbeccd-c409-447c-bfd5-825815ac5599",
        QYMC: "景迈大寨古茶林",
        JD: "",
        WD: "",
    },
    {
        ID: "da6ebfbd-bb46-41d0-9287-bec37dae53a2",
        QYMC: "糯岗古茶林",
        JD: "",
        WD: "",
    },
    {
        ID: "419b0c73-66f5-4a09-97ef-be3d9c7cbf1b",
        QYMC: "芒景上下寨-芒洪古茶林",
        JD: "",
        WD: "",
    },
    {
        ID: "c330cc49-62c1-4cc0-9cec-0d41e7129764",
        QYMC: "翁基-翁洼古茶林",
        JD: "",
        WD: "",
    },
    {
        ID: "faac11d4-7afc-4731-bcf4-e66204b6b222",
        QYMC: "景迈-糯岗分隔防护林",
        JD: "100.01060485839844",
        WD: "22.2173639594691",
    },
    {
        ID: "c002a7d1-7baa-46e7-a2b2-e62f600a0104",
        QYMC: "糯岗-芒景分隔防护林",
        JD: "",
        WD: "",
    },
    {
        ID: "1f779c0f-c98e-4caf-b32f-d3a6aefd432b",
        QYMC: "景迈-芒景分隔防护林",
        JD: "",
        WD: "",
    },
    {
        ID: "a55d6adf-030a-4e70-aed8-98594ba5bd1c",
        QYMC: "芒埂",
        JD: "",
        WD: "",
    },
    {
        ID: "5a6e90bb-d703-4d4c-b9a8-0a462e4b40e1",
        QYMC: "勐本",
        JD: "",
        WD: "",
    },
    {
        ID: "83993039-c388-48be-8afd-f8ce5f2eae6b",
        QYMC: "景迈大寨",
        JD: "100.02729892730713",
        WD: "22.203766002783418",
    },
    {
        ID: "534b2ef3-e679-4e38-ada5-342cd9fadba0",
        QYMC: "糯岗",
        JD: "",
        WD: "",
    },
    {
        ID: "2b3de846-ce59-4b82-bde5-db923324bbe9",
        QYMC: "芒景上寨",
        JD: "100.01322508205241",
        WD: "22.16707533167069",
    },
    {
        ID: "6a48dcdf-8d45-493d-a46b-005b114393ae",
        QYMC: "芒景下寨",
        JD: "",
        WD: "",
    },
    {
        ID: "18115cf2-ada7-4b5f-85ef-92865dbbd8ea",
        QYMC: "芒洪",
        JD: "",
        WD: "",
    },
    {
        ID: "9cd7461c-1fd7-4a4b-a469-4505b6ce95ea",
        QYMC: "翁基",
        JD: "",
        WD: "",
    },
    {
        ID: "07f2003c-e90a-412e-a605-4c75ae135ea8",
        QYMC: "翁洼",
        JD: "",
        WD: "",
    },
    {
        ID: "d4546ce9-a0f2-11eb-b3ce-000c2977b7fd",
        QYMC: "班改",
        JD: "99.9632105539957",
        WD: "22.226330753529187",
    },
    {
        ID: "bad07d65-a0f2-11eb-b3ce-000c2977b7fd",
        QYMC: "南座",
        JD: "",
        WD: "",
    },
    {
        ID: "ab04c0bd-a0f2-11eb-b3ce-000c2977b7fd",
        QYMC: "笼蚌",
        JD: "",
        WD: "",
    },
    {
        ID: "9b7449a2-a0f2-11eb-b3ce-000c2977b7fd",
        QYMC: "那乃",
        JD: "",
        WD: "",
    },
    {
        ID: "d91ca997-a0f2-11eb-b3ce-000c2977b7fd",
        QYMC: "芒云老寨",
        JD: "",
        WD: "",
    },
    {
        ID: "5e121b90-a0f2-11eb-b3ce-000c2977b7fd",
        QYMC: "老酒坊",
        JD: "",
        WD: "",
    }
];
export { derectionData };