<template>
    <span class="sn_count">
        <select v-model="pageTiaoshu" class="total" @change="handleChange">
            <option
                v-for="(key, index) in eachtotal"
                :key="index"
                :value="key"
                :selected="index === 0"
            >
                {{ key }}
            </option>
        </select>
    </span>
</template>
<script>
export default {
    name: "sn-count",
    props: {
        snPage: {
            default: function () {
                return {};
            },
        },
    },
    data() {
        return {
            pageTiaoshu: 20,
            eachtotal: [20, 50, 100],
        };
    },
    computed: {},
    watch: {
        snPage: {
            handler: function () {
                this.pageTiaoshu = this.snPage.tiaoshu;
            },
            deep: true,
        },
    },
    mounted() {},
    methods: {
        handleData() {},
        handleChange(data) {
            this.$emit("handleChange", "total", [this.pageTiaoshu]);
        },
    },
};
</script>
<style lang="scss" scoped>
* {
    box-sizing: border-box;
}
</style>