<template>
    <div class="sn_table_group">
        <sn-search
            ref="snSearch"
            class="sn-search clearfix"
            :snSearch.sync="snSearch"
            @handleChange="handleChange"
        ></sn-search>
        <slot />
        <sn-num
            class="sn-num"
            :snPage.sync="snPage"
            @handleChange="handleChange"
        ></sn-num>
        <sn-count class="sn-count" @handleChange="handleChange"></sn-count>
        <sn-button
            class="sn-button"
            :snButton.sync="snButton"
            :commonMethods="commonMethods"
            @handleChange="handleChange"
        ></sn-button>
        <div class="sn_tablebody">
            <sn-load v-show="loading"></sn-load>
            <sn-table
                v-show="!loading"
                ref="snTable"
                :exp.sync="exp"
                :snTable.sync="snTable"
                :boxHeight.sync="boxHeight"
                :commonMethods="commonMethods"
                @handleChange="handleChange"
                @getTableData="$emit('getTableData')"
            >
                <template slot="exp">
                    <slot name="exp"></slot>
                </template>
            </sn-table>
            <sn-page
                v-show="!loading"
                :snPage.sync="snPage"
                @handleChange="handleChange"
            ></sn-page>
        </div>
        <slot name="tableRight"></slot>
    </div>
</template>
<script>
export default {
    name: "sn_table_group",
    props: {
        tableGroupAttributes: {
            default: function () {
                return {};
            },
        },
        commonMethods: {
            default: function () {
                return {};
            },
        },
    },
    data() {
        return {
            snTable: {},
            snSearch: {},
            snButton: {},
            snPage: {},
            loading: false,
            boxHeight: 0,
            exp: false,
        };
    },
    computed: {
        expand: function () {
            const result = (this.snTable.table || {}).expand;
            return !result;
        },
    },
    watch: {
        tableGroupAttributes: {
            handler: function () {
                this.handleData();
            },
            deep: true,
        },
    },
    mounted() {
        this.handleData();
        setTimeout(() => {
            this.exp = this.tableGroupAttributes.exp;
        }, 500);
    },
    updated() {
        if (this.exp !== this.tableGroupAttributes.exp) {
            this.exp = this.tableGroupAttributes.exp;
        }
    },
    methods: {
        handleData() {
            Object.assign(this.$data, this.$options.data());
            Object.keys(this.tableGroupAttributes).forEach((key) => {
                if (key === "exp") return;
                this[key] = this.tableGroupAttributes[key];
            });
            this.autoResize();
        },
        handleChange(type, value) {
            this.$emit("handleChange", type, value);
        },
        autoResize() {
            // eslint-disable-next-line
            this.boxHeight = $(".sn_tablebody").height();
        },
    },
};
</script>
<style lang="scss" scoped>
.sn_table_group {
    display: block;
    width: 100%;

    .sn-search {
        width: 100%;
        display: block;
        padding: 10px;
        border-bottom: 1px solid #ccc;
    }

    .sn-num {
        height: 40px;
        line-height: 40px;
        margin-left: 10px;
        float: left;
    }

    .sn-count {
        float: right;
        margin: 10px 10px 0px 10px;
    }

    .sn-button {
        height: 40px;
        line-height: 40px;
        float: right;
    }

    .sn_tablebody {
        padding: 10px;
    }
}
</style>
