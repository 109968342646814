<template>
    <el-form
        ref="commonForm"
        :inline="true"
        size="small"
        :model="fromData"
        label-width="120px"
        class="common-form"
    >
        <el-form-item
            v-for="(item,index) in fromStructure" :key="index"
            :prop="item.key"
            :label="item.label"
            :rules="[
                { required: item.required, message: getRequireMes(item.type) + item.label, trigger: 'blur' }
            ]"
        >
            <form-control :controlData.sync="fromData" :controlStructure="item" :isEdit="isEdit"></form-control>
        </el-form-item>
    </el-form>
</template>

<script>

import formControl from '@comp/form/control';
export default {
    name: "common-form",
    components: {
        formControl
    },
    props: {
        fromStructure: {
            type: Array,
            default: []
        },
        fromData: {
            type: Object,
            default: {}
        },
        isEdit: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return {

        };
    },
    computed: {
    },
    watch: {

    },
    mounted() {

    },
    methods: {
        checkForm() {
            return this.$refs.commonForm.validate();
        },
        getRequireMes(type) {
            switch (type) {
                case 'pic':
                    return '请上传';
                case 'select':
                    return '请选择';
                default:
                    return '请输入';

            }
        }
    }
};
</script>

<style lang="scss" scoped>

</style>
