<template>
    <div class="sn_num">
        共<span> {{ count }}条 </span>数据
    </div>
</template>
<script>
export default {
    name: "sn-num",
    props: {
        snPage: {
            default: function () {
                return {};
            },
        },
    },
    data() {
        return {
            count: 20,
            tiaoshu: 10,
            currentPage: 1,
            operateType: "",
        };
    },
    computed: {},
    watch: {
        snPage: {
            handler: function () {
                this.handleData();
            },
            deep: true,
        },
    },
    mounted() {
        this.handleData();
    },
    methods: {
        handleData() {
            Object.assign(this.$data, this.$options.data());
            Object.keys(this.snPage).forEach((key) => {
                this[key] = this.snPage[key];
            });
        },
    },
};
</script>
<style lang="scss" scoped>
* {
    box-sizing: border-box;
}
</style>