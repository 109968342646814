<template>
    <div>
        <!-- 输入框 -->
        <span v-if="radioData.label" class="sn_search_label">{{radioData.label}}</span>
        <el-radio-group
            v-if="radioData.group && radioData.group.length > 0"
            v-model="radio"
            :style="{ display: 'flex',alignItems: 'center',height: '30px',marginRight: '10px' }"
            @change="((val) => handleChange(radioData.operateType, [val, radioData.value]))"
        >
            <el-radio v-for="(item,index) in radioData.group" :key="index" :label="item.value">{{item.label}}</el-radio>
        </el-radio-group>
    </div>
</template>
<script>
export default {
    name: "sn_radio",
    props: {
        radioData: {
            default: function() {
                return {
                    label: "",
                    group: []
                };
            }
        },
    },
    data() {
        return {
            radio: null
        };
    },
    watch: {
    },
    mounted() {
        if (this.radioData.group.length > 0) {
            this.radio = this.radioData.group[0].value;
            this.handleChange(this.radioData.operateType, [this.radio, this.radioData.value]);
        }
    },
    methods: {
        handleChange(type, value) {
            this.$emit("handleChange", type, value);
        },
        btnClick(val) {
            this.$emit("btnClick", val);
        }
    }
};
</script>
<style scoped>
</style>