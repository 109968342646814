<template>
    <el-table
        :ref="ref || 'commonTable'"
        class="sn-table"
        :show-header="showHeader"
        :span-method="(arg) => spanMethod(arg, data)"
        :data="data"
        :row-key="rowKey || 'ID'"
        :highlight-current-row="highlightCurrentRow || true"
        :expand-row-keys="expandRowKeys"
        :stripe="stripe"
        :max-height="maxHeight"
        :tree-props="treeProps"
        :lazy="true"
        :load="
            (tree, treeNode, resolve) => {
                handleChange('load', [tree, treeNode, resolve]);
            }
        "
        :row-class-name="
            (val) => {
                return getRowClassName(getRowClassMethod, val);
            }
        "
        style="width: 100%; max-height: calc(100% - 60px)"
        @sort-change="
            (val) => {
                handleChange('sortable', [val]);
            }
        "
        @selection-change="
            (val) => {
                handleChange('selection', [val]);
            }
        "
        @current-change="
            (val) => {
                handleChange('rowClick', [val]);
            }
        "
        @row-dblclick="
            (a, b, c) => {
                handleDouble(a);
            }
        "
        @cell-click="cellClick"
        @filter-change="filterChange"
        @expand-change="handleExpandChange"
    >
        <template v-if="exp">
            <el-table-column type="expand">
                <template slot-scope="props">
                    <SycnSxjz
                        :dataList="props.row.CNSXJZLIST"
                        :cnsxInfo="{
                            CNLY: props.row.CNLY,
                            CNSXNR_ZW: props.row.CNSXNR_ZW,
                        }"
                        @getTableData="$emit('getTableData')"
                    />
                </template>
            </el-table-column>
        </template>
        <el-table-column
            v-for="(item, index) in columnsOperate"
            :key="'operate' + index"
            :type="item.type"
            :width="item.width || 50"
            :min-width="item.minWidth"
            :label="item.label"
            :selectable="
                (val) => {
                    return getSelectShow(item.selectMethod, val);
                }
            "
        >
        </el-table-column>
        <template v-for="(item, index) in columnsData">
            <template v-if="index === columnExpandIndex">
                <el-table-column
                    type="expand"
                    :key="'data-expand' + index"
                    :class="{ 'sn-expand': true }"
                >
                    <template slot-scope="props">
                        <HisEditon :restData="props.row"></HisEditon>
                    </template>
                </el-table-column>
            </template>
            <el-table-column
                :key="'data' + index"
                :type="item.type"
                :prop="item.prop"
                :label="item.label"
                :width="item.width"
                :min-width="item.minWidth"
                :sortable="item.sortable"
                :show-overflow-tooltip="item.showoverflowtooltip || true"
                :filters="item.filters"
                :filter-placement="item.filterPlacement || 'bottom-end'"
                :sort-method="
                    (...arg) => {
                        return sortMethod(...arg, item);
                    }
                "
                :filter-method="
                    item.filterMethod &&
                    ((...arg) => {
                        return filterMethod(...arg, item);
                    })
                "
            >
                <template slot-scope="scope">
                    <!-- 文件 -->
                    <span v-if="item.showFileBtn">
                        <img
                            class="column-file"
                            :src="filterFileType(scope.row[item.prop])"
                            alt=""
                            @click="
                                handleChange(
                                    item.operateType
                                        ? item.operateType
                                        : 'fileBtn',
                                    [scope.row]
                                )
                            "
                        />
                        <!-- <i class="column-file" :class="filterFileType(scope.row[item.prop])"></i> -->
                    </span>
                    <!-- 图片 -->
                    <span v-else-if="item.showImg">
                        <!-- :preview-src-list="
                                scope.row[item.prop1]
                                    ? scope.row[item.prop1]
                                    : [scope.row[item.prop]]
                            " -->
                        <el-image
                            @click="
                                getImgBig(
                                    scope.row[item.prop1]
                                        ? scope.row[item.prop1][0]
                                        : scope.row[item.prop]
                                )
                            "
                            :class="item.class"
                            style="
                                width: 32px;
                                height: 32px;
                                margin-top: 6px;
                                cursor: pointer;
                            "
                            :src="
                                common.getThumbnailImg(
                                    scope.row[item.prop1]
                                        ? scope.row[item.prop1][0]
                                        : scope.row[item.prop]
                                )
                            "
                            alt=""
                            fit="fill"
                        >
                            <div slot="error" class="image-slot"></div>
                        </el-image>
                    </span>
                    <!--审核状态的特殊展示-->
                    <span
                        v-else-if="item.transList && item.tip"
                        class="flex align-items-center"
                        :style="{
                            color: item.colorList
                                ? item.colorList[scope.row[item.prop]]
                                : '',
                            textDecoration: item.textDecoration || 'none',
                        }"
                    >
                        <span
                            v-if="Number(scope.row[item.prop]) !== 3"
                            class="shzt-not-pass-detail"
                            >{{ item.transList[scope.row[item.prop]] }}</span
                        >
                        <el-popover
                            v-if="Number(scope.row[item.prop]) === 3"
                            placement="bottom-start"
                            title="审核未通过原因:"
                            width="200"
                            trigger="hover"
                            :content="scope.row['SHBTGSM'] || '暂无数据'"
                            popper-class="shzt-not-pass-popper"
                        >
                            <template slot="reference">
                                <span class="shzt-not-pass-detail">{{
                                    item.transList[scope.row[item.prop]]
                                }}</span>
                                <span
                                    class="shzt-not-pass-icon"
                                    @click="
                                        handleChange('showNotPassReason', [
                                            scope.row,
                                        ])
                                    "
                                ></span>
                            </template>
                        </el-popover>
                    </span>
                    <!-- 文字 -->
                    <span
                        v-else
                        @click="
                            handleChange(
                                item.operateType
                                    ? item.operateType
                                    : 'textClick',
                                [scope.row]
                            )
                        "
                    >
                        <!-- 定制方法转换 内容，不改变原数据-->
                        <span v-if="item.transMethod">{{
                            commonMethods[item.transMethod] &&
                            commonMethods[item.transMethod](
                                scope.row[item.prop],
                                scope.row
                            )
                        }}</span>
                        <!-- 翻译枚举及文字样式 -->
                        <span
                            v-else-if="item.transList"
                            :style="{
                                color: item.colorList
                                    ? item.colorList[scope.row[item.prop]]
                                    : '',
                            }"
                        >
                            {{ item.transList[scope.row[item.prop]] }}
                        </span>
                        <span v-else-if="item.substr">
                            {{ scope.row[item.prop] | subStr }}
                        </span>
                        <span v-else-if="item.splitByComma">
                            {{ scope.row[item.prop] | splitByComma }}
                        </span>
                        <span
                            :style="{
                                textDecoration: item.textDecoration || 'none',
                            }"
                            v-else-if="item.fileText"
                        >
                            <a
                                :style="{ color: item.colorStr || '#606266' }"
                                :href="
                                    scope.row[item.prop] &&
                                    scope.row[item.prop].CCLJ
                                        ? scope.row[item.prop].CCLJ
                                        : ''
                                "
                                target="_blank"
                                >{{
                                    scope.row[item.prop] &&
                                    scope.row[item.prop].WDMC
                                        ? scope.row[item.prop].WDMC
                                        : ""
                                }}</a
                            >
                        </span>
                        <span
                            v-else-if="item.isReplaceText"
                            :style="item.textStyle"
                        >
                            {{
                                scope.row[item.prop] &&
                                scope.row[item.prop].length > 0
                                    ? item.replaceText
                                    : item.replaceChar
                            }}
                        </span>
                        <!--前后带图标的样式-->
                        <span
                            v-else-if="item.beforeIcon || item.afterIcon"
                            @click="
                                handleChange(item.operateType || 'iconClick', [
                                    scope.row,
                                ])
                            "
                        >
                            <i
                                v-if="
                                    commonMethods[item.showMethod] &&
                                    commonMethods[item.showMethod](scope.row)
                                "
                                :class="item.beforeIcon"
                                style="color: #00a2ff"
                            ></i>
                            {{ scope.row[item.prop] }}
                            <i
                                v-if="
                                    commonMethods[item.showMethod] &&
                                    commonMethods[item.showMethod](scope.row)
                                "
                                :class="item.afterIcone"
                                style="color: #00a2ff"
                            ></i>
                        </span>
                        <span v-else-if="item.textFile">
                            <a
                                class="text-file"
                                :href="scope.row[item.URL]"
                                target="_blank"
                                >{{ scope.row[item.prop] }}</a
                            >
                        </span>
                        <span v-else-if="item.rowSelect">
                            <RowSelect :dataSource="scope.row"></RowSelect>
                        </span>
                        <!-- 普通文字样式 -->
                        <span v-else>
                            {{
                                scope.row[item.prop] && scope.row[item.prop1]
                                    ? scope.row[item.prop] +
                                      (item.hyphen || "") +
                                      scope.row[item.prop1]
                                    : scope.row[item.prop]
                            }}
                            <!-- 列数据单位 -->
                            <i v-if="scope.row[item.prop] && item.unit">{{
                                item.unit
                            }}</i>
                        </span>
                    </span>
                </template>
            </el-table-column>
        </template>

        <el-table-column
            v-if="
                columnsBtn &&
                columnsBtn.buttonData &&
                columnsBtn.buttonData.length > 0
            "
            label="操作"
            :width="columnsBtn.width || 100"
        >
            <template slot-scope="scoped">
                <sn-button
                    :snButton.sync="columnsBtn"
                    :sourceData.sync="scoped.row"
                    :commonMethods.sync="commonMethods"
                    @handleChange="handleChange"
                ></sn-button>
            </template>
        </el-table-column>

        <ViewerAlert ref="snTableImg" :imgList="imgList"></ViewerAlert>
    </el-table>
</template>
<script>
import SycnSxjz from "@views/working_desk/monitoring_data/comp/sycn_sxjz";
import HisEditon from "@views/distribution/common/hisEditon";
import RowSelect from "@views/distribution/warning_object_set/row_select";
import ViewerAlert from "@comp/ViewerAlert.vue"; //大图查看
export default {
    name: "sn-table",
    components: {
        HisEditon,
        SycnSxjz,
        RowSelect,
        ViewerAlert,
    },
    computed: {
        // param: function() {
        //     let p = [this.loading, this.sortable];
        //     return p.join("-");
        // },
        columnExpandIndex() {
            const res = this.columnsData.findIndex((ele) => !!ele.expanded);
            return res;
        },
    },
    filters: {
        subStr: function (value) {
            let result = "";
            result = typeof value === "string" ? value.split(" ")[0] : "";
            return result;
        },
        splitByComma: function (value) {
            return String(value).replace(/\B(?=(\d{3})+$)/g, ",");
        },
    },
    props: {
        exp: {
            default: false,
        },
        snTable: {
            default: function () {
                return {};
            },
        },
        boxHeight: {
            default: function () {
                return 500;
            },
        },
        commonMethods: {
            default: function () {
                return {};
            },
        },
    },
    data() {
        return {
            imgList: [], //查看大图专用
            imgOptions: {
                url: "data-src",
                title: (image) => {
                    return image.alt;
                },
            },
            DOWN_URL: window.DOWN_URL,
            // table 属性
            table: {},
            maxHeight: 500,
            showHeader: true,
            data: [],
            rowKey: "",
            highlightCurrentRow: true,
            expandRowKeys: [],
            getRowClassMethod: "getRowClassMethod",
            ref: "",
            stripe: false,
            treeProps: {
                children: "Children",
            },
            // column 属性
            columns: {},
            columnsData: [],
            columnsOperate: [],
            columnsBtn: {},
            expandData: {},
            expandRowData: [],
            index: 0,
        };
    },
    watch: {
        snTable: {
            handler: function (val, old) {
                this.handleData();
            },
            deep: true,
        },
        boxHeight: function () {
            this.autoResize();
        },
        // param: function(val) {
        //     let res = val.split("-");
        //     if (res[0] == "false" && res[1] == "true") {
        //         setTimeout(() => {
        //             document.body.ondrop = function(event) {
        //                 event.preventDefault();
        //                 event.stopPropagation();
        //             };
        //             this.rowDrop();
        //         }, 0);
        //     }
        // }
    },
    created() {
        this.handleData();
    },
    methods: {
        spanMethod({ row, column, rowIndex, columnIndex }, data) {
            const expandRowData = this.expandRowData;
            if (typeof this.commonMethods.spanMethod === "function") {
                return this.commonMethods.spanMethod(
                    { row, column, rowIndex, columnIndex },
                    data,
                    expandRowData
                );
            }
        },
        handleExpandChange(row, expandedRows) {
            const commonTable = this.$refs.commonTable;
            this.expandRowData = expandedRows;
            this.handleChange("expandChange", [
                { row, expandedRows, commonTable },
            ]);
        },
        handleDouble(row) {
            let flag;
            if (row.ID) {
                flag = this.expandData[row.ID] = !this.expandData[row.ID];
            } else {
                flag = this.expandData[JSON.stringify(row)] =
                    !this.expandData[JSON.stringify(row)];
            }
            this.$refs["commonTable"].toggleRowExpansion(row, flag);
        },
        // 解析数据
        handleData() {
            Object.assign(this.$data, this.$options.data());
            Object.keys(this.snTable).forEach((key) => {
                this[key] = this.snTable[key];
                Object.keys(this[key]).forEach((keynode) => {
                    this[keynode] = this[key][keynode];
                });
            });
            this.autoResize();
            // this.rowDrop();
        },
        // 行拖拽
        rowDrop() {
            let _this = this;
            let tbody = document.querySelector(".el-table__body-wrapper tbody");
            // eslint-disable-next-line
            Sortable.create(tbody, {
                onEnd({ newIndex, oldIndex }) {
                    let currRow = _this.data.splice(oldIndex, 1)[0];
                    _this.data.splice(newIndex, 0, currRow);
                    _this.data.forEach((item, index) => {
                        // TODO 会有报错
                        item.SX = index + 1;
                    });
                    _this.handleChange("rowDrop", _this.data);
                },
            });
        },
        // 操作的统一出口
        handleChange(type, value) {
            this.$emit("handleChange", type, value);
        },
        // 获取文件后缀
        filterFileType(value) {
            if (value) {
                let type = value
                    .slice(value.lastIndexOf(".") + 1)
                    .toLowerCase();
                let typeArr = [
                    "dwg",
                    "exl",
                    "pdf",
                    "rar",
                    "shp",
                    "tiff",
                    "txt",
                    "xls",
                    "zip",
                ];
                type = typeArr.indexOf(type) > -1 ? type : "normal";
                let src = require("@image/file/" + type + ".png");
                return src;
            }
        },
        getSelectShow(method, row) {
            let show = method ? this.commonMethods[method](row) : true;
            return show;
        },
        getRowClassName(method, value) {
            let className = "";
            if (method && typeof this.commonMethods[method] === "function") {
                className = this.commonMethods[method](value);
            }
            return className;
        },

        autoResize() {
            this.$nextTick(() => {
                this.maxHeight = this.boxHeight - 60;
            });
        },
        cellClick(row, column, cell, event) {
            let methodArr = Object.keys(this.commonMethods);
            if (methodArr.length > 0 && methodArr.indexOf("cellClick") > -1) {
                this.commonMethods.cellClick(
                    { row, column, cell, event },
                    this
                );
            }
        },
        sortMethod(prev, next, item) {
            return (
                this.commonMethods[item.sortMethod] &&
                this.commonMethods[item.sortMethod]({ prev, next, item }, this)
            );
        },
        filterMethod(value, row, col, item) {
            return (
                this.commonMethods[item.filterMethod] &&
                this.commonMethods[item.filterMethod]({ value, row, col }, this)
            );
        },
        filterChange(filters) {
            return this.commonMethods.filterChange(filters, this);
        },
        // 查看大图
        getImgBig(item) {
            let ary = [];
            let obj = {
                alt: ``,
                src: item,
                thumbnail: this.common.getThumbnailImg(item),
            };
            ary.push(obj);
            this.imgList = Array.from(ary);
            if (this.imgList.length) {
                this.$refs.snTableImg.showViewer();
            }
        },
    },
};
</script>
<style lang="scss" scoped>
.shzt-not-pass-detail {
    border: 1px solid;
    border-radius: 4px;
    padding: 0 4px;
    &:hover {
        & + span {
            background: url("~@image/working_desk/monitor_data/sh_not_pass_detail_active.png")
                no-repeat center/contain;
        }
    }
    & + span {
        background: url("~@image/working_desk/monitor_data/sh_not_pass_detail.png")
            no-repeat center/contain;
    }
}
.shzt-not-pass-icon {
    display: inline-block;
    margin-left: 5px;
    width: 15px;
    height: 15px;
    color: #fff;
}
.text-file {
    color: #0066ff;
    text-decoration: underline;
}
</style>
