<template>
    <div>
        <div
            v-for="(item, index) in listInner"
            :key="keyValue + index"
            class="flex-between-y-center layerItem"
        >
            <div class="name">{{ item.name }}</div>
            <onOff
                :onOffState="item.state"
                :item="item"
                :index="index"
                @switchStateChange="switchStateChange"
            ></onOff>
        </div>
    </div>
</template>
<script>
import { mapGetters, mapMutations, mapActions } from "vuex";
import OnOff from "@views/big_screen/comp/OnOff.vue"; //开关
export default {
    components: {
        OnOff,
    },
    name: "",
    data() {
        return {
            listInner: [],
        };
    },
    computed: {
        ...mapGetters({}),
    },
    props: {
        listAry: {
            type: Array,
            default() {
                return [];
            },
        },
        keyValue: {
            type: String,
            default() {
                return "";
            },
        },
    },
    watch: {
        listAry: {
            immediate: true,
            handler() {
                this.listInner = [];
                this.listAry.map((item) => {
                    if (item.existState) {
                        this.listInner.push(item);
                    }
                });
            },
        },
    },
    mounted() {},
    methods: {
        ...mapActions([]),
        ...mapMutations({}),
        switchStateChange(state, item, index) {
            this.$emit("switchStateChange", state, item, index);
        },
    },
};
</script>
<style scoped lang="scss">
.layerItem {
    height: 40px;
    line-height: 40px;
    border-bottom: 1px dashed #e1e1e1;
    padding: 0 10px;
    &:nth-last-child(1) {
        border-bottom: none;
    }
    .name {
        color: #666;
        font-size: 14px;
    }
}
</style>
