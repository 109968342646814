/* eslint-disable */
import { $GET, $POST, $AXIOS } from '@request/http.js';
import { $GET as $GET1, $POST as $POST1, $AXIOS as $AXIOS1 } from '@request/http_ctmj.js';
import { Message } from 'element-ui';
import * as Types from './mutation-type.js';


const state = {
    depNameList: {
        default: null,
        1: null,
        2: null
    },
    userListFromDepIdAndLrfs: {},
    userList: {},
};

const getters = {
    'SJCJFZRBMID_list': ({ depNameList }) => ({
        dataItems: depNameList[1] || []
    }),
};

const mutations = {
    [Types.SET_STATE_MUTATION]: (state, payload) => {
        for (const prop in payload) {
            if (payload[prop] !== undefined) {
                state[prop] = payload[prop];
            }
        }
    },
};

const actions = {
    setState_userManage({ commit }, payload) {
        commit(Types.SET_STATE_MUTATION, payload);
    },
    async getState_userManage({ dispatch, state }, payload) {
        let res = state[payload.state];
        if (!res || (Array.isArray(res) && res.length === 0) || (typeof res === 'object' && Object.keys(res).length === 0)) {
            res = await dispatch('get_' + payload.state, payload.params);
            dispatch('setState_userManage', { [payload.state]: res });
            return res;
        } else {
            return res;
        }
    },
    async getStateChild_userManage({ dispatch, state }, payload = {}) {
        const key = payload.child || 'default';
        let res = state[payload.state][key];
        if (!res || (Array.isArray(res) && res.length === 0) || (typeof res === 'object' && Object.keys(res).length === 0)) {
            res = await dispatch('get_' + payload.state, payload.params);
            dispatch('setState', { [payload.state]: { ...state[payload.state], [key]: res } });
            return res;
        } else {
            return res;
        }
    },
    // 获取 配置页面 采集人 列表
    async get_userListFromDepIdAndLrfs({ dispatch, state }, { depID, LRFS }) {
        const lrfsMap = { '0': '1', '2': '1' };
        const payload = { keywords: '', lrfs: lrfsMap[LRFS], depID, roleid: '', pageindex: 1, pagesize: 10 };
        const { Data } = await dispatch('_getUserListForConfig', payload);
        return Data;
    },
    // 获取 配置页面 采集人 列表_内部调用
    async _getUserListForConfig({ dispatch, commit }, payload) {
        let result = await $GET('JcpzService/GetUserListForConfig', payload);
        return result.ResultValue;
    },
    // 获取用户列表
    async getUserList({ dispatch, commit }, args) {
        let result = await $GET('OperationandMaintenanceManagement/GetUserList', args);
        return result.ResultValue.result;
    },
    // 获取用户详情
    async getUserData({ dispatch, commit }, args) {
        let result = await $GET('OperationandMaintenanceManagement/GetUserData', args);
        return result.ResultValue[0];
    },
    // 获取部门筛选列表 改为多级结构，vue-select-tree
    async getDepNameList({ dispatch, commit }, args) {
        let result = await $GET('OperationandMaintenanceManagement/GetDepNameList', args);
        let res = result.ResultValue.result;
        function setData(list) {
            list.forEach(item => {
                item.id = item.ID;
                item.label = item.NAME;
                item.children = item.CHID;
                if (item.children && item.children.length > 0) {
                    item.children = setData(item.children);
                } else {
                    delete item.children;
                }
            });
            return list;
        }
        return setData(res);
    },
    // 获取部门筛选列表 单级结构 el-select
    async getDepNameListOrig({ dispatch, commit }, args) {
        let result = await $GET('OperationandMaintenanceManagement/GetDepNameListOrig', args);
        return result.ResultValue.result;
    },
    // 获取角色筛选列表
    async getRoleNameList({ dispatch, commit }, args) {
        let result = await $GET('OperationandMaintenanceManagement/GetRoleNameList', args);
        return result.ResultValue.result;
    },
    // 验证用户名是否存在
    async checkUserName({ dispatch, commit }, args) {
        let result = await $GET('OperationandMaintenanceManagement/CheckUserName', args);
        return result.IsSuccess;
    },
    // 获取APP权限
    async getMobleFunList({ dispatch, commit }, args) {
        let result = await $GET('OperationandMaintenanceManagement/GetMobleFunList', args);
        return result.ResultValue.result;
    },
    // 获取APP权限-树形结构
    async getMobleFunListEx({ dispatch, commit }, args) {
        let result = await $GET('OperationandMaintenanceManagement/GetMobleFunListEx', args);
        return result.IsSuccess ? result.ResultValue : [];
    },
    // 保存用户信息
    async saveUserData({ dispatch, commit }, args) {
        let result = await $POST('OperationandMaintenanceManagement/InsertUser', args);
        return result;
    },
    // 修改用户信息
    async updateUserData({ dispatch, commit }, args) {
        let result = await $POST('OperationandMaintenanceManagement/UpdateUser', args);
        return result;
    },
    // 删除用户
    async deleteUser({ dispatch, commit }, args) {
        let result = await $GET('OperationandMaintenanceManagement/DeleteUser', args);
        return result.IsSuccess;
    },
    // 获取部门列表
    async getDepList({ dispatch, commit }, args) {
        let result = await $GET('OperationandMaintenanceManagement/GetDeptList', args);
        return result.ResultValue;
    },
    // 获取部门详情
    async getDepData({ dispatch, commit }, args) {
        let result = await $GET('OperationandMaintenanceManagement/GetDeptData', args);
        return result.ResultValue;
    },
    // 验证部门名称是否存在
    async checkDepName({ dispatch, commit }, args) {
        let result = await $GET('OperationandMaintenanceManagement/CheckDeptName', args);
        return result.IsSuccess;
    },
    // 保存部门信息
    async saveDepData({ dispatch, commit }, args) {
        let result = await $POST('OperationandMaintenanceManagement/InsertDept', args);
        return result;
    },
    // 修改部门信息
    async updateDepData({ dispatch, commit }, args) {
        let result = await $POST('OperationandMaintenanceManagement/UpdateDept', args);
        return result;
    },
    // 删除部门
    async deleteDep({ dispatch, commit }, args) {
        let result = await $GET('OperationandMaintenanceManagement/DeleteDept', args);
        return result.IsSuccess;
    },
    // 获取数据树服务
    async getDataItem({ dispatch, commit }, args) {
        let result = await $GET('OperationandMaintenanceManagement/GetDataItem', args);
        return result;
    },
    // 获取角色列表
    async getRoleList({ dispatch, commit }, args) {
        let result = await $GET('OperationandMaintenanceManagement/GetRoleList', args);
        return result.ResultValue;
    },
    // 获取角色详情
    async getRoleData({ dispatch, commit }, args) {
        let result = await $GET('OperationandMaintenanceManagement/GetRoleData', args);
        return result.ResultValue;
    },
    // 验证角色名称是否存在
    async checkRoleName({ dispatch, commit }, args) {
        let result = await $GET('OperationandMaintenanceManagement/CheckRoleName', args);
        return result.IsSuccess;
    },
    // 保存角色信息
    async saveRoleData({ dispatch, commit }, args) {
        let result = await $POST('OperationandMaintenanceManagement/InsertRole', args);
        return result;
    },
    // 修改角色信息
    async updateRoleData({ dispatch, commit }, args) {
        let result = await $POST('OperationandMaintenanceManagement/UpdateRole', args);
        return result;
    },
    // 获取权限
    async getFuncTree({ dispatch, commit }, args) {
        let result = await $GET('OperationandMaintenanceManagement/GetFuncTree', args);
        return result.ResultValue;
    },
    // 删除角色
    async deleteRole({ dispatch, commit }, args) {
        let result = await $GET('OperationandMaintenanceManagement/DeleteRole', args);
        return result.IsSuccess;
    },
    // 获取民居调查权限的用户列表
    async getMJDCUserList({ dispatch, commit }, args) {
        let result = await $GET('OperationandMaintenanceManagement/GetMjdcUserList', args);
        return result.IsSuccess ? result.ResultValue : [];
    },
    // 为用户绑定村民小组权限
    async insertMjdcXzqhbm({ dispatch, commit }, args) {
        let result = await $GET('OperationandMaintenanceManagement/InsertMjdcXzqhbm', args);
        return result.IsSuccess;
    },
    // 获取民居调查行政村和村民小组列表
    async getAreaGroupTree({ dispatch, commit }, args) {
        let result = await $GET1('Jmdc/GetJmxzbmList', args);
        return result.ISSUCCESS ? result.RESULTVALUE : [];
    },
    // 获取用户已绑定的权限
    async getUserXzqhbm({ dispatch, commit }, args) {
        let result = await $GET1('Jmdc/GetUserXzqhbm', args);
        return result.ISSUCCESS ? result.RESULTVALUE : {};
    },
    // 授权管理 start
    // 获取用户列表数据信息(根据部门 用于左侧展示)
    async GetUserByDepList({ dispatch, commit }, args) {
        let result = await $GET("OperationandMaintenanceManagement/GetUserByDepList", args);
        return result.IsSuccess ? result.ResultValue : [];
    },
    // 获取用户权限信息
    async GetUserAuthority({ dispatch, commit }, args) {
        let result = await $GET("OperationandMaintenanceManagement/GetUserAuthority", args);
        return result.IsSuccess ? result.ResultValue : [];
    },
    // 保存用户权限信息
    async SaveUserAuthority({ dispatch, commit }, args) {
        let result = await $POST("OperationandMaintenanceManagement/SaveUserAuthority", args);
        return result.IsSuccess;
    },
    // 授权管理 end
    // 获取日志
    async GetCustomAutoHistory({ dispatch, commit }, args) {
        let result = await $GET('RzglService/GetCustomAutoHistory', args);
        return result.RESULTVALUE;
    },
};
export default {
    state,
    getters,
    mutations,
    actions
};