// 自然环境数据
let list = [
    {
        name: "气象",
        itemID: "8020101"
    },
    {
        name: "大气质量",
        itemID: "8020202"
    },
    {
        name: "气象灾害",
        itemID: "8020512"
    }, {
        name: "微环境气象监测",
        itemID: "8020103"
    }, {
        name: "微环境空气质量监测",
        itemID: "8020204"
    }, {
        name: "植物多样性监测",
        itemID: "8020514"
    }, {
        name: "动物多样性监测",
        itemID: "8020515"
    }, {
        name: "物候监测",
        itemID: "8020516"
    }, {
        name: "微环境土壤监测",
        itemID: "8020504"
    }, {
        name: "微环境水文水质监测",
        itemID: "8020510"
    }, {
        name: "温度月度记录",
        itemID: "8020102"
    }, {
        name: "降雨量月度记录",
        itemID: "8020104"
    },
]
let listNew = []
list.map((item) => {
    listNew.push(item.itemID)
})
export default listNew