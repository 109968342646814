let list = [
    {
        name: "民居建设",
        itemID: "90501"
    },
]

let listNew = []
list.map((item) => {
    if (item.list) {
        item.list.map((item1) => {
            listNew.push(item1.itemID)
        })
    } else {
        listNew.push(item.itemID)
    }
})
export default listNew
