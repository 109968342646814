<template>
    <div class="sn_search">
        <!-- 单选框 -->
        <div v-if="radioData && radioData.length > 0"
             class="sn_seatch_item">
            <sn-radio v-for="(item, index) in radioData"
                      ref="snRadio"
                      :key="index + item.value"
                      :radioData="item"
                      @handleChange="handleChange"></sn-radio>
        </div>
        <!-- 输入框筛选 -->
        <div v-if="inputData.length > 0"
             class="sn_seatch_item">
            <sn-input v-for="(item, index) in inputData"
                      ref="snInput"
                      :key="index"
                      type="text"
                      onkeydown="if(event.keyCode==32) return false"
                      :item="JSON.stringify(item)"
                      @handleChange="handleChange"></sn-input>
        </div>
        <!-- 树下拉框筛选-->
        <div v-if="treeselectData.length > 0"
             class="sn_seatch_item">
            <sn-treeselect v-for="(item, index) in treeselectData"
                           ref="snTreeselect"
                           :key="index"
                           :treeselectData="item"
                           :treeselectValue="item.default"
                           :clearable="item.clearable"
                           @handleChange="handleChange"></sn-treeselect>
        </div>
        <!-- 下拉框筛选  -->
        <div v-if="selectData.length > 0"
             class="sn_seatch_item">
            <sn-select v-for="(item, index) in selectData"
                       ref="snSelect"
                       :key="index"
                       :selectData="item"
                       @handleChange="handleChange"></sn-select>
        </div>
        <!-- 时间筛选 -->
        <div v-if="dateData.length > 0"
             class="sn_seatch_item">
            <sn-date-picker v-for="(item, index) in dateData"
                            ref="snDatePicker"
                            :key="index"
                            :dateData="item"
                            :selectTimeValue="item.default"
                            @handleChange="handleChange"></sn-date-picker>
        </div>
        <!-- 按钮 -->
        <div class="sn_seatch_item">
            <sn-button class="sn_button"
                       :snButton.sync="searchBtn"
                       @handleChange="handleChange"></sn-button>
        </div>
    </div>
</template>
<script>
export default {
    name: "sn_search_item",
    props: {
        snSearch: {
            default: function () {
                return {};
            },
        },
    },
    data () {
        return {
            radioData: [],
            inputData: [],
            treeselectData: [],
            selectData: [],
            dateData: [],
            buttonData: [],
            searchBtn: {},
        };
    },
    watch: {
        snSearch: {
            deep: true,
            immediate: true,
            handler () {
                this.handleData();
            }
        }
    },
    created () { },
    mounted () { },
    methods: {
        handleData () {
            Object.assign(this.$data, this.$options.data());
            Object.keys(this.snSearch).forEach((key) => {
                this[key] = this.snSearch[key];
            });
            console.log('%c 🥕 snSearch: ', 'font-size:20px;background-color: #4b4b4b;color:#fff;', this.snSearch);
        },
        handleChange (type, value) {
            this.$emit("handleChange", type, value);
        },
        resetSearchValue (id, value) {
            const snSelect = this.$refs.snSelect;
            const snDatePicker = this.$refs.snDatePicker;
            [snSelect, snDatePicker].forEach((ref) => {
                if (Array.isArray(ref)) {
                    ref.forEach((ele) => {
                        ele.resetValue && ele.resetValue(id, value);
                    });
                } else if (ref) {
                    ref.resetValue && ref.resetValue(id, value);
                }
            });
        },
    },
};
</script>
<style scoped>
.sn_button {
    margin-top: -5px;
}
</style>
