// 封装axios
import axios from "axios";
import QS from "qs";
import { Message } from "element-ui";
import router from "@router/index.js";
let BASE_URL = window.REQUEST_URL;

axios.defaults.timeout = 20000;

// 提示框
const tip = (msg) => {
    Message({
        showClose: false,
        message: msg,
        type: "error",
    });
};

// 跳转到登录页面
const toLogin = () => {
    router.replace({
        name: "login",
    });
};

// 请求失败后错误统一处理
const errorHandle = (status, other) => {
    switch (status) {
        // 401: 未登录状态，跳转登录页
        case 401:
            toLogin();
            break;
        // 403 token过期
        // 清除token并跳转登录页
        case 403:
            tip("登录过期，请重新登录");
            setTimeout(() => {
                toLogin();
            }, 2000);
            break;
        default:
            console.log(other);
    }
};

// 创建axios实例
let instance = axios.create({
    timeout: 1000 * 15,
    headers: {
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
        token: "",
    },
});

// 请求拦截器 调用 非登录模块的接口
instance.interceptors.request.use(
    (config) => {
        config.headers.Authorization = localStorage.sysAccessToken;
        config.headers.SystemId = localStorage.requestSystemId;
        config.headers.SystemName = encodeURIComponent(
            localStorage.requestSystemName
        );
        config.headers.ModuleId = localStorage.requestModuleId;
        config.headers.ModuleName = encodeURIComponent(
            localStorage.requestModuleName
        );
        let itemID = localStorage.requestItemID;
        let isNotNum = isNaN(itemID);
        config.headers.ItemId = isNotNum ? encodeURIComponent(itemID) : itemID;
        config.headers.ItemName = encodeURIComponent(
            localStorage.requestItemName
        );
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

// 响应拦截器
instance.interceptors.response.use(
    // 请求成功
    (res) => (res.status === 200 ? Promise.resolve(res) : Promise.reject(res)),
    // 请求失败
    (error) => {
        const { response } = error;
        if (response) {
            // 请求已发出，但是不在2xx的范围
            errorHandle(response.status, response.data.message);
            return Promise.reject(response);
        } else {
            // 处理断网的情况
            // eg:请求超时或断网时，更新state的network状态
            // network状态在app.vue中控制着一个全局的断网提示组件的显示隐藏
            // 关于断网组件中的刷新重新获取数据，会在断网组件中说明
            if (!window.navigator.onLine) {
                // store.commit('changeNetwork', false);
            } else {
                return Promise.reject(error);
            }
        }
    }
);
/**
 * get方法，对应get请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
export function $GET(url, params) {
    // console.log("GET服务参数--", "服务名：", url, "----参数：", params);
    return new Promise((resolve, reject) => {
        BASE_URL = window.REQUEST_URL
        if (url == "dock/DockData") {
            BASE_URL = BASE_URL.split("jmjc/api/")[0] + "dock/";
        }
        instance
            .get(BASE_URL + url, {
                params: params,
            })
            .then((res) => {
                resolve(res.data);
            })
            .catch((err) => {
                reject(err.data);
            });
    });
}

/**
 * post方法，对应post请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
export function $POST(url, params) {
    // console.log('POST服务参数--', '服务名：', URL, '----参数：', params);
    let obj = JSON.parse(JSON.stringify(params));

    for (let key in obj) {
        if (
            Object.prototype.hasOwnProperty.call(obj, key) &&
            typeof obj[key] === "object"
        ) {
            obj[key] = JSON.stringify(obj[key]);
        }
    }
    return new Promise((resolve, reject) => {
        instance
            .post(BASE_URL + url, QS.stringify(obj))
            .then((res) => {
                resolve(res.data);
            })
            .catch((err) => {
                reject(err.data);
            });
    });
}

export function $POST1(url, params) {
    // console.log("POST服务参数--", "服务名：", URL, "----参数：", params);
    let obj = JSON.parse(JSON.stringify(params));

    for (let key in obj) {
        if (
            Object.prototype.hasOwnProperty.call(obj, key) &&
            typeof obj[key] === "object"
        ) {
            obj[key] = JSON.stringify(obj[key]);
        }
    }
    return new Promise((resolve, reject) => {
        instance
            .post(url, QS.stringify(obj))
            .then((res) => {
                resolve(res.data);
            })
            .catch((err) => {
                reject(err.data);
            });
    });
}

export function $AXIOS(config) {
    return axios(config);
}
