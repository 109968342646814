/* eslint-disable no-unused-vars */
import { $GET, $POST, $AXIOS } from '@request/http.js';
import { Message } from 'element-ui';
import * as Types from './mutation-type.js';

const state = {
    /**
     * 查询参数
     */
    queryParamState: null,
    runningStatusResult: [],
    sbqdList: [],
    totalList: {},
    singleEquip: {}, // 单个异常设备检测结果
    abnormalCountWhole: null,

    serviceStatusResult: [],
    zdqdList: [],
    totalserviceList: {},
    ResultList: null,

    /**
     * 自然环境配置类别-服务配置
     */
    zrhjpzType: null,
    /**
     * 获取自然环境配置清单-服务配置
     */
    zrhjpzList: null,
    /**
     * 枚举值
     */
    enumData: [],
    fzrData: null,
    /**
     * grid数据源
     */
    tableData: [],
    /**
     * 记录总数
     */
    tableTotal: 0,
};

const getters = {
    [Types.queryParamState]: ({ queryParamState }) => queryParamState,
    [Types.RunningStatusResult]: ({ runningStatusResult }) => runningStatusResult,
    [Types.SBQDLIST]: ({ sbqdList }) => sbqdList,
    [Types.TOTALLIST]: ({ totalList }) => totalList,
    [Types.SingleEquip]: ({ singleEquip }) => singleEquip,
    [Types.abnormalCountWhole]: ({ abnormalCountWhole }) => abnormalCountWhole,

    [Types.serviceStatusResult]: ({ serviceStatusResult }) => serviceStatusResult,
    [Types.zdqdList]: ({ zdqdList }) => zdqdList,
    [Types.totalserviceList]: ({ totalserviceList }) => totalserviceList,
    [Types.ResultList]: ({ ResultList }) => ResultList,

    [Types.zrhjpzType]: ({ zrhjpzType }) => zrhjpzType,
    [Types.zrhjpzList]: ({ zrhjpzList }) => zrhjpzList,
    [Types.enumData]: ({ enumData }) => enumData,
    [Types.fzrData]: ({ fzrData }) => fzrData,
    [Types.tableData]: ({ tableData }) => tableData,
    [Types.tableTotal]: ({ tableTotal }) => tableTotal,
};

const mutations = {
    [Types.SET_QUERYPARAM]: (state, queryParam) => state.queryParamState = queryParam,
    [Types.SET_RunningStatusResult]: (state, runningStatusResult) => state.runningStatusResult = runningStatusResult,
    [Types.SET_SBQDLIST]: (state, sbqdList) => state.sbqdList = sbqdList,
    [Types.SET_TOTALLIST]: (state, totalList) => state.totalList = totalList,
    [Types.SET_SingleEquip]: (state, singleEquip) => state.singleEquip = singleEquip,
    [Types.SET_ABNORMALCOUNTWHOLE]: (state, abnormalCountWhole) => state.abnormalCountWhole = abnormalCountWhole,

    [Types.SET_ServiceStatusResult]: (state, serviceStatusResult) => state.serviceStatusResult = serviceStatusResult,
    [Types.SET_ZDQDLIST]: (state, zdqdList) => state.zdqdList = zdqdList,
    [Types.SET_TOTALSERVICLIST]: (state, totalserviceList) => state.totalserviceList = totalserviceList,
    [Types.SET_ResultList]: (state, ResultList) => state.ResultList = ResultList,

    [Types.SET_ZRHJPZTYPE]: (state, zrhjpzType) => state.zrhjpzType = zrhjpzType,
    [Types.SET_ZRHJPZLIST]: (state, zrhjpzList) => state.zrhjpzList = zrhjpzList,
    [Types.SET_ENUMDATA]: (state, enumData) => state.enumData = enumData,
    [Types.SET_FZRDATA]: (state, fzrData) => state.fzrData = fzrData,
    [Types.SET_TABLEDATA]: (state, tableData) => state.tableData = tableData,
    [Types.SET_TABLETOTAL]: (state, tableTotal) => state.tableTotal = tableTotal,
};

const actions = {
    // 设备运行状态--------------------------
    // 5.1.1获取运行状态(运行状态)
    async GetDevRunningStatus({ dispatch, commit }, payload) {
        let result = await $GET('EquipmentTesting/GetDevRunningStatus', payload);
        if (result && result.IsSuccess && result.ResultValue) {
            commit(Types.SET_RunningStatusResult, result.ResultValue.STATISLIST); // 各设备检测结果
            commit(Types.SET_SBQDLIST, result.ResultValue.SBQDLIST); // 所有设备清单
            commit(Types.SET_TOTALLIST, result.ResultValue.TOTALOBJ); // 全面检测结果
        } else {
            commit(Types.SET_TOTALLIST, {}); // 全面检测结果
        }
    },
    // 5.1.2检测单个设备接入是否正常(运行状态)
    async CheckSingleDataIsNormal({ dispatch, commit }, payload) {
        let result = await $GET('EquipmentTesting/CheckSingleDataIsNormal', payload);
        return result;
    },
    // 5.1.3获取每一类型下的检查结果清单(运行状态)
    async GetLxResultList({ dispatch, commit }, payload) {
        let result = await $GET('EquipmentTesting/GetLxResultList', payload);
        return result;
    },
    // 5.1.4获取检查类型详情服务(运行状态)
    async GetCheckTypeDetail({ dispatch, commit }, payload) {
        let result = await $GET('EquipmentTesting/GetCheckTypeDetail', payload);
        return result;
    },
    // 5.1获取遗产组成(运行状态)
    async GetCompositions({ dispatch, commit }, payload) {
        let result = await $GET('EquipmentTesting/GetCompositions', payload);
        return result;
    },

    // 共享服务状态------------------------------
    // 5.1.5获取共享服务运行状态的服务(运行状态)
    async GetShareServiceRunningStatus({ dispatch, commit }, payload) {
        let result = await $GET('EquipmentTesting/GetShareServiceRunningStatus', payload);
        if (result && result.IsSuccess && result.ResultValue) {
            result.ResultValue.ZDQDLIST.forEach(element => {
                element.checkState = '0';
                result.ResultValue.STATISLIST.forEach(ele => {
                    if (ele.SBLX == element.LX) {
                        element.time = ele.SCJCSJ;
                        ele.JRJGLIST[1].COUNT > 0 ? element.sfyc = '1' : element.sfyc = '0';
                        element.zcsj = ele.JRJGLIST[0].COUNT;
                        element.ycsj = ele.JRJGLIST[1].COUNT;
                    }

                });
            });
            commit(Types.SET_ServiceStatusResult, result.ResultValue.STATISLIST); // 各设备检测结果
            commit(Types.SET_ZDQDLIST, result.ResultValue.ZDQDLIST); // 所有设备清单
            commit(Types.SET_TOTALSERVICLIST, result.ResultValue.TOTALOBJ); // 全面检测结果
        }
    },
    // 5.1.7获取每一站点类型下的检查结果清单(运行状态)
    async GetShareSerLxResultList({ dispatch, commit }, payload) {
        let result = await $GET('EquipmentTesting/GetShareSerLxResultList', payload);
        if (result && result.IsSuccess && result.ResultValue) {
            commit(Types.SET_ResultList, result.ResultValue);
        } else {
            commit(Types.SET_ResultList, []);
        }
    },
    // 5.1.6检查共享服务单个数据接入是否正常(运行状态)
    async CheckShareServiceSingleDataIsNormal({ dispatch, commit }, payload) {
        let result = await $GET('EquipmentTesting/CheckShareServiceSingleDataIsNormal', payload);
        return result;
    },

    // 服务配置-----------------------
    // 5.3.1获取自然环境配置类别-服务配置
    async getZrhjpzType({ dispatch, commit }, payload) {
        let result = await $GET('EquipmentTesting/GetZrhjpzType', payload);
        if (result && result.IsSuccess && result.ResultValue) {
            commit(Types.SET_ZRHJPZTYPE, result.ResultValue);
        } else {
            commit(Types.SET_ZRHJPZTYPE, []);
        }
    },
    // 5.3.2获取自然环境配置清单-服务配置
    async getZrhjpzList({ dispatch, commit }, payload) {
        let result = await $GET('EquipmentTesting/GetZrhjpzList', payload);
        if (result && result.IsSuccess && result.ResultValue) {
            commit(Types.SET_ZRHJPZLIST, result.ResultValue);
        } else {
            commit(Types.SET_ZRHJPZLIST, []);
        }
    },
    // 5.2.1获取枚举值(年月日时分秒)(配置管理)
    async getEnumData({ dispatch, commit }, payload) {
        let result = await $GET('EquipmentTesting/GetEnumDataByTableId', payload);
        if (result && result.IsSuccess) {
            commit(Types.SET_ENUMDATA, result.ResultValue);
            return result.ResultValue;
        }
    },
    // 5.3.4保存设备配置服务（新建|编辑通用）
    async saveJieruConfig({ dispatch, commit }, payload) {
        let result = await $POST('EquipmentTesting/SaveJieruConfig', payload);
        return result;
    },
    async getFZR({ dispatch, commit }, payload) {
        // let args = ["114", "", "", "", null, null, true, ""];
        let result = await $GET('GeneralBusi/SelectByWhere', payload);
        if (result && result.IsSuccess && result.ResultValue && result.ResultValue.data) {
            commit(Types.SET_FZRDATA, result.ResultValue.data);
        }
    },
    async getSelectData({ dispatch, commit }, payload) {
        // 参数：string _cols, string _keyword, string _serarchCols, string _conditionList, string _dicOrderby, int _pageSize, int _pageIndex, bool _returnSum, bool _needVersion, bool _searchFormal, string itemid
        // 参数说明：
        // _cols	字段集
        // _keyword	查询关键字
        // _serarchCols	关键字字段集合
        // _conditionList	过滤条件集合
        // _dicOrderby
        // _pageSize	每页最大记录数
        // _pageIndex	页索引
        // _returnSum	是否返回记录总数
        // _needVersion	是否分版本查询
        // _searchFormal    T：查询最近的正式版本数据；F：查询最近的数据
        // itemid 	业务编号
        let result = await $GET('EquipmentTesting/_SelectByWhere', payload);
        return result.ResultValue;
        // if (result && result.IsSuccess) {
        //     if (result.ResultValue && result.ResultValue.data) {
        //         commit(Types.SET_TABLEDATA, result.ResultValue.data);
        //     } else {
        //         commit(Types.SET_TABLEDATA, []);
        //     }
        //     if (result.ResultValue && result.ResultValue.sum) {
        //         commit(Types.SET_TABLETOTAL, result.ResultValue.sum);
        //     } else {
        //         commit(Types.SET_TABLETOTAL, 0);
        //     }
        // }
    },

    /**
     * 数据管理-自然环境：获取列表
     */
    async GetNaturalEnvironmentList({ dispatch, commit, state }, payload) {
        let result = await $GET('EquipmentTesting/GetNaturalEnvironmentList', payload);
        return result.ResultValue;
    },

    /**
     * 数据管理-本体病害：获取列表
     */
    async GetDiseaseMonitorDataList({ dispatch, commit, state }, payload) {
        let result = await $GET('MonitorData/GetDiseaseMonitorDataList', payload);
        return result.ResultValue;
    },
    /**
     * 数据管理-本体病害：获取详情
     */
    async DetailsDiseaseMonitorData({ dispatch, commit, state }, payload) {
        let result = await $GET('MonitorData/DetailsDiseaseMonitorData', payload);
        return result.ResultValue;
    },
    /**
     * 数据管理-本体病害：保存
     */
    async SaveDiseaseMonitorData({ dispatch, commit, state }, payload) {
        let result = await $POST('MonitorData/SaveDiseaseMonitorData', payload);
        return result;
    },
    /**
     * 数据管理-本体病害：删除
     */
    async DeleteDiseaseMonitorData({ dispatch, commit, state }, payload) {
        let result = await $POST('MonitorData/DeleteDiseaseMonitorData', payload);
        return result;
    },

    /**
     * 配置管理：获取负责人列表
     */
    async GetGathererList({ dispatch, commit }, payload) {
        let result = await $GET('EquipmentTesting/GetGathererList', payload);
        return result.ResultValue;
    },
    /**
     * 配置管理：修改设备状态
     */
    async UpdateTaskState({ dispatch, commit }, payload) {
        let result = await $GET('EquipmentTesting/UpdateTaskState', payload);
        return result;
    },
    /**
     * 配置管理：获取详情
     */
    async GetSbqdAndJieruConfigDetail({ dispatch, commit }, payload) {
        let result = await $GET('GeneralBusi/GetSbqdAndJieruConfigDetail', payload);
        return result.ResultValue;
    },

    /**
     * 设备管理-设备类型：查询服务
     */
    async GetAllEquipmentList({ dispatch, commit }, payload) {
        let result = await $GET('EquipmentTesting/GetAllEquipmentList', payload);
        return result.ResultValue;
    },
    /**
     * 设备管理-设备类型：删除服务
     */
    async DeleteSoilEquipment({ dispatch, commit }, payload) {
        let result = await $GET('EquipmentTesting/DeleteSoilEquipment', payload);
        return result;
    },
    /**
     * 设备管理-设备类型：新增/修改服务(不传ID视为新增)
     */
    async SaveSoilEquipment({ dispatch, commit }, args) {
        let result = await $POST('EquipmentTesting/SaveSoilEquipment', args);
        return result;
    },

    /**
     * 设备管理-设备清单：获取详情
     */
    async GetDetailsData({ dispatch, commit }, payload) {
        let result = await $GET('GeneralBusi/GetDetailsData', payload);
        return result.ResultValue;
    },
    /**
     * 设备管理-设备清单：获取详情 新2020/03/26
     */
    async GetDetailsEquipmentData({ dispatch, commit }, payload) {
        let result = await $GET('EquipmentTesting/GetDetailsEquipmentData', payload);
        return result.ResultValue;
    },

    /**
     * 设备管理-设备清单：获取周期单位列表
     */
    async GetZqdwList({ dispatch, commit }, payload) {
        let result = [{
                TABLEID: "JIERU_CONFIG",
                COLUMNID: "grab_cycle_unit",
                CODE: "0",
                NAME: "年",
                ENUMID: "E99B826C-464A-405F-8530-644DD5B2FA9F",
                ENUMNAME: "周期单位"
            },
            {
                TABLEID: "JIERU_CONFIG",
                COLUMNID: "grab_cycle_unit",
                CODE: "1",
                NAME: "月",
                ENUMID: "E99B826C-464A-405F-8530-644DD5B2FA9F",
                ENUMNAME: "周期单位"
            },
            {
                TABLEID: "JIERU_CONFIG",
                COLUMNID: "grab_cycle_unit",
                CODE: "2",
                NAME: "周",
                ENUMID: "E99B826C-464A-405F-8530-644DD5B2FA9F",
                ENUMNAME: "周期单位"
            },
            {
                TABLEID: "JIERU_CONFIG",
                COLUMNID: "grab_cycle_unit",
                CODE: "3",
                NAME: "天",
                ENUMID: "E99B826C-464A-405F-8530-644DD5B2FA9F",
                ENUMNAME: "周期单位"
            },
            {
                TABLEID: "JIERU_CONFIG",
                COLUMNID: "grab_cycle_unit",
                CODE: "4",
                NAME: "时",
                ENUMID: "E99B826C-464A-405F-8530-644DD5B2FA9F",
                ENUMNAME: "周期单位"
            },
            {
                TABLEID: "JIERU_CONFIG",
                COLUMNID: "grab_cycle_unit",
                CODE: "5",
                NAME: "分",
                ENUMID: "E99B826C-464A-405F-8530-644DD5B2FA9F",
                ENUMNAME: "周期单位"
            },
            {
                TABLEID: "JIERU_CONFIG",
                COLUMNID: "grab_cycle_unit",
                CODE: "6",
                NAME: "秒",
                ENUMID: "E99B826C-464A-405F-8530-644DD5B2FA9F",
                ENUMNAME: "周期单位"
            }
        ];
        return result;
    },
    /**
     * 设备管理-设备清单：删除数据
     */
    async DeleteData({ dispatch, commit }, payload) {
        let result = await $POST('GeneralBusi/DeleteData', payload);
        return result;
    },
    /**
     * 设备管理-设备清单：删除数据 新2020/03/26
     */
    async DeleteEquipmentIds({ dispatch, commit }, payload) {
        let result = await $POST('EquipmentTesting/DeleteEquipmentIds', payload);
        return result;
    },

    /**
     * 设备管理-设备清单：保存数据
     */
    async SaveData({ dispatch, commit }, payload) {
        let result = await $POST('GeneralBusi/SaveData', payload);
        return result;
    },
    /**
     * 设备管理-设备清单：保存数据 新2020/03/26
     */
    async SaveEquipmentData({ dispatch, commit }, payload) {
        let result = await $POST('EquipmentTesting/SaveEquipmentData', payload);
        return result;
    },

    /**
     * 设备管理-设备清单-测量机器人：获取监测点构成列表
     */
    async GetJCDGCList({ dispatch, commit }, payload) {
        let result = await $GET('EquipmentTesting/GetJCDGCList', payload);
        return result.ResultValue;
    },
    /**
     * 设备管理-设备清单-测量机器人：获取监测指标列表
     */
    async GetZBXList({ dispatch, commit }, payload) {
        let result = await $GET('EquipmentTesting/GetZBXList', payload);
        return result.ResultValue;
    },
    /**
     * 设备管理-设备清单-测量机器人：保存服务(监测指标项)
     */
    async SaveZBX({ dispatch, commit }, payload) {
        let result = await $POST('EquipmentTesting/SaveZBX', payload);
        return result;
    },
    /**
     * 设备管理-设备清单-测量机器人：保存服务
     */
    async SaveCLJQR({ dispatch, commit }, payload) {
        let result = await $POST('EquipmentTesting/SaveCLJQR', payload);
        return result;
    },
    /**
     * 设备管理-设备清单-测量机器人：获取详情
     */
    async DetailsCLJQR({ dispatch, commit }, payload) {
        let result = await $GET('EquipmentTesting/DetailsCLJQR', payload);
        return result.ResultValue;
    },
    /**
     * 设备管理-设备清单-测量机器人：删除服务
     */
    async DeleteCLJQR({ dispatch, commit }, payload) {
        let result = await $POST('EquipmentTesting/DeleteCLJQR', payload);
        return result;
    },
    /**
     * 设备管理-设备清单-获取遗产组成和遗产要素清单（二级结构）
     */
    async GetYczcAndYcys({ dispatch, commit }, payload) {
        let result = await $GET('EquipmentTesting/GetYczcAndYcys', payload);
        return result.RESULTVALUE;
    },


    /**
     * 地图服务管理：获取地图类型下拉型列表服务
     */
    async GetEnumitemMap({ dispatch, commit, state }, payload) {
        let result = await $GET('MonitorData/GetEnumitem', payload);
        return result.ResultValue;
    },
    /**
     * 地图服务管理：获取类别下拉型列表服务
     */
    async GetMapCategoryList({ dispatch, commit, state }, payload) {
        let result = await $GET('EquipmentTesting/GetMapCategoryList', payload);
        return result.ResultValue;
    },
    /**
     * 获取地图数据
     */
    // async getVectorStyle({
    //     dispatch,
    //     commit
    // }, payload) {
    //     let result = await $AXIOS({
    //         method: 'get',
    //         url: payload.url,
    //         dataType: 'json',
    //     });
    //     return result.data;
    // },
    // 设备状态
    async getDevRunningStatusEx({ dispatch }) {
        const result = await $GET('EquipmentTesting/GetDevRunningStatusEx');
        return result.RESULTVALUE;
    },
    async getEquipmentList({ dispatch }, payload) {
        const result = await $GET('EquipmentTesting/GetEquipmentList', payload);
        return result.RESULTVALUE;
    },
    async getYcysqdList({ dispatch }) {
        const result = await $GET('EquipmentTesting/GetYcysqdList');
        return result.RESULTVALUE;
    },
    async getEquipmentDetial({ dispatch }, payload) {
        const result = await $GET('EquipmentTesting/GetEquipmentDetial', payload);
        return result.RESULTVALUE;
    },
    async getEquipmentFault({ dispatch }, payload) {
        const result = await $GET('EquipmentTesting/GetEquipmentFault', payload);
        return result.RESULTVALUE;
    },
    async checkSingleDataIsNormalEx({ dispatch }, payload) {
        const result = await $GET('EquipmentTesting/CheckSingleDataIsNormalEx', payload);
        return result.RESULTVALUE;
    },
    // 服务监控  --开始--
    async getServiceTypeList({ dispatch }, payload) {
        const result = await $GET('EquipmentTesting/GetServiceTypeList', payload);
        return result.RESULTVALUE;
    },
    async getServiceFault({ dispatch }, payload) {
        const result = await $GET('EquipmentTesting/GetServiceFault', payload);
        return result.RESULTVALUE;
    },
    // 服务管理
    async getServiceAdministration({ dispatch }, payload) {
        const result = await $GET('EquipmentTesting/GetServiceAdministration', payload);
        return result.RESULTVALUE;
    },
    async saveServiceAdministration({ dispatch }, payload) {
        const result = await $POST('EquipmentTesting/SaveServiceAdministration', payload);
        return result.RESULTVALUE;
    },
    async getUserInfoList({ dispatch }, payload) {
        const result = await $GET('EquipmentTesting/GetUserInfoList', payload);
        return result.RESULTVALUE;
    },
    async deleteFileInfo({ dispatch }, payload) {
        const result = await $POST('EquipmentTesting/DeleteFileInfo', payload);
        return result;
    },
};
export default {
    state,
    getters,
    mutations,
    actions
};