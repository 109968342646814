import monitorToBasicDataMap from "../../table_config/monitor_to_basic";
import basicUpload from '@comp/basic_upload';
import { mapGetters, mapActions } from 'vuex';
import { commonMethods } from '../../table_config/table-common-methods';
import enumList from "./enum_list";
import no_cjr_list from "./no_cjr_list";
import edit_cjsj from "./edit_cjsj";//编辑创建时间

export default {
    components: {
        basicUpload
    },
    props: {
        itemId: {
            type: String,
            default: '33'
        }
    },
    computed: {
        ...mapGetters([
            'heritageId',
            'userName',
            'userId'
        ]),
        enableEditDate: function () {
            let state = localStorage.monitoringPower.includes('1')
            return state;
        },
    },
    data() {
        // 记录id
        return {
            restData: null,
            timer: null,
            enumObj: {}, //枚举对象
            commonMethods,
            id: '',
            type: '',
            formdata: {
                dataDic: {},
                fileids: '',
                itemCode: '',
                metaJson: []
            },
            metaJson: [],
            isEdit: false, // 是否可编辑
            version: '', // 版本更新--版本号
            fid: '', // 新建子要素--父级要素编码
            ItemID: '', // 当前是哪项
            basicType: '',
            snButton: {
                buttonData: [
                    {
                        isShow: true,
                        btnType: "button",
                        operateType: "saveDataAdmin",
                        name: "保存",
                        round: true,
                        backColor: "#28cdd9",
                        color: "#fff"
                    },
                    {
                        isShow: true,
                        btnType: "button",
                        operateType: "saveNosubmit",
                        editType: "1",
                        name: "保存暂不提交",
                        round: true,
                        backColor: "#28cdd9",
                        color: "#fff"
                    },
                    {
                        isShow: true,
                        btnType: "button",
                        operateType: "saveSubmit",
                        editType: "1",
                        name: "保存并提交",
                        round: true,
                        backColor: "#6f32ff",
                        color: "#fff"
                    },
                    {
                        isShow: true,
                        btnType: 'button',
                        operateType: 'savePass',
                        editType: '2',
                        name: '审核通过',
                        round: true,
                        backColor: '#28cdd9',
                        color: '#fff'
                    }, {
                        isShow: true,
                        btnType: 'button',
                        operateType: 'saveNoPass',
                        editType: '2',
                        name: '审核不通过',
                        round: true,
                        backColor: '#6f32ff',
                        color: '#fff'
                    },
                    {
                        isShow: true,
                        btnType: "button",
                        operateType: "returnList",
                        editType: "0,1,2",
                        name: "返回",
                        round: true,
                        backColor: "#ff9066",
                        color: "#fff"
                    }
                ]
            },
        };
    },
    created() {
        this.id = this.$route.query.id;
        this.type = this.$route.query.type;
        this.basicType = this.$route.query.basicType;
        this.version = this.$route.query.version;
        this.ItemID = this.$route.query.ItemID;
        if (this.version) {
            this.formdata.isNewVersion = true;
        }
        this.fid = this.$route.query.fid;
    },
    mounted() {
        // 根据枚举配置 获取页面枚举字段
        enumList.map((item) => {
            if (item.itemID == this.ItemID) {
                this.getEnums();
            }
        })
        if (this.id) {
            // 判读是否是 和 基础数据来自同一个表，且 在基础数据里面有对应的 服务
            if (monitorToBasicDataMap[this.formdata.itemCode]) {
                const baiscItem = monitorToBasicDataMap[this.formdata.itemCode];
                this.getBasicFormData(baiscItem);
            } else {
                this.getFromData();
            }
        }
        this.snButton.buttonData.forEach(item => {
            item.isShow = true;
        });
        this.contralBtn();
    },
    watch: {
        // 修改数据的时候 获取 附件id
        formdata: {
            immediate: true,
            deep: true,
            handler: function () {
                let list = ["90201", "1401", "90501"]
                if (!list.includes(this.formdata.itemCode) && this.formdata.metaJson.length) {
                    this.formdata.fileids = this.formdata.metaJson.map(item => item.jcsjid).join(",");
                }
            }
        },
    },
    methods: {
        ...mapActions([
            'getGatherData',
            'saveMonitorData',
            'getMonitorDataDetails',
            'getAllEnumInfobytableID',
            'exportMonitorData',
            'updateShztOfMonitorData',
            'recalculateDatacode',
            'SavePointOpe'
        ]),
        // 时间改变保存数据
        autoSaveOnChange() {
            const toSave = !this.snButton.buttonData.some(ele => {
                return ['saveNoSubmit', 'saveSubmit'].includes(ele.operateType) && ele.isShow;
            });
            if (toSave) {
                this.$confirm('此次更改将会保存,确定要保存吗', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.saveData(false);
                }).catch(() => {
                    this.common.showMsg("已取消提交", "info")
                });
            }
        },
        // 从基础数据获取数据
        async getBasicFormData(baiscItem) {
            let params = {
                heritageId: this.heritageId,
                itemid: baiscItem,
                id: this.id,
            };
            let result = await this.getGatherData(params);
            if (result.issuccess) {
                const dataDicOrigin = result.resultvalue.savejson[0];
                const dataDicTarget = {};
                Object.keys(dataDicOrigin).forEach(key => {
                    dataDicTarget[key.toUpperCase()] = dataDicOrigin[key];
                });
                Object.assign(this.formdata.dataDic, dataDicTarget);
                this.formdata.metaJson = result.resultvalue.metajson || [];
                // 从服务获得数据后 数据需要进一步处理 具体单个文件里要用
                this.GetDataNext();
            }
        },
        GetDataNext() { },
        // 获取枚举数据
        async getEnums() {
            const res = await this.getAllEnumInfobytableID({ bid: this.ItemID });
            const obj = {};
            res && res.forEach(item => {
                if (item.COLUMNID === 'QKSM') {
                    obj[item.COLUMNID] = item.dataItems.sort((a, b) => (b.LABEL.length - a.LABEL.length));
                }
                else {
                    obj[item.COLUMNID] = item.dataItems;
                }
            });
            this.enumObj = obj;
        },
        // 从监测数据获取数据
        async getFromData() {
            let result = await this.getMonitorDataDetails({
                itemId: this.formdata.itemCode,
                id: this.id,
            });
            if (result[0] && result[0].Data && result[0].Data[0]) {
                const temp = result[0];
                const { Data, metaJson, PIC, ...restData } = temp;
                Object.assign(this.formdata.dataDic, Data[0]);
                this.formdata.metaJson = metaJson;
                this.formdata.PIC = PIC;
                this.restData = restData;
                this.GetDataNext();
                this.contralBtn()
            }
        },
        // 控制按钮显示
        contralBtn() {
            // type: 0 => 新建; 1 => 编辑; 2 => 查看 3=> 审核
            // btnType: 0 查看  1 编辑  2 审核
            let btnType = (this.type === '0' || this.type === '1') ? '1' : this.type === '3' ? '2' : '0';
            this.btnType = btnType;
            this.isEdit = btnType === '1';
            this.isCheck = this.commonMethods.isPower('2') && btnType === '2'; // 有审核权限并且点击审核按钮进入
            this.snButton.buttonData.forEach(item => {
                if (item.name == "保存") {
                    switch (this.formdata.itemCode) {
                        case "90501":
                            if (this.type === '999' &&
                                (this.formdata.dataDic.SHZT == '0' || this.formdata.dataDic.SHZT == '2') ||
                                localStorage.roleId.split(",").includes(window.ROLE_ID.CJGLY) ||
                                localStorage.roleId.split(",").includes(window.ROLE_ID.JCZXRY)
                            ) {
                                this.isEdit = true //输入框可以编辑
                                item.isShow = true //详情页面显示 保存按钮
                            } else {
                                item.isShow = false
                            }
                            break;
                        default:
                            if (this.type === '999' &&
                                (this.formdata.dataDic.SHZT == '0' || this.formdata.dataDic.SHZT == '2') &&
                                localStorage.roleId.split(",").includes(window.ROLE_ID.CJGLY)) {
                                this.isEdit = true //输入框可以编辑
                                item.isShow = true //详情页面显示 保存按钮
                            } else {
                                item.isShow = false
                            }
                            break;
                    }

                } else {
                    item.isShow = item.editType.split(',').indexOf(btnType) > -1;
                }
            });
        },
        handleChange(value) {
            this[value]();
        },
        // 注意特殊字符处理
        // 超级管理员 保存数据 不修改数据状态
        async saveDataAdmin() {
            let basicValidPromise = this.$refs.formdata.validate();
            let metaValidPromise = this.$refs.uploadFile && this.$refs.uploadFile.validate();
            Promise.all([
                basicValidPromise,
                metaValidPromise
            ]).then(([basicValid, metaValid]) => {
                this.saveData();
            }).catch((e) => {
                this.common.showMsg("请检查是否填写完整！", 'warning')
            });
        },
        // 保存暂不提交未做验证
        async saveNosubmit() {
            let basicValidPromise = this.$refs.formdata.validate();
            let metaValidPromise = this.$refs.uploadFile && this.$refs.uploadFile.validate();

            Promise.all([
                basicValidPromise,
                metaValidPromise
            ]).then(([basicValid, metaValid]) => {
                // 1205 一键上报修改数据即为审核通过
                this.formdata.dataDic.SHZT = this.formdata.itemCode == '1205' ? window.SHZT.AUDIT_PASSED : window.SHZT.NOT_SUBMITED;
                this.saveData();
            }).catch((e) => {
                this.common.showMsg("请检查是否填写完整！", 'warning')
            });
        },
        // 保存并提交
        async saveSubmit() {
            let basicValidPromise = this.$refs.formdata.validate();
            let metaValidPromise = this.$refs.uploadFile && this.$refs.uploadFile.validate();
            Promise.all([
                basicValidPromise,
                metaValidPromise
            ]).then(([basicValid, metaValid]) => {
                this.saveForm();
            }).catch(() => {
                this.common.showMsg("请检查是否填写完整！", 'warning')
            });
        },
        // 审核通过
        savePass() {
            this.saveSHZT(2);
        },
        // 增加积分
        async addpoints() {
            let res = await this.SavePointOpe({
                phoneNumber: this.formdata.dataDic.SBRDH,
                lx: 3,
                sjId: this.formdata.dataDic.ID,
                time: this.formdata.dataDic.SBSJ,
            })
        },
        // 审核不通过
        saveNoPass() {
            this.saveSHZT(3);
        },
        saveSHZT(zt) {
            this.$confirm('审核提交后不能更改，你确认要提交吗？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                if (Number(zt) === Number(window.SHZT.AUDIT_NOT_PASSED)) {
                    this.$prompt('请输入未审核通过原因', '提示', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                    }).then(({ value }) => {
                        if (value == null || value.trim().length == 0) {
                            this.common.showMsg("输入无效,请重新审核", 'info')
                        } else {
                            this.updateSjzt(zt, value)
                        }
                    }).catch(() => {
                        this.common.showMsg("已取消", 'info')
                    });
                } else {
                    this.updateSjzt(zt)
                }
            }).catch(() => {
                this.common.showMsg("已取消审核", "info")
            });
        },
        async updateSjzt(zt, reason) {
            let res = await this.updateShztOfMonitorData({
                itemid: this.formdata.itemCode,
                sjid: this.formdata.dataDic.ID,
                shzt: zt,
                shrid: this.userId,
                shsj: this.common.getTimeYmdhms(),
                shbtgsm: reason ? reason : "",
                rwid: this.$route.query.RWID
            });
            if (res.IsSuccess) {
                this.common.showMsg("审核成功", "success")
                this.returnList();
                // 一键上报审核通过时 小程序 增加积分 
                if (this.formdata.itemCode == '1205' && JSON.parse(this.$route.query.val).LRFS == '4') {
                    this.addpoints();
                }
            }
        },
        saveForm() {
            this.$confirm('数据提交后不能更改，你确认要提交吗？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.formdata.dataDic.SHZT = window.SHZT.SUBMITED;
                this.saveData();
            }).catch(() => {
                this.common.showMsg("已取消提交", "info")
            });
        },
        async saveData(back = true) {
            this.formdata.dataDic.RWID = this.$route.query.RWID;
            if (!no_cjr_list.includes(this.formdata.itemCode)) {
                this.dealCJRAndRWID();  // 加载采集人信息
            }
            if (!edit_cjsj.includes(this.formdata.itemCode)) {
                // 采集时间 默认当前时间 不可编辑
                this.formdata.dataDic.CJSJ = this.common.getTimeYmdhms();
            } else if (this.formdata.dataDic.CJSJ.indexOf("00:00:00") == -1) {
                this.formdata.dataDic.CJSJ = this.formdata.dataDic.CJSJ + " 00:00:00"
            }


            this.dealMoney();
            this.dealJcdxid();
            let res = await this.saveMonitorData(this.formdata);
            if (res.IsSuccess) {
                this.common.showMsg("保存成功", "success")
                if (this.type === '0') {
                    // await this.recalculateDatacode({ Datacode: this.formdata.itemID });
                }
                if (back) {
                    this.returnList();
                }
            }
        },
        dealJcdxid() {
            const itemCode = Number(this.formdata.itemCode);
            switch (itemCode) {
                case 101:
                    this.formdata.dataDic.JCDXID = this.formdata.dataDic.CNSXID;
                    break;
                case 704:
                    this.formdata.dataDic.JCDXID = this.formdata.dataDic.BHID;
                    break;
                case 803:
                case 804:
                    this.formdata.dataDic.JCDXID = this.formdata.dataDic.ZRHJJCXMBH;
                    break;
                case 90303:
                    this.formdata.dataDic.JCDXID = this.formdata.dataDic.MC;
                    break;
                case 1103:
                case 1106:
                    this.formdata.dataDic.JCDXID = this.formdata.dataDic.LYJDID;
                    break;
                case 1402:
                    this.formdata.dataDic.JCDXID = this.formdata.dataDic.XMBH;
                    break;
                case 1404:
                    this.formdata.dataDic.JCDXID = this.formdata.dataDic.KGFJJLID;
                    break;
                case 1602:
                    this.formdata.dataDic.JCDXID = this.formdata.dataDic.BHGLGHID;
                    break;
            }
        },
        // 返回列表
        returnList() {
            this.$router.push({
                name: 'monitoringTable',
                params: {
                    ItemID: this.formdata.itemCode,
                    MC: null
                }
            });
        },
        // 对输入框 为数字的约束函数，只对 el-input 起作用
        toNumber(value, prop, length = 8) {
            if ([undefined, null, ""].includes(value)) {
                this.formdata.dataDic[prop] = null;
                return;
            }
            if (value) { // int 无特殊值
                value = value.replace(/[^\d.]/g, ""); // 清除“数字”和“.”以外的字符
                let reg = new RegExp("^\(\\d\{0," + length + "\}\).*$");
                value = value.replace(reg, "$1");
                if (value != '') { // 整数过滤 整数不允许输入多个0
                    this.formdata.dataDic[prop] = parseFloat(value);
                } else {
                    this.formdata.dataDic[prop] = '';
                }
            }
        },
        debounce(fn, delay = 500) {
            let timer = null;
            return () => {
                if (timer) {
                    clearTimeout(timer);
                }
                timer = setTimeout(fn, delay);
            };
        },
        toMoney(value, prop) {
            if (this.timer) {
                clearTimeout(this.timer);
            }
            const fn = () => {
                if ([undefined, null, ""].includes(value)) {
                    this.formdata.dataDic[prop] = null;
                    return;
                }
                let temp = String(value).replace(/,/g, "");
                // let tempInt = parseFloat(temp).toFixed();
                let tempFixed = String(parseFloat(temp));
                if ((tempFixed).includes(".")) {
                    tempFixed = tempFixed.slice(tempFixed.indexOf("."));
                    if (tempFixed.length > 3) {
                        tempFixed = tempFixed.slice(0, 3);
                    } else if (tempFixed.length === 1) {
                        tempFixed = "";
                    }
                } else {
                    tempFixed = "";
                }
                // .toFixed(2).substr(-3);
                temp = parseInt(temp, 10) || null;
                if (temp !== null) {
                    temp = String(temp).replace(/\B(?=(\d{3})+$)/g, ',');
                }
                this.formdata.dataDic[prop] = temp + tempFixed;
            };
            this.timer = setTimeout(fn, 500);
        },
        blurMoney(prop, length = 8) {
            let num = this.formdata.dataDic[prop];
            num = String(num);
            num = num.replace(/。/g, '.'); // 将中文。转换为 英文.
            num = num.replace(/^\./, ''); // 不允许开头输入.
            num = num.replace(/[^\d.]/g, ""); // 清除“数字”和“.”以外的字符
            num = num.replace(/\.{2,}/g, "."); // 只保留第一个. 清除多余的
            num = num.replace(".", "$#$").replace(/\./g, "").replace("$#$", ".");
            let regexp = new RegExp("^(\\-)*(\\d{0," + length + "}).*\\.(\\d{" + 2 + "}).*$");
            num = num.replace(regexp, '$1$2.$3'); // 只能输入两个小数
            if (num.indexOf(".") < 0 && num != '') { // 整数过滤 数字前的0
                let reg = new RegExp("^\(\\d\{0," + length + "\}\).*$");
                num = num.replace(reg, "$1");
                // num = parseFloat(num);
            }
            let list = num.split('.');
            let value = '';
            if (list.length == 2) {
                value = list[0].replace(/\B(?=(\d{3})+$)/g, ',') + '.' + list[1];
            } else {
                value = String(num).replace(/\B(?=(\d{3})+$)/g, ',');
            }
            this.formdata.dataDic[prop] = value;
        },
        toDecimal(num, prop, length = 8, decimalLength = 2) {
            if (num) {
                num = String(num);
                num = num.replace(/。/g, '.'); // 将中文。转换为 英文.
                num = num.replace(/^\./, ''); // 不允许开头输入.
                num = num.replace(/[^\d.]/g, ""); // 清除“数字”和“.”以外的字符
                num = num.replace(/\.{2,}/g, "."); // 只保留第一个. 清除多余的
                num = num.replace(".", "$#$").replace(/\./g, "").replace("$#$", ".");
                let regexp = new RegExp("^(\\-)*(\\d{0," + length + "}).*\\.(\\d{" + decimalLength + "}).*$");
                num = num.replace(regexp, '$1$2.$3'); // 只能输入两个小数
                if (num.indexOf(".") < 0 && num != '') { // 整数过滤 数字前的0
                    let reg = new RegExp("^\(\\d\{0," + length + "\}\).*$");
                    num = num.replace(reg, "$1");
                    num = parseFloat(num);
                }
            }
            this.formdata.dataDic[prop] = num;
        },
        // 限制输入百分数
        limitPercent(num, prop) {
            // this.formdata.dataDic[prop] = num.indexOf('100')!=-1?100:this.toDecimal(num, prop);
            if (num && num.indexOf('100') != -1) {
                this.formdata.dataDic[prop] = 100;
            } else {
                this.toDecimal(num, prop, 2);
            }
        },
        dealCJRAndRWID() {
            if (this.formdata.dataDic.CJR) return;
            this.formdata.dataDic.CJR = localStorage.getItem('userRealName');
            this.formdata.dataDic.CJRID = localStorage.getItem('userId');
            this.formdata.dataDic.RWID = this.$route.query.RWID || "";
        },
        focusMoney(prop) {
            const temp = this.formdata.dataDic[prop];
            if (temp) {
                this.formdata.dataDic[prop] = Number(String(this.formdata.dataDic[prop]).replace(/,/g, "")) || 0;
            }
        },
        dealMoney() {
            this.toMoneyProps && this.toMoneyProps.forEach(prop => {
                const temp = this.formdata.dataDic[prop];
                if (temp) {
                    let value = (String(this.formdata.dataDic[prop]).replace(/,/g, ""));
                    if (value === '0') {
                        value = 0;
                    } else {
                        value = Number(value) || null;
                    }
                    this.formdata.dataDic[prop] = value;
                }
            });
        },
    },

};