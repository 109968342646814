import {
    $GET,
    $POST,
    $AXIOS
} from '@request/http.js';

const state = {
    lyj_sjgl_state: true, // 显示 隐藏 工作台面的 统计
};

const getters = {
    lyj_sjgl_state(state, getters) {
        return state.lyj_sjgl_state;
    },

};

const mutations = {
    SET_LYJ_SJGL: (state, payload) => {
        for (const prop in payload) {
            if (payload[prop] !== undefined) {
                state[prop] = payload[prop];
            }
        }
    },
};

const actions = {
    // 获取日常巡查年份数据信息
    async GetRcxcYearList({ dispatch }, payload) {
        let result = await $GET('LyjTableTopStatistics/GetRcxcYearList', payload);
        return result
    },
    // 获取日常巡查数据信息
    async GetRcxcStatisticInfo({ dispatch }, payload) {
        let result = await $GET('LyjTableTopStatistics/GetRcxcStatisticInfo', payload);
        return result
    },
    // 获取消防制度数据信息
    async GetAxfStatisticInfo({ dispatch }, payload) {
        let result = await $GET('LyjTableTopStatistics/GetAxfStatisticInfo', payload);
        return result
    },
    // 获取林业项目数据信息
    async GetLyjBhgcStatisticInfo({ dispatch }, payload) {
        let result = await $GET('LyjTableTopStatistics/GetLyjBhgcStatisticInfo', payload);
        return result
    },
    // 获取监测数据的年/月
    async GetNyList({ dispatch }, payload) {
        let result = await $GET('LargeShowSthj/GetNyList', payload);
        return result
    },

};
export default {
    state,
    getters,
    mutations,
    actions
};