<template>
    <div
        class="onOffImg"
        :class="[state ? 'imgActive' : 'imgDefault']"
        @click="changeState"
    ></div>
</template>
<script>
export default {
    name: "",
    components: {},
    props: {
        onOffState: {
            type: Boolean,
            default: false,
        },
        item: {
            type: Object,
            default: {},
        },
        index: {
            type: Number,
            default: 0,
        },
    },
    data() {
        return {
            state: false,
        };
    },
    computed: {},
    watch: {
        onOffState: {
            immediate: true,
            handler() {
                this.state = this.onOffState;
            },
            deep: true,
        },
    },
    mounted() {},
    methods: {
        // 状态改变
        changeState() {
            this.state = !this.state;
            this.$emit("switchStateChange", this.state, this.item, this.index);
        },
    },
};
</script>
<style scoped lang="scss">
.onOffImg {
    width: 42px;
    height: 20px;
    cursor: pointer;
}

.imgActive {
    background: url("~@image/bigScreen/icon/on.png") no-repeat;
    background-size: 100% 100%;
}

.imgDefault {
    background: url("~@image/bigScreen/icon/off.png") no-repeat;
    background-size: 100% 100%;
}

@media screen and (max-width: 1500px) {
}
</style>
