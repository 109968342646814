import { $GET, $POST, $AXIOS } from '@request/http.js';
import * as Types from './mutation-type.js';

const state = {
    conditionSearchHeight: 0,
};

const getters = {
    'conditionSearchHeight': ({ conditionSearchHeight }) => conditionSearchHeight
};

const mutations = {
    [Types.SET_STATE_MUTATION]: (state, payload) => {
        for (const prop in payload) {
            if (payload[prop] !== undefined) {
                state[prop] = payload[prop];
            }
        }
    },
};

const actions = {
    setState({ commit }, payload) {
        commit(Types.SET_STATE_MUTATION, payload);
    },
    // 导出监测数据
    async exportMonitorData({ dispatch, commit }, payload) {
        let result = await $GET('MonitorData/ExportMonitorData', payload);
        return result.ResultValue;
    },
    // 获取成图年份列表
    async getCtYearData({ dispatch, commit }, payload) {
        let result = await $GET('MonitorDataMap/GetCtYearData', payload);
        return result.ResultValue;
    },
    // 获取某年的  地图URL 和 文件列表
    async getYcMapData({ dispatch, commit }, payload) {
        let result = await $GET('MonitorDataMap/GetYcMapData', payload);
        return result.ResultValue;
    },
    async getYcysdtLmtmc({ dispatch, commit }, payload) {
        let result = await $GET('MonitorDataMap/GetYcysdtLmtmc', payload);
        return result.ResultValue;
    },
    async getYcysdtCjdmc({ dispatch, commit }, payload) {
        let result = await $GET('MonitorDataMap/GetYcysdtCjdmc', payload);
        return result.ResultValue;
    },
    async getCjd({ dispatch, commit }) {
        const result0 = await dispatch('getYcysdtLmtmc');
        const result1 = await dispatch('getYcysdtCjdmc');
        return [result0, result1];
    },
    async getYcsygnMap({ dispatch, commit }, payload) {
        let result = await $GET('MonitorDataMap/GetYcsygnMap', payload);
        return result.ResultValue;
    },
    async getMonitorDataList({ dispatch, commit }, payload) {
        let result = await $GET('MonitorData/GetMonitorDataList', payload);
        return result;
    },
    async getMonitorDataDetails({ dispatch, commit }, payload) {
        let result = await $GET('MonitorData/GetMonitorDataDetails', payload);
        return result.ResultValue;
    },
    async saveMonitorData({ dispatch, commit }, payload) {
        let result = await $POST('MonitorData/SaveMonitorDataEx', payload);
        return result;
    },
    // 获得 枚举值 服务
    async getAllEnumInfobytableID({ dispatch, commit }, payload) {
        let result = await $GET('MonitorData/GetAllEnumInfobytableID', payload);
        return result.ResultValue;
    },
    async deleteMonitorData({ dispatch, commit }, payload) {
        let result = await $GET('MonitorData/DeleteMonitorData', payload);
        return result;
    },
    // 获得 规划项目类别 列表 （ 现行规划执行情况记录 ）
    async GetGhxmlbListTree({ dispatch, commit }, payload) {
        let result = await $GET('MonitorData/GetGhxmlbListTree', payload);
        return result;
    },
    // 更新 监测数据 审核状态
    async updateShztOfMonitorData({ dispatch, commit }, payload) {
        let result = await $GET('MonitorData/UpdateSHZT', payload);
        return result;
    },
    // 获得 遗产要素 列表
    async GetYcysList({ dispatch, commit }, payload) {
        let result = await $GET('MonitorConfigure/GetYcysList', payload);
        return result;
    },

    // 删除 上传附件
    async DeleteMonitorDataAppendix({ dispatch, commit }, payload) {
        let result = await $GET('MonitorData/DeleteMonitorDataAppendix', payload);
        return result;
    },

    // 获得 关联项 列表。例：考古记录 表单 需获取 考古项目列表
    async GetGLXList({ dispatch, commit }, payload) {
        let result = await $GET('MonitorData/GetGLXList', payload);
        return result;
    },
    // 获得 巡查员 列表
    async GetPatrolList({ dispatch, commit }, payload) {
        let result = await $GET('OperationandMaintenanceManagement/GetUserByFunID', payload);
        return result;
    },
    // 获得 自然环境监测对象 列表
    async GetZRHJJCDXListTree({ dispatch, commit }, payload) {
        let result = await $GET('MonitorData/GetZRHJJCDXListTree', payload);
        return result;
    },
    // 获得 灾害类型 列表
    async GetZHLXListTree({ dispatch, commit }, payload) {
        let result = await $GET('MonitorData/GetZHLXListTree', payload);
        return result;
    },
    // 获得 病害 评估列表
    async GetBHPGListTree({ dispatch, commit }, payload) {
        let result = await $GET('MonitorData/GetBHPGListTree', payload);
        return result;
    },
    // 获得遥感 图标json 数据
    async GetSensingJson({ dispatch, commit }, payload) {
        let result = await $GET('SensingService/GetSensingJson', payload);
        return result;
    },
    // 获得图标列表
    async GetSensingList({ dispatch, commit }, payload) {
        let result = await $GET('SensingService/GetSensingList', payload);
        return result;
    },
    async GetSensingList({ dispatch, commit }, payload) {
        let result = await $GET('SensingService/GetSensingList', payload);
        return result;
    },
    async GetSensing({ dispatch, commit }, payload) {
        let result = await $GET('SensingService/GetSensing', payload);
        return result;
    },
    async SensingAdd({ dispatch, commit }, payload) {
        let result = await $POST('SensingService/SensingAdd', payload);
        return result;
    },
    async UpdateSensing({ dispatch, commit }, payload) {
        let result = await $POST('SensingService/UpdateSensing', payload);
        return result;
    },
    async SensingDelete({ dispatch, commit }, payload) {
        let result = await $GET('SensingService/SensingDelete', payload);
        return result;
    },
    async BatchUpdateSensing({ dispatch, commit }, payload) {
        let result = await $POST('SensingService/BatchUpdateSensing', payload);
        return result;
    },
    async SensingDeleteFile({ dispatch, commit }, payload) {
        let result = await $GET('SensingService/SensingDeleteFile', payload);
        return result;
    },

    // 遥感监测获取对比年份列表
    async GetYear({ dispatch, commit }, payload) {
        let result = await $GET('SensingService/GetYear', payload);
        return result.ResultValue;
    },
    // 遥感监测获取地图列表
    async GetDtList({ dispatch, commit }, payload) {
        let result = await $GET('SensingService/GetDtList', payload);
        return result.ResultValue;
    },
    // 一键上报转为异常
    async WdfxToYcjl({ dispatch, commit }, payload) {
        let result = await $GET('MonitorData/WdfxToYcjl', payload);
        return result;
    },
    // 一键上报 异常转预警
    async YcjlToYj({ dispatch, commit }, payload) {
        let result = await $GET('MonitorData/YcjlToYj', payload);
        return result;
    },
    // 监测数据添加功能
    async GetMobileCjsj({ dispatch, commit }, payload) {
        let result = await $GET('MonitorData/GetMobileCjsj', payload);
        return result.ResultValue;
    },
    async GetJcdList({ dispatch, commit }, payload) {
        let result = await $GET('MonitorData/GetJcdList', payload);
        return result.ResultValue;
    },
    // 修改【新增、删除】数据后局部更新数据信息服务
    async recalculateDatacode(a, payload) {
        let result = await $GET('DataCount/RecalculateDatacode', payload);
        return result;
    },
    // 一键上报 审核通过 添加积分
    async SavePointOpe({ dispatch, commit }, payload) {
        let result = await $GET('MyPoint/SavePointOpe', payload);
        return result;
    },
    // 获得宣传列表
    async GetXcjyList({ dispatch, commit }, payload) {
        let result = await $GET('MonitorData/GetXcjyList', payload);
        return result;
    },
    // 获得舆情处置信息
    async GetYqDetailData({ dispatch, commit }, payload) {
        let result = await $GET('MonitorData/GetPublicSentimentDetails', payload);
        return result;
    },
    // 保存舆情处置信息
    async SavePublicSentimentDetails({ dispatch, commit }, payload) {
        let result = await $POST('MonitorData/SavePublicSentimentDetails', payload);
        return result;
    },
    // 删除舆情处置信息
    async DeletePublicSentimentInfo({ dispatch, commit }, payload) {
        let result = await $GET('MonitorData/DeletePublicSentimentInfo', payload);
        return result;
    },
    // 获取遗产要素单体列表
    async getYcysdtData({ dispatch, commit }, payload) {
        let result = await $GET('MonitorData/GetYcysdtList', payload);
        return result;
    },

    // 获得病害监测数据 列表服务
    async GetBhjcsjList({ dispatch, commit }, payload) {
        let result = await $GET('MonitorData/GetBhjcsjList', payload);
        if (result.ISSUCCESS) {
            let data = {
                ResultValue: {
                    gridData: result.RESULTVALUE && result.RESULTVALUE.BHJCSJLIST,
                    total: result.RESULTVALUE && result.RESULTVALUE.DATACOUNT,
                    bhTotal: result.RESULTVALUE && result.RESULTVALUE.BHCOUNT,
                }
            }
            return data;
        }
        return false;
    },
    // 获取病害监测数据历史数据列表
    async GetBhjcsjHistoryData({ dispatch, commit }, payload) {
        let result = await $POST('MonitorData/GetBhjcsjHistoryData', payload);
        return result;
    },
    // 导出病害监测数据历史数据列表
    async ExportBhjcsjdData({ dispatch, commit }, payload) {
        let result = await $GET('MonitorData/ExportBhjcsjdData', payload);
        return result;
    },
    // 获取病害监测数据历史日均数据
    async GetMonitorDataAvgByDaily({ dispatch, commit }, payload) {
        let result = await $GET('MonitorData/GetMonitorDataAvgByDaily', payload);
        return result;
    },
    // 日常管理 日常巡查异常记录
    async GetYcjlTaskTimeList({ dispatch, commit }, payload) {
        let result = await $GET('MonitorConfigure/GetYcjlTaskTimeList', payload);
        return result;
    },
    // 导入附件 温度月度记录
    async ImportNatureAvgData({ dispatch, commit }, payload) {
        let result = await $POST('MonitorData/ImportNatureAvgData', payload);
        return result;
    },
    // 茶叶加工技艺监测数据  茶叶加工进程 枚举数据
    async GetCyjgjyJcData({ dispatch, commit }, payload) {
        let result = await $GET('MonitorData/GetCyjgjyJcData', payload);
        return result;
    },
    // 项目调研列表
    async XjxmGetXmdyList({ dispatch, commit }, payload) {
        let result = await $GET('MonitorData/XjxmGetXmdyList', payload);
        return result;
    },
};
export default {
    state,
    getters,
    mutations,
    actions
};