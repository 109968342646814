export default {
    path: '/expert_consultation',
    name: 'expert_consultation',
    redirect: { name: 'consulting_expert' },
    meta:{
        auth: "800"
    },
    component: () =>
        import ("@views/expert_consultation"),
    children: [{
        path: '/consulting_expert',
        name: 'consulting_expert',
        redirect: { name: 'consulting_expert_grid' },
        meta:{
            auth: "8001"
        },
        component: () =>
            import ("@views/expert_consultation/consulting_expert"),
        children: [{
            path: '/consulting_expert_grid',
            name: 'consulting_expert_grid',
            component: () =>
                import ("@views/expert_consultation/consulting_expert/grid")
        }, {
            path: '/consulting_expert_form',
            name: 'consulting_expert_form',
            component: () =>
                import ("@views/expert_consultation/consulting_expert/form")
        }]
    }, {
        path: '/expert_analysis',
        name: 'expert_analysis',
        meta:{
            auth: "8002"
        },
        // redirect: { name: 'expert_analysis_grid' },
        component: () =>
            import ("@views/expert_consultation/expert_analysis"),
        // children: [{
        //     path: '/expert_analysis_grid',
        //     name: 'expert_analysis_grid',
        //     component: () =>
        //         import ("@views/expert_consultation/expert_analysis/grid")
        // }, {
        //     path: '/expert_analysis_form',
        //     name: 'expert_analysis_form',
        //     component: () =>
        //         import ("@views/expert_consultation/expert_analysis/form")
        // }]
    }, {
        path: '/expert_information',
        name: 'expert_information',
        meta:{
            auth: "8003"
        },
        redirect: { name: 'expert_information_grid' },
        component: () =>
            import ("@views/expert_consultation/expert_information"),
        children: [{
            path: '/expert_information_grid',
            name: 'expert_information_grid',
            component: () =>
                import ("@views/expert_consultation/expert_information/grid")
        }, {
            path: '/expert_information_form',
            name: 'expert_information_form',
            component: () =>
                import ("@views/expert_consultation/expert_information/form")
        }]
    }]
}