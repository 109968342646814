<template>
    <div id="app" :class="[system]">
        <router-view />
    </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
export default {
    name: "",
    components: {},
    data() {
        return {
            timer: null,
            sysTimer: null,
            bigBg: require("@image/bigBg.jpg"),
        };
    },
    computed: {
        ...mapGetters(["system", "refreshTimer"]),
    },
    watch: {
        refreshTimer() {
            this.checkToken(); // 登录token
            this.checkSysToken(); // 子系统token
        },
        $route: {
            immediate: true,
            handler() {
                this.$nextTick(() => {
                    this.initFormatScreen();
                });
            },
        },
    },
    created() {
        let routerName = location.hash.slice(1);
        if (
            routerName == "/login" ||
            routerName == "/login2" ||
            routerName == "/login3" ||
            routerName == "/"
        ) {
            localStorage.clear();
        }
        if (localStorage.getItem("store")) {
            this.$store.replaceState({
                ...this.$store.state,
                ...JSON.parse(localStorage.getItem("store")),
            });
        }

        if (localStorage.getItem("routeInfo")) {
            let params = JSON.parse(localStorage.getItem("routeInfo"));
            let routeName = this.$route.name;
            if (routeName) {
                this.$router.push({ name: this.$route.name, params: params });
            }
        }
        // 在页面刷新时将vuex里的信息保存到localStorage里
        // 在页面刷新时将路由params里的信息保存到localStorage里
        window.addEventListener("beforeunload", () => {
            localStorage.setItem("store", JSON.stringify(this.$store.state));
            localStorage.setItem(
                "routeInfo",
                JSON.stringify(this.$route.params)
            );
        });
    },
    mounted() {
        let hostname = location.hostname;
        let routerName = location.hash.slice(1);
        let systemType = "";
        let title = "";
        let loginPage = true;
        switch (routerName) {
            case "/":
            case "/login":
                systemType = "system1";
                title = "普洱景迈山古茶林遗产监测预警平台";
                break;
            case "/login2":
                systemType = "system2";
                title = "普洱景迈山古茶林文化景观遗产之林业监测";
                break;
            case "/login3":
                systemType = "system3";
                title = "普洱景迈山古茶林文化景观遗产之茶特中心";
                break;
            default:
                loginPage = false;
        }
        if (loginPage) {
            document.cookie = `system=${systemType}`;
            document.cookie = `title=${title}`;
        } else {
            systemType = this.getCookie("system");
            title = this.getCookie("title");
        }
        this.setCommonState({
            system: systemType,
        });
        localStorage.setItem("system", systemType);
        document.title = title;
        this.checkToken(); // 登录token
        this.checkSysToken(); // 子系统token
        window.onresize = () => {
            this.initFormatScreen();
        };
    },
    methods: {
        ...mapMutations({
            setCommonState: "SET_COMMON_MUTATION",
        }),
        ...mapMutations([
            "SET_ACCESSTOKEN",
            "SET_SYS_ACCESSTOKEN",
            "SET_SYS_REFRESHTOKEN",
        ]),
        ...mapActions(["refreshToken", "refreshSysToken"]),
        initFormatScreen() {
            let clientWidth = document.body.clientWidth;
            let dom = document.getElementById(`app`);
            document.body.style.backgroundColor = null;
            document.body.style.background = null;
            // 适配移动端
            if (
                this.$route.path != "/get_mobile_video" &&
                this.$route.path != "/"
            ) {
                document.body.style.minWidth = "1360px";
            }

            if (this.$route.path == "/big_screen") {
                dom.style.width = "100%";
                document.body.style.backgroundColor = "#040e1e";
            } else if (this.$route.path != "/") {
                switch (clientWidth) {
                    case 5760:
                        dom.style.width = "1920px";
                        document.body.background = this.bigBg;
                        break;
                    default:
                        break;
                }
            }
        },
        getCookie(name) {
            var arr,
                reg = new RegExp("(^| )" + name + "=([^;]*)(;|$)");
            if ((arr = document.cookie.match(reg))) return unescape(arr[2]);
            else return null;
        },
        checkToken() {
            if (this.timer) {
                window.clearInterval(this.timer);
            }
            // let validity = 30000;
            let validity = localStorage.validity;
            if (validity < 300000) {
                validity = 300000;
                localStorage.validity = 300000;
            }
            if (!isNaN(validity)) {
                this.timer = setInterval(() => {
                    let token = localStorage.accessToken;
                    token && this.refreshAccessToken();
                }, validity / 2);
            }
        },
        checkSysToken() {
            if (this.sysTimer) {
                window.clearInterval(this.sysTimer);
            }
            // let sysValidity = 40000;
            let sysValidity = localStorage.sysValidity;
            if (sysValidity < 300000) {
                sysValidity = 300000;
                localStorage.sysValidity = 300000;
            }
            if (!isNaN(sysValidity)) {
                this.sysTimer = setInterval(() => {
                    let token = localStorage.sysAccessToken;
                    token && this.refreshSysAccessToken();
                }, sysValidity / 2);
            }
        },
        // 刷新token
        async refreshAccessToken() {
            let res = await this.refreshToken({
                refreshToken: localStorage.accessToken,
            });
            if (res.IsSuccess) {
                this.SET_ACCESSTOKEN(res.ResultValue.TokenContent);
            }
        },
        // 刷新子系统token
        async refreshSysAccessToken() {
            let res = await this.refreshSysToken({
                sign: "1",
                refreshToken: localStorage.sysRefreshToken,
            });
            if (res.ISSUCCESS) {
                this.SET_SYS_ACCESSTOKEN(res.RESULTVALUE.access_token);
                this.SET_SYS_REFRESHTOKEN(res.RESULTVALUE.refresh_token);
            }
        },
    },
};
</script>

<style>
html,
body {
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
}
#app {
    width: 100%;
    height: 100%;
    margin: 0 auto;
}
</style>
