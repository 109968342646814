export default {
    path: "/basicdata",
    name: "basicdata",
    redirect: {
        name: "basicTable"
    },
    meta: {
        title: "基础数据采集",
    },
    itemId: 1,
    component: () =>
        import("@views/working_desk/basic_data/index.vue"),
    children: [{
        path: "/basicTable/:ItemID/:MC",
        name: "basicTable",
        meta: {
            title: "列表页面",
        },
        component: () =>
            import("@views/working_desk/basic_data/basic_table.vue")
    },
    // 遗产基础信息 start
    {
        path: "/heritagePaper",
        name: "heritagePaper",
        itemId: 1,
        meta: {
            title: "申遗文本编辑页面",
        },
        component: () =>
            import("@views/working_desk/basic_data/table_type/1_heritage_basic_info/heritage_paper.vue")
    },
    {
        path: "/assemblyResolutions",
        name: "assemblyResolutions",
        itemId: 2,
        meta: {
            title: "大会决议编辑页面",
        },
        component: () =>
            import("@views/working_desk/basic_data/table_type/1_heritage_basic_info/assembly_resolutions.vue")
    },
    {
        path: "/dedicationCommitment",
        name: "dedicationCommitment",
        itemId: 33,
        meta: {
            title: "申遗承诺编辑页面",
        },
        component: () =>
            import("@views/working_desk/basic_data/table_type/1_heritage_basic_info/dedication_commitment.vue")
    },
    {
        path: "/souv",
        name: "souv",
        itemId: 3,
        meta: {
            title: "突出普遍价值（SOUV）声明材料编辑页面",
        },
        component: () =>
            import("@views/working_desk/basic_data/table_type/1_heritage_basic_info/souv.vue")
    },
    {
        path: "/periodicReport",
        name: "periodicReport",
        itemId: 4,
        meta: {
            title: "定期报告编辑页面",
        },
        component: () =>
            import("@views/working_desk/basic_data/table_type/1_heritage_basic_info/periodic_report.vue")
    },
    {
        path: "/heritageGeneralDrawing",
        name: "heritageGeneralDrawing",
        itemId: 5,
        meta: {
            title: "遗产总图编辑页面",
        },
        component: () =>
            import("@views/working_desk/basic_data/table_type/1_heritage_basic_info/heritage_general_drawing.vue")
    },
    {
        path: "/heDistributionDiagram",
        name: "heDistributionDiagram",
        itemId: 6,
        meta: {
            title: "遗产要素分布图编辑页面",
        },
        component: () =>
            import("@views/working_desk/basic_data/table_type/1_heritage_basic_info/he_distribution_diagram.vue")
    },
    {
        path: "/hemInfo",
        name: "hemInfo",
        itemId: 7,
        meta: {
            title: "遗产要素清单编辑页面",
        },
        component: () =>
            import("@views/working_desk/basic_data/table_type/1_heritage_basic_info/hem_info.vue")
    },
    {
        path: "/protectionUnits",
        name: "protectionUnits",
        itemId: 8,
        meta: {
            title: "遗产地和各级文保单位的对应关系编辑页面",
        },
        component: () =>
            import("@views/working_desk/basic_data/table_type/1_heritage_basic_info/protection_units.vue")
    },
    {
        path: "/fourFiles",
        name: "fourFiles",
        itemId: 9,
        meta: {
            title: "四有档案编辑页面",
        },
        component: () =>
            import("@views/working_desk/basic_data/table_type/1_heritage_basic_info/four_files.vue")
    },
    {
        path: "/heritageUseFunction",
        name: "heritageUseFunction",
        itemId: 10,
        meta: {
            title: "遗产使用功能图编辑页面",
        },
        component: () =>
            import("@views/working_desk/basic_data/table_type/1_heritage_basic_info/heritage_use_function.vue")
    },
    {
        path: "/diseaseDistributionDiagram",
        name: "diseaseDistributionDiagram",
        itemId: 11,
        meta: {
            title: "病害分布图编辑页面",
        },
        component: () =>
            import("@views/working_desk/basic_data/table_type/1_heritage_basic_info/disease_distribution_diagram.vue")
    },
    {
        path: "/diseaseInvestigationRecord",
        name: "diseaseInvestigationRecord",
        itemId: 12,
        meta: {
            title: "病害调查记录编辑页面",
        },
        component: () =>
            import("@views/working_desk/basic_data/table_type/1_heritage_basic_info/disease_investigation_record.vue")
    },
    {
        path: "/hemPartReferenceDrawing",
        name: "hemPartReferenceDrawing",
        itemId: 13,
        meta: {
            title: "遗产要素单体或局部的测绘图编辑页面",
        },
        component: () =>
            import("@views/working_desk/basic_data/table_type/1_heritage_basic_info/hem_part_reference_drawing.vue")
    },
    {
        path: "/hemPartImage",
        name: "hemPartImage",
        itemId: 14,
        meta: {
            title: "遗产要素单体或局部照片编辑页面",
        },
        component: () =>
            import("@views/working_desk/basic_data/table_type/1_heritage_basic_info/hem_part_image.vue")
    },
    {
        path: "/satelliteImage",
        name: "satelliteImage",
        itemId: 15,
        meta: {
            title: "卫星影像或航摄影像编辑页面",
        },
        component: () =>
            import("@views/working_desk/basic_data/table_type/1_heritage_basic_info/satellite_image.vue")
    },
    {
        path: "/otherPhotos",
        name: "otherPhotos",
        itemId: 16,
        meta: {
            title: "其他照片编辑页面",
        },
        component: () =>
            import("@views/working_desk/basic_data/table_type/1_heritage_basic_info/other_photos.vue")
    },
    // 遗产基础信息 end
    // 保护管理 start
    {
        path: "/proManagerInstitution",
        name: "proManagerInstitution",
        itemId: 17,
        meta: {
            title: "遗产地管理责任机构信息编辑页面",
        },
        component: () =>
            import("@views/working_desk/basic_data/table_type/2_protect_manage/pro_manager_institution.vue")
    },
    {
        path: "/monitorOrg",
        name: "monitorOrg",
        itemId: 18,
        meta: {
            title: "监测机构编辑页面",
        },
        component: () =>
            import("@views/working_desk/basic_data/table_type/2_protect_manage/monitor_org.vue")
    },
    {
        path: "/protectionManagementPlan",
        name: "protectionManagementPlan",
        itemId: 19,
        meta: {
            title: "保护管理规划编辑页面",
        },
        component: () =>
            import("@views/working_desk/basic_data/table_type/2_protect_manage/protection_management_plan.vue")
    },
    {
        path: "/protectionZoneMap",
        name: "protectionZoneMap",
        itemId: 20,
        meta: {
            title: "保护区划图编辑页面",
        },
        component: () =>
            import("@views/working_desk/basic_data/table_type/2_protect_manage/protection_zone_map.vue")
    },
    {
        path: "/protectionZoneDes",
        name: "protectionZoneDes",
        itemId: 21,
        meta: {
            title: "保护区划描述",
        },
        component: () =>
            import("@views/working_desk/basic_data/table_type/2_protect_manage/protection_zone_description.vue")
    },
    {
        path: "/specialRegulations",
        name: "specialRegulations",
        itemId: 22,
        meta: {
            title: "专项保护管理法规、规章编辑页面",
        },
        component: () =>
            import("@views/working_desk/basic_data/table_type/2_protect_manage/special_regulations.vue")
    },
    {
        path: "/monitorWorkStatus",
        name: "monitorWorkStatus",
        itemId: 23,
        meta: {
            title: "监测工作情况编辑页面",
        },
        component: () =>
            import("@views/working_desk/basic_data/table_type/2_protect_manage/monitor_work_status.vue")
    },
    {
        path: "/engineeringRecords",
        name: "engineeringRecords",
        itemId: 24,
        meta: {
            title: "保护展示与环境整治工程记录编辑页面",
        },
        component: () =>
            import("@views/working_desk/basic_data/table_type/2_protect_manage/engineering_record.vue")
    },
    {
        path: "/afxfInfo",
        name: "afxfInfo",
        itemId: 25,
        meta: {
            title: "现有安消防系统硬件设施信息编辑页面",
        },
        component: () =>
            import("@views/working_desk/basic_data/table_type/2_protect_manage/afxf_info.vue")
    },
    {
        path: "/otherRelativePlanning",
        name: "otherRelativePlanning",
        itemId: 26,
        meta: {
            title: "其他相关规划编辑页面",
        },
        component: () =>
            import("@views/working_desk/basic_data/table_type/2_protect_manage/other_relative_planning.vue")
    },
    // 保护管理 end
    // 文献部分 start
    {
        path: "/annals",
        name: "annals",
        itemId: 27,
        meta: {
            title: "志书、史书编辑页面",
        },
        component: () =>
            import("@views/working_desk/basic_data/table_type/3_document/annals.vue")
    },
    {
        path: "/territory",
        name: "territory",
        itemId: 28,
        meta: {
            title: "舆图编辑页面",
        },
        component: () =>
            import("@views/working_desk/basic_data/table_type/3_document/territory.vue")
    },
    {
        path: "/treatise",
        name: "treatise",
        itemId: 29,
        meta: {
            title: "专著编辑页面",
        },
        component: () =>
            import("@views/working_desk/basic_data/table_type/3_document/treatise.vue")
    },
    {
        path: "/specialReportInfo",
        name: "specialReportInfo",
        itemId: 30,
        meta: {
            title: "专项报告信息编辑页面",
        },
        component: () =>
            import("@views/working_desk/basic_data/table_type/3_document/special_report_info.vue")
    },
    {
        path: "/other",
        name: "other",
        itemId: 31,
        meta: {
            title: "其他编辑页面",
        },
        component: () =>
            import("@views/working_desk/basic_data/table_type/3_document/other.vue")
    },
    {
        path: "/xsyjBasic",
        name: "xsyjBasic",
        itemId: 34,
        meta: {
            title: "学术研究",
        },
        component: () =>
            import("@views/working_desk/basic_data/table_type/3_document/xsyj.vue")
    }
    ]
};