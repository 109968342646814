L.TileLayer.WMTS = L.TileLayer.extend({
    defaultWmtsParams: {
        service: 'WMTS',
        request: 'GetTile',
        version: '1.0.0',
        layer: '',
        style: '',
        tilematrixSet: '',
        format: 'image/png'
    },
    options: {
        crs: null,
        uppercase: false,
        noWrap: true,
    },

    initialize: function (url, options) { // (String, Object) 
        this._url = url;
        var wmtsParams = L.extend({}, this.defaultWmtsParams);
        var tileSize = options.tileSize || this.options.tileSize;
        if (options.detectRetina && L.Browser.retina) {
            wmtsParams.width = wmtsParams.height = tileSize * 2;
        } else {
            wmtsParams.width = wmtsParams.height = tileSize;
        }
        for (var i in options) {
            // all keys that are not TileLayer options go to WMTS params
            if (!this.options.hasOwnProperty(i) && i!="matrixIds") {
                wmtsParams[i] = options[i];
            }
        }
        this.wmtsParams = wmtsParams;
        this.matrixIds = options.matrixIds||this.getDefaultMatrix(options);
        L.setOptions(this, options);
    },

    onAdd: function (map) {
        this._crs = this.options.crs || map.options.crs;
        this.wmtsParams.crsCode = this._crs.code;
        L.TileLayer.prototype.onAdd.call(this, map);
    },

    getTileUrl: function (coords) { // (Point, Number) -> String
        if(this.wmtsParams.crsCode.indexOf('3857')!=-1){
            var tileSize = this.options.tileSize;
            var nwPoint = coords.multiplyBy(tileSize);
            nwPoint.x+=1;
            nwPoint.y-=1;
            var sePoint = nwPoint.add(new L.Point(tileSize, tileSize));
            var zoom = this._tileZoom;
            var X0 = -20037508.3428;
            var Y0 = 20037508.3428;
            var nw = this._crs.project(this._map.unproject(nwPoint, zoom));
            var se = this._crs.project(this._map.unproject(sePoint, zoom));
        }else{            
            var tileBounds = this._tileCoordsToBounds(coords),
                nw = this._crs.project(tileBounds.getNorthWest()),
                se = this._crs.project(tileBounds.getSouthEast());
            var zoom = coords.z + 1;
            var X0 = this.matrixIds[zoom].topLeftCorner.lng;
            var Y0 = this.matrixIds[zoom].topLeftCorner.lat;
        }
        var tilewidth = se.x - nw.x;
        var ident = this.matrixIds[zoom]?this.matrixIds[zoom].identifier:"";
        var tilecol = Math.floor((nw.x - X0) / tilewidth);
        var tilerow = -Math.floor((nw.y - Y0) / tilewidth);
        var url = this.wmtsParams.crsCode.indexOf('3857')!=-1?L.Util.template(this._url, {s: this._getSubdomain(coords)}):L.TileLayer.prototype.getTileUrl.call(this, coords);
        return url + L.Util.getParamString(this.wmtsParams, url, this.options.uppercase) + "&tilematrix=" + ident + "&tilerow=" + tilerow + "&tilecol=" + tilecol;
    },

    setParams: function (params, noRedraw) {
        L.extend(this.wmtsParams, params);
        if (!noRedraw) {
            this.redraw();
        }
        return this;
    },
    
    getDefaultMatrix : function (options) {
        let crs = options.crs?options.crs.code:'EPSG:4326';
        var matrixIds = new Array(22);
        for (var i= 0; i<22; i++) {
            matrixIds[i]= {
                identifier    : "" + i,
                topLeftCorner : crs.indexOf('3857')!=-1?new L.LatLng(20037508.3428,-20037508.3428):new L.LatLng(90,-180)
            };
        }
        return matrixIds;
    }
});

L.tileLayer.wmts = function (url, options) {
    return new L.TileLayer.WMTS(url, options);
};