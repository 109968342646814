// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@image/working_desk/monitor_data/sh_not_pass_detail_active.png");
var ___CSS_LOADER_URL_IMPORT_1___ = require("@image/working_desk/monitor_data/sh_not_pass_detail.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
exports.push([module.id, ".shzt-not-pass-detail[data-v-0b32b966]{border:1px solid;border-radius:4px;padding:0 4px}.shzt-not-pass-detail:hover+span[data-v-0b32b966]{background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat 50%/contain}.shzt-not-pass-detail+span[data-v-0b32b966]{background:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") no-repeat 50%/contain}.shzt-not-pass-icon[data-v-0b32b966]{display:inline-block;margin-left:5px;width:15px;height:15px;color:#fff}.text-file[data-v-0b32b966]{color:#06f;text-decoration:underline}", ""]);
// Exports
module.exports = exports;
