import { $GET, $POST, $POST1 } from '@request/http.js';
import { Message } from 'element-ui';
import * as Types from './mutation-type.js';

const state = {
    sbqdList: null,
};

const getters = {

};

const mutations = {
    [Types.SET_STATE_MUTATION]: (state, payload) => {
        for (const prop in payload) {
            if (payload[prop] !== undefined) {
                state[prop] = payload[prop];
            }
        }
    },
};

const actions = {
    setState_monitorCloud({ commit }, payload) {
        commit(Types.SET_STATE_MUTATION, payload);
    },
    async getState_monitorCloud({ dispatch, state }, payload) {
        let res = state[payload.state];
        if (!res || (Array.isArray(res) && res.length === 0) || (typeof res === 'object' && Object.keys(res).length === 0)) {
            res = await dispatch('get_' + payload.state, payload.params);
            dispatch('setState_monitorCloud', {
                [payload.state]: res
            });
            return res;
        } else {
            return res;
        }
    },
    async getStateChild_monitorCloud({ dispatch, state }, payload = {}) {
        const key = payload.child || 'default';
        let res = state[payload.state][key];
        if (!res || (Array.isArray(res) && res.length === 0) || (typeof res === 'object' && Object.keys(res).length === 0)) {
            res = await dispatch('get_' + payload.state, payload.params);
            dispatch('setState', {
                [payload.state]: { ...state[payload.state], [key]: res }
            });
            return res;
        } else {
            return res;
        }
    },
    // 获取设备清单列表_内部调用
    async get_sbqdList({ dispatch, commit }, payload) {
        let result = await $GET('MonitorConfigure/GetSbqdList', payload);
        return result.ResultValue;
    },
    // 获取设备清单列表
    async getSbqdList({ dispatch, commit }, payload) {
        let result = await $GET('MonitorConfigure/GetSbqdList', payload);
        return result.ResultValue;
    },
    // 获取遗产要素列表
    async getYcysdtList({ dispatch, commit }, payload) {
        let result = await $GET('MonitorConfigure/GetYcysList', payload);
        return result.ResultValue;
    },
    // 获取监测项
    async GetJCXMC({ dispatch, commit }, payload) {
        let result = await $GET('MonitorConfigure/GetJCXMC', payload);
        return result.resultvalue;
    },
    // old
    // 获取采集人列表
    async getCjrList({ dispatch, commit }, payload) {
        let result = await $GET('OperationandMaintenanceManagement/GetUserByFunID', payload);
        return result.ResultValue;
    },
    // 获取采集人列表  树结构
    async GetDepartmentUserTree({ dispatch, commit }, payload) {
        let result = await $GET('OperationandMaintenanceManagement/GetDepartmentUserTree', payload);
        return result.ResultValue;
    },
    // 获得遗产要素列表（带统计数字）ItemCode: 空 => 不返回统计数字 10 => 病害 14 => 单体
    async GetBhOrDtYcysList({ dispatch, commit }, payload) {
        let result = await $GET('MonitorConfigure/GetBhOrDtYcysList', payload);
        return result;
    },
    // 1、获取遗产要素单体局部图片
    // 2、保护工程 - 获取工程基本信息
    async getJbtuData({ dispatch, commit }, payload) {
        let result = await $GET('MonitorConfigure/GetMCSYTList', payload);
        return result.ResultValue;
    },
    // 获取日期字段枚举
    async getDateMenu({ dispatch, commit }, payload) {
        let result = await $GET('MonitorConfigure/GetJBTPDateList', payload);
        return result.ResultValue;
    },
    // 任务管理列表--获取列表
    async getRwglList({ dispatch, commit }, payload) {
        let result = await $GET('MonitorConfigure/GetRwglList', payload);
        return result.resultvalue;
    },
    // 任务管理列表--修改任务状态
    async updateJcpzZt({ dispatch, commit }, payload) {
        let result = await $GET('MonitorConfigure/UpdateJcpzZt', payload);
        return result;
    },
    // 任务管理列表--更换采集人
    async updateCjr({ dispatch, commit }, payload) {
        let result = await $GET('MonitorConfigure/UpdateJcpzCjr', payload);
        return result;
    },
    // 任务管理列表--删除监测配置
    async deleteJcpz({ dispatch, commit }, payload) {
        let result = await $GET('MonitorConfigure/DeleteJcpz', payload);
        return result;
    },
    // 任务执行列表--获取任务时间线
    async getTaskTimeLine({ dispatch, commit }, payload) {
        let result = await $GET('MonitorConfigure/GetTaskTimeList', payload);
        return result.ResultValue;
    },
    // 任务执行列表--获取任务执行的详情
    async getTaskDetails({ dispatch, commit }, payload) {
        let result = await $GET('MonitorConfigure/GetTaskDetails', payload);
        return result.ResultValue;
    },
    // 病害-12，日常巡查-1201，单体-14 景观视廊-90301查询详情
    async getCorridorForm({ dispatch, commit }, payload) {
        let result = await $GET('MonitorConfigure/GetconfigureDetails', payload);
        return result.ResultValue;
    },
    // 遗产要素、本体病害、景观视廊通用保存服务
    async commonSaveform({ dispatch, commit }, payload) {
        let result = await $POST('MonitorConfigure/SaveConfigure', payload);
        return result;
    },
    // 日常巡查片区保存服务
    async areaSaveform({ dispatch, commit }, payload) {
        let result = await $POST('MonitorConfigure/SaveRcxcQy', payload);
        return result;
    },
    // 获取遗产要素任务列表
    async getCorridorTask({ dispatch, commit }, payload) {
        let result = await $GET('MonitorConfigure/GetYcysdtPzids', payload);
        return result.resultvalue;
    },
    // 删除监测配置
    async DeleteJcpz({ dispatch, commit }, payload) {
        let result = await $GET('MonitorConfigure/DeleteJcpz', payload);
        return result;
    },
    // 日常巡查 - 获取列表服务
    async GetRcxcQyListData({ dispatch, commit }, payload) {
        let result = await $GET('MonitorConfigure/GetRcxcQyListData', payload);
        return result.resultvalue;
    },
    // 日常巡查 - 获取片区配置详情
    async GetRcxcQyInfo({ dispatch, commit }, payload) {
        let result = await $GET('MonitorConfigure/GetRcxcQyInfo', payload);
        return result.resultvalue;
    },
    // 日常巡查 - 移动监测点
    async updateLatLng({ dispatch, commit }, payload) {
        let result = await $POST('MonitorConfigure/MoveJcwz', payload);
        return result;
    },
    // 保护工程 - 获取列表服务
    async getProtectionList({ dispatch, commit }, payload) {
        let result = await $GET('MonitorConfigure/GetBHGCList', payload);
        return result.ResultValue;
    },
    // 新建项目 - 获取列表服务
    async getNewProjectList({ dispatch, commit }, payload) {
        let result = await $GET('MonitorConfigure/GetXJXMList', payload);
        return result.ResultValue;
    },
    // 客流高峰 - 获取列表服务
    async getCommuterRushHourList({ dispatch, commit }, payload) {
        let result = await $GET('MonitorConfigure/GetLyjdList', payload);
        return result.ResultValue;
    },
    // 考古发掘 - 获取列表服务
    async getArchaeologyDigList({ dispatch, commit }, payload) {
        let result = await $GET('MonitorConfigure/GetKGFJList', payload);
        return result.ResultValue;
    },
    // 民族文化 - 获取列表服务
    async getEthnicCultureList({ dispatch, commit }, payload) {
        let result = await $GET('MonitorConfigure/GetFYJCList', payload);
        return result.ResultValue;
    },
    // 民族文化 - 获取遗产组成
    async getFswz({ dispatch, commit }, payload) {
        let result = await $GET('MyFind/GetFswz', payload);
        return result.ResultValue;
    },
    // 民居考评 - 获取列表服务
    async getMJKPDataList({ dispatch, commit }, payload) {
        let result = await $GET('MonitorConfigure/GetMJKPDataList', payload);
        return result.ResultValue;
    },
    // 民居考评 - 获取考评指标列表服务
    async getMjkpQuestion({ dispatch, commit }, payload) {
        let result = await $GET('Mobile/GetMjkpQuestion', payload);
        return result.ResultValue;
    },
    // 异常预警 - 异常预警列表
    async GetRcxcycjlList({ dispatch, commit }, payload) {
        let result = await $GET('MonitorConfigure/GetRcxcycjlList', payload);
        return result.ResultValue;
    },
    // 异常预警 - 异常类型列表 App
    async GetRcxcycjlYclxList({ dispatch, commit }, payload) {
        let result = await $GET('MonitorConfigure/GetRcxcycjlYclxList', payload);
        return result.ResultValue;
    },
    // 异常预警 - 异常类型列表 小程序
    async GetDxYclx({ dispatch, commit }, payload) {
        let result = await $GET('MyFind/GetDxYclx', payload);
        return result.ResultValue;
    },
    // 异常预警 - 上报人列表
    async getReportPersonList({ dispatch, commit }, payload) {
        let result = await $GET('MonitorConfigure/GetReportPersonList', payload);
        return result.ResultValue;
    },
    // 异常预警 - 异常预警详情
    async GetRcxcycjlDetail({ dispatch, commit }, payload) {
        let result = await $GET('MonitorConfigure/GetRcxcycjlDetail', payload);
        return result.ResultValue;
    },
    // 异常预警 - 异常预警任务详情页
    async GetYcjlToTaskDetails({ dispatch, commit }, payload) {
        let result = await $GET('MonitorConfigure/GetYcjlToTaskDetails', payload);
        return result.ResultValue;
    },
    // 异常预警 - 异常预警解除服务
    async GetRcxcycjlRemoveWarn({ dispatch, commit }, payload) {
        let result = await $GET('MonitorConfigure/GetRcxcycjlRemoveWarn', payload);
        return result;
    },
    // 异常预警 - 异常预警误报服务
    async UpdateEarlywarning({ dispatch, commit }, payload) {
        let result = await $GET('MonitorConfigure/UpdateEarlywarning', payload);
        return result;
    },
    // 异常预警 - 异常预警任务保存服务
    async AddYcjlToTask({ dispatch, commit }, payload) {
        let result = await $POST('MonitorConfigure/AddYcjlToTask', payload);
        return result;
    },
    // 异常预警 - 异常预警列表(地图)
    async getRcxcycjlMapList({ dispatch, commit }, payload) {
        let result = await $GET('MonitorConfigure/GetRcxcycjlMapList', payload);
        return result.ResultValue;
    },
    // 巡查轨迹 - 获取某月巡查情况
    async getPatrolInfoByMonth({ dispatch, commit }, payload) {
        let result = await $GET('MonitorConfigure/GetDate', payload);
        return result.ResultValue;
    },
    // 巡查轨迹 - 获取某巡查员某天的巡查详情
    async getPatrolDetail({ dispatch, commit }, payload) {
        let result = await $GET('MonitorConfigure/GetXcyInfo', payload);
        return result.ResultValue;
    },
    // 删除监测云 病害配置
    async DeleteConfigure({ dispatch, commit }, payload) {
        let result = await $GET('MonitorConfigure/DeleteConfigure', payload);
        return result;
    },
    // 数据统计
    async getStatistics({ dispatch, commit }, payload) {
        let result = await $GET('MonitorTasksStatictis/GetMornitorStatistic', payload);
        return result.ResultValue;
    },
    // 数据统计
    async getStatistics_zx({ dispatch, commit }, payload) {
        let result = await $GET('MonitorTasksStatictis/GetTaskStatistic', payload);
        return result.ResultValue;
    },
    // 百度地图纠偏
    async rectifyDeviation({ dispatch, commit }, payload) {
        let result = await $POST1('api/rectify/v1/track', payload);
        return result;
    },
    // 审核采集的数据
    async updateSHZT({ dispatch, commit }, payload) {
        let result = await $POST('MonitorConfigure/UpdateShzt', payload);
        return result;
    },
    // 获取 配置历史版本
    async getHisJcpzInfo({ dispatch, commit }, payload) {
        let result = await $GET('MonitorConfigure/GetHisJcpzInfo', payload);
        return result;
    },

    // 任务日志-获取巡查管理列表
    async GetXcglList({ dispatch, commit }, payload) {
        let result = await $POST('MonitorConfigure/GetXcglList', payload);
        return result.RESULTVALUE;
    },

    // 森林护林员-查看
    async GetSlhlyList({ dispatch, commit }, payload) {
        let result = await $GET('MonitorConfigure/GetSlhlyList', payload);
        return result;
    },

    // 森林护林员-删除
    async DeleteSlhlyInfo({ dispatch, commit }, payload) {
        let result = await $GET('MonitorConfigure/DeleteSlhlyInfo', payload);
        return result;
    },

    // 森林护林员-新建
    async SaveSlhlyInfo({ dispatch, commit }, payload) {
        let result = await $POST('MonitorConfigure/SaveSlhlyInfo', payload);
        return result;
    },
    // 森林护林员-森林护理员ID
    async GetUserid({ dispatch, commit }, payload) {
        let result = await $GET('MonitorConfigure/GetUserid', payload);
        return result;
    },

    // 报告管理
    async GetBgglList({ dispatch, commit }, payload) {
        let result = await $GET('Bggl/GetBgglList', payload);
        return result;
    },
    // 导出报告 
    async ExportBgglData({ dispatch, commit }, payload) {
        let result = await $GET('Bggl/ExportBgglData', payload);
        return result;
    },
    // 林业局 生成 监测报告 
    async CreatPatrolReport({ dispatch, commit }, payload) {
        let result = await $POST('Bggl/CreatPatrolReport', payload);
        return result;
    },
    // 林业局  生成 巡查报告
    async CreatLyjPatrolReport({ dispatch, commit }, payload) {
        let result = await $POST('Bggl/CreatLyjPatrolReport', payload);
        return result;
    },
    // 管理局 生成 巡查报告
    async CreatSystemPatrolReport({ dispatch, commit }, payload) {
        let result = await $POST('Bggl/CreatSystemPatrolReport', payload);
        return result;
    },

    // 生成临时任务
    async CreatePeriodTask({ dispatch, commit }, payload) {
        let result = await $GET('MonitorConfigure/CreatePeriodTask', payload);
        return result;
    },

    // 古茶树-获取管护区数据
    async GetManageAreaData({ dispatch, commit }, payload) {
        let result = await $GET('MonitorConfigure/GetManageAreaData', payload);
        return result;
    },
    // 古茶树-获取古茶树基础数据
    async GetGcsqdData({ dispatch, commit }, payload) {
        let result = await $GET('MonitorConfigure/GetGcsqdData', payload);
        return result;
    },
    // 古茶树-跟踪记录-时间列表
    async GetGcsTaskTimeList({ dispatch, commit }, payload) {
        let result = await $GET('MonitorConfigure/GetGcsTaskTimeList', payload);
        return result;
    },
};
export default {
    state,
    getters,
    mutations,
    actions
};