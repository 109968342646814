<template>
    <div class="actionPartComp flex-between">
        <div
            v-for="(item, index) in listAry"
            :key="index"
            class="tableItem pointer"
            :class="nameDirection ? '' : 'flex-y-center'"
            @click="goAct(item)"
            :style="{
                marginRight: item.name == '用户手册' ? '20px' : '',
            }"
        >
            <img class="btn" :src="srcState ? item.srcDark : item.src" />
            <div
                class="name"
                :style="{
                    color: srcState ? 'rgb(24, 109, 112)' : '#fff',
                }"
            >
                {{ item.name }}
            </div>
        </div>
    </div>
</template>

<script>
import * as TYPES from "@store/modules/mutation-type.js";
import { mapActions, mapMutations, mapGetters } from "vuex";
export default {
    name: "",
    components: {},
    data() {
        return {
            listAry: [],
        };
    },
    props: {
        nameDirection: {
            type: Boolean,
            default: true,
        },
        // 菜单页面 用icon深色背景
        srcState: {
            type: Boolean,
            default: true,
        },
        showNameList: {
            type: Array,
            default: () => {
                return [];
            },
        },
    },
    mounted() {
        let list = [
            {
                name: "用户手册",
                src: require("@image/icon/user_book.png"),
                srcDark: require("@image/icon/user_book.png"),
            },
            {
                name: "翻译",
                src: require("@image/icon/fanyi.png"),
                srcDark: require("@image/icon/fanyi.png"),
            },
            {
                name: "全屏",
                src: require("@image/icon/full.png"),
                srcDark: require("@image/icon/full_dark.png"),
            },
            {
                name: "主页",
                src: require("@image/icon/main.png"),
                srcDark: require("@image/icon/full_dark.png"),
            },
            {
                name: "退出",
                src: require("@image/icon/exit.png"),
                srcDark: require("@image/icon/exit_dark.png"),
            },
        ];

        list.map((item, index) => {
            if (this.showNameList.includes(item.name)) {
                this.listAry.push(item);
            }
        });
    },
    computed: {
        ...mapGetters({
            jczlFyNum: TYPES.jczlFyNum,
        }),
    },
    methods: {
        ...mapMutations({
            SET_JCZLFYNUM: TYPES.SET_JCZLFYNUM,
            SET_DATAPAGEINDEX: TYPES.SET_DATAPAGEINDEX,
            SET_LYJ_COMMON: "SET_LYJ_COMMON",
            SET_LYJ_SJGL: "SET_LYJ_SJGL",
            SET_LYJ_XCGL: "SET_LYJ_XCGL",
            SET_LYJ_GZTM: "SET_LYJ_GZTM",
            SET_SYSTEM3_COMMON: "SET_SYSTEM3_COMMON",
        }),
        goAct(item) {
            switch (item.name) {
                case "用户手册":
                    window.open(
                        `http://jmsgcl.geo-compass.com/file/yhsc_v1.pdf?v=${Math.random()}`
                    );
                    break;
                case "翻译":
                    if (this.jczlFyNum == 0) {
                        this.SET_JCZLFYNUM(1);
                    } else {
                        this.SET_JCZLFYNUM(0);
                    }
                    break;
                case "全屏":
                    this.common.fullScreen();
                    break;
                case "主页":
                    this.clearLocalStortage();
                    this.SET_DATAPAGEINDEX(1);
                    this.$router.push({
                        path: "/home",
                    });
                    break;
                case "退出":
                    this.clearLocalStortage();
                    this.SET_DATAPAGEINDEX(1);
                    this.SET_LYJ_SJGL({
                        lyj_sjgl_state: true,
                    });
                    this.SET_LYJ_XCGL({
                        lyj_inspect_state: true,
                    });
                    this.SET_LYJ_GZTM({
                        lyj_work_state: true,
                    });
                    this.SET_LYJ_COMMON({
                        lyjActiveNum: 0,
                    });
                    this.SET_SYSTEM3_COMMON({
                        system3ActiveNum: 0,
                    });
                    let path = "";
                    switch (localStorage.system) {
                        case "system1":
                            path = "/login";
                            break;
                        case "system2":
                            path = "/login2";
                            break;
                        case "system3":
                            path = "/login3";
                            break;
                        default:
                            path = "/login";
                    }
                    this.$router.push({
                        path: path,
                    });
                    break;
            }
        },
        clearLocalStortage() {
            localStorage.removeItem("snSearch");
            localStorage.removeItem("snPage");
        },
    },
};
</script>

<style lang="scss" scoped>
.actionPartComp {
    .tableItem {
        text-align: center;
        margin-left: 15px;
        .btn {
            width: 21px;
            height: 21px;
        }
        .name {
            white-space: nowrap;
            color: #fff;
            font-size: 14px;
            margin-left: 4px;
        }
    }
}
</style>
