export const self = {
    loading: false,
    snTable: {
        table: {
            data: [],
            rowKey: 'ID',
            treeProps: {
                children: 'Children',
                hasChildren: 'hasChildren'
            }
        },
        columns: {
            // 选择、序号
            columnsOperate: [
                // {
                //     isShow: true,
                //     type: 'selection',
                //     width: 35
                // }, 
                {
                    isShow: true,
                    label: '序号',
                    type: 'index',
                    width: 50
                },

            ],
            // 数据列
            columnsData: [
                {
                    isShow: true,
                    label: '监测对象名称',
                    prop: 'MC',
                    minWidth: 105,
                },
                {
                    isShow: true,
                    label: '监测配置方案名称',
                    prop: 'JCPZFAMC',
                    minWidth: 105,
                },
                {
                    isShow: true,
                    label: '配置状态',
                    prop: 'ZT_BM',
                    minWidth: 105
                },
                {
                    isShow: true,
                    label: '采集方式',
                    prop: 'LRFS',
                    minWidth: 65,
                    transList: {
                        0: '系统平台',
                        1: '设备采集',
                        2: '移动采集',
                    }
                },
                {
                    isShow: true,
                    label: '数据采集负责人',
                    prop: 'SJCJFZRMC',
                    minWidth: 80
                },
                {
                    isShow: true,
                    label: '采集周期',
                    prop: 'ZQ',
                    prop1: 'ZQDW_BM',
                    minWidth: 50,
                    transMethod: 'getCjzqText'
                },
            ],
            // 操作列
            columnsBtn: {
                buttonData: []
            }
        },
    },
    snPage: {
        tiaoshu: 20,
        count: 0,
        currentPage: 1,
        operateType: 'page'
    },
    snCount: {
        eachtotal: [20, 50, 100],
        defaultTiaoshu: 20
    },
    snSearch: {
        inputData: [
            {
                label: '关键字',
                placeholder: "请输入关键字",
                value: 'keyword',
                operateType: 'search',
                isShow: true
            },
        ],
        selectData: [],
        searchBtn: {
            buttonData: [
                {
                    btnType: 'button',
                    operateType: 'searchBtn',
                    name: '查询',
                    round: true,
                    backColor: '#2a63d5',
                    color: '#fff'
                }
            ]
        }
    },
    snButton: {
        buttonData: [
        ]
    }
};
