import {
    $GET,
    $POST,
    $AXIOS
} from '@request/http.js';
import * as Types from '../mutation-type.js';

const state = {
    jskzPanelNum: 0, // 0 总览 1 项目 
    jskzProjectItem: null, // 某一个  项目
    jskzProjectList: [], // 所有  项目
    jskzJgsxItem: null, // 某一个  景观视线
    jskzPdfItem: null, // 某一个  pdf

};

const getters = {
    jskzPanelNum(state, getters) {
        return state.jskzPanelNum;
    },
    jskzProjectItem(state, getters) {
        return state.jskzProjectItem;
    },
    jskzProjectList(state, getters) {
        return state.jskzProjectList;
    },
    jskzJgsxItem(state, getters) {
        return state.jskzJgsxItem;
    },
    jskzPdfItem(state, getters) {
        return state.jskzPdfItem;
    },
};

const mutations = {
    setStateJskz: (state, payload) => {
        for (const prop in payload) {
            if (payload[prop] !== undefined) {
                state[prop] = payload[prop];
            }
        }
    },
};

const actions = {
    // 遗产区、缓冲区、遗产区和区划外统计 
    async GetHeritageBufferTotal({
        dispatch,
        commit
    }, payload) {
        let result = await $GET('LargeShowJskzNew/GetHeritageBufferTotal', payload);
        return result.RESULTVALUE;
    },
    //  新建项目年份数据列表
    async GetNewProjectYearList({
        dispatch,
        commit
    }, payload) {
        let result = await $GET('LargeShowJskzNew/GetNewProjectYearList', payload);
        return result.RESULTVALUE;
    },
    //  新建项目记录列表
    async GetNewProjectList({
        dispatch,
        commit
    }, payload) {
        let result = await $GET('LargeShowJskzNew/GetNewProjectList', payload);
        return result.RESULTVALUE;
    },
    //  新建项目基本信息
    async GetNewProjectDetail({
        dispatch,
        commit
    }, payload) {
        let result = await $GET('LargeShowJskzNew/GetNewProjectDetail', payload);
        return result.RESULTVALUE;
    },
    //  新建项目记录位置
    async GetNewProjectPositionList({
        dispatch,
        commit
    }, payload) {
        let result = await $GET('LargeShowJskzNew/GetNewProjectPositionList', payload);
        return result.RESULTVALUE;
    },
    //  新建项目记录位置
    async GetScenePhotosList({
        dispatch,
        commit
    }, payload) {
        let result = await $GET('LargeShowJskzNew/GetScenePhotosList', payload);
        return result.RESULTVALUE;
    },
    //  景观视线点位—地图
    async GetSightLineList({
        dispatch,
        commit
    }, payload) {
        let result = await $GET('LargeShowBuildControl/GetSightLineList', payload);
        return result.RESULTVALUE;
    },
    //  景观视线基础数据
    async GetSightLineBasic({
        dispatch,
        commit
    }, payload) {
        let result = await $GET('LargeShowBuildControl/GetSightLineBasic', payload);
        return result.RESULTVALUE;
    },
    //  景观视线监测数据
    async GetSightLineMonitorList({
        dispatch,
        commit
    }, payload) {
        let result = await $GET('LargeShowBuildControl/GetSightLineMonitorList', payload);
        return result.RESULTVALUE;
    },
    //  土地利用(地表覆盖)
    async GetLandUseList({
        dispatch,
        commit
    }, payload) {
        let result = await $GET('LargeShowBuildControl/GetLandUseList', payload);
        return result.RESULTVALUE;
    },
    //  跟踪记录详情
    async GetMjjsCjsjList({
        dispatch,
        commit
    }, payload) {
        let result = await $GET('LargeShowJskzNew/GetMjjsCjsjList', payload);
        return result.RESULTVALUE;
    },
    //  跟踪记录详情 现状描述的照片
    async GetMjjsCjsjFiles({
        dispatch,
        commit
    }, payload) {
        let result = await $GET('LargeShowJskzNew/GetMjjsCjsjFiles', payload);
        return result.RESULTVALUE;
    },
    // 建设控制 新建项目折线图
    async GetNewProjectEchartsEx({
        dispatch,
        commit
    }, payload) {
        let result = await $GET('LargeShowMonitoringOverview/GetNewProjectEchartsEx', payload);
        return result.RESULTVALUE;
    },

};
export default {
    state,
    getters,
    mutations,
    actions
};