import { $GET, $POST } from '@request/http.js';
import * as Types from './mutation-type.js';

const state = {};
const getters = {

};
const mutations = {

};
const actions = {
    // 业务分配-预警 ------------------------------ start ---------------------------------
    // 1 预警预案
    // 1.1 获取预警项数据
    async GetYjdxInfo({ dispatch, commit }, payload) {
        let result = await $GET('EarlyWarn/GetYjdxInfo', payload);
        return result.RESULTVALUE;
    },
    // 1.2 获取预警指标项
    async GetYjzbDetail({ dispatch, commit }, payload) {
        let result = await $GET('EarlyWarn/GetYjzbDetail', payload);
        return result.RESULTVALUE;
    },
    // 1.3 保存预警项数据
    async SaveYjszInfo({ dispatch, commit }, payload) {
        let result = await $POST('EarlyWarn/SaveYjszInfo', payload);
        return result;
    },
    // 删除预警项数据
    async DeleteYjszData({ dispatch, commit }, payload) {
        let result = await $GET('EarlyWarn/DeleteYjszData', payload);
        return result;
    },
    // 1.4 获取预警对象列表  类型
    async GetYjxList({ dispatch, commit }, payload) {
        let result = await $GET('EarlyWarn/GetYjxList', payload);
        return result.RESULTVALUE;
    },
    // 获取预警对象列表
    async GetYjdxList({ dispatch, commit }, payload) {
        let result = await $GET('EarlyWarn/GetYjdxList', payload);
        return result.RESULTVALUE;
    },
    // 获取预警指标
    async GetYjzbList({ dispatch, commit }, payload) {
        let result = await $GET('EarlyWarn/GetYjzbList', payload);
        return result.RESULTVALUE;
    },
    // 2 预警对象设置
    // 2.2 保存预警预案信息
    async SaveYjyaDataInfo({ dispatch, commit }, payload) {
        let result = await $POST('EarlyWarn/SaveYjyaDataInfo', payload);
        return result;
    },
    // 2.3 获取预警预案数据
    async GetYjyaDataList({ dispatch, commit }, payload) {
        let result = await $GET('EarlyWarn/GetYjyaDataList', payload);
        return result.RESULTVALUE;
    },
    // 2.4 获取预警等级的枚举
    async GetEnumIdList({ dispatch, commit }, payload) {
        let result = await $GET('EarlyWarn/GetEnumIdList', payload);
        return result.RESULTVALUE;
    },
    // 2.5 删除预警预案数据
    async DeleteYjyaData({ dispatch, commit }, payload) {
        let result = await $GET('EarlyWarn/DeleteYjyaData', payload);
        return result;
    },

    // 3 预警配置
    // 3.- 获取人员列表
    async GetUserList({ dispatch, commit }, payload) {
        let result = await $GET('OperationandMaintenanceManagement/GetUserList', payload);
        return result.ResultValue;
    },
    // 3.1 获取运算符号
    async GetOperationSymbol({ dispatch, commit }, payload) {
        let result = await $GET('EarlyWarn/GetOperationSymbol', payload);
        return result.RESULTVALUE;
    },
    // 3.2 验证表达式是否可用
    async ValidExpression({ dispatch, commit }, payload) {
        let result = await $GET('EarlyWarn/ValidExpression', payload);
        return result;
    },
    // 3.3 获取预警配置列表
    async GetYjpzDataList({ dispatch, commit }, payload) {
        let result = await $GET('EarlyWarn/GetYjpzDataList', payload);
        return result.RESULTVALUE;
    },
    // 3.4 获取预警配置详情
    async GetYjpzDetail({ dispatch, commit }, payload) {
        let result = await $GET('EarlyWarn/GetYjpzDetail', payload);
        return result.RESULTVALUE;
    },
    // 3.5 获取预警预案附件列表
    async GetYjyaList({ dispatch, commit }, payload) {
        let result = await $GET('EarlyWarn/GetYjyaList', payload);
        return result.RESULTVALUE;
    },
    // 3.6 保存预警配置数据
    async SaveYjpzData({ dispatch, commit }, payload) {
        let result = await $POST('EarlyWarn/SaveYjpzData', payload);
        return result;
    },
    // 3.7 获取枚举类指标结果
    async GetYjxxPgjgList({ dispatch, commit }, payload) {
        let result = await $GET('EarlyWarn/GetYjxxPgjgList', payload);
        return result.RESULTVALUE;
    },
    // 3.8 修改配置状态
    async UpdateYjpzPzzt({ dispatch, commit }, payload) {
        let result = await $GET('EarlyWarn/UpdateYjpzPzzt', payload);
        return result;
    },
    // 业务分配-预警 ---------------------------- end -------------------------------------


    // 工作台面-预警 ------------------------------ start ---------------------------------
    // 4 预警处置
    // 4.2 获取预警处置列表
    async GetYjsjDataList({ dispatch, commit }, payload) {
        let result = await $GET('EarlyWarn/GetYjsjDataList', payload);
        return result.RESULTVALUE;
    },
    // 4.3 获取预警信息
    async GetYjInfo({ dispatch, commit }, payload) {
        let result = await $GET('EarlyWarn/GetYjInfo', payload);
        return result.RESULTVALUE;
    },
    // 4.3 获取触警数据
    async GetCjsjData({ dispatch, commit }, payload) {
        let result = await $GET('EarlyWarn/GetCjsjData', payload);
        return result.RESULTVALUE;
    },
    // 4.4 获取预警跟踪配置数据
    async GetYjgzpzData({ dispatch, commit }, payload) {
        let result = await $GET('EarlyWarn/GetYjgzpzData', payload);
        return result.RESULTVALUE;
    },
    // 4.5 保存预警跟踪配置数据
    async SaveYjgzpzData({ dispatch, commit }, payload) {
        let result = await $POST('EarlyWarn/SaveYjgzpzData', payload);
        return result;
    },
    // 4.6 获取预警跟踪记录数据
    async GetYjgzjlData({ dispatch, commit }, payload) {
        let result = await $GET('EarlyWarn/GetYjgzjlData', payload);
        return result.RESULTVALUE;
    },
    // 4.9 获取处置跟踪记录数据
    async GetCzgzjlData({ dispatch, commit }, payload) {
        let result = await $GET('EarlyWarn/GetCzgzjlData', payload);
        return result.RESULTVALUE;
    },
    // 4.10 保存预警处置结果
    async SaveYjczjgData({ dispatch, commit }, payload) {
        let result = await $POST('EarlyWarn/SaveYjczjgData', payload);
        return result;
    },
    // 4.11 获取处置结果列表
    async GetCzjgList({ dispatch, commit }, payload) {
        let result = await $GET('EarlyWarn/GetCzjgList', payload);
        return result.RESULTVALUE;
    },
    // 4.12 获取处置结果跟踪数据
    async GetCzjggzData({ dispatch, commit }, payload) {
        let result = await $GET('EarlyWarn/GetCzjggzData', payload);
        return result.RESULTVALUE;
    },
    // 4.13 保存处置结果数据
    async SaveCzjgData({ dispatch, commit }, payload) {
        let result = await $POST('EarlyWarn/SaveCzjgData', payload);
        return result;
    },
    // 4.14 下发预警配置任务
    async GetYjxxYjgzTask({ dispatch, commit }, payload) {
        let result = await $GET('EarlyWarn/GetYjxxYjgzTask', payload);
        return result;
    },
    // 4.15 删除预警事件数据
    async DeleteYjsjData({ dispatch, commit }, payload) {
        let result = await $GET('EarlyWarn/DeleteYjsjData', payload);
        return result;
    },
    // 4.16 获取预警核实数据
    async GetYjhsData({ dispatch, commit }, payload) {
        let result = await $GET('EarlyWarn/GetYjhsData', payload);
        return result.RESULTVALUE;
    },
    // 4.17 保存预警核实数据
    async SaveYjhsData({ dispatch, commit }, payload) {
        let result = await $POST('EarlyWarn/SaveYjhsData', payload);
        return result;
    },
    // 4.18 获取预警上报列表
    async GetYjsbList({ dispatch, commit }, payload) {
        let result = await $GET('EarlyWarn/GetYjsbList', payload);
        return result.RESULTVALUE;
    },
    // 4.19 保存预警上报数据
    async SaveYjsbData({ dispatch, commit }, payload) {
        let result = await $POST('EarlyWarn/SaveYjsbData', payload);
        return result;
    },
    // 4.20 短信发送
    async SendMessageForYjjsr({ dispatch, commit }, payload) {
        let result = await $GET('EarlyWarn/SendMessageForYjjsr', payload);
        return result;
    },
    // 4.21获取预警处置结果
    async GetYjczjgValue({ dispatch, commit }, payload) {
        let result = await $GET('EarlyWarn/GetYjczjgValue', payload);
        return result.RESULTVALUE;
    },
    // 删除附件服务
    async DeleteFiles({ dispatch, commit }, payload) {
        let result = await $GET('EarlyWarn/DeleteFiles', payload);
        return result;
    },
    // 获取异常记录图片 选择一张 作为基准照
    async GetRcxcycjlPicture({ dispatch, commit }, payload) {
        let result = await $GET('EarlyWarn/GetRcxcycjlPicture', payload);
        return result;
    },



    // 工作台面-预警 ---------------------------- end -------------------------------------
    // 指导建议
    async SaveYjxxZdjy({ dispatch, commit }, payload) {
        let result = await $POST('EarlyWarn/SaveYjxxZdjy', payload);
        return result;
    },
};

export default {
    state,
    getters,
    mutations,
    actions
};