<template>
    <ul class="sycn-sxjz">
        <li v-for="(item, ind) in dataList" :key="item.ID + ind">
            <div class="year">{{ item.NF }}</div>
            <div class="icon"></div>
            <div class="content">
                <div>
                    <div>
                        <div>
                            <div style="display: inline-block; width: 30%">
                                <span class="bold">目前进展:</span>
                                <span class="left-spaced">{{
                                    getName(item.MQJZ)
                                }}</span>
                            </div>
                            <template v-if="item.PIC.length > 0">
                                <span class="bold"> 相关文档:</span>
                                <a
                                    v-for="(file, fileid) in item.PIC.slice(
                                        0,
                                        3
                                    )"
                                    :key="fileid"
                                    class="download"
                                    :href="file.CCLJ"
                                    :title="file.WDMC"
                                    download=""
                                >
                                    {{ file.WDMC | wdmc }}
                                </a>
                                <span
                                    v-show="item.PIC.length > 3"
                                    class="left-spaced bold"
                                    >更多附件请查看详情</span
                                >
                            </template>
                        </div>
                        <div class="text-box">
                            <div class="bold">说明:</div>
                            <div class="left-spaced">{{ item.DWCQKDBYSM }}</div>
                        </div>
                    </div>
                    <div class="shzt" :style="{ color: colorList[item.SHZT] }">
                        <span
                            v-if="Number(item.SHZT) !== 3"
                            class="shzt-not-pass-detail"
                        >
                            {{ transList[item.SHZT] }}
                        </span>
                        <el-popover
                            v-if="Number(item.SHZT) === 3"
                            placement="bottom-start"
                            title="审核未通过原因:"
                            width="200"
                            trigger="hover"
                            :content="item['SHBTGSM'] || '暂无数据'"
                            popper-class="shzt-not-pass-popper"
                        >
                            <template slot="reference">
                                <span class="shzt-not-pass-detail">
                                    {{ transList[item.SHZT] }}
                                </span>
                                <span class="shzt-not-pass-icon"></span>
                            </template>
                        </el-popover>
                    </div>
                    <div
                        class="shzt"
                        :style="{ color: colorListDJZT[item.SFYDJ] }"
                    >
                        <span class="shzt-not-pass-detail">
                            {{ transListDJZT[item.SFYDJ] }}
                        </span>
                    </div>
                    <div class="btn">
                        <sn-button
                            class="sn-common"
                            :snButton.sync="columnsBtn"
                            :sourceData.sync="item"
                            :commonMethods.sync="commonMethods"
                            @handleChange="handleChange"
                        ></sn-button>
                    </div>
                </div>
            </div>
        </li>
        <li v-if="dataList.length === 0" class="empty">
            暂无事项进展, 点击右侧操作列<i
                class="el-icon-document-add"
                style="color: rgb(0, 167, 189); padding: 0 8px"
            ></i
            >按钮录入事项进展。
        </li>
    </ul>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import { commonMethods } from "../table_config/table-common-methods";
import infoMixin from "../table_type/0_com_js/info_mixin.js";
export default {
    mixins: [infoMixin],
    filters: {
        wdmc: function (value) {
            let res;
            const dotIndex = String(value).lastIndexOf(".");
            if (dotIndex > 0) {
                res = String(value).substring(0, dotIndex);
            } else {
                res = String(value);
            }

            if (res.length > 3) {
                res = res.substr(0, 3) + "...";
            }
            return res;
        },
    },
    props: {
        cnsxInfo: {
            default: () => ({}),
        },
        dataList: {
            default: () => [],
        },
    },
    watch: {
        dataList: {
            immediate: true,
            handler() {
                // console.log(
                //     "%c 🍫 this.dataList: ",
                //     "font-size:20px;background-color: #B03734;color:#fff;",
                //     this.dataList
                // );
            },
        },
    },
    data() {
        return {
            commonMethods,
            columnsBtn: {
                buttonData: [
                    {
                        isShow: true,
                        showMethod: "showSeeBtn",
                        btnType: "icon",
                        operateType: "columnSee",
                        title: "查看",
                        icon: "el-icon-view",
                        backColor: "transparent",
                        color: "#20a0ff",
                    },
                    {
                        isShow: true,
                        showMethod: "showEditBtn",
                        btnType: "icon",
                        operateType: "columnEdit",
                        title: "编辑",
                        icon: "el-icon-edit",
                        backColor: "transparent",
                        color: "green",
                    },
                    {
                        isShow: true,
                        showMethod: "showCheckBtn",
                        btnType: "icon",
                        operateType: "columnCheck",
                        title: "审核",
                        icon: "el-icon-document-checked",
                        backColor: "transparent",
                        color: "#00a7bd",
                    },
                    {
                        isShow: true,
                        showMethod: "showDelBtn",
                        btnType: "icon",
                        operateType: "columnDelete",
                        title: "删除",
                        icon: "el-icon-delete",
                        backColor: "transparent",
                        color: "red",
                    },
                    {
                        isShow: true,
                        showMethod: "showConnect",
                        btnType: "icon",
                        operateType: "columnIntegrat",
                        title: "对接",
                        icon: "el-icon-upload",
                        backColor: "transparent",
                        color: "#008c8c",
                    },
                ],
            },
            transList: {
                0: "未审核", // 未审核 / 提交未审核
                1: "未提交", // 未提交
                2: "审核通过", // 审核通过
                3: "审核不通过", // 审核不通过
            },
            colorList: {
                0: "#28ccd9",
                1: "#ff9066",
                2: "#2ec392",
                3: "#fa6f1b",
            },
            transListDJZT: {
                null: "未对接",
                "": "未对接",
                0: "未对接",
                1: "已对接",
            },
            colorListDJZT: {
                null: "#28ccd9",
                "": "#28ccd9",
                0: "#28ccd9",
                1: "#2ec392",
            },
        };
    },
    mounted() {},
    methods: {
        ...mapActions(["deleteMonitorData", "dockData"]),
        getName(num) {
            let state = null;
            switch (num) {
                case "0":
                    state = "无承诺事项";
                    break;
                case "1":
                    state = "正常履行";
                    break;
                case "2":
                    state = "未正常履行";
                    break;
            }
            return state;
        },
        handleChange(type, [value]) {
            console.log("点击类型:" + type, "处理数据:", value);
            switch (type) {
                case "columnSee":
                    this.toNewPage(value, "2", false, false);
                    break;
                case "columnEdit":
                    this.toNewPage(
                        { ...value, ...this.cnsxInfo },
                        "1",
                        false,
                        false
                    );
                    break;
                case "columnDelete":
                    this.deleteData(value.ID, 101);
                    break;
                case "columnCheck": // 审核
                    this.toNewPage(
                        { ...value, ...this.cnsxInfo },
                        "3",
                        false,
                        false
                    );
                    break;
                case "columnIntegrat":
                    this.integrat(value);
                    break;
                default:
                    break;
            }
        },
        async integrat(value) {
            if (value.SHZT === SHZT.AUDIT_PASSED) {
                const payload = {
                    funId: "101",
                    id: value.ID,
                };
                const res = await this.dockData(payload);
                console.log(res);
            } else {
                this.$alert("该数据暂不能对接");
            }
        },
        // 跳转到新建或编辑页面
        toNewPage(val, type, updataVersion, addChild) {
            // type: 0 => 新建 1 => 编辑 2 => 查看
            let bm = this.$route.params.ItemID;
            let ID = val.ID;
            let editPage = "sycnlxqk";

            this.$router.push({
                name: editPage,
                query: {
                    type: type,
                    id: addChild ? "" : ID,
                    ItemID: bm,
                    CNLY: val.CNLY,
                    CNSXNR_ZW: val.CNSXNR_ZW,
                },
            });
        },
        // 删除前确认
        deleteData(value, itemID) {
            let checkboxlist = value;
            this.$confirm("是否永久删除该条记录?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            })
                .then(() => {
                    this.delData(checkboxlist, itemID);
                })
                .catch(() => {
                    this.common.showMsg("已取消删除", "info");
                });
        },
        // 删除数据
        async delData(id, itemID) {
            let res = await this.deleteMonitorData({
                ids: id,
                itemid: 101,
            });
            if (res.IsSuccess) {
                this.common.showMsg("删除成功", "success");
                this.$emit("getTableData");
            } else {
                this.common.showMsg("删除失败", "error");
            }
        },
    },
};
</script>

<style lang="scss" scoped>
ul.sycn-sxjz {
    > li {
        display: flex;
        align-items: center;
        height: 80px;
        &.empty:only-child {
            height: 30px;
            padding-left: 50px;
        }
        > div {
            &.year {
                flex: 0 0;
                flex-basis: 55px;
                text-align: center;
            }
            &.icon {
                flex: 0 0;
                flex-basis: 20px;
                // margin:5px;
                height: 20px;
                text-align: center;
                border: 5px solid rgb(41, 96, 207);
                border-radius: 50%;
                position: relative;
                z-index: 1;
                &::after,
                &::before {
                    display: block;
                    content: "";
                    left: 50%;
                    transform: translateX(-50%);
                    position: absolute;
                    width: 5px;
                    height: 30px;
                    background: rgb(201, 201, 201);
                }
                &::after {
                    bottom: -35px;
                }
                &::before {
                    top: -35px;
                }
            }
            &.content {
                flex: 1 1;
                > div {
                    &:hover {
                        box-shadow: 5px 5px 5px #888888;
                    }
                    &::before {
                        display: block;
                        content: "";
                        width: 10px;
                        height: 10px;
                        position: absolute;
                        left: -6px;
                        top: 50%;
                        transform: translateY(-50%) rotate(-45deg);
                        background-color: #fff;
                    }
                    position: relative;
                    box-shadow: 2px 2px 2px #888888;
                    background-color: #fff;
                    width: 90%;
                    height: 80%;
                    margin-left: 20px;
                    display: flex;
                    align-items: center;
                    padding-left: 20px;
                    > div {
                        &:nth-of-type(1) {
                            flex: 1 1;
                            > div {
                                overflow: hidden;
                                white-space: nowrap;
                                span {
                                    &.left-spaced {
                                        margin-left: 10px;
                                    }
                                    &.bold {
                                        font-weight: 700;
                                    }
                                }
                                .download {
                                    display: inline-block;
                                    padding-left: 25px;
                                    background-image: url("~@image/working_desk/monitor_data/download.png");
                                    background-repeat: no-repeat;
                                    background-position: left center;
                                    color: rgb(98, 195, 254);
                                    margin-left: 10px;
                                }
                                &.text-box {
                                    display: flex;
                                    > div {
                                        max-width: 500px;
                                        overflow: hidden;
                                        text-overflow: ellipsis;
                                        white-space: nowrap;
                                        &.left-spaced {
                                            margin-left: 10px;
                                        }
                                        &.bold {
                                            font-weight: 700;
                                        }
                                    }
                                }
                            }
                        }
                        &.shzt {
                            flex: 0 0 130px;
                            padding-left: 15px;
                            border-left: 1px solid
                                rgba($color: #000000, $alpha: 0.3);
                        }
                        &.btn {
                            flex: 0 0 90px;
                            padding-left: 15px;
                            border-left: 1px solid
                                rgba($color: #000000, $alpha: 0.3);
                        }
                    }
                }
            }
            &.sn-common {
                flex: 0 0;
                flex-basis: 100px;
            }
        }
    }
}
</style>
