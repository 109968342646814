import SnButton from "@comp/sn-common/sn_button";
import SnDialog from "@comp/sn-common/sn_dialog";
import SnPage from "@comp/sn-common/sn_page";
import SnCount from "@comp/sn-common/sn_count";
import SnSearch from "@comp/sn-common/sn_search";
import SnInput from "@comp/sn-common/sn_input";
import SnRadio from "@comp/sn-common/sn_radio";
import SnSelect from "@comp/sn-common/sn_select";
import SnTreeselect from "@comp/sn-common/sn_treeselect";
import SnDatePicker from "@comp/sn-common/sn_date_picker";
import SnTable from "@comp/sn-common/sn_table";
import SnTableGroup from "@comp/sn-common/sn_table_group";
import SnBread from "@comp/sn-common/sn_bread";
import SnLoad from "@comp/sn-common/sn_load";
import SnNum from "@comp/sn-common/sn_num";
import SnNoData from "@comp/sn-common/sn_no_data";
import Mymap from "@comp/map/BaseMap";
import VueDPlayer from "vue-dplayer";
import SnActionBtn from "@comp/sn-common/sn_action_btn";
import SnTreeCjr from "@comp/sn-common/sn_treeCjr";
import SnFileDialog from "@comp/sn-common/sn_file_dialog";

const components = {
    SnButton,
    SnDialog,
    SnPage,
    SnCount,
    SnSearch,
    SnInput,
    SnRadio,
    SnSelect,
    SnTreeselect,
    SnDatePicker,
    SnTable,
    SnTableGroup,
    SnBread,
    SnLoad,
    SnNum,
    SnNoData,
    Mymap,
    VueDPlayer,
    SnActionBtn,
    SnTreeCjr,
    SnFileDialog
};

const install = function (Vue, opts = {}) {
    Object.keys(components).forEach(key => {
        Vue.component(key, components[key]);
    });
};
export default install;