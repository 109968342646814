<template>
    <div class="sn_button">
        <span
            v-for="(item, index) in buttonData"
            :key="index"
            @click="handleChange(item.operateType, [sourceData])"
        >
            <el-dropdown
                v-if="getShow(item, sourceData) && item.btnType === 'dropdown'"
                trigger="click"
                @command="
                    (val) => {
                        handleChange('dropdown', [val]);
                    }
                "
            >
                <el-button
                    :size="item.size || 'small'"
                    :type="item.type"
                    :plain="item.plain"
                    :round="item.round"
                    :circle="item.circle"
                    :disabled="item.disabled"
                    :icon="item.beforeIcon"
                    :autofocus="item.autofocus"
                    :title="item.title"
                    :style="{
                        background: item.backColor || '#2a63d5',
                        color: item.color || '#fff',
                        borderColor: item.backColor,
                        boxShadow: '0px 1px 6px' + item.backColor,
                    }"
                >
                    {{ item.name }}
                    <i class="el-icon-arrow-down el-icon--right"></i>
                </el-button>
                <el-dropdown-menu slot="dropdown" :class="item.dropdownClass">
                    <el-dropdown-item
                        v-for="(i, k) in item.menuList"
                        :key="k"
                        :command="i"
                        >{{ i.value }}</el-dropdown-item
                    >
                </el-dropdown-menu>
            </el-dropdown>
            <el-button
                v-else-if="
                    getShow(item, sourceData) && item.btnType === 'button'
                "
                :size="item.size || 'small'"
                :type="item.type"
                :plain="item.plain"
                :round="item.round"
                :circle="item.circle"
                :disabled="item.disabled"
                :icon="item.beforeIcon"
                :autofocus="item.autofocus"
                :title="item.title"
                :style="{
                    background: item.backColor || '#2a63d5',
                    color: item.color || '#fff',
                    borderColor: item.backColor,
                    boxShadow: '0px 1px 6px' + item.backColor,
                }"
            >
                {{ item.name }}
                <i
                    v-if="item.afterIcon"
                    class="iconBefore"
                    :class="item.afterIcon"
                ></i>
            </el-button>
            <i
                v-else-if="getShow(item, sourceData) && item.btnType === 'icon'"
                class="btn btn-icon"
                :class="item.icon"
                :title="item.title"
                :style="{
                    color: item.color || '#2a63d5',
                }"
            ></i>
            <img
                v-else-if="getShow(item, sourceData) && item.btnType === 'img'"
                class="btn btn-img"
                :title="item.title"
                :class="item.class"
                :src="getSrc(item.src)"
                alt
            />
            <span
                v-else-if="getShow(item, sourceData) && item.btnType === 'text'"
                :style="{
                    textDecoration: item.textDecoration || 'none',
                    paddingRight: item.paddingRight || 0,
                    color: item.color || '#606266',
                    cursor: 'pointer',
                }"
                >{{ item.text }}</span
            >
            <!--  :action="`${uploadURL}UpLoad/FileSave?LJ=YJYA`" -->
            <el-upload
                v-else-if="
                    getShow(item, sourceData) && item.btnType === 'upload'
                "
                :accept="item.accept"
                action="#"
                :auto-upload="false"
                v-model="fileList"
                :file-list="fileList"
                :limit="1"
                :on-change="onChangeFile"
            >
                <el-button
                    :size="item.size || 'small'"
                    :type="item.type"
                    :plain="item.plain"
                    :round="item.round"
                    :circle="item.circle"
                    :disabled="item.disabled"
                    :icon="item.beforeIcon"
                    :autofocus="item.autofocus"
                    :title="item.title"
                    :style="{
                        background: item.backColor || '#2a63d5',
                        color: item.color || '#fff',
                        borderColor: item.backColor,
                        boxShadow: '0px 1px 6px' + item.backColor,
                    }"
                >
                    {{ item.name }}
                    <i
                        v-if="item.afterIcon"
                        class="iconBefore"
                        :class="item.afterIcon"
                    ></i>
                </el-button>
            </el-upload>
        </span>
    </div>
</template>
<script>
export default {
    name: "sn_button",
    props: {
        snButton: {
            default: function () {
                return {};
            },
        },
        commonMethods: {
            default: function () {
                return {};
            },
        },
        sourceData: {
            default: function () {
                return [];
            },
        },
    },
    data() {
        return {
            uploadURL: window.REQUEST_URL,
            fileList: [],
            buttonData: [],
        };
    },
    watch: {
        snButton: {
            handler: function () {
                this.handleData();
            },
            deep: true,
        },
    },
    mounted() {
        this.handleData();
    },
    methods: {
        handleData() {
            Object.assign(this.$data, this.$options.data());
            Object.keys(this.snButton).forEach((key) => {
                this[key] = this.snButton[key];
            });
        },
        getSrc(src) {
            let imgSrc = require("@image/" + src);
            return imgSrc;
        },
        getShow(row, sourceData) {
            let show = false;
            if (
                row.showMethod &&
                typeof this.commonMethods[row.showMethod] === "function"
            ) {
                show = this.commonMethods[row.showMethod](sourceData, this);
            } else {
                show = row.hasOwnProperty("isShow") ? row.isShow : true;
            }
            return show;
        },
        handleChange(type, value) {
            this.$emit("handleChange", type, value);
        },
        onChangeFile(file) {
            this.$emit("handleChange", "buttonImport", file);
            this.fileList = [];
        },
    },
};
</script>
<style lang="scss" scoped>
.sn_button {
    .iconBefore {
        margin-left: 5px;
    }
    .btn {
        margin-right: 5px;
        cursor: pointer;
        vertical-align: middle;
    }

    i.btn-icon {
        font-size: 16px;
    }

    img.btn-img {
        height: 16px;
    }
}
</style>
