/* eslint-disable no-unused-vars */
// 分析评估系统
import { $GET, $POST, $AXIOS } from '@request/http.js';
import { Message } from 'element-ui';
import * as Types from './mutation-type.js';

const state = {
    undoneDataAssess: [],
    undoneTotalAssess: 0,
    doneDataAssess: [],
    doneTotalAssess: 0,
};

const getters = {
    [Types.undoneDataAssess]: ({ undoneDataAssess }) => undoneDataAssess,
    [Types.undoneTotalAssess]: ({ undoneTotalAssess }) => undoneTotalAssess,
    [Types.doneDataAssess]: ({ doneDataAssess }) => doneDataAssess,
    [Types.doneTotalAssess]: ({ doneTotalAssess }) => doneTotalAssess,
};

const mutations = {
    [Types.SET_UNDONEDATA]: (state, undoneDataAssess) => state.undoneDataAssess = undoneDataAssess,
    [Types.SET_UNDONETOTAL]: (state, undoneTotalAssess) => state.undoneTotalAssess = undoneTotalAssess,
    [Types.SET_DONEDATA]: (state, doneDataAssess) => state.doneDataAssess = doneDataAssess,
    [Types.SET_DONETOTAL]: (state, doneTotalAssess) => state.doneTotalAssess = doneTotalAssess,
};

const actions = {
    // 6.分析评估-评估对象：获取对象类型列表
    async GetDxlxList({ dispatch, commit, state }, payload) {
        let result = await $GET('EvaluationAnalysis/GetDxlxList', payload);
        return result.RESULTVALUE;
    },
    // 6.分析评估-评估对象：获取评估对象信息列表
    async GetPgdxInfoList({ dispatch, commit, state }, payload) {
        let result = await $GET('EvaluationAnalysis/GetPgdxInfoList', payload);
        return result.RESULTVALUE;
    },
    // 6.分析评估-评估对象：删除评估对象信息
    async DeletePgdxInfo({ dispatch, commit, state }, payload) {
        let result = await $GET('EvaluationAnalysis/DeletePgdxInfo', payload);
        return result;
    },
    // 6.分析评估-评估对象：新建/修改评估对象
    async AddPgdxInfo({ dispatch, commit, state }, payload) {
        let result = await $POST('EvaluationAnalysis/AddPgdxInfo', payload);
        return result;
    },
    // 6.分析评估-评估对象：获取遗产要素列表
    async GetYcysListAA({ dispatch, commit, state }, payload) {
        let result = await $GET('EvaluationAnalysis/GetYcysList', payload);
        return result.RESULTVALUE;
    },
    // 6.分析评估-评估对象：获取遗产要素组成列表
    async GetYcyszcList({ dispatch, commit, state }, payload) {
        let result = await $GET('EvaluationAnalysis/GetYcyszcList', payload);
        return result.RESULTVALUE;
    },
    // 6.分析评估-评估对象：获取监测对象列表
    async GetJcdxList({ dispatch, commit, state }, payload) {
        let result = await $GET('EvaluationAnalysis/GetJcdxList', payload);
        return result.RESULTVALUE;
    },
    // 6.分析评估-评估对象：获取评估数据列表
    async GetPgsjList({ dispatch, commit, state }, payload) {
        let result = await $GET('EvaluationAnalysis/GetPgsjList', payload);
        return result.RESULTVALUE;
    },
    // 6.分析评估-评估对象：获取相关数据列表
    async GetXgsjList({ dispatch, commit, state }, payload) {
        let result = await $GET('EvaluationAnalysis/GetXgsjList', payload);
        return result.RESULTVALUE;
    },

    // 6.分析评估-评估记录：获取评估记录信息列表
    async GetPgjlInfoList({ dispatch, commit, state }, payload) {
        let result = await $GET('EvaluationAnalysis/GetPgjlInfoList', payload);
        return result.RESULTVALUE;
    },
    // 6.分析评估-评估记录：新建/修改评估记录
    async AddPgjlInfo({ dispatch, commit, state }, payload) {
        let result = await $POST('EvaluationAnalysis/AddPgjlInfo', payload);
        return result;
    },
    // 6.分析评估-评估记录：删除评估记录信息
    async DeletePgjlInfo({ dispatch, commit, state }, payload) {
        let result = await $GET('EvaluationAnalysis/DeletePgjlInfo', payload);
        return result;
    },

    // 6.分析评估-评估配置：获取评估配置列表
    async GetPgpzInfoList({ dispatch, commit, state }, payload) {
        let result = await $GET('EvaluationAnalysis/GetPgpzInfoList', payload);
        return result.RESULTVALUE;
    },
    // 6.分析评估-评估配置：删除评估配置
    async DeletePgpzInfo({ dispatch, commit, state }, payload) {
        let result = await $GET('EvaluationAnalysis/DeletePgpzInfo', payload);
        return result;
    },
    // 6.分析评估-评估配置：获取评估人员列表
    async GetPgryList({ dispatch, commit, state }, payload) {
        let result = await $GET('EvaluationAnalysis/GetPgryList', payload);
        return result.RESULTVALUE;
    },
    // 6.分析评估-评估配置：新建/修改评估配置
    async AddPgpzInfo({ dispatch, commit, state }, payload) {
        let result = await $POST('EvaluationAnalysis/AddPgpzInfo', payload);
        return result;
    },
    // 6.分析评估-评估配置：更新评估配置状态
    async UpdatePgpzzt({ dispatch, commit, state }, payload) {
        let result = await $GET('EvaluationAnalysis/UpdatePgpzzt', payload);
        return result;
    },
    // 6.分析评估-评估配置：获取评估配置详情
    async GetPgpzInfoDetail({ dispatch, commit, state }, payload) {
        let result = await $GET('EvaluationAnalysis/GetPgpzInfoDetail', payload);
        return result.RESULTVALUE;
    },


    // 8.工作台面-分析评估：获取评估任务列表
    async GetPgrwList({ dispatch, commit, state }, payload) {
        let result = await $GET('EvaluationAnalysis/GetPgrwList', payload);
        if (result && result.ISSUCCESS && result.RESULTVALUE) {
            commit(Types.SET_UNDONEDATA, result.RESULTVALUE.RESULT);
            commit(Types.SET_UNDONETOTAL, result.RESULTVALUE.COUNT);
        } else {
            commit(Types.SET_UNDONEDATA, []);
            commit(Types.SET_UNDONETOTAL, 0);
        }
    },
    // 8.工作台面-分析评估：获取历史评估结果列表
    async GetPgjgList({ dispatch, commit, state }, payload) {
        let result = await $GET('EvaluationAnalysis/GetPgjgList', payload);
        if (result && result.ISSUCCESS && result.RESULTVALUE) {
            commit(Types.SET_DONEDATA, result.RESULTVALUE.RESULT);
            commit(Types.SET_DONETOTAL, result.RESULTVALUE.COUNT);
        } else {
            commit(Types.SET_DONEDATA, []);
            commit(Types.SET_DONETOTAL, 0);
        }
    },
    // 8.工作台面-分析评估：获取各项来源监测对象列表
    async GetJcdxListByPgrw({ dispatch, commit, state }, payload) {
        let result = await $GET('EvaluationAnalysis/GetJcdxListByPgrw', payload);
        return result.RESULTVALUE;
    },
    // 8.工作台面-分析评估：获取各项来源相关数据列表
    async GetXgxdxListByPgrw({ dispatch, commit, state }, payload) {
        let result = await $GET('EvaluationAnalysis/GetXgxdxListByPgrw', payload);
        return result.RESULTVALUE;
    },
    // 8.工作台面-分析评估：获取监测数据信息
    async GetJcsjInfo({ dispatch, commit, state }, payload) {
        let result = await $GET('EvaluationAnalysis/GetJcsjInfo', payload);
        return result.RESULTVALUE;
    },
    // 8.工作台面-分析评估：获取评估数据信息
    async GetPgsjInfo({ dispatch, commit, state }, payload) {
        let result = await $GET('EvaluationAnalysis/GetPgsjInfo', payload);
        return result.RESULTVALUE;
    },
    // 8.工作台面-分析评估：获取相关数据信息
    async GetXgsjInfo({ dispatch, commit, state }, payload) {
        let result = await $POST('EvaluationAnalysis/GetXgsjInfo', payload);
        return result.RESULTVALUE;
    },
    // 8.工作台面-分析评估：根据任务id获取监测对象列表
    async GetJcdxListByRwid({ dispatch, commit, state }, payload) {
        let result = await $GET('EvaluationAnalysis/GetJcdxListByRwid', payload);
        return result.RESULTVALUE;
    },
    // 8.工作台面-分析评估：保存评估结果与评估明细信息
    async AddPgjgAndPgjgmxInfo({ dispatch, commit, state }, payload) {
        let result = await $POST('EvaluationAnalysis/AddPgjgAndPgjgmxInfo', payload);
        return result;
    },
    // 8.工作台面-分析评估：获取评估结果信息明细
    async GetPgjgmxInfo({ dispatch, commit, state }, payload) {
        let result = await $GET('EvaluationAnalysis/GetPgjgmxInfo', payload);
        return result.RESULTVALUE;
    },
    // 8.查询监测数据图片数据信息
    async GetJcsjDataByPic({ dispatch, commit, state }, payload) {
        let result = await $GET('EvaluationAnalysis/GetJcsjDataByPic', payload);
        return result.RESULTVALUE;
    },


    // 7.分析评估-评估结果：根据评估结果信息获取评估对象列表
    async GetPgdxListByPgjg({ dispatch, commit, state }, payload) {
        let result = await $GET('EvaluationAnalysis/GetPgdxListByPgjg', payload);
        return result.RESULTVALUE;
    },
    // 7.分析评估-评估结果：获取评估结果信息
    async GetPgjgInfo({ dispatch, commit, state }, payload) {
        let result = await $GET('EvaluationAnalysis/GetPgjgInfo', payload);
        return result.RESULTVALUE;
    },


    // 4.分析评估-报告管理：获取报告列表
    async GetReportList({ dispatch, commit, state }, payload) {
        let result = await $GET('EvaluationAnalysis/GetReportList', payload);
        return result.RESULTVALUE;
    },
    // 4.分析评估-报告管理：获取报告详情
    async GetReportDetails({ dispatch, commit, state }, payload) {
        let result = await $GET('EvaluationAnalysis/GetReportDetails', payload);
        return result.RESULTVALUE;
    },
    // 4.分析评估-报告管理：保存报告
    async SaveReport({ dispatch, commit, state }, payload) {
        let result = await $POST('EvaluationAnalysis/SaveReport', payload);
        return result;
    },
    // 4.分析评估-报告管理：删除报告
    async DelReport({ dispatch, commit, state }, payload) {
        let result = await $POST('EvaluationAnalysis/DelReport', payload);
        return result;
    },
    // 4.分析评估-报告管理：删除附件
    async DelFile({ dispatch, commit, state }, payload) {
        let result = await $POST('EvaluationAnalysis/DelFile', payload);
        return result;
    },
    // 4.分析评估-报告管理：获取对象列表
    async GetReportPgdxEnum({ dispatch, commit, state }, payload) {
        let result = await $GET('EvaluationAnalysis/GetReportPgdxEnum', payload);
        return result.RESULTVALUE;
    },

    // 4.分析评估-报告管理：获取监测区域
    async GetChbgJcqy({ dispatch, commit, state }, payload) {
        let result = await $GET('ImportChbgExcel/GetChbgJcqy', payload);
        return result.RESULTVALUE;
    },
    // 4.分析评估-报告管理：获取测绘报告的测点编号
    async GetChbgCdbh({ dispatch, commit, state }, payload) {
        let result = await $GET('ImportChbgExcel/GetChbgCdbh', payload);
        return result.RESULTVALUE;
    },
    // 4.分析评估-报告管理：获取测绘报告数据
    async GetChbgDataList({ dispatch, commit, state }, payload) {
        let result = await $GET('ImportChbgExcel/GetChbgDataList', payload);
        return result.RESULTVALUE;
    },
    // 4.分析评估-报告管理：导入报告
    async ImportExcel({ dispatch, commit, state }, payload) {
        let result = await $POST('ImportChbgExcel/ImportExcel', payload);
        return result.RESULTVALUE;
    },
    // 5.分析评估-报告管理：导出报告
    async ExportBasicData({ dispatch, commit, state }, payload) {
        let result = await $GET('GatherPart/ExportBasicData', payload);
        return result.RESULTVALUE;
    },


};
export default {
    state,
    getters,
    mutations,
    actions
};